<script>
  import copy from 'clipboard-copy';
  import { CopyButton } from 'carbon-components-svelte';

  export let replacements = [];
</script>

<div class="replacements">
  <h3 class="h3">Replacements</h3>
  <ul class="list">
    {#each replacements as { name, value }}
      <li class="replacement">
        <div class="details">
          <strong class="title">{name}</strong>
          <code>{value}</code>
        </div>
        <CopyButton text={value} feedback="Copied to clipboard" copy={(text) => copy(text)} />
      </li>
    {/each}
  </ul>
</div>

<style>
  .h3 {
    font-size: 1.25rem;
    margin: 0 0.5rem;
  }

  .replacements {
    position: sticky;
    top: 0;
    margin-top: 0.5rem;
    padding: 0 1rem;
  }

  .list {
    margin-top: 1rem;
  }

  .replacement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }

  .details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .details :global(code) {
    font-family: monospace !important;
    margin-top: 0.3rem;
    padding: 0.3rem 0.6rem;
    border-radius: 2px;
    font-size: 0.8rem;
    background-color: var(--cds-background-hover);
    overflow-y: auto;
    white-space: nowrap;
    max-width: 95%;
    user-select: all;
    cursor: text;
  }
</style>
