<script>
  import ChangedInstancePermissionsDiff from '../../pages/groups/group-permissions/instances/ChangedInstancePermissionsDiff.svelte';

  export let eventData;
</script>

{#if eventData.created?.length}
  <h6>Added:</h6>
  {#each eventData.created as { name, instancePermissionsDifference }}
    <div>
      {name}{#if Object.keys(instancePermissionsDifference).length > 0}:
        <ChangedInstancePermissionsDiff {...instancePermissionsDifference} />
      {/if}
    </div>
  {/each}
{/if}
{#if eventData.updated?.length}
  <h6>Updated:</h6>
  {#each eventData.updated as { name, instancePermissionsDifference }}
    <div>
      {name}{#if Object.keys(instancePermissionsDifference).length > 0}:
        <ChangedInstancePermissionsDiff {...instancePermissionsDifference} />
      {/if}
    </div>
  {/each}
{/if}
{#if eventData.deleted?.length}
  <h6>Deleted:</h6>
  <div>{eventData.deleted.join(', ')}</div>
{/if}
