import { readable } from 'svelte/store';

import defer from 'lodash/defer';

import { ROOT_SERVER_URL } from '../config';

const SUPPORTED_UPLOAD_DESTINATIONS = {
  sftp05: 'SFTP05',
  crushFTP: 'CRUSHFTP',
};

export const appConfig = readable({ loading: true }, function start(set) {
  try {
    defer(async () => {
      const config = await fetch(`${ROOT_SERVER_URL}/open-api/config`).then((response) => response.json());
      set({
        data: {
          ...config,
          supportedUploadDestinations: config.flags.crushFTPEnabled ? SUPPORTED_UPLOAD_DESTINATIONS : { sftp05: 'SFTP05' },
        },
        loading: false,
      });
    });
  } catch (error) {
    console.error('[appConfig] Failed to retrieve app configuration from server!', error);
    set({ loading: false, error });
  }
});
