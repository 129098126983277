import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function changeCategoryDefault(categoryId, changes) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/categories/${categoryId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function createEmailTemplate(data) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates`, {
    data,
    method: 'POST',
  });
}

export function deleteEmailTemplate(templateId) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/${templateId}`, {
    method: 'DELETE',
  });
}

export function getEmailReplacementsForCategory(categoryId) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/categories/${categoryId}/replacements`);
}

export function getEmailTemplate(templateId) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/categories/${templateId}`);
}

export function getEmailTemplateCategories() {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/categories`);
}

export function getEmailTemplates(categoryId) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/categories/${categoryId}`);
}

export function parseEmailTemplates(categoryId, entityId, data) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/categories/${categoryId}/${entityId}`, {
    data,
    method: 'POST',
  });
}

export function parseEmailTemplate(data) {
  return request(`${ROOT_SERVER_URL}/api/emails/messages/parse`, {
    data,
    method: 'POST',
  });
}

export function sendEmail(data) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/email`, {
    data,
    method: 'POST',
  });
}

export function updateEmailTemplate(templateId, changes) {
  return request(`${ROOT_SERVER_URL}/api/emails/templates/${templateId}`, {
    data: changes,
    method: 'PUT',
  });
}
