<script>
  import * as yup from 'yup';
  import { createEventDispatcher } from 'svelte';

  import { Form } from '@mst-fe/sveltejs-forms';
  import { FormGroup, TextArea, TextInput, Toggle } from '@mst-fe/carbon-components-svelte';
  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { createEmailTemplate, updateEmailTemplate } from '../../services';

  export let template,
    categoryId,
    open = false;

  let loading = false,
    resultNotification,
    formSubmitButtonRef;

  const initialValues = template
    ? {
        name: template.name,
        description: template.description,
        isPrivate: !!template.isPrivate,
      }
    : null;

  const dispatch = createEventDispatcher();

  async function handleFormSubmission({ detail: { values } }) {
    loading = true;

    try {
      const data = await (template ? updateEmailTemplate(template.id, values) : createEmailTemplate({ categoryId, ...values }));
      dispatch('close', data);
    } catch (error) {
      console.error('[AddOrEditEmailTemplateModal] Failed to create/edit email template!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to create/edit email template! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function handleCustomChangeEvent({ key, setValue }) {
    return function onCustomChange({ detail: value }) {
      setValue(key, value);
    };
  }

  function handleChangeEvent({ key, setValue }) {
    return function onChange({ target: { value } }) {
      setValue(key, value);
    };
  }

  function handleToggleEvent({ key, setValue }) {
    return function onToggle({ detail: { toggled } }) {
      setValue(key, toggled);
    };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: schema = yup.object().shape({
    name: yup.string().required('Template name cannot be empty'),
    description: yup.string().nullable(),
    isPrivate: yup.boolean(),
  });
</script>

<Modal
  modalHeading={`${template ? 'Edit' : 'Create'} Email Template`}
  preventCloseOnClickOutside={true}
  primaryButtonText={template ? 'Save' : 'Create'}
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <Form
      initialValues={initialValues ?? { isPrivate: false }}
      {schema}
      let:submitForm
      let:setValue
      let:touched
      let:values
      let:errors
      on:submit={handleFormSubmission}
    >
      <FormGroup>
        <TextInput
          name="name"
          autocomplete="off"
          invalid={touched.name && !!errors.name}
          invalidText={errors.name}
          labelText="Name"
          placeholder="Template name"
          value={values.name}
          required
          on:change={handleCustomChangeEvent({ key: 'name', setValue })}
        />
        <TextArea
          name="description"
          labelText="Description"
          placeholder="Feature description"
          value={values.description}
          on:change={handleChangeEvent({ key: 'description', setValue })}
        />
        <Toggle
          name="isPrivate"
          labelA="Private"
          labelB="Private"
          toggled={values.isPrivate}
          on:toggle={handleToggleEvent({ key: 'isPrivate', setValue })}
        />
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>
