<script>
  import { Tooltip } from 'carbon-components-svelte';
  import truncate from 'lodash/truncate';

  export let text;
</script>

<div class="long-text-tooltip-wrapper">
  <Tooltip hideIcon triggerText={truncate(text)}>
    <slot>
      <span>{text}</span>
    </slot>
  </Tooltip>
</div>

<style>
  .long-text-tooltip-wrapper {
    cursor: pointer;
  }
  .long-text-tooltip-wrapper :global(.bx--tooltip__label) {
    font-size: 0.875rem;
  }
</style>
