<script>
  import { createEventDispatcher } from 'svelte';

  import {
    DataTable,
    InlineNotification,
    Modal,
    Tabs,
    Tab,
    TabContent,
    TextArea,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
  } from 'carbon-components-svelte';

  import CustomParameterValueCell from './CustomParameterValueCell.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { createBuildConfig, addBuildParameters } from '../../services';
  import { rowContainsText } from '../../utils';

  export let targetName,
    configId,
    entityType,
    entityName,
    entityId,
    open = false;

  let loading = false,
    resultNotification,
    validJson = false,
    stringifiedJson = '',
    json = {},
    searchText = '';

  const dispatch = createEventDispatcher();
  const tableHeaders = [
    { key: 'parameterName', value: 'Parameter Name' },
    { key: 'parameterValue', value: 'Parameter Value' },
  ];
  const CUSTOM_CELL_COMPONENTS = {
    parameterValue: CustomParameterValueCell,
  };

  function onSearchTextChange({ target }) {
    searchText = target?.value?.trim() ?? '';
  }

  function handleTextAreaInput(event) {
    let finalValue = event.target.value;

    try {
      json = JSON.parse(finalValue);
      finalValue = JSON.stringify(json, null, 2);
      validJson = true;
    } catch (_) {
      json = {};
      validJson = false;
    }

    stringifiedJson = finalValue;
  }

  async function createDefaultConfig() {
    try {
      const buildConfig = await createBuildConfig({
        ownerType: entityType,
        ownerId: entityId,
        name: 'Default',
        isDefault: true,
      });

      return buildConfig;
    } catch (error) {
      console.error('[JsonBuildInputModal] Failed to create default build config!', error);
      return {};
    }
  }

  async function addParameters() {
    loading = true;

    try {
      if (!configId) {
        const configDetails = await createDefaultConfig();
        configId = configDetails.id;
      }

      const createdParameters = await addBuildParameters({ configId, parameters });

      dispatch('close', { id: configId, parameters: createdParameters });
    } catch (error) {
      console.error('[JsonBuildInputModal] Failed to add parameters!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to add parameters! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  $: parameters = Object.keys(json).map((parameterName) => ({ id: parameterName, parameterName, parameterValue: json[parameterName] }));
  $: filteredRows = searchText ? parameters.filter((param) => rowContainsText(param, searchText)) : parameters;
</script>

<Modal
  modalHeading="Import JSON build configuration"
  preventCloseOnClickOutside={true}
  shouldSubmitOnEnter={false}
  primaryButtonText="Import"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={!validJson || !parameters.length}
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={addParameters}
>
  <LoadingSpinner small {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are about to add build parameters under the {entityType} <strong>{entityName}</strong>
      {#if targetName}
        for the <strong>{targetName}</strong> target
      {/if}
    </p>
    <Tabs>
      <Tab label="JSON input" />
      <Tab label="Table view" disabled={!validJson || !parameters.length} />
      <svelte:fragment slot="content">
        <TabContent>
          <TextArea
            labelText="JSON build configuration"
            placeholder="Paste JSON build configuration"
            invalid={stringifiedJson.length && !validJson}
            invalidText="Invalid JSON"
            on:input={handleTextAreaInput}
            value={stringifiedJson}
          />
        </TabContent>
        <TabContent>
          <DataTable sortable headers={tableHeaders} rows={filteredRows}>
            <span slot="cell" let:cell>
              {#if CUSTOM_CELL_COMPONENTS[cell.key]}
                <svelte:component this={CUSTOM_CELL_COMPONENTS[cell.key]} value={cell.value} />
              {:else}
                {cell.value}
              {/if}
            </span>
            <Toolbar>
              <ToolbarContent>
                <ToolbarSearch
                  placeholder="Search for parameters"
                  persistent
                  value={searchText}
                  on:clear={onSearchTextChange}
                  on:input={onSearchTextChange}
                />
              </ToolbarContent>
            </Toolbar>
          </DataTable>
        </TabContent>
      </svelte:fragment>
    </Tabs>
  </LoadingSpinner>
</Modal>
