<script>
  import { createEventDispatcher } from 'svelte';
  import { Checkbox, InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { makePlanActive, makePlanInactive } from '../../services';

  export let open = false,
    plan = {};

  const dispatch = createEventDispatcher();

  let confirmation = false,
    loading = false,
    resultNotification = null;

  async function onModalSubmit() {
    loading = true;
    resultNotification = null;

    try {
      const updatedPlans = await (plan.active ? makePlanInactive(plan.id) : makePlanActive(plan.id));
      dispatch('close', updatedPlans);
    } catch (error) {
      console.error('[WbPlanEditModal] Failed to edit plan', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to change plan status. Please try again later!`,
      };
    } finally {
      loading = false;
    }
  }
</script>

<div class="modal">
  <Modal
    danger
    modalHeading={plan.active ? 'Deactivate Plan' : 'Activate Plan'}
    preventCloseOnClickOutside={true}
    shouldSubmitOnEnter={false}
    primaryButtonText="Confirm"
    primaryButtonDisabled={!confirmation}
    secondaryButtonText="Cancel"
    bind:open
    on:click:button--secondary={() => dispatch('close')}
    on:close
    on:submit={onModalSubmit}
  >
    <LoadingSpinner {loading}>
      {#if resultNotification}
        <InlineNotification
          hideCloseButton
          kind={resultNotification.kind}
          lowContrast
          title={resultNotification.title}
          subtitle={resultNotification.subtitle}
        />
      {/if}
      <p class="main">
        {#if plan.active}
          You are about to deactivate this plan. <strong>No plans for this date will be active after this action takes place.</strong>
        {:else}
          You are about to activate this plan. If another plan with <strong>same start date</strong> is already active, it will be
          <strong>deactivated</strong>.
        {/if}
      </p>
      <Checkbox labelText="I understand and I want to proceed" bind:checked={confirmation} />
    </LoadingSpinner>
  </Modal>
</div>

<style>
  .modal :global(.bx--modal-content) {
    margin-bottom: 1rem;
  }

  .modal :global(p.main) {
    margin-bottom: 1rem;
  }
</style>
