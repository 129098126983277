<script>
  import { createEventDispatcher } from 'svelte';

  import { TextInput } from '@mst-fe/carbon-components-svelte';

  import LoadingSpinner from './LoadingSpinner.svelte';

  export let invalid, name, invalidText, labelText, placeholder, value, required, autocomplete, loading, suggestions, focusOnMount;

  const dispatch = createEventDispatcher();

  let suggestionsActive = false,
    inputElement;

  function handleOptionKeyDown(event) {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const { id, value: selectedValue } = event.target.dataset;

    dispatch('select', { id, value: selectedValue });
    inputElement.focus();
  }

  function handleInput({ detail: event }) {
    dispatch('input', event);
  }

  function handleChange({ detail: event }) {
    dispatch('change', event);
  }

  function handleKeyDown(event) {
    dispatch('keydown', event);
  }

  function handleBlur(event) {
    if (event.relatedTarget?.dataset && 'isListItem' in event.relatedTarget.dataset) {
      return;
    }

    dispatch('blur', event);
  }

  function handleOptionClick(selection) {
    return function selectItem() {
      dispatch('select', selection);
      inputElement.focus();
    };
  }

  $: suggestionsActive = suggestions.length || loading;
  $: if (focusOnMount && inputElement) {
    inputElement.focus();
  }
</script>

<div class="input-wrapper">
  <TextInput
    {name}
    {invalid}
    {invalidText}
    {labelText}
    {placeholder}
    {value}
    {required}
    {autocomplete}
    on:input={handleInput}
    on:change={handleChange}
    on:keydown={handleKeyDown}
    on:blur={handleBlur}
    bind:ref={inputElement}
  />
  {#if suggestionsActive}
    <div role="listbox" aria-label="Choose an item" class="bx--list-box__menu">
      {#if loading}
        <div class="bx--list-box__menu-item passive">
          <LoadingSpinner withOverlay={false} compact />
        </div>
      {/if}
      {#each suggestions as match}
        <div
          class="bx--list-box__menu-item custom-item"
          on:click={handleOptionClick({ value: match.name || match.value, id: match.id })}
          on:keydown={handleOptionKeyDown}
        >
          <div
            tabindex="0"
            class="bx--list-box__menu-item__option custom-option"
            data-is-list-item
            data-value={match.name || match.value}
            data-id={match.id}
          >
            <div class="bx--form-item">{match.name || match.value}</div>
            {#if match.description}
              <span class="item-description">{match.description}</span>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  .input-wrapper {
    position: relative;
    margin-top: 0.75rem;
    width: 100%;
  }

  .input-wrapper .passive {
    pointer-events: none;
  }

  .input-wrapper :global(.bx--list-box__menu) {
    max-height: 200px;
  }

  .input-wrapper .custom-option,
  .input-wrapper .custom-item {
    height: 3.5rem;
  }

  .input-wrapper .item-description {
    font-style: italic;
    font-size: 12px;
    color: var(--cds-text-05);
  }
</style>
