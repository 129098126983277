import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function createOrganization(data) {
  return request(`${ROOT_SERVER_URL}/api/organizations`, {
    data,
    method: 'POST',
  });
}

export function getOrganization(organizationId) {
  return request(`${ROOT_SERVER_URL}/api/organizations/${organizationId}`);
}

export function getOrganizations() {
  return request(`${ROOT_SERVER_URL}/api/organizations`);
}

export function updateOrganization(organizationId, changes) {
  return request(`${ROOT_SERVER_URL}/api/organizations/${organizationId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function getOrganizationUsers(organizationId) {
  return request(`${ROOT_SERVER_URL}/api/organizations/${organizationId}/users`);
}
