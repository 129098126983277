<script>
  import { InlineNotification, NotificationActionButton, OverflowMenu, OverflowMenuItem } from 'carbon-components-svelte';

  export let kind,
    title,
    subtitle,
    actions = [],
    extraActions = [],
    dismissible = false,
    onClose = () => {};
</script>

<InlineNotification
  class="custom-inline-notification"
  hideCloseButton={!dismissible}
  lowContrast
  {...{ kind, title, subtitle }}
  on:close={onClose}
>
  <div slot="actions" class="actions">
    {#if actions.length}
      {#each actions as { text, onClick, disabled }}
        <NotificationActionButton on:click={onClick} {disabled}>
          {text}
        </NotificationActionButton>
      {/each}
    {/if}
    {#if extraActions.length}
      <OverflowMenu flipped>
        {#each extraActions as { text, onClick, disabled, danger }}
          <OverflowMenuItem {text} on:click={onClick} {disabled} {danger} />
        {/each}
      </OverflowMenu>
    {/if}
  </div>
</InlineNotification>

<style>
  :global(.custom-inline-notification .bx--overflow-menu) {
    width: 2rem;
    height: 2rem;
  }

  :global(.custom-inline-notification .bx--overflow-menu-options__btn) {
    max-width: unset;
  }

  :global(.custom-inline-notification .bx--overflow-menu-options) {
    min-width: 13rem;
  }
</style>
