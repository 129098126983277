<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { Accordion, AccordionItem, Checkbox, InlineNotification, Modal } from 'carbon-components-svelte';

  import WbUserLimitPlanForm from './WbUserLimitPlanForm.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { getOrganizationUsersWithWbAccess, updateUserLimits } from '../../services';

  export let open = false,
    organizationId,
    organizationName,
    plan = {};

  const dispatch = createEventDispatcher();

  let confirmation = false,
    loading = false,
    resultNotification = null,
    organizationUsers = [],
    planData = {
      users: Object.entries(plan.awsLoginLimitsPerUser).reduce(
        (allUsers, [userId, userData]) => ({
          ...allUsers,
          [userId]: {
            ...Object.entries(userData.limits).reduce(
              (allLimits, [limit, limitData]) => ({
                ...allLimits,
                [limit]: limitData.value,
                exceedAction: limitData.exceedAction,
              }),
              {}
            ),
          },
        }),
        {}
      ),
      invalid: false,
    };

  async function fetchDependencies() {
    loading = true;
    resultNotification = null;

    try {
      organizationUsers = await getOrganizationUsersWithWbAccess(organizationId);
    } catch (error) {
      console.error('[WbPlanEditLimitsModal] Failed to fetch dependencies', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to get users. Please try again later!`,
      };
    } finally {
      loading = false;
    }
  }

  async function onModalSubmit() {
    if (planData.invalid) {
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Limit edits are invalid. You cannot proceed.',
      };

      return;
    }

    loading = true;
    resultNotification = null;

    try {
      const updatedPlans = await updateUserLimits(plan.id, planData.users);
      dispatch('close', updatedPlans);
    } catch (error) {
      console.error('[WbPlanEditLimitsModal] Failed to edit user limits', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to change user limits. Please try again later!',
      };
    } finally {
      loading = false;
    }
  }

  onMount(fetchDependencies);

  $: selectedAmounts = plan?.awsResources.reduce(
    (all, resource) => ({
      ...all,
      [resource.resourceId]: resource.value,
    }),
    {}
  );
  $: totalAmount = plan?.awsResources.reduce((all, resource) => all + resource.value * resource.unitCharge, 0);
</script>

<div class="modal">
  <Modal
    modalHeading={`${plan.awsLoginResources.length ? 'Edit' : 'Create'} User Limits`}
    preventCloseOnClickOutside={true}
    shouldSubmitOnEnter={false}
    primaryButtonDisabled={!confirmation || planData.invalid}
    primaryButtonText="Confirm"
    secondaryButtonText="Cancel"
    bind:open
    on:click:button--secondary={() => dispatch('close')}
    on:close
    on:submit={onModalSubmit}
  >
    <LoadingSpinner {loading}>
      {#if resultNotification}
        <InlineNotification
          hideCloseButton
          kind={resultNotification.kind}
          lowContrast
          title={resultNotification.title}
          subtitle={resultNotification.subtitle}
        />
      {/if}
      <div class="main">
        {#if !organizationUsers.length && !loading}
          <InlineNotification lowContrast hideCloseButton kind="warning">
            <strong slot="title"> No users with access to Workbench found under any of the organization's groups. </strong>
            <p slot="subtitle">
              To be able to edit user limits, you need to associate organization's users with groups that have Workbench permission enabled.
              Then, come back to this interface to edit their limits.
            </p>
          </InlineNotification>
        {:else if !totalAmount}
          <InlineNotification lowContrast hideCloseButton kind="warning">
            <strong slot="title"> No extra purchased resources for this Workbench subscription. </strong>
            <p slot="subtitle">You can only add user limits to extra purchased resources. The current plan has none.</p>
          </InlineNotification>
        {:else}
          <p>You are about to edit user limits for <strong>{organizationName}</strong>.</p>
          <Accordion size="sm">
            {#each organizationUsers as user}
              <AccordionItem>
                <svelte:fragment slot="title">
                  <h5>
                    {user.name}
                    <br />
                    {#each user.roles as role}
                      <em>({role.name} under {role.groupName} group)</em>
                    {/each}
                  </h5>
                </svelte:fragment>
                <div>
                  <WbUserLimitPlanForm {user} {selectedAmounts} onExceed={plan.exceedAction} bind:planData />
                </div>
              </AccordionItem>
            {/each}
          </Accordion>
          <Checkbox labelText="I understand and I want to proceed" disabled={planData.invalid} bind:checked={confirmation} />
        {/if}
      </div>
    </LoadingSpinner>
  </Modal>
</div>

<style>
  .modal :global(.bx--modal-content) {
    margin-bottom: 1rem;
  }

  .modal :global(p.main) {
    margin-bottom: 1rem;
  }

  .main :global(p) {
    margin-bottom: 1rem;
  }

  .main :global(.bx--checkbox-wrapper) {
    margin-top: 1rem;
  }

  .main :global(h5 em) {
    font-size: 0.75rem;
    font-weight: normal;
    margin-left: 0.25rem;
  }
</style>
