<script>
  import {
    Button,
    Column,
    FormGroup,
    Grid,
    InlineNotification,
    NotificationActionButton,
    Link,
    NumberInput,
    Row,
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
    TextArea,
    Checkbox,
  } from 'carbon-components-svelte';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';
  import Settings from 'carbon-icons-svelte/lib/Settings20';
  import copy from 'clipboard-copy';
  import ipRegex from 'ip-regex';
  import truncate from 'lodash/truncate';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import DeleteGroupModal from './DeleteGroupModal.svelte';
  import LoadingButton from '../../components/LoadingButton.svelte';

  import { appConfig } from '../../stores';
  import { updateGroup, getCrushFTPUser } from '../../services';
  import { convertToLocalDisplayTime, getServerErrorMessage } from '../../utils';
  import CreateCrushFTPUserModal from './CreateCrushFTPUserModal.svelte';
  import EditCrushFtpUserModal from './EditCrushFTPUserModal.svelte';

  export let groupId, group, loadGroupData;

  let formSubmitMessage,
    crushFtpAction,
    modals = {
      delete: { open: false },
      createCrushFTPUser: { open: false },
      editCrushFTPUser: { open: false },
    };

  const { crushFTPEnabled } = $appConfig.data.flags;
  const ipValidator = ipRegex({ exact: true });
  const navigate = useNavigate();
  const initialValues = {
    ...group,
    licenseLookbackDays: group.licenseLookbackDays ?? 15,
  };

  const schema = yup.object().shape({
    name: yup.string().required('Enter the group name!'),
    shortName: yup.string().nullable(),
    aliases: yup.string().nullable(),
    sourceIpAddresses: yup
      .string()
      .nullable()
      .test('valid-ip', 'All entries must be valid IP addresses!', (value) =>
        value ? value.split(',').every((entry) => ipValidator.test(entry.trim())) : true
      ),
    addIpToAuthorizedKeys: yup.boolean(),
    sftp05Username: yup.string().nullable(),
    crushftpUsername: yup.string().nullable(),
    crushftpIpAddresses: yup
      .string()
      .nullable()
      .test('valid-ip', 'All entries must be valid IP addresses!', (value) =>
        value ? value.split(',').every((entry) => ipValidator.test(entry.trim())) : true
      ),
    licenseLookbackDays: yup.number().min(0).integer(),
    notes: yup.string().nullable(),
  });

  async function crushFTPUserExists(crushftpUsername) {
    try {
      const crushFTPUser = await getCrushFTPUser(groupId, crushftpUsername);
      return !!crushFTPUser;
    } catch (error) {
      const statusCode = error?.response?.status;
      const userNotFound = statusCode === 404;
      const errorMessage = userNotFound ? 'User not found.' : 'Verify your submission and try again.';
      console.error('[GroupBasicInformation] Failed to retrieve CrushFTP user!', errorMessage);
      formSubmitMessage = {
        kind: userNotFound ? 'warning' : 'error',
        title: userNotFound ? '' : 'Error:',
        subtitle: `Failed to retrieve CrushFTP user! ${errorMessage}`,
      };
      return userNotFound ? false : null;
    }
  }

  async function handleCrushFTPUsernameChange(userName, setFormValue) {
    setFormValue('crushftpUsername', userName);
    if (!userName || userName === '') {
      return;
    }

    const crushFTPUser = await crushFTPUserExists(userName);
    if (crushFTPUser !== null) {
      handleClickForModal('createCrushFTPUser', { setFormValue, userName, userExists: crushFTPUser })();
    }
  }

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    formSubmitMessage = undefined;
    crushFtpAction = undefined;

    try {
      const result = await updateGroup(groupId, values);
      await loadGroupData();
      formSubmitMessage = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Group updated successfully!',
      };

      if (result.crushFtpPassword) {
        crushFtpAction = {
          crushFtpPassword: result.crushFtpPassword,
        };
      }
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Verify your submission and try again.';
      console.error('[GroupBasicInformation] Failed to update group data!', errorMessage);
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to update group! ${errorMessage}`,
      };
    } finally {
      setSubmitting(false);
      document.querySelector('#main-content').scrollTo(0, 0);
    }
  }

  function viewOrganizationDetails(event, organizationId) {
    event.preventDefault();
    navigate(`/organization/${organizationId}`);
  }

  function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'delete-group':
        formSubmitMessage = {
          kind: 'success',
          title: 'Success:',
          subtitle: `Group ${data.name} deleted successfully! Redirecting...`,
        };

        setTimeout(() => navigate('/groups'), 2500);
        break;
      case 'delete-crushFtpUser':
        formSubmitMessage = {
          kind: 'success',
          title: 'Success:',
          subtitle: `Group's CrushFTP user ${data.userName} deleted successfully!`,
        };

        crushFtpAction = null;
        break;
      case 'resetPass-crushFtpUser':
        formSubmitMessage = {
          kind: 'success',
          title: 'Success:',
          subtitle: "Group's CrushFTP user password reset successfully!",
        };

        crushFtpAction = {
          ...data,
        };
        break;
      default:
        console.warn(`[GroupBasicInformation] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function handleClickForModal(name, forwardData = {}) {
    return function openModal() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }
</script>

{#if formSubmitMessage}
  <InlineNotification kind={formSubmitMessage.kind} lowContrast title={formSubmitMessage.title} subtitle={formSubmitMessage.subtitle} />
{/if}
{#if crushFtpAction}
  <InlineNotification kind="warning" lowContrast hideCloseButton>
    <strong slot="title">
      {crushFtpAction.userName ? 'CrushFTP user updated:' : 'CrushFTP user created:'}
    </strong>
    <span slot="subtitle">
      Temporary password is <code class="psw">{crushFtpAction.crushFtpPassword}</code> and this is your
      <strong>only chance to save it</strong>.
    </span>
    <svelte:fragment slot="actions">
      <NotificationActionButton on:click={() => copy(crushFtpAction.crushFtpPassword)}>Copy to clipboard</NotificationActionButton>
    </svelte:fragment>
  </InlineNotification>
{/if}
<Grid noGutterLeft noGutterRight padding>
  <Row>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading">Read-Only Fields</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Group ID</StructuredListCell>
            <StructuredListCell>
              <div class="id-controls">
                {truncate(group.id, { length: 20 })}
                <Button
                  class="copy-button"
                  hasIconOnly
                  icon={Copy16}
                  iconDescription="Copy to clipboard"
                  kind="ghost"
                  size="small"
                  on:click={() => copy(group.id)}
                />
              </div>
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Parent Organization</StructuredListCell>
            <StructuredListCell>
              <Link
                href={`/organization/${group.organization?.id}`}
                on:click={(event) => viewOrganizationDetails(event, group.organization?.id)}
              >
                {group.organization?.name}
              </Link>
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
      <h2 class="column-heading">Metadata</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Created</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(group.createdAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Created By</StructuredListCell>
            <StructuredListCell>{group.createdByDisplayName}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Last Updated</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(group.updatedAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Updated By</StructuredListCell>
            <StructuredListCell>{group.updatedByDisplayName}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
    </Column>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading column-heading-form">Editable Fields</h2>
      <Form
        {schema}
        {initialValues}
        validateOnBlur={true}
        validateOnChange={true}
        on:submit={onFormSubmit}
        let:setValue
        let:values
        let:submitForm
        let:isSubmitting
        let:errors
        let:touched
      >
        <FormGroup>
          <TextInput
            helperText="Capitalized, user-friendly name unless purposely stylized otherwise by the customer. Example: Client Services."
            invalid={touched.name && !!errors.name}
            invalidText={errors.name}
            labelText="Group Name"
            name="name"
            placeholder="Group name"
            type="text"
            value={values.name}
            required
            on:change={({ detail: text }) => setValue('name', text)}
          />
          <TextInput
            helperText="Used to uniquely identify the group in email chains and Jenkins. May be combined with SFTP/SFTP05 username in the future. Example: mst_cs."
            invalid={touched.shortName && !!errors.shortName}
            invalidText={errors.shortName}
            labelText="Short Name"
            name="shortName"
            placeholder="Short name"
            type="text"
            value={values.shortName}
            on:change={({ detail: text }) => setValue('shortName', text)}
          />
          <TextInput
            helperText="Alternative or previous names for this group."
            invalid={touched.aliases && !!errors.aliases}
            invalidText={errors.aliases}
            labelText="Aliases"
            name="aliases"
            placeholder="Comma-separated aliases"
            type="text"
            value={values.aliases}
            on:change={({ detail: text }) => setValue('aliases', text)}
          />
          <NumberInput
            helperText="Used to prematurely notify that a license is about to expire."
            invalid={touched.licenseLookbackDays && !!errors.licenseLookbackDays}
            invalidText="License lookback days must be a positive integer!"
            label="License lookback days"
            min={0}
            name="licenseLookbackDays"
            placeholder="15"
            value={values.licenseLookbackDays}
            on:change={({ detail: value }) => setValue('licenseLookbackDays', value)}
            on:input={({ detail: value }) => setValue('licenseLookbackDays', value)}
          />
          <h3 class="column-subheading">SFTP05</h3>
          <TextInput
            invalid={touched.sftp05Username && !!errors.sftp05Username}
            invalidText="This SFTP05 username is already assigned to another group!"
            labelText="SFTP05 Username"
            name="sftp05Username"
            placeholder="SFTP05 username"
            type="text"
            value={values.sftp05Username}
            disabled={group.sftp05Username}
            on:change={({ detail: text }) => setValue('sftp05Username', text)}
          />
          <TextInput
            helperText="Use commas to separate entries."
            invalid={touched.sourceIpAddresses && !!errors.sourceIpAddresses}
            invalidText={errors.sourceIpAddresses}
            labelText="Source IP Addresses"
            name="sourceIpAddresses"
            placeholder="Source IP addresses"
            type="text"
            value={values.sourceIpAddresses}
            on:change={({ detail: text }) => setValue('sourceIpAddresses', text)}
          />
          <Checkbox
            name="addIpToAuthorizedKeys"
            labelText="Add source IP Addresses to authorized keys"
            checked={values.addIpToAuthorizedKeys}
            on:check={({ detail }) => setValue('addIpToAuthorizedKeys', detail)}
          />
          {#if crushFTPEnabled}
            <h3 class="column-subheading">CrushFTP</h3>
            <div class="crushftp-user">
              <TextInput
                invalid={touched.crushftpUsername && !!errors.crushftpUsername}
                invalidText="This CrushFTP username is already assigned to another group!"
                labelText="CrushFTP Username"
                name="crushftpUsername"
                placeholder="CrushFTP username"
                type="text"
                value={values.crushftpUsername}
                disabled={group.crushftpUsername}
                on:change={({ detail: text }) => handleCrushFTPUsernameChange(text, setValue)}
              />
              {#if group.crushftpUsername}
                <Button
                  icon={Settings}
                  hasIconOnly
                  kind="tertiary"
                  iconDescription="Edit User"
                  on:click={handleClickForModal('editCrushFTPUser', { setFormValue: setValue })}
                />
              {/if}
            </div>
            <TextInput
              helperText="Restrict unlisted IPs from connecting. If empty, every IP is allowed."
              invalid={touched.crushftpIpAddresses && !!errors.crushftpIpAddresses}
              invalidText={errors.crushftpIpAddresses}
              labelText="Source IP addresses"
              name="crushftpIpAddresses"
              placeholder="Source IP addresses"
              type="text"
              value={values.crushftpIpAddresses}
              on:change={({ detail: text }) => setValue('crushftpIpAddresses', text)}
            />
          {/if}
          <h3 class="column-subheading">Miscellaneous</h3>
          <TextArea
            invalid={touched.notes && !!errors.notes}
            invalidText={errors.notes}
            labelText="Notes"
            name="notes"
            placeholder="Additional notes"
            type="text"
            value={values.notes}
            on:change={({ target }) => setValue('notes', target.value)}
          />
        </FormGroup>
        <div class="form-controls">
          <LoadingButton kind="primary" on:click={submitForm} disabled={isSubmitting} isLoading={isSubmitting}>Save</LoadingButton>
        </div>
      </Form>
      <h2 class="column-heading column-heading-additional-actions">Additional Actions</h2>
      <div class="form-controls">
        <p class="text-info">
          Delete group and all related entities. <strong>Attention! This action is irreversible.</strong>
        </p>
        <Button kind="danger" on:click={handleClickForModal('delete')}>Delete Group</Button>
      </div>
    </Column>
  </Row>
</Grid>

{#if modals.delete.open}
  <DeleteGroupModal
    open
    groupId={group.id}
    groupName={group.name}
    on:close={({ detail: data }) =>
      closeModal('delete', {
        action: 'delete-group',
        data,
      })}
  />
{/if}

{#if modals.createCrushFTPUser.open}
  <CreateCrushFTPUserModal
    open
    userName={modals.createCrushFTPUser.userName}
    userExists={modals.createCrushFTPUser.userExists}
    on:cancel={() => {
      modals.createCrushFTPUser.setFormValue('crushftpUsername', null);
      closeModal('createCrushFTPUser');
    }}
    on:confirm={() => {
      closeModal('createCrushFTPUser');
    }}
  />
{/if}

{#if modals.editCrushFTPUser.open}
  <EditCrushFtpUserModal
    open
    groupId={group.id}
    userName={group.crushftpUsername}
    on:close={() => {
      closeModal('editCrushFTPUser');
    }}
    on:confirm={async ({ detail }) => {
      loadGroupData();
      if (detail.action === 'delete-crushFtpUser') {
        modals.editCrushFTPUser.setFormValue('crushftpUsername', null);
        modals.editCrushFTPUser.setFormValue('crushftpIpAddresses', null);
      }
      closeModal('editCrushFTPUser');
      document.querySelector('#main-content').scrollTo(0, 0);
      updatePageData(detail);
    }}
  />
{/if}

<style>
  /* For accessibility, keep the column-heading elements as h2 tags, but style them similar to h4 */
  .column-heading {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .column-heading + :global(.bx--structured-list) {
    margin-bottom: 2.5rem;
  }
  .column-heading + :global(.bx--structured-list:last-child) {
    margin-bottom: 0.5rem;
  }
  .column-heading + :global(.bx--structured-list .bx--structured-list-td) {
    width: 50%;
  }

  .column-heading-form + :global(.sveltejs-forms > .bx--fieldset > .bx--form-item:first-child) {
    margin: 0 0 0.75rem;
  }

  @media screen and (min-width: 66rem) {
    .column-heading-form {
      margin-bottom: 1.875rem;
    }
    .column-heading-form + :global(.sveltejs-forms) {
      margin-top: 0;
    }
  }

  .column-heading-additional-actions {
    margin-top: 2.5rem;
  }

  .column-subheading {
    font-size: 1rem;
    margin-top: 1.25rem;
  }

  .crushftp-user {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  .crushftp-user :global(.bx--btn) {
    max-height: 2.5rem;
    min-height: 2.5rem;
    margin-left: 10px;
  }

  .id-controls :global(.bx--btn) {
    min-height: unset;
    padding-top: 0;
  }
  .id-controls :global(.bx--btn svg > path) {
    fill: var(--cds-link-01) !important;
  }

  .text-info {
    color: var(--cds-text-02);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .psw {
    padding: 0.3em;
    border: 1px solid var(--cds-layer-selected-disabled);
    background-color: var(--cds-layer-selected);
    border-radius: 6px;
    font-family: monospace !important;
    font-size: 0.9em;
  }
</style>
