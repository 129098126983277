import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function getCacheStats() {
  return request(`${ROOT_SERVER_URL}/api/cache/stats`);
}

export function clearAuthorizationCache() {
  return request(`${ROOT_SERVER_URL}/api/cache/users`, {
    method: 'DELETE',
  });
}
