<script>
  import { diffArrays } from 'diff';

  export let originalArray = [],
    modifiedArray = [];

  $: diffs = diffArrays(originalArray, modifiedArray);
</script>

<div class="wrapper">
  <div class="main-array">
    {#each diffs as part}
      {#each part.value as value}
        <div class="value" class:removed={part.removed}>{part.added ? '' : value}</div>
      {/each}
    {/each}
  </div>
  <div class="secondary-array">
    {#each diffs as part}
      {#each part.value as value}
        <div class="value" class:added={part.added}>{part.removed ? '' : value}</div>
      {/each}
    {/each}
  </div>
</div>

<style>
  .value {
    height: 1.5rem;
    padding: 0.2rem;
  }

  .wrapper {
    display: flex;
    max-height: 500px;
    overflow-y: auto;
  }

  .main-array,
  .secondary-array {
    flex: 1;
  }

  .added {
    background-color: var(--cds-inverse-support-02);
    color: var(--cds-text-04);
  }

  .removed {
    background-color: var(--cds-support-error-inverse);
    color: var(--cds-text-04);
  }
</style>
