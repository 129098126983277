<script>
  import { Tooltip } from 'carbon-components-svelte';
  import View16 from 'carbon-icons-svelte/lib/View16';

  export let destinations;
</script>

<Tooltip triggerText={destinations.length} icon={View16}>
  <div class="values-list-wrapper">
    <p>List of destinations:</p>
    <ul class="values-list">
      {#each destinations as { path }}
        <li>{path}</li>
      {/each}
    </ul>
  </div>
</Tooltip>

<style>
  .values-list-wrapper {
    font-size: 12px;
  }

  .values-list-wrapper > :global(ul) {
    font-size: 12px;
    list-style: disc;
    overflow-y: auto;
    max-height: 250px;
    padding: 0.5rem 2rem;
    margin-top: 0.25rem;
  }
</style>
