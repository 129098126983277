import { format, addDays } from 'date-fns';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import CheckIcon from 'carbon-icons-svelte/lib/CheckmarkOutline16';
import ClockIconSmall from 'carbon-icons-svelte/lib/Time16';
import ErrorIcon from 'carbon-icons-svelte/lib/ErrorOutline16';
import WarningIcon from 'carbon-icons-svelte/lib/WarningAlt16';

import { LICENSE_DATE_FORMAT } from '../../shared/constants';

export const LicenseStatus = {
  WARNING: 'warning',
  DANGER: 'danger',
  ERROR: 'error',
  VALID: 'valid',
  EXPIRED: 'expired',
};

export const StatusIcon = {
  [LicenseStatus.WARNING]: WarningIcon,
  [LicenseStatus.ERROR]: ErrorIcon,
  [LicenseStatus.VALID]: CheckIcon,
  [LicenseStatus.EXPIRED]: ClockIconSmall,
};

export const findLicenseStatus = (currentLicense, lookbackDays) => {
  // most critical case: All selected features were deleted
  if (!currentLicense.fullFeatureList?.length) {
    return {
      status: LicenseStatus.ERROR,
      icon: StatusIcon[LicenseStatus.ERROR],
      message: 'No selected features',
    };
  }

  // no license generated yet
  if (!currentLicense.newestGeneratedLicense) {
    return {
      status: LicenseStatus.WARNING,
      icon: StatusIcon[LicenseStatus.WARNING],
      message: 'Requires generation',
    };
  }

  const today = format(new Date(), LICENSE_DATE_FORMAT);
  const lookAheadDate = format(addDays(new Date(), lookbackDays ?? 15), LICENSE_DATE_FORMAT);

  const { expirationDate, isPermanent } = currentLicense.newestGeneratedLicense;

  // check if actually expired
  if (!isPermanent && expirationDate <= today) {
    return {
      status: LicenseStatus.EXPIRED,
      icon: StatusIcon[LicenseStatus.EXPIRED],
      message: 'Expired',
    };
  }

  // about to expire, check lookback days for this
  if (!isPermanent && lookAheadDate >= expirationDate) {
    return {
      status: LicenseStatus.DANGER,
      icon: StatusIcon[LicenseStatus.EXPIRED],
      message: 'About to expire',
    };
  }

  if (!isEqual(sortBy(currentLicense.fullFeatureList, ['id']), sortBy(currentLicense.newestGeneratedLicense.features, ['id']))) {
    // TODO: add any other condition
    return {
      status: LicenseStatus.WARNING,
      icon: StatusIcon[LicenseStatus.WARNING],
      message: 'Modified since last generation',
    };
  }

  return {
    status: LicenseStatus.VALID,
    icon: StatusIcon[LicenseStatus.VALID],
    message: 'Active',
  };
};
