<script>
  import { Pagination } from 'carbon-components-svelte';

  export let page, pageSize, pageSizes, totalItems;

  const handlePopstate = () => {
    const url = new URL(window.location);
    page = parseInt(url.searchParams.get('page'), 10);
    pageSize = parseInt(url.searchParams.get('pageSize'), 10);
  };

  const synchronizePagination = (isInitial) => {
    const url = new URL(window.location);

    const currentUrlPage = parseInt(url.searchParams.get('page'), 10);
    const currentUIPage = parseInt(page, 10);
    const currentUrlPageSize = parseInt(url.searchParams.get('pageSize'), 10);
    const currentUIPageSize = parseInt(pageSize, 10);

    if (isInitial) {
      page = currentUrlPage || page;
      pageSize = currentUrlPageSize || pageSize;
      return;
    }

    if (currentUrlPage !== currentUIPage || currentUrlPageSize !== currentUIPageSize) {
      url.searchParams.set('page', currentUIPage);
      url.searchParams.set('pageSize', currentUIPageSize);
      if (!currentUrlPage || !currentUrlPageSize) {
        window.history.replaceState({}, '', url);
      } else {
        window.history.pushState({}, '', url);
      }
    }
  };

  synchronizePagination(true);

  // eslint-disable-next-line no-unused-expressions
  $: synchronizePagination(false) && page && pageSize && totalItems;
</script>

<svelte:window on:popstate={handlePopstate} />

{#if totalItems > 0}
  <Pagination bind:page bind:pageSize {pageSizes} {totalItems} on:update />
{/if}
