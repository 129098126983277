<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { useNavigate } from 'svelte-navigator';

  import { DataTable, Modal, Link } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import StatusIcon from '../../components/StatusIcon.svelte';
  import { getDataFeedSubscribers } from '../../services';

  export let open = false;
  export let dataFeedId;
  export let dataFeedName;
  export let permissionType;

  let loading = true;
  let subscriptions = [];

  const dispatch = createEventDispatcher();
  const navigate = useNavigate();

  const permissionTypesMap = {
    'data-feeds-lake': 'AWS Lake',
    'data-feeds-aws-arbitrated-pcaps': 'AWS Arbitrated PCAPs',
    'data-feeds-sftp05': 'SFTP05',
    'data-feeds-crushftp': 'CrushFTP',
  };

  onMount(async () => {
    subscriptions = await getDataFeedSubscribers(dataFeedId, permissionType);
    loading = false;
  });

  function viewOrganization(event, organizationId) {
    event.preventDefault();
    navigate(`/organization/${organizationId}`);
  }

  function viewGroup(event, groupId) {
    event.preventDefault();
    navigate(`/group/${groupId}`);
  }
</script>

<Modal
  class="subscribers-modal"
  bind:open
  on:close
  size="lg"
  modalHeading={`${dataFeedName} (${permissionTypesMap[permissionType]})`}
  modalLabel="Data Feed Subscribers"
  primaryButtonText="Close"
  preventCloseOnClickOutside={true}
  on:click:button--primary={() => dispatch('close')}
>
  <LoadingSpinner {loading}>
    {#if !subscriptions.length}
      No subscribers.
    {:else}
      <DataTable
        headers={[
          { key: 'organizationName', value: 'Organization Name' },
          { key: 'groupName', value: 'Group Name' },
          { key: 'startDate', value: 'Start Date' },
          { key: 'endDate', value: 'End Date' },
          { key: 'isOngoing', value: 'Is Ongoing' },
        ]}
        rows={subscriptions}
        sortable
      >
        <span slot="cell" let:cell let:row>
          {#if cell.key === 'isOngoing'}
            <StatusIcon valid={cell.value} />
          {:else if cell.key === 'organizationName'}
            <Link href={`/organization/${row.organizationId}`} on:click={(event) => viewOrganization(event, row.organizationId)}>
              {cell.value}
            </Link>
          {:else if cell.key === 'groupName'}
            <Link href={`/group/${row.groupId}`} on:click={(event) => viewGroup(event, row.groupId)}>
              {cell.value}
            </Link>
          {:else}
            {cell.value}
          {/if}
        </span>
      </DataTable>
    {/if}
  </LoadingSpinner>
</Modal>

<style>
  :global(.subscribers-modal .bx--modal-content) {
    overflow-y: hidden;
  }
</style>
