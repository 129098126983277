<script>
  import sortBy from 'lodash/sortBy';
  import uniqBy from 'lodash/uniqBy';

  import { StructuredList, StructuredListRow, StructuredListCell, StructuredListBody } from 'carbon-components-svelte';

  import { extractFeaturesAndGroupsWithDetails } from './AddLicenseModal.svelte';

  export let treeData = [],
    checkedItemIds = [];

  function findActualFeatures(features) {
    return features.reduce((allFeatures, feature) => {
      const featureDetails = feature.featureDetails ?? feature;

      if (!featureDetails.isCustom) {
        return [...allFeatures, featureDetails];
      }

      const actualFeature = featureDetails.featureGroups.find((group) => group.isFeedHandler);

      if (actualFeature) {
        return [...allFeatures, actualFeature];
      }

      return allFeatures;
    }, []);
  }

  function extractFeaturesFromGroups(featureGroups) {
    return featureGroups.reduce((allFeatures, group) => {
      const groupDetails = group.featureGroupDetails ?? group;
      const [newFeatures, newGroups] = (groupDetails.children ?? []).reduce(
        (all, child) => {
          if (child.type === 'feature') {
            all[0].push(child);
            return all;
          }

          all[1].push(child);
          return all;
        },
        [[], []]
      );

      if (newGroups) {
        newFeatures.push(...extractFeaturesFromGroups(newGroups));
      }

      const finalFeatures = newFeatures.reduce((all, feature) => {
        if (!feature.isCustom) {
          return [...all, feature];
        }

        const finalFeature = feature.featureGroups.find((details) => details.isFeedHandler);

        if (finalFeature) {
          return [...all, finalFeature];
        }

        return all;
      }, []);

      return [...allFeatures, ...finalFeatures];
    }, []);
  }

  $: selectedFeaturesAndGroups = extractFeaturesAndGroupsWithDetails(checkedItemIds, treeData);
  $: selections = sortBy(
    uniqBy(
      [...findActualFeatures(selectedFeaturesAndGroups.features), ...extractFeaturesFromGroups(selectedFeaturesAndGroups.groups)],
      'name'
    ),
    'name'
  );
</script>

<div class="selections">
  <div class="title">
    <h4>{selections.length} {selections.length === 1 ? 'Selection' : 'Selections'}</h4>
  </div>
  <StructuredList condensed>
    <StructuredListBody>
      {#each selections as selection}
        <StructuredListRow>
          <StructuredListCell noWrap>{selection.name}</StructuredListCell>
        </StructuredListRow>
      {/each}
    </StructuredListBody>
  </StructuredList>
</div>

<style>
  .selections {
    max-height: 300px;
    overflow-y: auto;
    margin-left: 1rem;
  }

  .title {
    position: sticky;
    top: 0;
    background-color: var(--cds-ui-01);
    z-index: 1;
  }
</style>
