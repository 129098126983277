<script>
  import { onMount } from 'svelte';
  import { useNavigate } from 'svelte-navigator';

  import CopyIcon from 'carbon-icons-svelte/lib/CopyFile32';
  import PlusIcon from 'carbon-icons-svelte/lib/AddAlt32';

  import CustomInlineNotification from '../../components/CustomInlineNotification.svelte';
  import GroupLicenseListModals from '../../components/licenses/GroupLicenseListModals.svelte';
  import LicenseTemplateCard from '../../components/licenses/LicenseTemplateCard.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';

  import { appConfig } from '../../stores';
  import { getLicenseTemplates } from '../../services';

  export let groupId, groupName, sftp05Username, crushftpUsername, shortName, licenseLookbackDays;

  let pageData = {
    licenses: [],
    loading: false,
    notifications: new Set(),
    focusedTemplateId: null,
  };

  let modals = {
    addOrEditNote: { open: false },
    createLicense: { open: false },
    copyLicense: { open: false },
    generateLicense: { open: false },
    editBasicLicenseInfo: { open: false },
    editFeatures: { open: false },
    deleteTemplate: { open: false },
    viewLicense: { open: false },
  };

  const {
    defaultCrushftpDestination,
    defaultSftp05Destination,
    flags: { crushFTPEnabled },
  } = $appConfig.data;
  const navigate = useNavigate();
  const defaultDestinations =
    !crushftpUsername && sftp05Username ? [`sftp05:${defaultSftp05Destination}/${sftp05Username}/data/licenses`] : [];

  if (crushFTPEnabled && crushftpUsername) {
    defaultDestinations.push(`crushFTP:${defaultCrushftpDestination}/${crushftpUsername}/data/licenses`);
  }

  const notifications = {
    licensesLoadFail: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load licenses list! Please try again later.',
    },
    noLicenses: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no licenses for this group.',
      actions: [
        {
          text: 'Add license',
          onClick: () => openModal('createLicense'),
        },
      ],
    },
    licenseNotFound: {
      kind: 'warning',
      subtitle: "Cannot find template. It's either deleted or ID is not valid for current group.",
      dismissible: true,
      onClose: () => navigate(`/group/${groupId}#licenses`),
    },
    licenseCreated: {
      kind: 'success',
      subtitle: 'Successfully created license.',
      dismissible: true,
      actions: [
        {
          text: 'Check details',
          onClick: () => navigate('/licenses'),
        },
      ],
    },
  };

  async function fetchLicenses() {
    pageData = {
      ...pageData,
      focusedTemplateId: new URLSearchParams(window.location.search).get('template'),
      loading: true,
    };

    try {
      const licenses = await getLicenseTemplates({ ownerId: groupId });
      const notificationAction = licenses.length ? 'delete' : 'add';

      pageData.notifications[notificationAction]('noLicenses');
      pageData.notifications.delete('licensesLoadFail');
      pageData = {
        ...pageData,
        licenses,
      };
    } catch (error) {
      console.error('[GroupLicensesList] Failed to load licenses!', error);
      pageData.notifications.add('licensesLoadFail');
    } finally {
      pageData = {
        ...pageData,
        loading: false,
      };
    }
  }

  function updatePageData(modification) {
    const { action, data } = modification;
    let updatedLicenses;

    switch (action) {
      case 'create-license':
        if (!data) {
          break;
        }

        pageData.notifications.delete('noLicenses');

        pageData = {
          ...pageData,
          licenses: [...pageData.licenses, data],
        };

        break;

      case 'delete-template':
        if (!data) {
          break;
        }

        updatedLicenses = pageData.licenses.filter(({ id: templateId }) => templateId !== data.id);

        if (!updatedLicenses.length) {
          pageData.notifications.add('noLicenses');
        }

        pageData = {
          ...pageData,
          licenses: updatedLicenses,
        };

        break;

      case 'edit-license-note':
      case 'edit-license-feature':
      case 'edit-license-basic-info':
        if (!data) {
          break;
        }

        updatedLicenses = pageData.licenses.map((license) => (license.id === data.id ? data : license));

        pageData = {
          ...pageData,
          licenses: updatedLicenses,
        };

        break;

      case 'generate-license':
        if (!data) {
          break;
        }

        pageData.notifications.add('licenseCreated');
        updatedLicenses = pageData.licenses.map((license) => (license.id === data.id ? data : license));

        pageData = {
          ...pageData,
          licenses: updatedLicenses,
        };

        break;
      case 'copy-license':
        if (!data) {
          break;
        }

        openModal('createLicense', { licenseDetails: data });

        break;
      default:
        console.warn(`[GroupLicenseList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
        break;
    }
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  onMount(fetchLicenses);

  $: hasSearchButNotFound =
    pageData.focusedTemplateId &&
    !pageData.loading &&
    !pageData.licenses.some(({ id: licenseId }) => licenseId === pageData.focusedTemplateId);
  $: if (hasSearchButNotFound) {
    pageData.notifications.add('licenseNotFound');
  }
</script>

<LoadingSpinner loading={pageData.loading}>
  {#each Object.keys(notifications) as notification}
    {#if pageData.notifications.has(notification)}
      <CustomInlineNotification {...notifications[notification]} />
    {/if}
  {/each}
  {#if pageData.licenses.length}
    <h2 class="h2">Licenses</h2>
    <div class="licenses">
      {#each pageData.licenses as license}
        <LicenseTemplateCard
          focus={license.id === pageData.focusedTemplateId}
          {license}
          {licenseLookbackDays}
          editBasicLicenseInfo={() => openModal('editBasicLicenseInfo', { license })}
          viewLicense={() => openModal('viewLicense', { license })}
          editFeatures={() => openModal('editFeatures', { license })}
          generateLicense={() => openModal('generateLicense', { license })}
          addOrEditNote={() => openModal('addOrEditNote', { license })}
          deleteTemplate={() => openModal('deleteTemplate', { license })}
        />
      {/each}
      <div>
        <div
          class="license-create"
          class:split={pageData.licenses.length}
          tabindex="0"
          role="button"
          on:click={() => openModal('createLicense')}
        >
          <div class="label">
            <PlusIcon />
            <h3 class="h3">Create new</h3>
          </div>
        </div>
        {#if pageData.licenses.length}
          <div
            class="license-create"
            class:split={pageData.licenses.length}
            tabindex="0"
            role="button"
            on:click={() => openModal('copyLicense')}
          >
            <div class="label">
              <CopyIcon />
              <h3 class="h3">Clone</h3>
            </div>
          </div>
        {/if}
      </div>
    </div>
  {/if}
  <GroupLicenseListModals
    {closeModal}
    {defaultDestinations}
    {modals}
    licenses={pageData.licenses}
    group={{
      groupId,
      groupName,
      licenseLookbackDays,
      sftp05Username,
      shortName,
    }}
  />
</LoadingSpinner>

<style>
  .h2 {
    font-size: 1.5rem;
  }

  .h3 {
    font-size: 1.25rem;
  }

  .licenses {
    margin-top: 1rem;
    margin-bottom: 7rem; /* for multiple lines of cards: opening last line's overflow menu will make page need more scroll */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    row-gap: 1rem;
  }

  .license-create {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--cds-ui-03);
    min-height: 300px;
    margin: 0.3rem;
  }

  .license-create.split {
    min-height: 155px;
  }

  .license-create:hover {
    background-color: var(--cds-hover-ui);
    cursor: pointer;
  }

  .license-create:focus {
    outline: 2px solid var(--cds-focus);
    outline-offset: -2px;
  }

  .license-create .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
  }

  .license-create .label :global(h3) {
    color: var(--cds-text-02);
    font-weight: bold;
  }

  .license-create .label :global(svg) {
    margin-bottom: 0.5rem;
    fill: var(--cds-text-02);
  }
</style>
