<script>
  import TreeSelect from '../tree-select/TreeSelect.svelte';

  export let treeData, checkedItemIds;

  function handleItemCheck({ detail }) {
    checkedItemIds = detail.newCheckedIds;
  }

  function createFeatureDescription(feature) {
    if (feature.children) {
      // eslint-disable-next-line no-param-reassign
      feature.children = feature.children.map((child) => createFeatureDescription(child));
    }

    if (!feature.dataFeedHandler) {
      return feature;
    }

    return {
      ...feature,
      // TODO: rethink. Maybe append to description once we allow users to add actual descr to features
      description: `Data feed handler "${feature.dataFeedHandler.name}"`,
    };
  }

  $: data = treeData.reduce((all, feature) => [...all, createFeatureDescription(feature)], []);
</script>

{#if data?.length}
  <TreeSelect {data} bind:checkedItemIds withDetailsView on:item-check={handleItemCheck} />
{/if}
