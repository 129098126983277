<script>
  import { ProgressStep } from 'carbon-components-svelte';

  export let clickable,
    complete,
    content = {},
    current,
    description,
    label,
    upcoming;
</script>

<div class="progress-step" class:clickable class:complete class:current class:upcoming>
  <ProgressStep {complete} {current} {description} {label} invalid={content.invalid} on:click />
</div>

<style>
  .progress-step {
    display: contents;
  }

  .progress-step:not(.clickable) {
    pointer-events: none;
  }

  /**
    * carbon marks with blue the bar above
    * the step when it's in "current" mode
    * but we only need this if step is "complete"
    */
  .progress-step.complete :global(.bx--progress-line) {
    background-color: var(--cds-interactive-04);
  }

  .progress-step.current :global(.bx--progress-line),
  .progress-step.upcoming :global(.bx--progress-line) {
    background-color: var(--cds-ui-03);
  }
</style>
