import { convertToLocalDisplayTime } from './dataTable';

const timeColumns = ['createdAt', 'updatedAt', 'modified', 'created'];

/**
 * @function rowContainsText
 * @desc Asserts if the row object contains the given filter text, following the rules:
 * - It checks only for cell values.
 * - It ignores object type cells.
 * - Date cells it firstly convert the dates to the format displayed in the app.
 * - It ignores the string casing.
 * @example
 * // returns true
 * rowContainsText({ name: 'steVe', age: 10, organization: { name: 'MayStreet' }}, 'Steve');
 * @example
 * // returns false
 * rowContainsText({ name: 'Steve', age: 10, organization: { name: 'MayStreet' }}, 'MayStreet');
 * @returns {Boolean}
 */

export const rowContainsText = (row, filterText) => {
  // An additional needed in case we want to have ability to filter objects (one level deep only)
  // const flattenedRow = Object.entries(row).reduce((obj, [key, val]) => {
  //   if (typeof val === 'object' && val !== null) {
  //     Object.entries(val).forEach(([nestedKey, nestedVal]) => {
  //       obj[`${key}.${nestedKey}`] = nestedVal;
  //     });
  //     return obj;
  //   }
  //   return { ...obj, [key]: val };
  // }, {});

  const rowMappedValues = Object.entries(row).reduce((arr, [key, val]) => {
    if (typeof val === 'object') {
      return arr;
    }
    return [...arr, timeColumns.includes(key) ? convertToLocalDisplayTime(val) : String(val)];
  }, []);

  //  unicode character (U+E000) is meant to be a cell values separator which won't be used in filtering
  return rowMappedValues.join('').toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
};
