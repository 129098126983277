import { io } from 'socket.io-client';
import { writable } from 'svelte/store';

import { WEBSOCKET_URL } from '../config';

export const messagesStore = writable({});

const updateKey = (eventKey, eventValue) => messagesStore.update((oldMessagesStore) => ({ ...oldMessagesStore, [eventKey]: eventValue }));

const EVENTS = ['failedTaskExecutions', 'runningTaskExecutions', 'usersActivity'];
const socket = io(WEBSOCKET_URL);

socket.on('shareInitialState', (value) => {
  messagesStore.update(() => value);
});

EVENTS.forEach((event) => {
  socket.on(event, (value) => {
    updateKey(event, value);
  });
});

export const changeUserActivityState = (state) => {
  socket.emit('usersActivity', state);
};
