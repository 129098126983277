<script>
  import { createEventDispatcher } from 'svelte';

  import { Button } from 'carbon-components-svelte';

  import { downloadJsonAsFile } from '../../utils';

  export let row;

  const VIEW_DETAILS_LOG_LIMIT = 300_000;
  const dispatch = createEventDispatcher();

  function viewDetails() {
    dispatch('showExecutionDetailsModal', row.data);
  }

  function downloadDetailsLog() {
    downloadJsonAsFile(`execution-log-${row.id}`, row.data);
  }

  $: executionLogIsLong = JSON.stringify(row.data).length > VIEW_DETAILS_LOG_LIMIT;
</script>

{#if executionLogIsLong}
  <Button kind="ghost" on:click={downloadDetailsLog}>Download Log</Button>
{:else}
  <Button kind="ghost" on:click={viewDetails}>View details</Button>
{/if}
