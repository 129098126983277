<script>
  import { onMount } from 'svelte';

  import { Button, InlineNotification, Link, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import StatusIcon from '../../components/StatusIcon.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getAllUsers } from '../../services';
  import { asBooleanStatusColumn, asOptionalTextColumn, rowContainsText } from '../../utils';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  let pageData = { loading: true };
  let pagination = { currentPage: 1, pageSize: 25 };
  let searchText = '';
  let filteredRows = [];

  const navigate = useNavigate();

  onMount(async () => {
    try {
      const users = await getAllUsers();
      pageData = { users, loading: false };
    } catch (error) {
      console.error('[UserList] Failed to load users!', error);
      pageData = { loading: false, error };
    }
  });

  function onSearchTextChange(e) {
    // Note: we intentionally fall back on an empty string here since the event raised by clicking the "Clear" button
    // will not contain a value.
    searchText = e?.detail ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  function viewUserDetails(event, userId) {
    event.preventDefault();
    navigate(`/user/${userId}`);
  }

  $: if (pageData.users) {
    filteredRows = searchText ? pageData.users.filter((user) => rowContainsText(user, searchText)) : pageData.users;
  }
</script>

{#if pageData.error}
  <InlineNotification hideCloseButton kind="error" lowContrast title="Error:" subtitle="Failed to load users! Please try again later." />
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'name', value: 'Name' },
        { key: 'email', value: 'Email' },
        { key: 'organizationName', value: 'Organization', ...asOptionalTextColumn },
        { key: 'groupNames', value: 'Group Names' },
        { key: 'isEnabled', value: 'Enabled', ...asBooleanStatusColumn },
        { key: 'statusDescription', value: 'Status' },
      ]}
      rows={filteredRows}
      sortable
      pageSize={pagination.pageSize}
      page={pagination.currentPage}
    >
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'name'}
          <Link href={`/user/${row.id}`} on:click={(event) => viewUserDetails(event, row.id)}>{row.name}</Link>
        {:else if cell.key === 'isEnabled'}
          <StatusIcon valid={row.isEnabled} />
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearchWithRouting
            labelText="Search for groups"
            persistent
            bind:searchText
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          <Button on:click={() => navigate('/user/create')}>Create user</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:page={pagination.currentPage}
      bind:pageSize={pagination.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={filteredRows.length}
    />
  </LoadingSpinner>
{/if}
