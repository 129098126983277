<script>
  import { onMount } from 'svelte';

  import { HeaderSearch } from 'carbon-components-svelte';

  import SearchBarOutputWrapper from './SearchBarOutputWrapper.svelte';
  import { getGroups, getOrganizations, getAllUsers, getSshKeys } from '../../services';
  import { searchStore, updateKeys } from '../../stores';
  import { rowContainsText } from '../../utils';

  let searchRef;

  const checkCloseClick = (e) => {
    if (!$searchStore.overlayHidden && !searchRef.contains(e.target)) {
      updateKeys({ overlayHidden: true });
    }
  };

  onMount(() => {
    document.addEventListener('click', checkCloseClick);
  });

  const getFilteredData = () => {
    return Object.entries($searchStore.rawData).reduce((obj, [entityKey, entityData]) => {
      const filteredData = entityData.filter((record) => rowContainsText(record, $searchStore.searchString));
      return { ...obj, [entityKey]: filteredData };
    }, {});
  };

  $: updateKeys({ filteredData: $searchStore.searchString ? getFilteredData() : $searchStore.rawData });

  const loadSearchData = async () => {
    searchRef.querySelector('input')?.select();
    updateKeys({ overlayHidden: false });
    if ($searchStore.loaded || $searchStore.loading) {
      return;
    }
    updateKeys({ loading: true });
    try {
      const [users, groups, organizations, sshKeys] = await Promise.all([getAllUsers(), getGroups(), getOrganizations(), getSshKeys()]);

      updateKeys({ rawData: { users, groups, organizations, sshKeys }, loading: false, loaded: true });
    } catch (error) {
      console.error('[SearchBar] Failed to retrieve search data!', error);
      updateKeys({ loading: false, error, loaded: true });
    }
  };
</script>

<div class="search-input-wrapper" bind:this={searchRef}>
  <HeaderSearch
    disabled={$searchStore.error}
    bind:value={$searchStore.searchString}
    on:focus={loadSearchData}
    on:clear={() => updateKeys({ overlayHidden: true })}
    on:input={() => $searchStore.overlayHidden && updateKeys({ overlayHidden: false })}
  />
</div>

<SearchBarOutputWrapper overlayHidden={$searchStore.overlayHidden} />

<style>
  :global(.search-input-wrapper div[role='search']) {
    margin: 0.5rem 0 !important;
    background-color: transparent !important;
  }

  :global(.search-input-wrapper div[role='combobox']) {
    border-bottom: none !important;
  }

  :global(.search-input-wrapper .bx--header__action) {
    margin: 0;
  }
</style>
