<script>
  import { FormGroup, InlineNotification, Link, PasswordInput, TextInput, Tile } from 'carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import { login } from '../../services';

  import { appConfig } from '../../stores';

  // const ACCESS_TOKEN_COOKIE_NAME = 'mst.auth-portal.access-token';

  const setCookieAndRedirect = () => {
    // TODO: do we need this?
    // Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
    //   domain: $appConfig.data.cognitoCookieStorageDomain,
    //   secure: $appConfig.data.useSecureCookieStorage,
    //   sameSite: 'Strict',
    //   // expire after 5 minutes, it's only really required by the next redirect
    //   expires: new Date(new Date().getTime() + 5 * 60 * 1000),
    //   path: '/',
    // });

    const redirectUrl = localStorage.getItem('auth_redirect') ?? window.location.origin;
    localStorage.removeItem('auth_redirect');

    window.location.href = redirectUrl;
  };

  const schema = yup.object().shape({
    username: yup.string().required('Enter your email address!'),
    password: yup.string().required('Enter your password!'),
  });

  let errorMessage, showSuccessBanner, isLoading;

  const navigate = useNavigate();

  let showChangedPasswordNotification = localStorage.getItem('password_changed');
  localStorage.removeItem('password_changed');

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    errorMessage = null;
    showChangedPasswordNotification = null;
    isLoading = true;

    try {
      const { resetRequired, tokens } = await login(values.username, values.password);

      if (resetRequired) {
        navigate('/set-new-password', { state: { fromLogin: true, username: values.username } });
      } else {
        showSuccessBanner = true;
        setTimeout(() => {
          setCookieAndRedirect(tokens.idToken.jwtToken);
        }, 500);
      }
    } catch (error) {
      setSubmitting(false);
      if (error.code === 'InternalFailure' || error.code === 'ServiceUnavailable') {
        errorMessage = 'An error occurred. Please try again later.';
      } else if (error.code === 'UserNotConfirmedException') {
        navigate('/verify', { state: { fromLogin: true, username: values.username } });
      } else {
        errorMessage = 'The email address and password entered did not match our records. Please try again.';
      }
    } finally {
      isLoading = false;
    }
  }
</script>

<Tile light>
  <div class="mst-global-custom-css-form">
    <h1>Login</h1>
    {#if showChangedPasswordNotification}
      <InlineNotification
        kind="success"
        title="Success"
        subtitle="Your password has been changed successfully. You can now log in."
        lowContrast
      />
    {/if}
    {#if showSuccessBanner}
      <InlineNotification kind="success" title="Success!" subtitle="Redirecting..." hideCloseButton lowContrast />
    {:else}
      {#if errorMessage}
        <InlineNotification kind="error" title="Error:" subtitle={errorMessage} lowContrast />
      {/if}
      <Form
        {schema}
        validateOnBlur={true}
        validateOnChange={true}
        on:submit={onFormSubmit}
        let:isSubmitting
        let:setValue
        let:errors
        let:touched
      >
        <FormGroup>
          <TextInput
            autocomplete="email"
            invalid={touched.username && !!errors.username}
            invalidText={errors.username}
            labelText="Email address"
            name="username"
            placeholder="Email address"
            type="email"
            on:change={({ detail: text }) => setValue('username', text)}
          />
          <PasswordInput
            autocomplete="current-password"
            invalid={touched.password && !!errors.password}
            invalidText={errors.password}
            labelText="Password"
            name="password"
            placeholder="Password"
            on:change={({ target }) => setValue('password', target.value)}
          />
          <Link on:click={() => navigate('/reset-password')}>Forgot Password?</Link>
        </FormGroup>
        <div class="form-controls">
          <LoadingButton disabled={isSubmitting} kind="primary" type="submit" {isLoading}>Login</LoadingButton>
        </div>
      </Form>
    {/if}
  </div>
</Tile>

<div class="note">
  <p>
    Experiencing login issues? Please use our <Link href={`${$appConfig.data.authRedirectUrl}?redirect=${window.location.origin}`}
      >previous authentication portal</Link
    > instead.
  </p>
</div>

<style>
  .mst-global-custom-css-form > h1 {
    display: block;
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .mst-global-custom-css-form :global(.bx--link) {
    cursor: pointer;
    display: block;
    margin-top: 0.75rem;
    text-align: right;
    width: 100%;
  }

  .mst-global-custom-css-form .form-controls {
    display: flex;
    justify-content: flex-end;
  }

  .note {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .note :global(p) {
    font-size: 0.75rem;
    text-align: center;
    color: var(--cds-text-helper);
  }

  .note :global(.bx--link) {
    font-size: 0.75rem;
  }
</style>
