<script>
  import { onMount } from 'svelte';

  import { Column, ClickableTile, InlineNotification, Row } from 'carbon-components-svelte';

  import BuildsConfigurator32 from 'carbon-icons-svelte/lib/LicenseMaintenance32';
  import Category32 from 'carbon-icons-svelte/lib/Category32';
  import Email32 from 'carbon-icons-svelte/lib/Email32';
  import Events32 from 'carbon-icons-svelte/lib/Events32';
  import License32 from 'carbon-icons-svelte/lib/License32';
  import Store32 from 'carbon-icons-svelte/lib/Store32';
  import Subscription32 from 'carbon-icons-svelte/lib/WatsonHealthAnnotationVisibility32';
  import Timer32 from 'carbon-icons-svelte/lib/Timer32';
  import UserMultiple32 from 'carbon-icons-svelte/lib/UserMultiple32';

  import { useNavigate } from 'svelte-navigator';

  import LoadingSpinner from '../components/LoadingSpinner.svelte';
  import { getInternalPermissions } from '../services';

  const GRID_BREAKPOINTS = {
    sm: {
      span: 4,
      offset: 0,
    },
    md: {
      span: 4,
      offset: 0,
    },
    lg: {
      span: 4,
      offset: 0,
    },
    xlg: {
      span: 4,
      offset: 0,
    },
    max: {
      span: 4,
      offset: 0,
    },
  };

  const navigate = useNavigate();

  let pageData = { loading: true };

  function handleTileClick(destination) {
    return function onClick(event) {
      event.preventDefault();
      navigate(destination);
    };
  }

  onMount(async () => {
    try {
      const permissions = await getInternalPermissions();
      pageData = { permissions, loading: false };
    } catch (error) {
      console.error('[Home] Failed to retrieve permissions!', error);
      pageData = { loading: false, error };
    }
  });
</script>

{#if pageData.loading}
  <LoadingSpinner withOverlay={false} />
{:else if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load your access permissions! Please try again later."
  />
{:else if !pageData.permissions || pageData.permissions.length === 0}
  <p>You are not currently authorized to access any modules.</p>
{:else}
  <p>Welcome to the MayStreet Access Control Engine. You are currently authorized to access the following modules:</p>
  <div class="modules">
    <Row>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/users')} href="/users">
          <div class="card-action">
            <UserMultiple32 />
          </div>
          <span class="card-title">Users</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/groups')} href="/groups">
          <div class="card-action">
            <Events32 />
          </div>
          <span class="card-title">Groups</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/organizations')} href="/organizations">
          <div class="card-action">
            <Category32 />
          </div>
          <span class="card-title">Organizations</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/customer-builds')} href="/customer-builds">
          <div class="card-action">
            <BuildsConfigurator32 />
          </div>
          <span class="card-title">Customer Builds</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/licenses')} href="/licenses">
          <div class="card-action">
            <License32 />
          </div>
          <span class="card-title">Licenses</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/workbench')} href="/workbench">
          <div class="card-action">
            <Subscription32 />
          </div>
          <span class="card-title">Workbench Plans</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/data-feeds')} href="/data-feeds">
          <div class="card-action">
            <Store32 />
          </div>
          <span class="card-title">Data Feed Inventory</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/tasks')} href="/tasks">
          <div class="card-action">
            <Timer32 />
          </div>
          <span class="card-title">Tasks</span>
        </ClickableTile>
      </Column>
      <Column {...GRID_BREAKPOINTS}>
        <ClickableTile on:click={handleTileClick('/emails')} href="/emails">
          <div class="card-action">
            <Email32 />
          </div>
          <span class="card-title">Emails</span>
        </ClickableTile>
      </Column>
    </Row>
  </div>
{/if}

<style>
  .modules :global(.bx--row) {
    padding-top: 1rem;
  }

  .card-action {
    display: flex;
    justify-content: center;
  }

  .card-title {
    display: block;
    padding-top: 1rem;
    text-align: center;
    width: 100%;
  }
</style>
