<script>
  import { createEventDispatcher } from 'svelte';

  import { FormGroup, InlineNotification, Modal } from 'carbon-components-svelte';
  import * as yup from 'yup';

  import { TextInput, TextArea, Select, SelectItem } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { addParameterName, updateParameterNameData } from '../../services';

  export let open,
    initialValues = { type: 'single' };

  let loading = false,
    formSubmitButtonRef,
    resultNotification;

  const dispatch = createEventDispatcher();
  const TYPE_HELP_TEXTS = {
    single: 'A single value can only be related to this parameter.',
    multiple: 'Multiple values can be related to this parameter.',
    boolean: 'The parameter has a true/false value.',
  };

  function onInputChange(key, setValue) {
    return function handleInputChange({ detail: text, target }) {
      setValue(key, target?.value ?? text);
    };
  }

  async function handleFormSubmission({ detail: { values } }) {
    resultNotification = null;
    loading = true;

    try {
      const parameter = await (isEdit ? updateParameterNameData(initialValues.id, values) : addParameterName(values));
      dispatch('close', parameter);
    } catch (error) {
      console.error(`[AddOrEditParameterModal] Failed to ${isEdit ? 'edit' : 'add'} parameter!`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to ${isEdit ? 'edit' : 'add'} parameter!`,
      };
    } finally {
      loading = false;
    }
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: isEdit = !!initialValues.name;
  $: schema = yup.object().shape({
    name: yup.string().required('Parameter name cannot be empty'),
    type: yup.mixed().oneOf(['single', 'multiple', 'boolean']).required('Please select type'),
    description: yup.string(),
  });
</script>

<Modal
  hasForm
  modalHeading={`${isEdit ? 'Edit' : 'Add'} Parameter`}
  preventCloseOnClickOutside
  primaryButtonText={isEdit ? 'Save' : 'Add'}
  secondaryButtonText="Cancel"
  shouldSubmitOnEnter={false}
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close={() => dispatch('close')}
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    {#if isEdit}
      <br />
      <InlineNotification
        hideCloseButton
        lowContrast
        kind="warning"
        title="Important"
        subtitle="Any changes on parameter names and/or values will not automatically update any build configurations currently using them."
      />
    {/if}
    <Form
      validateOnBlur={false}
      validateOnChange={false}
      {initialValues}
      {schema}
      let:submitForm
      let:errors
      let:setValue
      let:touched
      let:values
      on:submit={handleFormSubmission}
    >
      <FormGroup>
        <TextInput
          required
          name="name"
          type="text"
          value={values.name}
          invalid={touched.name && !!errors.name}
          invalidText={errors.name}
          labelText="Parameter Name"
          placeholder="Name"
          on:change={onInputChange('name', setValue)}
          autocomplete="off"
        />
        <TextArea
          type="text"
          name="description"
          invalid={touched.description && !!errors.description}
          invalidText={errors.description}
          value={values.description}
          labelText="Description"
          placeholder="Enter a description..."
          on:change={onInputChange('description', setValue)}
        />
        <Select
          required
          name="type"
          selected={values.type}
          invalid={touched.type && !!errors.type}
          invalidText={errors.type}
          helperText={TYPE_HELP_TEXTS[values.type]}
          labelText="Type"
          on:change={({ detail: value }) => setValue('type', value)}
        >
          <SelectItem value="single" text="Single-valued" />
          <SelectItem value="multiple" text="Multi-valued" />
          <SelectItem value="boolean" text="Boolean" />
        </Select>
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>
