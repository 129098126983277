<script>
  import GeneratedPlansList from './GeneratedPlansList.svelte';
  import ManageWbPricing from './ManageWbPricing.svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';
  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
</script>

<div class="system-tabs">
  <TabsWithRouting
    defaultHash="#plans"
    tabsWrapperProps={{ 'aria-label': 'Licenses tabs' }}
    tabs={[
      { label: 'Generated Plans', hash: '#plans' },
      { label: 'Pricing & Defaults', hash: '#prices' },
    ]}
  >
    <LazyLoadedTabContent hash="#plans"><GeneratedPlansList /></LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#prices"><ManageWbPricing /></LazyLoadedTabContent>
  </TabsWithRouting>
</div>

<style>
  .system-tabs :global(.bx--tabs__nav-link) {
    width: 11rem;
  }
</style>
