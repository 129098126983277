import { ROOT_SERVER_URL } from '../config';
import { collectQueryParams, request } from '../utils';

export function createSshKey(data) {
  return request(`${ROOT_SERVER_URL}/api/ssh-keys`, {
    data,
    method: 'POST',
  });
}

export function getSshKey(keyId) {
  return request(`${ROOT_SERVER_URL}/api/ssh-keys/${keyId}`);
}

export function getSshKeys(params) {
  const urlSearchParams = collectQueryParams(params);
  return request(`${ROOT_SERVER_URL}/api/ssh-keys?${urlSearchParams}`);
}

export function updateSshKey(keyId, changes) {
  return request(`${ROOT_SERVER_URL}/api/ssh-keys/${keyId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function removeSshKey(keyId) {
  return request(`${ROOT_SERVER_URL}/api/ssh-keys/${keyId}`, {
    method: 'DELETE',
  });
}

export function grantServiceAccess(keyId, data) {
  return request(`${ROOT_SERVER_URL}/api/ssh-keys/${keyId}/service-association`, {
    data,
    method: 'POST',
  });
}

export function revokeServiceAccess(keyId, serviceAssociationId) {
  return request(`${ROOT_SERVER_URL}/api/ssh-keys/${keyId}/service-association/${serviceAssociationId}`, {
    method: 'DELETE',
  });
}

export function convertKey(data) {
  return request(`${ROOT_SERVER_URL}/api/ssh-keys/key-conversion`, {
    data,
    method: 'POST',
  });
}
