export const convertToLocalDisplayTime = (date) => new Date(date).toLocaleString();

export const asBooleanStatusColumn = {
  sort: (a, b) => Number(a) - Number(b),
};

export const asOptionalTextColumn = {
  display: (val) => val ?? ' ',
  sort: (a, b) => (a ?? '').localeCompare(b ?? ''),
};

const getRandomId = () => Math.random().toString(36).slice(2);
export const addRandomId = (obj) => ({ ...obj, id: getRandomId() });
