<script>
  import { onMount } from 'svelte';
  import dayjs from 'dayjs';

  import { DataTable } from 'carbon-components-svelte';

  import FeedPermissionsTableRow from './FeedPermissionsTableRow.svelte';
  import SaveFeeds from './SaveFeeds.svelte';
  import { getGroupFeedsPermissions } from '../../../../services';
  import { DataInventoryServiceIds } from '../../../../../shared/constants';
  import { messagesStore } from '../../../../stores';
  import { asOptionalTextColumn } from '../../../../utils';
  import { getAvailabilityDates, validateDateInput, validateDemoDate } from './_shared';
  import { isExecutionColliding } from '../../../../../shared/taskUtils';

  export let dbFeeds,
    feedInventory,
    feedPermissionsTableNotifications,
    fetchError,
    groupId,
    permissionId,
    permissionTableLoading,
    serviceId,
    visibleFeeds;

  const tableHeaders = [
    { key: 'name', value: 'Feed Name' },
    { key: 'startDate', value: 'Start Date' },
    { key: 'endDate', value: 'End Date' },
    { key: 'isOngoing', value: 'Ongoing' },
    { key: 'expiresAt', value: 'Expires At', ...asOptionalTextColumn },
    { key: 'actions', empty: true, sort: false },
  ];

  const getCrushFtpDate = {
    startDate: () => dayjs('2000-01-01').format('YYYY-MM-DD'),
    endDate: () => dayjs().format('YYYY-MM-DD'),
  };

  onMount(async () => {
    try {
      const { groupDataFeedPermissions } = await getGroupFeedsPermissions(permissionId);
      visibleFeeds = groupDataFeedPermissions;
      dbFeeds = groupDataFeedPermissions;
    } catch (error) {
      console.error('[DataFeedPermission] Failed to load feed permissions!', error);
      fetchError = 'Failed to load group feed permissions.';
    } finally {
      permissionTableLoading = false;
    }
  });

  $: validationErrors =
    feedInventory?.length &&
    visibleFeeds.reduce((obj, curr) => {
      let dataFeed = feedInventory.find(({ id }) => id === curr.feedId);
      if (
        serviceId === DataInventoryServiceIds.SFTP05 &&
        (!dataFeed.availableStartDate || !dataFeed.availableEndDate || !dataFeed.availabilityStartMonth || !dataFeed.availabilityEndMonth)
      ) {
        const startDate = getCrushFtpDate.startDate();
        const endDate = getCrushFtpDate.endDate();

        dataFeed = {
          ...dataFeed,
          missingDays: [],
          missingMonths: [],
          availabilityStartDate: startDate,
          availabilityEndDate: endDate,
          availabilityStartMonth: startDate.slice(0, 7),
          availabilityEndMonth: endDate.slice(0, 7),
        };
      }
      const { availableStartDate, availableEndDate, missingDays, missingMonths } = getAvailabilityDates(dataFeed, curr.expiresAt);

      const generalizedValidateDateInput = (key, value) => {
        const oppositeDateKey = key === 'startDate' ? 'endDate' : 'startDate';
        return validateDateInput({
          value,
          availableStartDate,
          availableEndDate,
          isDemo: Boolean(curr.expiresAt),
          oppositeDate: curr[oppositeDateKey] ?? getCrushFtpDate[oppositeDateKey](),
          key,
          missingDays,
          missingMonths,
        });
      };

      return {
        ...obj,
        [curr.id]: {
          startDate: generalizedValidateDateInput('startDate', curr.startDate ?? getCrushFtpDate.startDate()),
          endDate: generalizedValidateDateInput('endDate', curr.endDate ?? getCrushFtpDate.endDate()),
          expiresAt: curr.expiresAt && validateDemoDate(curr.expiresAt),
        },
      };
    }, {});

  $: hasRunningConnection =
    serviceId === DataInventoryServiceIds.SFTP05 &&
    isExecutionColliding({ relatedEntityId: groupId, taskId: 'crushftp-group-reconciliation' }, $messagesStore.runningTaskExecutions);

  $: notifications = {
    noFeedsAdded: !permissionTableLoading && !dbFeeds.length && !visibleFeeds.length,
    hasRunningConnection,
  };

  $: if (notifications) {
    feedPermissionsTableNotifications = notifications;
  }
</script>

<div class="feed-permissions-wrapper">
  {#if visibleFeeds.length > 0}
    <h5 class="section-heading">Entitlements</h5>
    <DataTable headers={tableHeaders} rows={visibleFeeds} sortable size="short">
      <span slot="cell" let:cell let:row>
        <FeedPermissionsTableRow
          {cell}
          {serviceId}
          {feedInventory}
          {row}
          rowValidationErrors={validationErrors[row.id]}
          bind:visibleFeeds
        />
      </span>
    </DataTable>
  {/if}
</div>

<SaveFeeds
  {dbFeeds}
  {groupId}
  {hasRunningConnection}
  {permissionId}
  {validationErrors}
  {serviceId}
  bind:fetchError
  bind:permissionTableLoading
  bind:visibleFeeds
/>

<style>
  .feed-permissions-wrapper {
    margin: 2rem 0 0;
  }

  .feed-permissions-wrapper :global(td:first-child) {
    min-width: 8rem;
    width: 15%;
  }

  .feed-permissions-wrapper :global(td:nth-child(2)),
  .feed-permissions-wrapper :global(td:nth-child(3)) {
    min-width: 10rem;
    width: 20%;
  }

  .feed-permissions-wrapper :global(td:nth-child(4)) {
    min-width: 8rem;
  }

  .feed-permissions-wrapper :global(td:nth-child(5)) {
    min-width: 12rem;
    width: 25%;
  }

  .feed-permissions-wrapper :global(td:nth-child(6)) {
    min-width: 7rem;
  }

  .section-heading {
    margin: 0 0 0.5rem;
  }
</style>
