<script>
  import CollapseIcon from 'carbon-icons-svelte/lib/CheckboxIndeterminate16';
</script>

<div class="collapse-btn" tabindex="0" role="button" on:click>
  <CollapseIcon />
</div>

<style>
  .collapse-btn {
    display: flex;
    align-items: center;
    margin-right: 0.125rem;
  }

  .collapse-btn :global(svg) {
    cursor: pointer;
  }
</style>
