<script>
  import { onMount } from 'svelte';

  export let row;

  let runningTime = 0;

  $: taskIsRunning = row.status === 'running';
  $: executionRunTime = new Date(row.updatedAt).getTime() - new Date(row.createdAt).getTime();

  onMount(() => {
    const interval = setInterval(() => {
      if (!taskIsRunning) {
        return;
      }
      if (!runningTime) {
        runningTime = Date.now() - new Date(row.createdAt).getTime();
      } else {
        runningTime += 1000;
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  $: displayedRunTime = taskIsRunning ? runningTime : executionRunTime;
</script>

{#if displayedRunTime >= 1000 || taskIsRunning}
  {new Date(displayedRunTime).toISOString().substr(11, 8)}
{:else}
  Immediate
{/if}
