<script>
  import { createEventDispatcher } from 'svelte';

  import { Button, InlineNotification, Modal } from 'carbon-components-svelte';
  import { JsonView } from '@zerodevx/svelte-json-view';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';
  import copy from 'clipboard-copy';

  import { DataInventoryServiceIds } from '../../shared/constants';
  import LoadingSpinner from './LoadingSpinner.svelte';
  import { getDataInventoryServicePermissions, getWorkbenchPermissions } from '../services';

  export let entityId;
  export let open;

  let modalData = { loading: true };

  const dispatch = createEventDispatcher();

  async function loadPermissionSummary() {
    modalData = { ...modalData, error: undefined, loading: true };
    try {
      const [permissionsSftp05, permissionsAwsLake, permissionsAwsArbitratedPcaps, permissionWorkbench] = await Promise.all([
        getDataInventoryServicePermissions(entityId, DataInventoryServiceIds.SFTP05),
        getDataInventoryServicePermissions(entityId, DataInventoryServiceIds.AWS_LAKE),
        getDataInventoryServicePermissions(entityId, DataInventoryServiceIds.AWS_ARBITRATED_PCAPS),
        // getDataInventoryServicePermissions(entityId, DataInventoryServiceIds.CRUSHFTP),
        getWorkbenchPermissions(entityId),
      ]);
      modalData = {
        ...modalData,
        loading: false,
        permissions: { permissionsSftp05, permissionsAwsLake, permissionsAwsArbitratedPcaps, permissionWorkbench },
      };
    } catch (error) {
      console.error('[GroupPermissionsList] Failed to load permissions summary!', error);
      modalData = { ...modalData, error, loading: false };
    }
  }

  $: if (open) {
    loadPermissionSummary();
  }
</script>

<Modal modalHeading="Permissions Summary" passiveModal preventCloseOnClickOutside={true} bind:open on:close={() => dispatch('close')}>
  {#if modalData.error}
    <InlineNotification
      hideCloseButton
      kind="error"
      lowContrast
      title="Error:"
      subtitle="Failed to load permissions! Please try again later."
    />
  {:else}
    <LoadingSpinner loading={modalData.loading}>
      <div class="action-bar">
        <Button
          iconDescription="Copy to clipboard"
          icon={Copy16}
          kind="ghost"
          tooltipPosition="right"
          on:click={() => copy(JSON.stringify(modalData?.permissions ?? '', null, 2))}
        />
      </div>
      <div class="wrapper">
        <JsonView json={modalData?.permissions} />
      </div>
    </LoadingSpinner>
  {/if}
</Modal>

<style>
  .action-bar {
    height: 3rem;
    background-color: var(--cds-layer-accent);
  }

  .wrapper {
    --nodePaddingLeft: 1.5rem; /* css variable for svelte-json-view indentation; only available under .raw-view container */
    padding: 1rem 2rem;
    border: 1px solid var(--cds-layer-accent);
    word-break: break-word;
  }

  .wrapper :global(.key) {
    color: var(--cds-text-01);
  }
</style>
