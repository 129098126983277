<script>
  import { createEventDispatcher, tick } from 'svelte';

  import { Button, FormGroup, InlineNotification, Modal } from 'carbon-components-svelte';
  import defer from 'lodash/defer';
  import * as yup from 'yup';

  import { TextArea } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { convertKey, createSshKey, updateSshKey } from '../../services';

  export let editingSshKey, ownerId, ownerName, ownerType;
  export let open = false;

  let formSubmitButtonRef, previouslyOpen, resultNotification;
  let initialValues = {
    publicKey: '',
  };
  let loaderCount = 0;
  let submitting = false;

  const dispatch = createEventDispatcher();

  const schema = yup.object().shape({
    publicKey: yup
      .string()
      .required('Enter a public key!')
      .test('contains-newlines', 'Public keys must not exceed one line in length!', (value) => !value.includes('\n')),
  });

  async function onConvertKey(publicKey, setValue) {
    if (!publicKey || !publicKey.trim()) {
      console.debug('[AddOrEditSshKeyModal] Cannot convert empty public key string. Skipping...');
      return;
    }

    resultNotification = null;
    submitting = true;

    try {
      const { convertedKey } = await convertKey({ publicKey });
      setValue('publicKey', convertedKey);
      resultNotification = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Key converted from SSH2 to OpenSSH format.',
      };
    } catch (error) {
      const errorDetails = error.response.data?.message ?? 'Please contact an administrator for assistance.';
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed convert SSH2 key to OpenSSH format! ${errorDetails}`,
      };
    } finally {
      submitting = false;
    }
  }

  async function onFormSubmit({ detail: { values } }) {
    resultNotification = null;
    submitting = true;

    try {
      const requestBody = {
        ...values,
        ownerId,
        ownerType,
      };
      const promise = editingSshKey ? () => updateSshKey(editingSshKey.id, requestBody) : () => createSshKey(requestBody);
      await promise();

      submitting = false;
      dispatch('submit');
      dispatch('close');
    } catch (error) {
      console.error(`[AddOrEditSshKeyModal] Failed to add SSH key for ${ownerType}!`, error);

      const errorDetails = error.response.data?.message ?? 'Verify your submission and try again.';
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to add SSH key for ${ownerType}! ${errorDetails}`,
      };
      submitting = false;
    }
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: if (open && !previouslyOpen) {
    loaderCount++;
    previouslyOpen = true;

    defer(async () => {
      await tick();
      initialValues = editingSshKey ? { publicKey: editingSshKey.publicKey } : { publicKey: '' };
      loaderCount--;
    });
  }

  $: if (!open && previouslyOpen) {
    previouslyOpen = false;
  }
</script>

<Modal
  hasScrollingContent={true}
  modalHeading={editingSshKey ? 'Update SSH Key' : 'Add SSH Key'}
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner loading={loaderCount > 0 || submitting}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are {editingSshKey ? 'updating an existing' : 'creating a new'} SSH key for {ownerName}.
      {#if !editingSshKey && ownerType === 'user'}
        Once created, this key will be visible on all Group Details pages on which this user appears.
      {/if}
    </p>
    <!-- Re-mount the form every time the modal re-opens: this ensures initialValues are set correctly -->
    {#if loaderCount === 0}
      <Form {initialValues} {schema} let:submitForm let:errors let:setValue let:touched let:values on:submit={onFormSubmit}>
        <FormGroup>
          <div class="form-item-with-additional-action">
            <TextArea
              helperText="Single-line public key string."
              invalid={touched.publicKey && !!errors.publicKey}
              invalidText={errors.publicKey}
              labelText="Public Key"
              name="publicKey"
              placeholder="<algorithm> <key> <comment>"
              rows={10}
              value={values.publicKey}
              required
              on:change={({ target }) => setValue('publicKey', target.value)}
            />
            <Button disabled={!values.publicKey} kind="ghost" size="small" on:click={() => onConvertKey(values.publicKey, setValue)}>
              Convert SSH2 key
            </Button>
          </div>
        </FormGroup>
        <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
      </Form>
    {/if}
  </LoadingSpinner>
</Modal>

<style>
  .form-item-with-additional-action {
    position: relative;
  }
  .form-item-with-additional-action :global(.bx--btn) {
    border: 0;
    bottom: 0;
    font-size: var(--cds-helper-text-01-font-size, 0.75rem);
    line-height: var(--cds-helper-text-01-line-height, 1.33333);
    min-height: 0;
    padding-bottom: 0.125rem;
    padding-top: 0.125rem;
    position: absolute;
    right: 0;
  }
</style>
