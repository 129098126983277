<script>
  import { Link, ListItem, OrderedList } from 'carbon-components-svelte';

  export let deletedDates = {};
  export let fullyDeletedDataFeeds = [];
  export let regressedMonths = {};
</script>

<OrderedList>
  {#if fullyDeletedDataFeeds.length > 0}
    <ListItem>Feeds missing on service: {fullyDeletedDataFeeds.join(', ')}.</ListItem>
  {/if}
  {#each Object.entries(deletedDates) as [feedName, dates]}
    <ListItem>
      {feedName}:
      <Link
        download={`regression-sftp05-${feedName}-deleted-dates.json`}
        href={`data:application/json,${JSON.stringify(deletedDates[feedName])}`}
      >
        {dates.length} deleted dates
      </Link>
      ranging from {dates[0]} to {dates[dates.length - 1]}.
    </ListItem>
  {/each}
  {#each Object.entries(regressedMonths) as [feedName, newMonth]}
    <ListItem>
      {feedName}: latest month folder regressed to {newMonth || '(empty)'}.
    </ListItem>
  {/each}
</OrderedList>
