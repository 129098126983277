<script>
  import AuditTableEntityCellLicense from './AuditTableEntityCellLicense.svelte';

  export let entityId, row;
</script>

{#if row.entityType === 'build-parameter'}
  <div class="build-param-entity" data-entity={row.entityType}>
    <strong>{row.entityId}</strong>
    <span>Target name</span>
  </div>
{:else if row.entityType === 'license-template'}
  <AuditTableEntityCellLicense {entityId} {row} />
{:else}
  {row.entityId}
{/if}

<style>
  :global([data-entity='build-parameter']) {
    display: flex;
    flex-direction: column;
  }

  .build-param-entity :global(b) {
    font-size: 16px;
  }

  .build-param-entity :global(span) {
    font-size: 12px;
    font-style: italic;
  }
</style>
