<script>
  import { Button, DataTable, InlineNotification, Link, NotificationActionButton, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import { asOptionalTextColumn, convertToLocalDisplayTime } from '../../utils';

  export let groups, organizationId;

  const navigate = useNavigate();

  function viewGroupDetails(event, groupId) {
    event.preventDefault();
    navigate(`/group/${groupId}`);
  }

  const createGroupForOrganization = () => {
    navigate(`/group/create?organizationId=${organizationId}`);
  };
</script>

{#if groups && groups.length}
  <DataTable
    headers={[
      { key: 'name', value: 'Name' },
      { key: 'shortName', value: 'Short Name', ...asOptionalTextColumn },
      { key: 'aliases', value: 'Aliases', ...asOptionalTextColumn },
      { key: 'createdAt', value: 'Created', display: convertToLocalDisplayTime },
      { key: 'updatedAt', value: 'Updated', display: convertToLocalDisplayTime },
    ]}
    rows={groups}
    sortable
  >
    <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
      {#if cell.key === 'name'}
        <Link href={`/group/${row.id}`} on:click={(event) => viewGroupDetails(event, row.id)}>{row.name}</Link>
      {:else if cell.display}
        {cell.display(cell.value, row)}
      {:else}
        {cell.value}
      {/if}
    </span>
    <Toolbar>
      <ToolbarContent>
        <Button on:click={createGroupForOrganization}>Add group</Button>
      </ToolbarContent>
    </Toolbar>
  </DataTable>
{:else}
  <InlineNotification hideCloseButton lowContrast title="Info:" subtitle="There are no groups in this organization." kind="info">
    <div slot="actions">
      <NotificationActionButton on:click={createGroupForOrganization}>Add group</NotificationActionButton>
    </div>
  </InlineNotification>
{/if}
