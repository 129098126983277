<script>
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';

  export let difference;
</script>

<ul class="diff-list">
  {#each Object.entries(difference) as [changedKey, { previous, change }]}
    <li>
      - {changedKey} - {previous}
      <ArrowRight16 />
      {change}
    </li>
  {/each}
</ul>

<style>
  .diff-list {
    margin-left: 1.5rem;
  }

  .diff-list :global(svg) {
    margin-bottom: -0.2rem;
  }
</style>
