<script>
  import CheckboxIndeterminate from 'carbon-icons-svelte/lib/CheckboxIndeterminate16';
</script>

<div class="expand-btn" tabindex="0" role="button" on:click>
  <CheckboxIndeterminate />
</div>

<style>
  .expand-btn {
    position: relative;
    display: flex;
    align-items: center;
  }

  .expand-btn :global(svg) {
    cursor: pointer;
  }

  .expand-btn::after {
    content: ' ';
    position: absolute;
    cursor: pointer;
    display: block;
    left: 0.45rem;
    height: 0.375rem;
    width: 0.125rem;
    background-color: var(--cds-text-01);
  }
</style>
