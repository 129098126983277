<script context="module">
  /**
   * @description Finds IDs of items from an array of nested objects that do not match against a text
   * @param {Array.<Object>} array - The array to be checked
   * @param {string} value - The value to search
   * @param searchKey - The key we match against the value
   * @param childrenKey - The key of the children array
   * @returns {Set<string|number>} - The filtered Set of IDs
   */
  export function findNotMatchingIds(array, value, searchKey = 'name', childrenKey = 'children') {
    return array.reduce((items, item) => {
      const hasChildren = item[childrenKey];
      let filteredChildrenIds = [];

      if (hasChildren) {
        filteredChildrenIds = findNotMatchingIds(item[childrenKey], value);
      }

      if (item[searchKey].toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
        return items;
      }

      const itemsToReturn = [...filteredChildrenIds, ...items];

      const shouldIncludeParent = hasChildren ? item[childrenKey].every(({ id: childId }) => filteredChildrenIds.includes(childId)) : true;

      if (shouldIncludeParent) {
        itemsToReturn.push(item.id);
      }

      return itemsToReturn;
    }, []);
  }
</script>

<script>
  import { createEventDispatcher } from 'svelte';
  import { Search } from 'carbon-components-svelte';

  import Leaf from './Leaf.svelte';

  export let data = [],
    withDetailsView,
    selectedItem = null,
    locked = false,
    checkedItemIds = [];

  let searchTerm = '';

  const FIRST_LEVEL_ITEM_IDS = data.map(({ id }) => id);
  const dispatch = createEventDispatcher();

  function handleItemCheck({ detail }) {
    dispatch('item-check', detail);
  }

  function handleItemSelect({ detail }) {
    selectedItem = selectedItem?.id === detail.id ? null : { ...detail };
  }

  function handleSearchChange({ target }) {
    searchTerm = target.value?.trim() ?? '';
  }

  $: filteredIdsSet = new Set(searchTerm.length ? findNotMatchingIds(data, searchTerm) : []);
  $: hasData = FIRST_LEVEL_ITEM_IDS.some((firstLevelId) => !filteredIdsSet.has(firstLevelId));
  $: checkedIdsSet = new Set(checkedItemIds);
</script>

<div class="tree">
  <Search size="sm" value={searchTerm} on:input={handleSearchChange} />
  <div class="content">
    <div class="list">
      {#if hasData}
        {#each data as leaf}
          <Leaf
            {...leaf}
            {locked}
            selectedId={selectedItem?.id}
            bind:filteredIdsSet
            bind:checkedIdsSet
            on:item-select={handleItemSelect}
            on:item-check={handleItemCheck}
          />
        {/each}
      {:else}
        <em class="message">No matches found.</em>
      {/if}
    </div>
    {#if withDetailsView && hasData}
      <div class="details-panel">
        {#if selectedItem}
          {#if selectedItem.description}
            <strong class="title">{selectedItem.name}</strong>
            <p class="description">{selectedItem.description}</p>
          {:else}
            <em class="message">No description for selected item.</em>
          {/if}
        {:else}
          <em class="message">Click on an item to view more details.</em>
        {/if}
      </div>
    {/if}
  </div>
</div>

<style>
  .tree {
    background-color: var(--cds-ui-01);
  }

  .content {
    display: flex;
  }

  .list {
    flex: 2;
    padding: 0.5rem;
    overflow-y: auto;
    max-height: 300px;
  }

  .details-panel {
    flex: 1;
    border-left: 2px solid var(--cds-ui-03);
    padding: 1rem 0.5rem;
    overflow-y: auto;
    max-height: 300px;
  }

  .details-panel .title {
    font-size: 1.1rem;
    font-style: italic;
  }

  .details-panel .description {
    margin-top: 1rem;
    font-size: 0.85rem;
  }

  .message {
    color: var(--cds-text-helper);
  }
</style>
