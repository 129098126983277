<script>
  import { onMount } from 'svelte';
  import { useLocation } from 'svelte-navigator';

  import { UUID_PATTERN } from '../../shared/constants';
  import { changeUserActivityState, messagesStore, removeCriticalWarning, setCriticalWarning, userSession } from '../stores';

  const location = useLocation();
  let lastMouseMove = new Date().getTime();

  onMount(() => {
    const interval = setInterval(listenOnActivity, 1000);
    return () => clearInterval(interval);
  });

  const listenOnActivity = () => {
    window.addEventListener(
      'mousemove',
      () => {
        lastMouseMove = new Date().getTime();
      },
      { once: true }
    );
  };

  $: currentFullpath = $location.pathname + $location.hash;
  $: user = $userSession.userProfile;

  $: if (user && (lastMouseMove || currentFullpath)) {
    changeUserActivityState({
      lastMouseMove,
      fullpath: currentFullpath,
      userSub: user.sub,
      username: user.nickname || user.name || 'username',
    });
  }

  $: pathCanCollide = currentFullpath.match(UUID_PATTERN);

  $: collisions =
    user &&
    pathCanCollide &&
    Object.values($messagesStore?.usersActivity || {})?.filter(({ status, fullpath, userSub }) => {
      return userSub !== user.sub && currentFullpath === fullpath && status !== 'OFFLINE';
    });

  $: collisionUsernames = collisions?.map(({ username }) => username).join(', ');

  $: collisionWarningText = `
    Other users (${collisionUsernames}) are currently active on this page.
    Changes performed by multiple users could cause data conflicts.
  `;

  $: if (collisionUsernames) {
    setCriticalWarning('ActivityMonitor', { text: collisionWarningText });
  } else {
    removeCriticalWarning('ActivityMonitor');
  }
</script>
