<script>
  import { onMount } from 'svelte';

  import {
    Button,
    InlineNotification,
    Link,
    NotificationActionButton,
    OverflowMenu,
    OverflowMenuItem,
    Toolbar,
    ToolbarContent,
  } from 'carbon-components-svelte';
  import orderBy from 'lodash/orderBy';
  import { useNavigate } from 'svelte-navigator';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import AddUsersToGroupModal from './AddUsersToGroupModal.svelte';
  import EditUserGroupRolesModal from './EditUserGroupRolesModal.svelte';
  import RemoveUserFromGroupModal from './RemoveUserFromGroupModal.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getGroupUsers } from '../../services';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';

  export let groupId, organizationId;

  let pageData = { loading: true };
  const query = { pageSize: 25, currentPage: 1 };
  let modals = {
    addToGroup: { open: false },
    editRoles: { open: false, user: null },
    removeFromGroup: { open: false, user: null },
  };

  const notifications = {
    error: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load group users! Please try again later.',
    },
    noUsers: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no users in this group.',
      action: {
        text: 'Add user to group',
        onClick: () => openModal('addToGroup'),
      },
    },
  };

  const navigate = useNavigate();

  async function loadGroupUsers() {
    pageData = { ...pageData, loading: true };
    try {
      const users = await getGroupUsers(groupId);
      pageData = { users, loading: false, displayedNotification: !users.length && 'noUsers' };
    } catch (error) {
      console.error('[GroupUserList] Failed to load group users!', error);
      pageData = { loading: false, displayedNotification: 'error' };
    }
  }

  /**
   * @description Close a given modal by name. Since the modal components may forward the inner Carbon modal's "close"
   *    event multiple times, a single "toggle" method is more prone to unexpected behavior.
   * @param name
   */
  function closeModal(name) {
    modals = {
      ...modals,
      [name]: { ...modals[name], open: false },
    };
  }

  /**
   * @description Open a given modal by name, optionally passing an object of data to forward.
   * @param {string} name
   * @param {object?} forwardData
   */
  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  const openEditRolesModal = (userId) => {
    openModal('editRoles', { user: pageData.users.find(({ id }) => id === userId) });
  };

  function viewUserDetails(event, userId) {
    event.preventDefault();
    navigate(`/user/${userId}`);
  }

  onMount(loadGroupUsers);
</script>

{#if notifications[pageData.displayedNotification]}
  <InlineNotification hideCloseButton lowContrast {...notifications[pageData.displayedNotification]}>
    <div slot="actions">
      {#if notifications[pageData.displayedNotification].action}
        <NotificationActionButton on:click={notifications[pageData.displayedNotification].action?.onClick}>
          {notifications[pageData.displayedNotification].action?.text}
        </NotificationActionButton>
      {/if}
    </div>
  </InlineNotification>
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'name', value: 'Name' },
        { key: 'email', value: 'Email Address' },
        {
          key: 'roles',
          value: 'Roles',
          display: (roles) => orderBy(roles ?? []).join(', '),
          sort: false,
        },
        { key: 'statusDescription', value: 'User Status' },
        { key: 'actions', empty: true, sort: false },
      ]}
      rows={pageData.users ?? []}
      sortable
      pageSize={query.pageSize}
      page={query.currentPage}
    >
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem text="Edit roles" on:click={() => openEditRolesModal(row.id)} />
            <OverflowMenuItem danger text="Remove from group" on:click={() => openModal('removeFromGroup', { user: row })} />
          </OverflowMenu>
        {:else if cell.key === 'name'}
          <Link href={`/user/${row.id}`} on:click={(event) => viewUserDetails(event, row.id)}>{row.name}</Link>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <Button on:click={() => openModal('addToGroup')}>Add user to group</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:pageSize={query.pageSize}
      bind:page={query.currentPage}
      pageSizes={[10, 25, 50]}
      totalItems={pageData.users?.length}
    />
  </LoadingSpinner>
{/if}
<AddUsersToGroupModal
  {groupId}
  {organizationId}
  existingGroupUsers={pageData.users ?? []}
  open={modals.addToGroup.open}
  on:close={() => closeModal('addToGroup')}
  on:submit={loadGroupUsers}
/>
<RemoveUserFromGroupModal
  {groupId}
  open={modals.removeFromGroup.open}
  user={modals.removeFromGroup.user}
  on:close={() => closeModal('removeFromGroup')}
  on:submit={loadGroupUsers}
/>
{#if modals.editRoles.open}
  <EditUserGroupRolesModal
    {groupId}
    open
    user={modals.editRoles.user}
    on:close={() => closeModal('editRoles')}
    on:submit={loadGroupUsers}
  />
{/if}

<style>
  .action-cell :global(.bx--overflow-menu-options) {
    min-width: 11rem;
  }
</style>
