import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function createWbPlan(organizationId, data) {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/create`, {
    data: { ...data, organizationId },
    method: 'POST',
  });
}

export function getOrganizationUsersWithWbAccess(organizationId) {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/organization/${organizationId}/users`);
}

export function getLoginDefaults() {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/login-defaults`);
}

export function getResourceCharges() {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/resource-charges`);
}

export function getAllWbPlans() {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/plans`);
}

export function getOrganizationWbPlans(organizationId) {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/plans/organization/${organizationId}`);
}

export function makePlanActive(planId) {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/activate/${planId}`, {
    method: 'PUT',
  });
}

export function makePlanInactive(planId) {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/deactivate/${planId}`, {
    method: 'PUT',
  });
}

export function updateDefaults(data) {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/defaults`, {
    method: 'POST',
    data,
  });
}

export function updateUserLimits(planId, limits) {
  return request(`${ROOT_SERVER_URL}/api/workbench/subscription/limits/${planId}`, {
    method: 'PUT',
    data: limits,
  });
}
