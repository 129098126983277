<script>
  import { onMount } from 'svelte';

  import { DataTable, InlineNotification, Link } from 'carbon-components-svelte';
  import uniqueBy from 'lodash/uniqBy';
  import { useNavigate } from 'svelte-navigator';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getOrganizationUsers } from '../../services';

  export let organizationId;

  let pageData = { loading: true };

  const NOTIFICATIONS = {
    error: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load organization users! Please try again later.',
    },
    noUsers: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no users in this organization.',
    },
  };

  const navigate = useNavigate();

  async function loadOrganizationUsers() {
    pageData = { ...pageData, loading: true };
    try {
      const rawUsers = await getOrganizationUsers(organizationId);
      const users = rawUsers.map((user) => {
        return {
          ...user,
          groupsWithRoles: uniqueBy(user.groupsWithRoles, 'group.id'),
        };
      });
      pageData = { users, loading: false, displayedNotification: !users.length && 'noUsers' };
    } catch (error) {
      console.error('[OrganizationUserList] Failed to load group users!', error);
      pageData = { loading: false, displayedNotification: 'error' };
    }
  }

  function viewUserDetails(event, userId) {
    event.preventDefault();
    navigate(`/user/${userId}`);
  }

  onMount(loadOrganizationUsers);
</script>

{#if NOTIFICATIONS[pageData.displayedNotification]}
  <InlineNotification hideCloseButton lowContrast {...NOTIFICATIONS[pageData.displayedNotification]} />
{:else}
  <LoadingSpinner loading={pageData.loading} small>
    <DataTable
      headers={[
        { key: 'name', value: 'Name' },
        { key: 'email', value: 'Email Address' },
        {
          key: 'groupsWithRoles',
          value: 'Groups',
          display: (val) => (val ?? []).map((groupMembership) => groupMembership?.group?.name ?? '').join(', '),
        },
        { key: 'statusDescription', value: 'User Status' },
      ]}
      rows={pageData.users ?? []}
      sortable
    >
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'name'}
          <Link href={`/user/${row.id}`} on:click={(event) => viewUserDetails(event, row.id)}>{row.name}</Link>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
    </DataTable>
  </LoadingSpinner>
{/if}

<style>
  .action-cell :global(.bx--link) {
    white-space: nowrap;
  }
</style>
