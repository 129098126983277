<script>
  import { createEventDispatcher } from 'svelte';
  import { Modal, Select, SelectItem } from 'carbon-components-svelte';

  export let open, licenses;

  const dispatch = createEventDispatcher();

  let licenseDetails = [];

  function handleTemplateSelection({ detail: templateId }) {
    if (!templateId) {
      licenseDetails = [];
      return;
    }

    const templateToCopy = licenses.find(({ id }) => id === templateId);

    if (!templateToCopy) {
      licenseDetails = [];
      return;
    }

    const { cycle, diff, hosts, isDemo, name, uploadDestinations, explicitlySelectedFeatures, flatSelectedGroups } = templateToCopy;

    licenseDetails = [
      {
        cycle,
        diff,
        hosts,
        isDemo,
        name: `${name} Clone`,
        destinations: uploadDestinations.map(({ destination, path }) => `${destination}:${path}`),
      },
      [...explicitlySelectedFeatures.map(({ id }) => id), ...flatSelectedGroups.map(({ id }) => id)],
    ];
  }

  function onModalSubmit() {
    dispatch('close', licenseDetails);
  }
</script>

<div class="license-modal">
  <Modal
    size="lg"
    modalHeading="Copy License Template"
    preventCloseOnClickOutside={true}
    shouldSubmitOnEnter={false}
    primaryButtonText="Copy values and edit"
    primaryButtonDisabled={!licenseDetails.length}
    secondaryButtonText="Cancel"
    bind:open
    on:close
    on:click:button--secondary={() => dispatch('close')}
    on:submit={onModalSubmit}
  >
    <div class="intro">
      <p>Please select a license template to clone. You can further edit the values of it after the selection.</p>
    </div>
    <div class="clone">
      <div class="field">
        <Select labelText="Clone template" on:change={handleTemplateSelection}>
          <SelectItem value="" text="Select template to copy" />
          {#each licenses as template}
            <SelectItem value={template.id} text={template.name} />
          {/each}
        </Select>
      </div>
    </div>
  </Modal>
</div>

<style>
  .intro {
    margin-bottom: 1rem;
  }

  .license-modal :global(.bx--modal-content) {
    margin-bottom: var(--cds-spacing-05);
  }

  .clone {
    margin-bottom: 1rem;
  }
</style>
