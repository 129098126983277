<script>
  import { createEventDispatcher } from 'svelte';

  import { FormGroup, InlineNotification, Modal } from 'carbon-components-svelte';
  import * as yup from 'yup';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { updateBuildConfig } from '../../services';
  import { getServerErrorMessage } from '../../utils';

  export let target, open;

  let loading = false,
    formSubmitButtonRef,
    resultNotification;

  const dispatch = createEventDispatcher();

  async function editTarget({ detail: { values } }) {
    resultNotification = null;
    loading = true;

    try {
      const updatedConfig = await updateBuildConfig(target.id, {
        name: values.name,
        isDefault: !!target.isDefault,
        ownerId: target.ownerId,
        ownerType: target.ownerType,
      });

      dispatch(
        'close',
        updatedConfig
          ? {
              name: updatedConfig.name,
              updatedAt: updatedConfig.updatedAt,
              updatedBy: updatedConfig.updatedBy,
            }
          : null
      );
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) || 'Verify your submission and try again!';
      console.error(`[EditTargetModal] Failed to update target! Server says: ${errorMessage}`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to update target name! ${errorMessage}`,
      };
    } finally {
      loading = false;
    }
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: schema = yup.object().shape({
    name: yup.string().required('Target name cannot be empty'),
  });
</script>

<Modal
  class="edit-target"
  hasForm
  hasScrollingContent
  modalHeading="Edit Target"
  preventCloseOnClickOutside
  shouldSubmitOnEnter={false}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <Form
      initialValues={{ name: target.name }}
      validateOnBlur={false}
      validateOnChange={false}
      {schema}
      let:submitForm
      let:errors
      let:setValue
      let:touched
      let:values
      on:submit={editTarget}
    >
      <FormGroup>
        <TextInput
          name="name"
          invalid={touched.name && !!errors.name}
          invalidText={errors.name}
          labelText="Target Name"
          placeholder="Set Target Name"
          value={values.name}
          required
          on:change={({ detail: text }) => setValue('name', text)}
        />
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>

<style>
  :global(.edit-target .bx--modal-content) {
    overflow-y: hidden;
  }
</style>
