const DataInventoryServiceIds = {
  SFTP05: 1,
  AWS_LAKE: 2,
  AWS_ARBITRATED_PCAPS: 3,
};

const DataInventoryServiceNames = {
  SFTP05: 'SFTP05',
  AWS_LAKE: 'AWS Data Lake',
  AWS_ARBITRATED_PCAPS: 'AWS Arbitrated PCAPs',
};

const DataInventoryServiceCodeNames = {
  SFTP05: 'SFTP05',
  AWS_LAKE: 'AWS_LAKE',
  AWS_ARBITRATED_PCAPS: 'AWS_PCAP',
};

const DataInventoryServiceIdToName = {
  [DataInventoryServiceIds.SFTP05]: DataInventoryServiceNames.SFTP05,
  [DataInventoryServiceIds.AWS_LAKE]: DataInventoryServiceNames.AWS_LAKE,
  [DataInventoryServiceIds.AWS_ARBITRATED_PCAPS]: DataInventoryServiceNames.AWS_ARBITRATED_PCAPS,
};

const DataInventoryServiceIdToCodeName = {
  [DataInventoryServiceIds.SFTP05]: DataInventoryServiceCodeNames.SFTP05,
  [DataInventoryServiceIds.AWS_LAKE]: DataInventoryServiceCodeNames.AWS_LAKE,
  [DataInventoryServiceIds.AWS_ARBITRATED_PCAPS]: DataInventoryServiceCodeNames.AWS_ARBITRATED_PCAPS,
};

const UUID_PATTERN = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g;

const PARAMETER_MERGE_SUFFIX = '.MERGE';

const LICENSE_PERMANENT_DATE_VALUE = 'PERMANENT';
const LICENSE_PERMANENT_MUST_HAVE_FIELDS = ['host_id=', 'cpus=', 'hostname='];
const LICENSE_DATE_FORMAT = 'yyyy-MM-dd';
const LICENSES_INTERNAL_EMAIL_ADDRESS = 'RTUD_Licenses@lseg.com';
const DISPLAY_ONLY_LICENSE_EXPIRATION_DATE_FORMAT = 'MM-dd-yyyy';

const WB_PLAN_EXCEED_ACTIONS = {
  shutdown: 'Shutdown',
  charge: 'Keep charging',
};

module.exports = {
  DataInventoryServiceIdToName,
  DataInventoryServiceIdToCodeName,
  DataInventoryServiceIds,
  DataInventoryServiceNames,
  DataInventoryServiceCodeNames,
  DISPLAY_ONLY_LICENSE_EXPIRATION_DATE_FORMAT,
  LICENSE_DATE_FORMAT,
  LICENSES_INTERNAL_EMAIL_ADDRESS,
  LICENSE_PERMANENT_DATE_VALUE,
  LICENSE_PERMANENT_MUST_HAVE_FIELDS,
  PARAMETER_MERGE_SUFFIX,
  UUID_PATTERN,
  WB_PLAN_EXCEED_ACTIONS,
  BuildStatus: {
    READY: 'ready',
    TRIGGERED: 'triggered',
    SKIPPED: 'skipped',
    FINISHED: 'finished',
    FAILED: 'failed',
    CANCELLED: 'cancelled',
  },
  // See https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UserType.html
  CognitoUserStatus: {
    // User is no longer active. This status isn't actually used by Cognito but is listed in the docs:
    ARCHIVED: 'ARCHIVED',
    // User is disabled due to a potential security threat.
    COMPROMISED: 'COMPROMISED',
    // User is active and has been confirmed:
    CONFIRMED: 'CONFIRMED',
    // The user is confirmed and the user can sign in using a temporary password.
    // On first sign-in, the user must change their password to a new value before doing anything else:
    FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
    // User is confirmed, but must request a code and reset their password before signing in:
    RESET_REQUIRED: 'RESET_REQUIRED',
    // User has registered or been created, but has not confirmed their email address:
    UNCONFIRMED: 'UNCONFIRMED',
    // User status is not known.
    UNKNOWN: 'UNKNOWN',
  },
  TaskExecutionStatus: {
    CANCELLED: 'cancelled',
    COMPLETED: 'completed',
    FAILED: 'failed',
    INTERRUPTED: 'interrupted',
    RUNNING: 'running',
  },
};
