<script>
  import { onMount } from 'svelte';

  import { Tabs, Tab } from 'carbon-components-svelte';
  import Checkmark from 'carbon-icons-svelte/lib/Checkmark16';
  import Save from 'carbon-icons-svelte/lib/Save16';

  import AddOrEditFeatureModal from '../../components/licenses/AddOrEditFeatureModal.svelte';
  import AddOrEditFeatureGroupModal from '../../components/licenses/AddOrEditFeatureGroupModal.svelte';
  import DeleteFeatureModal from '../../components/licenses/DeleteFeatureModal.svelte';
  import DeleteFeatureGroupModal from '../../components/licenses/DeleteFeatureGroupModal.svelte';
  import DraggableListTab from '../../components/licenses/DraggableListTab.svelte';
  import GroupedFeaturesBoard from '../../components/licenses/GroupedFeaturesBoard.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import ReviewFeatureChangesModal from '../../components/licenses/ReviewFeatureChangesModal.svelte';

  import { getFeatureGroups, getFeatures, getGroupedFeatures } from '../../services/licenses';

  const Entities = {
    FEATURES: 'features',
    GROUPS: 'groups',
  };

  let groups = [],
    features = [],
    groupedFeatures = [],
    searchedFeature = '',
    searchedGroup = '',
    locked = true,
    changes = [];

  let modals = {
    addOrEditFeature: { open: false },
    deleteFeature: { open: false },
    addOrEditGroup: { open: false },
    deleteGroup: { open: false },
    review: { open: false },
  };

  let loading = { groups: false, features: false, groupedFeatures: false };

  async function fetchDependencies() {
    loading = {
      ...loading,
      groups: true,
      features: true,
      groupedFeatures: true,
    };

    try {
      [groups, features, groupedFeatures] = await Promise.all([getFeatureGroups(), getFeatures(), getGroupedFeatures()]);
    } catch (error) {
      console.error('[FeatureAndGroupList] Failed to fetch dependencies!', error);
      // TODO: add error
    } finally {
      loading = {
        ...loading,
        groups: false,
        features: false,
        groupedFeatures: false,
      };
    }
  }

  async function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'add-feature':
        features = [data, ...features];
        searchedFeature = data.name;
        break;

      case 'edit-feature':
        features = features.map((feature) => (feature.id !== data.id ? feature : data));
        await refreshBoard();
        break;

      case 'remove-feature':
        features = features.filter((feature) => feature.id !== data.id);
        await refreshBoard();
        break;

      case 'add-group':
        groups = [data, ...groups];
        searchedGroup = data.name;
        await refreshBoard();
        break;

      case 'edit-group':
        groups = groups.map((group) => (group.id !== data.id ? group : data));
        await refreshBoard();
        break;

      case 'remove-group':
        groups = groups.filter((group) => group.id !== data.id);
        await refreshBoard();
        break;

      case 'review-submit':
        if (data.success) {
          changes = [];
        }
        break;

      default:
        console.warn(`[FeatureAndGroupList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
        break;
    }
  }

  function handleLock({ detail: expectedAction }) {
    if (synced) {
      locked = expectedAction;
      return;
    }

    openModal('review', { fromLock: true });
  }

  async function handleChangesDiscard() {
    closeModal('review');

    await refreshBoard();
    changes = [];
    locked = true;
  }

  async function refreshBoard() {
    loading = { ...loading, groupedFeatures: true };

    try {
      groupedFeatures = await getGroupedFeatures();
    } catch (error) {
      console.error('[FeatureAndGroupList] Failed to fetch grouped features!', error);
      // TODO: add error
    } finally {
      loading = { ...loading, groupedFeatures: false };
    }
  }

  function openModal(name, forwardData = {}) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  onMount(fetchDependencies);

  $: synced = !changes.length;
</script>

<div>
  <p class="intro">
    From the lists on the left, you can manage feature and group entities. Using the board on the right, you can configure the structure of
    the groups and features by dragging and dropping items from the lists on the left.
  </p>
  <div class="wrapper">
    <div class="lists">
      <div class="tabs">
        <Tabs type="container">
          <Tab label="Features" />
          <Tab label="Groups" />
          <svelte:fragment slot="content">
            <LoadingSpinner loading={loading.groups || loading.features}>
              <DraggableListTab
                {locked}
                listType={Entities.FEATURES}
                listItems={features}
                searchTerm={searchedFeature}
                on:item-create={() => openModal('addOrEditFeature')}
                on:item-edit={({ detail: data }) => openModal('addOrEditFeature', { data })}
                on:item-delete={({ detail: data }) => openModal('deleteFeature', { data })}
              />
              <DraggableListTab
                {locked}
                listType={Entities.GROUPS}
                listItems={groups}
                searchTerm={searchedGroup}
                on:item-create={() => openModal('addOrEditGroup')}
                on:item-edit={({ detail: data }) => openModal('addOrEditGroup', { data })}
                on:item-delete={({ detail: data }) => openModal('deleteGroup', { data })}
              />
            </LoadingSpinner>
          </svelte:fragment>
        </Tabs>
      </div>
    </div>
    <GroupedFeaturesBoard
      {locked}
      {features}
      {groups}
      bind:groupedFeatures
      bind:changes
      loading={loading.groupedFeatures}
      on:lock-action={handleLock}
    />
  </div>
</div>
{#if modals.addOrEditFeature.open}
  <AddOrEditFeatureModal
    open
    feature={modals.addOrEditFeature.data}
    on:close={({ detail: data }) =>
      closeModal('addOrEditFeature', { action: modals.addOrEditFeature.data ? 'edit-feature' : 'add-feature', data })}
  />
{/if}
{#if modals.addOrEditGroup.open}
  <AddOrEditFeatureGroupModal
    open
    group={modals.addOrEditGroup.data}
    on:close={({ detail: data }) => closeModal('addOrEditGroup', { action: modals.addOrEditGroup.data ? 'edit-group' : 'add-group', data })}
  />
{/if}
{#if modals.deleteFeature.open}
  <DeleteFeatureModal
    open
    feature={modals.deleteFeature.data}
    on:close={({ detail: data }) => closeModal('deleteFeature', { action: 'remove-feature', data })}
  />
{/if}
{#if modals.deleteGroup.open}
  <DeleteFeatureGroupModal
    open
    group={modals.deleteGroup.data}
    on:close={({ detail: data }) => closeModal('deleteGroup', { action: 'remove-group', data })}
  />
{/if}
{#if modals.review.open}
  <ReviewFeatureChangesModal
    {changes}
    {features}
    {groups}
    open
    fromLock={modals.review.fromLock}
    on:close={({ detail: data }) => closeModal('review', { action: 'review-submit', data })}
    on:discard={handleChangesDiscard}
  />
{/if}
{#if !locked}
  <div class="floating-btn" class:synced tabindex="0" role="button" on:click={() => openModal('review')}>
    {#if synced}
      Synced
      <Checkmark />
    {:else}
      {changes.length}
      {changes.length > 1 ? ' changes' : 'change'}
      <Save />
    {/if}
  </div>
{/if}

<style>
  .intro {
    margin-bottom: 1rem;
  }

  .wrapper {
    display: flex;
  }

  .lists {
    flex: 1;
    max-width: 400px;
  }

  .tabs {
    position: sticky;
    top: 0;
  }

  .tabs :global(.bx--tabs__nav),
  .tabs :global(.bx--tabs__nav-link) {
    width: 100%;
  }

  .floating-btn {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    padding: 1rem 1.5rem;
    border-radius: 10rem;
    cursor: pointer;
    user-select: none;
    background-color: var(--outline-warning-color);
    color: var(--cds-text-04);
    box-shadow: 2px 2px 3px var(--cds-shadow);
  }

  .floating-btn :global(svg) {
    margin-left: 0.5rem;
  }

  .floating-btn.synced {
    background-color: var(--cds-inverse-support-02);
  }
</style>
