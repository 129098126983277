<script>
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';

  export let previous;
  export let change;
</script>

{#if previous && change}
  <ul class="diff-list">
    <li>
      {previous.join(', ')}
      <ArrowRight16 />
      {change.join(', ')}
    </li>
  </ul>
{/if}

<style>
  .diff-list {
    margin-left: 1.5rem;
  }

  .diff-list :global(svg) {
    margin-bottom: -0.2rem;
  }
</style>
