<script>
  import { Accordion, AccordionItem } from 'carbon-components-svelte';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import StatusIcon from '../../../../components/StatusIcon.svelte';
  import { userSession } from '../../../../stores';
  import { asOptionalTextColumn } from '../../../../utils';

  export let affectedPermissionsByGroup;

  $: displayedAffectedPermissions = Object.entries(affectedPermissionsByGroup || {}).reduce((arr, [groupName, permissions]) => {
    const computedPermissions = permissions.reduce((prevPermissions, permission) => {
      const createdPermissions = permission.newDateRanges.map((newDateRange) => ({
        ...newDateRange,
        feedName: permission.feedName,
        isOngoing: permission.isOngoing,
        expiresAt: permission.expiresAt,
        action: 'added',
        updatedByDisplayName: $userSession.userProfile.name,
      }));
      return [...prevPermissions, permission, ...createdPermissions];
    }, []);
    return [...arr, { groupName, affectedGroupPermissions: computedPermissions.map((row, id) => ({ ...row, id })) }];
  }, []);
</script>

<Accordion align="start">
  {#each displayedAffectedPermissions as { groupName, affectedGroupPermissions }, i}
    <AccordionItem title={groupName} open={i === 0} class="accordion-item-padding">
      <DataTable
        headers={[
          { key: 'feedName', value: 'Feed Name' },
          { key: 'startDate', value: 'Start Date' },
          { key: 'endDate', value: 'End Date' },
          { key: 'isOngoing', value: 'Ongoing' },
          { key: 'expiresAt', value: 'Expires At', ...asOptionalTextColumn },
          { key: 'updatedByDisplayName', value: 'Updated By', ...asOptionalTextColumn },
        ]}
        rows={affectedGroupPermissions}
        sortable
        rowClasses={affectedGroupPermissions.reduce(
          (obj, { action, id }) => ({
            ...obj,
            [id]: action === 'added' ? '' : 'overwritten',
          }),
          {}
        )}
      >
        <span slot="cell" let:cell let:row>
          {#if cell.key === 'isOngoing'}
            <StatusIcon valid={Boolean(cell.value)} />
          {:else if cell.display}
            {cell.display(cell.value, row)}
          {:else}
            {cell.value}
          {/if}
        </span>
      </DataTable>
    </AccordionItem>
  {/each}
</Accordion>

<style>
  :global(.affected-permissions-structured-list) {
    margin-bottom: 4rem;
  }

  :global(.affected-permissions-first-header-cell) {
    width: 4rem;
  }

  :global(.affected-permissions-add-icon svg) {
    fill: var(--cds-support-02);
  }

  :global(.affected-permissions-thrash-icon svg) {
    fill: var(--cds-danger-01);
  }

  :global(.accordion-item-padding > .bx--accordion__content) {
    padding: 2rem 0;
    margin-left: 0;
  }
</style>
