<script>
  import AuditCellTooltipListItem from './AuditCellTooltipListItem.svelte';
  import AuditCellTooltipDiff from './AuditCellTooltipDiff.svelte';

  export let row;

  const {
    eventType,
    eventData: {
      destinationsAdded = [],
      destinationsRemoved = [],
      emailsAdded = [],
      emailsRemoved = [],
      basicTemplateInfo,
      featuresRemoved = [],
      featuresAdded = [],
      groupsRemoved = [],
      groupsAdded = [],
    },
  } = row;

  $: eventItems = [
    {
      triggerText: `${destinationsAdded.length} ${destinationsAdded.length !== 1 ? 'destinations' : 'destination'} added`,
      list: destinationsAdded,
    },
    {
      triggerText: `${destinationsRemoved.length} ${destinationsRemoved.length !== 1 ? 'destinations' : 'destination'} removed`,
      list: destinationsRemoved,
    },
    { triggerText: `${emailsAdded.length} ${emailsAdded.length !== 1 ? 'emails' : 'email'} added`, list: emailsAdded },
    { triggerText: `${emailsRemoved.length} ${emailsRemoved.length !== 1 ? 'emails' : 'email'} removed`, list: emailsRemoved },
    { triggerText: `${featuresAdded.length} ${featuresAdded.length !== 1 ? 'features' : 'feature'} added`, list: featuresAdded },
    { triggerText: `${featuresRemoved.length} ${featuresRemoved.length !== 1 ? 'features' : 'feature'} removed`, list: featuresRemoved },
    { triggerText: `${groupsAdded.length} ${groupsAdded.length !== 1 ? 'groups' : 'group'} added`, list: groupsAdded },
    { triggerText: `${groupsRemoved.length} ${groupsRemoved.length !== 1 ? 'groups' : 'group'} removed`, list: groupsRemoved },
  ];
</script>

{#if eventType === 'updated'}
  <ul class="changes-list">
    {#if basicTemplateInfo}
      <li>
        <AuditCellTooltipDiff triggerText="Modified information" eventData={basicTemplateInfo} />
      </li>
    {/if}
    {#each eventItems as { triggerText, list }}
      <AuditCellTooltipListItem {triggerText} {list} />
    {/each}
  </ul>
{:else}
  <span>-</span>
{/if}

<style>
  .changes-list {
    padding: 1rem 0;
  }
</style>
