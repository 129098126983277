<script>
  import { Modal } from 'carbon-components-svelte';
  import sortBy from 'lodash/sortBy';

  import ArrayDiff from './ArrayDiff.svelte';

  import { SUPPORTED_RENEWAL_CYCLES } from './LicenseTemplateBasicForm.svelte';

  export let open = false,
    selections = [];

  $: [
    { features: featureListOne, filename: filenameOne, cycle: cycleOne },
    { features: featureListTwo, filename: filenameTwo, cycle: cycleTwo },
  ] = sortBy(selections, 'createdAt');
  $: sortedFeatureListOne = featureListOne.map(({ name }) => name).sort();
  $: sortedFeatureListTwo = featureListTwo.map(({ name }) => name).sort();
</script>

<Modal modalHeading="Compare Licenses" passiveModal preventCloseOnClickOutside={true} bind:open on:close>
  <div class="header">
    <div class="title">
      <h4 class="heading">{filenameOne}.lic</h4>
      <span class="bx--label">{SUPPORTED_RENEWAL_CYCLES[cycleOne]}</span>
    </div>
    <div class="title">
      <h4 class="heading">{filenameTwo}.lic</h4>
      <span class="bx--label">{SUPPORTED_RENEWAL_CYCLES[cycleTwo]}</span>
    </div>
  </div>
  <ArrayDiff originalArray={sortedFeatureListOne} modifiedArray={sortedFeatureListTwo} />
</Modal>

<style>
  .heading {
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
  }

  .header {
    display: flex;
  }

  .title {
    flex: 1;
  }
</style>
