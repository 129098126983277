<script>
  import { onMount, tick } from 'svelte';
  import { useNavigate } from 'svelte-navigator';
  import noop from 'lodash/noop';

  import {
    Button,
    InlineNotification,
    Link,
    OverflowMenu,
    OverflowMenuItem,
    Select,
    SelectItem,
    Tag,
    Toolbar,
    ToolbarBatchActions,
    ToolbarContent,
    TooltipDefinition,
  } from 'carbon-components-svelte';
  import { DataTable } from '@mst-fe/carbon-components-svelte';
  import CompareIcon from 'carbon-icons-svelte/lib/Compare16';
  import CloseIcon from 'carbon-icons-svelte/lib/Close16';
  import CloudUploadIcon from 'carbon-icons-svelte/lib/CloudUpload16';
  import CheckmarkIcon from 'carbon-icons-svelte/lib/Checkmark16';
  import SendIcon from 'carbon-icons-svelte/lib/SendAlt16';
  import ViewIcon from 'carbon-icons-svelte/lib/View16';

  import AddOrEditLicenseNoteModal from '../../components/licenses/AddOrEditLicenseNoteModal.svelte';
  import Badge from '../../components/Badge.svelte';
  import CompareLicenseModal from '../../components/licenses/CompareLicenseModal.svelte';
  import CustomLicenseFeaturesCell from '../../components/licenses/CustomLicenseFeaturesCell.svelte';
  import CustomLicenseUploadCell from '../../components/licenses/CustomLicenseUploadCell.svelte';
  import JsonPassiveModal from '../customer-builds/JsonPassiveModal.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import PreviewGeneratedLicense from '../../components/licenses/PreviewGeneratedLicense.svelte';
  import PreviewSentEmailModal from '../../components/licenses/PreviewSentEmailModal.svelte';
  import SendEmailModal from '../../components/licenses/SendEmailModal.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';
  import UploadLicenseModal from '../../components/licenses/UploadLicenseModal.svelte';

  import { getGeneratedLicenses } from '../../services';
  import { convertToLocalDisplayTime, rowContainsText } from '../../utils';

  import { SUPPORTED_RENEWAL_CYCLES } from '../../components/licenses/LicenseTemplateBasicForm.svelte';

  const LICENSE_FILENAME_SUFFIX = '.lic';
  const navigate = useNavigate();

  let loading = false,
    pagination = { currentPage: 1, pageSize: 25 },
    generatedLicenses = [],
    searchText = '',
    filteredRows = [],
    selectedGroup = '',
    groups = [],
    selectedRowIds = [],
    backupSelections = [],
    resultNotification;

  let modals = {
    note: { open: false },
    preview: { open: false },
    compare: { open: false },
    emailPreview: { open: false },
    emailSend: { open: false },
    logs: { open: false },
    upload: { open: false },
  };

  async function fetchGeneratedLicenses() {
    loading = true;

    try {
      generatedLicenses = await getGeneratedLicenses();
      groups = extractGroupsFromLicenses(generatedLicenses);
    } catch (error) {
      console.error('[GeneratedLicensesList] Failed to get generated licenses...', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to get generated licenses! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function extractGroupsFromLicenses(licenses) {
    return Object.values(
      licenses.reduce((all, license) => {
        if (all[license.licenseOwner.id]) {
          return all;
        }

        return {
          ...all,
          [license.licenseOwner.id]: { ...license.licenseOwner },
        };
      }, {})
    );
  }

  function handleFilterSelection({ detail: selection }) {
    selectedGroup = selection;
  }

  function onSearchTextChange(e) {
    searchText = e?.detail ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  function featuresContainsText(features, value) {
    return !!features.find(({ name }) => name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  }

  function filterRows(groupId, search, data) {
    let filteredData = data;

    if (groupId) {
      filteredData = filteredData.filter((license) => license.licenseOwner.id === selectedGroup);
    }

    if (search) {
      filteredData = filteredData.filter((license) => featuresContainsText(license.features, search) || rowContainsText(license, search));
    }

    return filteredData;
  }

  function goToEntity(groupId, licenseTemplateId) {
    return function go(event) {
      event.preventDefault();
      navigate(`/group/${groupId}?template=${licenseTemplateId}#licenses`);
    };
  }

  function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'email-sent':
        generatedLicenses = generatedLicenses.map((license) => (license.id !== data.licenseId ? license : { ...license, emails: [data] }));
        break;

      case 'upload':
      case 'update-license':
        generatedLicenses = generatedLicenses.map((license) => (license.id !== data.id ? license : data));
        break;

      default:
        console.warn(`[GeneratedLicenseList] Unknown action "${action}" for page data update`, data);
        break;
    }
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function openModal(name, forwardData = {}) {
    return function handleClickForOpen() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  function normalizeLog(data) {
    let normalizedData = data;

    try {
      normalizedData = JSON.parse(data);
      return normalizedData;
    } catch (error) {
      return normalizedData;
    }
  }

  function isTableFilterable(hasGroupSelected, items) {
    if (hasGroupSelected) {
      return {
        batchSelection: true,
        selectable: true,
      };
    }

    const owners = items.reduce((all, { ownerId }) => {
      all.add(ownerId);
      return all;
    }, new Set());

    if (owners.size === 1) {
      return {
        batchSelection: true,
        selectable: true,
      };
    }

    return {
      batchSelection: false,
      selectable: false,
    };
  }

  async function lock(selections) {
    if (selections.length > 2) {
      await tick();
      selectedRowIds = [...backupSelections];
      return;
    }

    backupSelections = [...selections];
  }

  onMount(fetchGeneratedLicenses);

  $: filteredRows = filterRows(selectedGroup, searchText, generatedLicenses);
  $: if (!selectedGroup) {
    selectedRowIds = [];
  }
  $: filterable = isTableFilterable(!!selectedGroup, filteredRows);
  $: lock(selectedRowIds);
  $: selectedLicenses = generatedLicenses.filter(({ id }) => selectedRowIds.includes(id));
</script>

<LoadingSpinner {loading}>
  {#if resultNotification}
    <InlineNotification
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  {#if generatedLicenses.length}
    <div class="table">
      <div class="table-filters">
        <Select
          inline
          class="filter-selector"
          labelText="Filter by group"
          size="sm"
          selected={selectedGroup}
          on:change={handleFilterSelection}
        >
          <SelectItem value="" text="All" />
          {#each groups as group}
            <SelectItem value={group.id} text={group.name} />
          {/each}
        </Select>
      </div>
      <DataTable
        headers={[
          { key: 'licenseOwner.name', value: 'Group Name' },
          { key: 'licenseTemplate.name', value: 'License Name' },
          { key: 'filename', value: 'Filename', display: (filename) => `${filename}${LICENSE_FILENAME_SUFFIX}` },
          { key: 'cycle', value: 'Cycle', display: (cycle) => SUPPORTED_RENEWAL_CYCLES[cycle] },
          { key: 'features', value: 'Features', sort: false },
          { key: 'expirationDate', value: 'Expiration Date', display: (date, row) => (row.isPermanent ? 'No expiration' : date ?? '-') },
          { key: 'emails', value: 'Email', sort: false },
          { key: 'uploadDestinations', value: 'Upload', sort: false },
          { key: 'createdAt', value: 'Generated At', display: convertToLocalDisplayTime },
          { key: 'createdByDisplayName', value: 'Generated By' },
          { key: 'actions', empty: true, sort: false },
        ]}
        {...filterable}
        bind:selectedRowIds
        rows={filteredRows}
        sortable
        pageSize={pagination.pageSize}
        page={pagination.currentPage}
      >
        <div class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
          {#if cell.key === 'actions'}
            <Badge active={!!row.note} value="!">
              <OverflowMenu aria-label="Open and close list of options" flipped>
                <OverflowMenuItem text="View details" on:click={openModal('preview', { data: row })} />
                <OverflowMenuItem text="View logs" on:click={openModal('logs', { data: normalizeLog(row.extra) })} />
                <OverflowMenuItem text="Note" on:click={openModal('note', { data: row })}>
                  <Badge active={!!row.note} value="!" position="middle-right">Note</Badge>
                </OverflowMenuItem>
              </OverflowMenu>
            </Badge>
          {:else if cell.key === 'licenseOwner.name'}
            <div class="grouped flex-cell flex-column">
              <strong>{row.licenseOwner.name}</strong>
            </div>
          {:else if cell.key === 'licenseTemplate.name'}
            <div class="grouped flex-cell flex-column">
              <div>
                {#if row.isDemo}
                  <Tag type="blue" size="sm">DEMO</Tag>
                {/if}
                {#if row.licenseTemplate.deletedAt || row.licenseOwner.deletedAt}
                  <TooltipDefinition tooltipText="Deleted entity">{row.licenseTemplate.name}</TooltipDefinition>
                {:else}
                  <Link
                    href={`/group/${row.ownerId}?template=${row.templateId}#licenses`}
                    on:click={goToEntity(row.ownerId, row.templateId)}
                  >
                    <strong>{row.licenseTemplate.name}</strong>
                  </Link>
                {/if}
              </div>
            </div>
          {:else if cell.key === 'features'}
            <CustomLicenseFeaturesCell features={row.features} />
          {:else if cell.key === 'emails'}
            <div class="modal-cell">
              {#if row.emails.length}
                <div class="status sent">
                  <CheckmarkIcon />
                </div>
                <div>
                  <Link icon={ViewIcon} on:click={openModal('emailPreview', { data: row.emails[0] })}>View</Link>
                  <Link
                    disabled={row.licenseTemplate.deletedAt || row.licenseOwner.deletedAt}
                    icon={SendIcon}
                    on:click={row.licenseTemplate.deletedAt || row.licenseOwner.deletedAt ? noop : openModal('emailSend', { data: row })}
                    >Resend</Link
                  >
                </div>
              {:else}
                <div class="status pending">
                  <CloseIcon />
                </div>
                <Link
                  disabled={row.licenseTemplate.deletedAt || row.licenseOwner.deletedAt}
                  icon={SendIcon}
                  on:click={row.licenseTemplate.deletedAt || row.licenseOwner.deletedAt ? noop : openModal('emailSend', { data: row })}
                  >Send</Link
                >
              {/if}
            </div>
          {:else if cell.key === 'uploadDestinations'}
            <div class="modal-cell">
              {#if row.uploadDestinations.length}
                <div class="status sent">
                  <CheckmarkIcon />
                </div>
                <CustomLicenseUploadCell destinations={row.uploadDestinations} />
              {:else}
                <div class="status pending">
                  <CloseIcon />
                </div>
                <Link
                  disabled={row.licenseTemplate.deletedAt || row.licenseOwner.deletedAt || !row.licenseTemplate.uploadDestinations?.length}
                  icon={CloudUploadIcon}
                  on:click={row.licenseTemplate.deletedAt || row.licenseOwner.deletedAt || !row.licenseTemplate.uploadDestinations?.length
                    ? noop
                    : openModal('upload', { data: row })}
                >
                  Upload
                </Link>
              {/if}
            </div>
          {:else if cell.display}
            {cell.display(cell.value, row)}
          {:else}
            {cell.value}
          {/if}
        </div>
        <Toolbar>
          <ToolbarBatchActions>
            <Button icon={CompareIcon} on:click={openModal('compare')} disabled={selectedRowIds.length < 2}>Compare</Button>
          </ToolbarBatchActions>
          <ToolbarContent>
            <ToolbarSearchWithRouting
              persistent
              placeholder="Search for licenses"
              bind:searchText
              on:toolbarSearchValueChange={onSearchTextChange}
            />
          </ToolbarContent>
        </Toolbar>
      </DataTable>
      <PaginationWithRouting
        bind:page={pagination.currentPage}
        bind:pageSize={pagination.pageSize}
        pageSizes={[10, 25, 50]}
        totalItems={filteredRows?.length ?? 0}
      />
    </div>
  {:else}
    <InlineNotification lowContrast hideCloseButton kind="info" title="Info:" subtitle="No generated licenses found" />
  {/if}
  {#if modals.emailPreview.open}
    <PreviewSentEmailModal open email={modals.emailPreview.data} on:close={() => closeModal('emailPreview')} />
  {/if}
  {#if modals.emailSend.open}
    <SendEmailModal
      open
      license={modals.emailSend.data}
      on:close={({ detail: data }) =>
        closeModal('emailSend', {
          action: 'email-sent',
          data,
        })}
    />
  {/if}
  {#if modals.upload.open}
    <UploadLicenseModal
      open
      license={modals.upload.data}
      on:close={({ detail: data }) =>
        closeModal('upload', {
          action: 'upload',
          data,
        })}
    />
  {/if}
  {#if modals.note.open}
    <AddOrEditLicenseNoteModal
      open
      license={modals.note.data}
      on:close={({ detail: data }) =>
        closeModal('note', {
          action: 'update-license',
          data,
        })}
    />
  {/if}
  {#if modals.compare.open}
    <CompareLicenseModal open selections={selectedLicenses} on:close={() => closeModal('compare')} />
  {/if}
  {#if modals.preview.open}
    <PreviewGeneratedLicense open license={modals.preview.data} on:close={() => closeModal('preview')} />
  {/if}
  {#if modals.logs.open}
    <JsonPassiveModal open name="License generation logs" data={modals.logs.data} on:close={() => closeModal('logs')} />
  {/if}
</LoadingSpinner>

<style>
  .table :global(.bx--data-table-container) {
    padding-top: 0;
  }

  .table :global(.bx--data-table thead .bx--table-column-checkbox .bx--checkbox--inline) {
    display: none;
  }

  .table-filters {
    display: flex;
    align-items: center;
    padding: 0.5rem 0 0.5rem 2rem;
    background-color: var(--cds-skeleton-01);
  }

  .table-filters :global(.filter-selector) {
    flex: 0;
    margin-right: 1rem;
  }

  .flex-cell {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .grouped :global(em) {
    font-size: 0.75rem;
  }

  .modal-cell {
    display: flex;
    align-items: center;
  }

  .modal-cell :global(a) {
    cursor: pointer;
  }

  .status {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    border-right: 1px solid var(--cds-ui-04);
  }

  .status.sent :global(svg) {
    fill: #24a148;
  }

  .status.pending :global(svg) {
    fill: var(--cds-danger);
  }

  .action-cell {
    width: 40px;
  }

  .action-cell :global(ul button) {
    display: block;
  }

  .action-cell :global(.bx--overflow-menu) {
    min-height: 2.5rem;
  }

  .action-cell :global(.bx--overflow-menu-options) {
    min-width: 11rem;
  }
</style>
