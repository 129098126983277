<script>
  import { InlineNotification, NotificationActionButton } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import { criticalWarningsStore } from '../stores';

  const navigate = useNavigate();
</script>

<div class="critical-warnings-container">
  {#each Object.values($criticalWarningsStore) as { text, notificationButton }}
    <div class="critical-warning">
      <InlineNotification
        class="critical-warning"
        kind="warning"
        title="Warning:"
        subtitle={text}
        lowContrast={true}
        hideCloseButton={true}
      >
        <div slot="actions" class="actions">
          {#if notificationButton?.link}
            <NotificationActionButton on:click={() => navigate(notificationButton.link)}>
              {notificationButton.text}
            </NotificationActionButton>
          {/if}
        </div>
      </InlineNotification>
    </div>
  {/each}
</div>

<style>
  .critical-warnings-container {
    top: 0;
    position: sticky;
    padding-left: 0.5rem;
    z-index: 1;
    max-height: 40vh;
    overflow-y: auto;
  }

  .critical-warning {
    position: relative;
  }

  :global(.critical-warning > .bx--inline-notification) {
    max-width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  .critical-warning:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.5rem;
    right: 0;
    background: linear-gradient(
          -45deg,
          transparent 45%,
          var(--cds-support-01, #f1c21b) 45%,
          var(--cds-support-01, #f1c21b) 55%,
          transparent 55%
        )
        0 0/10px 10px,
      linear-gradient(-45deg, transparent 45%, var(--cds-support-01, #f1c21b) 45%, var(--cds-support-01, #f1c21b) 55%, transparent 55%) 5px
        0px/10px 10px;
    background-color: var(--cds-support-03, #f1c21b);
  }

  @keyframes pulsate {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.3);
    }
  }

  :global(.critical-warning .bx--inline-notification__icon) {
    animation-duration: 1s;
    animation-name: pulsate;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
</style>
