<script>
  import { createEventDispatcher } from 'svelte';
  import { Checkbox } from 'carbon-components-svelte';

  import ExpandButton from './ExpandButton.svelte';
  import CollapseButton from './CollapseButton.svelte';

  export let id,
    name,
    description = null,
    children = [],
    indent = 0,
    selectedId,
    locked,
    checkedIdsSet,
    filteredIdsSet,
    isParentChecked = false,
    parentId = null;

  let expanded = true,
    checkboxEl = null;

  const CHECKBOX_ICON_PX_ESTATE = 20;
  const dispatch = createEventDispatcher();

  function handleCollapse() {
    expanded = false;
  }

  function handleExpand() {
    expanded = true;
  }

  // bubble select event to top (used by parent leafs)
  function handleItemSelect({ detail }) {
    dispatch('item-select', detail);
  }

  // bubble check event to top (used by parent leafs)
  function handleItemCheck({ detail }) {
    const {
      newCheckedIds,
      details: { checked: childChecked, changedId },
    } = detail;

    const allChildrenIds = children.map(({ id: childId }) => childId);
    const allChildrenChecked = childChecked ? children.every(({ id: childId }) => newCheckedIds.includes(childId)) : false;
    const isCurrentChecked = newCheckedIds.includes(id) || newCheckedIds.includes(parentId);
    const idsToRemove = isCurrentChecked && !allChildrenChecked ? [id] : [];
    const idsToAdd =
      allChildrenChecked && !isCurrentChecked
        ? [id]
        : isCurrentChecked
        ? children.reduce((all, { id: childId, children: childs }) => {
            if (childId === changedId || childs?.some(({ id: grandChildId }) => grandChildId === changedId)) {
              return all;
            }

            return [...all, childId];
          }, [])
        : [];

    // only include parent as checked if all children are selected
    const finalCheckedIds = allChildrenChecked
      ? newCheckedIds.reduce((ids, checkedId) => {
          if ([...allChildrenIds, ...idsToRemove].includes(checkedId)) {
            return ids;
          }

          return [...ids, checkedId];
        }, idsToAdd)
      : [...newCheckedIds.filter((checkedId) => !idsToRemove.includes(checkedId)), ...idsToAdd];

    dispatch('item-check', { ...detail, newCheckedIds: finalCheckedIds });
  }

  function getIdsToCheck() {
    return !children.length || children.every(({ id: childId }) => !filteredIdsSet.has(childId))
      ? [id]
      : indeterminate
      ? []
      : children.reduce((all, { id: childId }) => (!filteredIdsSet.has(childId) ? [...all, childId] : all), []);
  }

  function handleCheckboxChange(event) {
    event.stopPropagation();

    const shouldCheck = event.target.checked;
    const allChildrenIds = children.reduce(
      (all, { id: childId, children: childs = [] }) => [...all, childId, ...childs.map(({ id: itemId }) => itemId)],
      []
    );
    const previousCheckedIds = [...checkedIdsSet];
    const idsToAdd = shouldCheck ? getIdsToCheck() : [];
    const idsToRemove = shouldCheck ? allChildrenIds : [id, ...allChildrenIds];

    const newCheckedIds = previousCheckedIds.reduce((ids, checkedId) => {
      if (idsToRemove.includes(checkedId)) {
        return ids;
      }

      return [...ids, checkedId];
    }, idsToAdd);

    dispatch('item-check', { newCheckedIds, details: { changedId: id, checked: shouldCheck, parentId, event } });
  }

  function handleItemClick(event) {
    if (event.target === checkboxEl) {
      handleCheckboxChange(event);
      return;
    }

    const parentNode = checkboxEl?.parentNode;
    if (event.target === parentNode) {
      dispatch('item-select', { id, name, description });
      // eslint-disable-next-line no-useless-return
      return;
    }
  }

  $: selected = selectedId === id;
  $: checked =
    checkedIdsSet.has(id) || isParentChecked || (children.length && children.every(({ id: childId }) => checkedIdsSet.has(childId)));
  $: indeterminate = !checked && children.some(({ id: childId }) => checkedIdsSet.has(childId));
</script>

{#if !filteredIdsSet.has(id)}
  <div class="leaf" style="padding-left: {!indent && !children.length ? CHECKBOX_ICON_PX_ESTATE : indent}px">
    {#if children.length}
      <div class="parent">
        <div class="leaf-btn">
          {#if expanded}
            <CollapseButton on:click={handleCollapse} />
          {:else}
            <ExpandButton on:click={handleExpand} />
          {/if}
        </div>
        <div class="checkbox-wrapper" class:selected>
          <Checkbox disabled={locked} bind:checked bind:indeterminate labelText={name} on:click={handleItemClick} bind:ref={checkboxEl} />
        </div>
      </div>
      {#if expanded}
        {#each children as leaf}
          <svelte:self
            {...leaf}
            {locked}
            {selectedId}
            bind:filteredIdsSet
            bind:checkedIdsSet
            on:item-select={handleItemSelect}
            on:item-check={handleItemCheck}
            parentId={id}
            isParentChecked={checked}
            indent={leaf.children?.length ? CHECKBOX_ICON_PX_ESTATE : 2 * CHECKBOX_ICON_PX_ESTATE}
          />
        {/each}
      {/if}
    {:else}
      <div class="checkbox-wrapper" class:selected>
        <Checkbox disabled={locked} bind:checked bind:indeterminate labelText={name} on:click={handleItemClick} bind:ref={checkboxEl} />
      </div>
    {/if}
  </div>
{/if}

<style>
  .leaf :global(.bx--checkbox-label) {
    padding-top: 0.14rem;
  }

  .leaf :global(.bx--form-item.bx--checkbox-wrapper) {
    margin-bottom: unset;
    margin-top: unset;
  }

  .leaf :global(.bx--form-item.bx--checkbox-wrapper):hover {
    background-color: var(--cds-layer-hover);
    cursor: pointer;
  }

  .leaf-btn {
    margin-left: 0.125rem;
  }

  .parent {
    display: flex;
    align-items: center;
  }

  .checkbox-wrapper {
    flex: 1;
  }

  .selected :global(.bx--form-item.bx--checkbox-wrapper) {
    background-color: var(--cds-selected-ui) !important;
  }
</style>
