<script>
  import { Tooltip } from 'carbon-components-svelte';
  import View16 from 'carbon-icons-svelte/lib/View16';

  export let value;

  let normalizedValue;

  $: {
    try {
      const possiblyArray = JSON.parse(value);
      normalizedValue = Array.isArray(possiblyArray) && possiblyArray.length === 1 ? possiblyArray[0] : possiblyArray;
    } catch (_) {
      normalizedValue = value;
    }
  }
</script>

{#if Array.isArray(normalizedValue)}
  <Tooltip triggerText={`${normalizedValue.length} values`} icon={View16}>
    <div class="values-list-wrapper">
      <p>List of values:</p>
      <ul class="values-list">
        {#each normalizedValue as value}
          <li>{value}</li>
        {/each}
      </ul>
    </div>
  </Tooltip>
{:else}
  <span>{normalizedValue}</span>
{/if}

<style>
  .values-list-wrapper {
    font-size: 12px;
  }

  .values-list-wrapper > :global(ul) {
    font-size: 12px;
    list-style: disc;
    overflow-y: auto;
    max-height: 250px;
    padding: 0.5rem 2rem;
    margin-top: 0.25rem;
  }
</style>
