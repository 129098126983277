<script>
  import { createEventDispatcher } from 'svelte';

  import { Modal } from 'carbon-components-svelte';

  export let open = false,
    userName = null,
    userExists;

  const dispatch = createEventDispatcher();
</script>

<Modal
  modalHeading={`User${!userExists ? " doesn't " : ' already '}exist!`}
  primaryButtonText="Confirm"
  secondaryButtonText="Cancel"
  preventCloseOnClickOutside={true}
  bind:open
  on:click:button--secondary={() => dispatch('cancel')}
  on:close={() => dispatch('cancel')}
  on:submit={() => dispatch('confirm')}
>
  <div class="message">
    {#if userExists}
      <p>User <strong>{userName ?? ''}</strong> already exists under CrushFTP.</p>
      <br />
      <p>
        You can still assign this user to a group (if they're not already assigned), but <strong>no new password or folders</strong> will be
        created. The assignment will happen after saving the changes to the group.
      </p>
    {:else}
      <p>
        User <strong>{userName ?? ''}</strong> doesn't exist. It will be created after saving the changes to the group. Also, the following
        folders accessible to <strong>{userName ?? ''}</strong> user will be created under CrushFTP:
      </p>
      <ul>
        <li>data</li>
        <li>upload</li>
        <li>public</li>
      </ul>
    {/if}
  </div>
  <strong>Please confirm that you are happy to proceed.</strong>
</Modal>

<style>
  .message {
    margin-bottom: 20px;
  }

  .message ul {
    margin: 10px 20px;
  }

  .message li {
    list-style-type: disc;
  }
</style>
