import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function addApiKey(apiKey) {
  return request(`${ROOT_SERVER_URL}/api/api-keys/`, {
    data: apiKey,
    method: 'POST',
  });
}

export function deleteApiKey(id) {
  return request(`${ROOT_SERVER_URL}/api/api-keys/${id}`, {
    method: 'DELETE',
  });
}

export function getApiKeys() {
  return request(`${ROOT_SERVER_URL}/api/api-keys/`);
}

export function getApiRoutes() {
  return request(`${ROOT_SERVER_URL}/api/api-keys/routes`);
}

export function updateApiKey(apiKey, apiKeyId) {
  return request(`${ROOT_SERVER_URL}/api/api-keys/${apiKeyId}`, {
    data: apiKey,
    method: 'PUT',
  });
}
