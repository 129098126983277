<script context="module">
  export const KNOWN_KEYS = {
    rth: 'RTH (Refinitiv Tick History)',
    mrn: 'MRN (Machine Readable News)',
    data_lake_bigquery: 'BigQuery Data Lake',
  };
</script>

<script>
  import { onMount } from 'svelte';

  import { Button, DataTable, OverflowMenu, OverflowMenuItem, Tag, Toolbar, ToolbarContent } from '@mst-fe/carbon-components-svelte';

  import AddOrEditKeyModal from '../../components/external-keys/AddOrEditKeyModal.svelte';
  import DeleteKeyModal from '../../components/external-keys/DeleteKeyModal.svelte';
  import CustomInlineNotification from '../../components/CustomInlineNotification.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';

  import { convertToLocalDisplayTime, rowContainsText } from '../../utils';
  import { getGroupExternalKeys } from '../../services';

  export let groupName, groupId;

  let pageState = {
      keys: [],
      loading: false,
      notifications: new Set(),
    },
    pagination = { currentPage: 1, pageSize: 25 },
    searchText = '';

  let modals = {
    addKey: { open: false },
    editKey: { open: false },
    deleteKey: { open: false },
  };

  const Notification = {
    EMPTY: {
      dismissible: false,
      kind: 'info',
      subtitle: 'There are no external service keys for this group',
      actions: [
        {
          text: 'Add new service key',
          onClick: openModal('addKey'),
        },
      ],
    },
    FETCH_ERROR: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to fetch keys! Please try again later.',
    },
  };

  async function fetchKeys() {
    pageState = { ...pageState, loading: true };

    try {
      const keys = await getGroupExternalKeys(groupId);

      if (!keys.length) {
        pageState.notifications.add('EMPTY');
      }

      pageState = { ...pageState, loading: false, keys };
    } catch (error) {
      console.error('[GroupExternalKeyList] Failed to fetch external keys..', error);
      pageState.notifications.add('FETCH_ERROR');
      pageState = { ...pageState, loading: false };
    }
  }

  function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'add-key':
        pageState = {
          ...pageState,
          keys: [data, ...pageState.keys],
        };

        pageState.notifications.delete('EMPTY');

        break;

      case 'edit-key':
        pageState = {
          ...pageState,
          keys: pageState.keys.map((key) => (key.id !== data.id ? key : data)),
        };

        break;

      case 'delete-key':
        pageState = {
          ...pageState,
          keys: pageState.keys.filter((key) => key.id !== data.id),
        };

        if (!pageState.keys.length) {
          pageState.notifications.add('EMPTY');
        }

        break;

      default:
        console.warn(`[GroupExternalKeyList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function openModal(name, forwardData) {
    return function open() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  function onSearchTextChange(e) {
    searchText = e?.detail ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  onMount(fetchKeys);

  $: filteredRows = searchText ? pageState.keys.filter((key) => rowContainsText(key, searchText)) : pageState.keys;
</script>

<LoadingSpinner loading={pageState.loading}>
  {#each Object.keys(Notification) as notification}
    {#if pageState.notifications.has(notification)}
      <div class="notification">
        <CustomInlineNotification {...Notification[notification]} />
      </div>
    {/if}
  {/each}
  {#if pageState.keys.length}
    <DataTable
      headers={[
        { key: 'name', value: 'Name' },
        { key: 'key', value: 'Key' },
        { key: 'createdAt', value: 'Generated At', display: convertToLocalDisplayTime },
        { key: 'createdByDisplayName', value: 'Created By' },
        { key: 'actions', empty: true, sort: false },
      ]}
      rows={filteredRows}
      sortable
      pageSize={pagination.pageSize}
      page={pagination.currentPage}
    >
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem text="Edit Entry" on:click={openModal('editKey', { data: row })} />
            <OverflowMenuItem danger text="Delete Entry" on:click={openModal('deleteKey', { data: row })} />
          </OverflowMenu>
        {:else if cell.key === 'key'}
          {#if KNOWN_KEYS[cell.value]}
            {KNOWN_KEYS[cell.value]}
          {:else}
            <Tag size="sm" type="blue">Custom</Tag>{cell.value}
          {/if}
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearchWithRouting
            labelText="Search for key"
            persistent
            bind:searchText
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          <Button on:click={openModal('addKey')}>Add new service key</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:page={pagination.currentPage}
      bind:pageSize={pagination.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={filteredRows.length}
    />
  {/if}
  {#if modals.addKey.open}
    <AddOrEditKeyModal
      open
      {groupName}
      {groupId}
      on:close={({ detail: data }) =>
        closeModal('addKey', {
          action: 'add-key',
          data,
        })}
    />
  {/if}
  {#if modals.deleteKey.open}
    <DeleteKeyModal
      open
      key={modals.deleteKey.data}
      on:close={({ detail: data }) =>
        closeModal('deleteKey', {
          action: 'delete-key',
          data,
        })}
    />
  {/if}
  {#if modals.editKey.open}
    <AddOrEditKeyModal
      open
      isEdit
      {groupName}
      {groupId}
      id={modals.editKey.data.id}
      initialValues={{ name: modals.editKey.data.name }}
      on:close={({ detail: data }) =>
        closeModal('editKey', {
          action: 'edit-key',
          data,
        })}
    />
  {/if}
</LoadingSpinner>
