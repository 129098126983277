<script>
  import { useNavigate } from 'svelte-navigator';
  import { Button, Select, SelectItem, TextInput } from 'carbon-components-svelte';

  import Add16 from 'carbon-icons-svelte/lib/Add16';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';

  import AdvancedBuildRunnerTable from './AdvancedBuildRunnerTable.svelte';
  import RunBuildModal from '../../components/build-parameters/RunBuildModal.svelte';
  import CustomInlineNotification from '../../components/CustomInlineNotification.svelte';

  import { getMatchedBuildConfigs } from '../../services';
  import { getServerErrorMessage } from '../../utils';

  const navigate = useNavigate();

  const DEBOUNCE_MS = 500;
  const FILTERS_DETAILS = {
    parameterName: {
      labelText: 'Parameter Name',
      placeholder: 'Enter parameter name',
    },
    parameterValue: {
      labelText: 'Parameter Value',
      placeholder: 'Enter parameter value',
    },
  };
  const BUILD_NOTIFICATIONS = {
    buildSuccess: {
      kind: 'success',
      title: 'Success:',
      subtitle: 'Build(s) successfully triggered',
    },
    buildFailure: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Build(s) could not be triggered',
    },
    buildPartially: {
      kind: 'warning',
      title: 'Warning:',
      subtitle: 'Not all builds triggered successfully',
    },
  };

  let searchTerm = '',
    advancedSearchEnabled = false,
    results = [],
    filters = [],
    debounce,
    loading = false,
    selectedRowIds = [],
    selectedSearchType = '',
    resultNotification;

  let modals = {
    build: { open: false },
  };

  function updatePage(mods) {
    if (!mods || !mods.data) {
      return;
    }

    const { action, data } = mods;
    let notificationKey = '';

    switch (action) {
      case 'build-result':
        notificationKey = data.done ? (typeof data.done === 'boolean' ? 'buildSuccess' : 'buildPartially') : 'buildFailure';
        resultNotification = {
          ...BUILD_NOTIFICATIONS[notificationKey],
          dismissible: true,
          actions: [
            {
              text: 'Check details',
              onClick: () => navigate('/customer-builds#builds-history'),
            },
          ],
        };

        break;

      default:
        console.warn(`Unknown action "${action}" with data:`, data);
        break;
    }
  }

  function handleInputChange(index, name) {
    return function onChange({ detail: value }) {
      filters = filters.map((filter, i) => (index !== i ? filter : { ...filter, [name]: value }));
    };
  }

  function handleValueAddition(index) {
    return function onValueAdd() {
      filters = filters.map((filter, i) => (i !== index ? filter : { ...filter, parameterValue: '' }));
    };
  }

  function handleFilterSelection({ detail: selection }) {
    selectedSearchType = selection;
  }

  function handleFilterAddition() {
    if (!selectedSearchType) {
      return;
    }

    filters = [...filters, { [selectedSearchType]: '' }];

    handleFilterSelection({ detail: '' });
  }

  function handleFilterRemoval(index) {
    return function onFilterRemove() {
      const newFilters = [...filters];
      newFilters.splice(index, 1);
      filters = newFilters;
    };
  }

  function handleModeChange() {
    searchTerm = '';
    results = [];
    selectedRowIds = [];
    filters = [];

    advancedSearchEnabled = !advancedSearchEnabled;
  }

  function handleClickForModal(name, forwardData = {}) {
    return function openModal() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePage(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function createFilterOverview(finalFilters) {
    return finalFilters.reduce((params, { parameterName, parameterValue }) => {
      if (!parameterName && !parameterValue) {
        return params;
      }

      if (!parameterValue) {
        return [...params, `Contains parameter "${parameterName}"`];
      }

      return [...params, `${parameterName ? `Parameter "${parameterName}"` : 'Any parameter'} contains "${parameterValue}"`];
    }, []);
  }

  async function findMatches() {
    searchTerm = filters
      .reduce((all, { parameterName, parameterValue }) => {
        if (!parameterValue && !parameterName) {
          return all;
        }

        if (!parameterName) {
          return [...all, `parameterValue:${parameterValue}`];
        }

        if (!parameterValue) {
          return [...all, `parameterName:${parameterName}`];
        }

        return [...all, `${parameterName}:${parameterValue}`];
      }, [])
      .join('+');

    results = [];
    resultNotification = null;
    loading = true;

    try {
      results = await getMatchedBuildConfigs({ type: 'advanced', searchText: searchTerm });
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Could not fetch configurations!';
      console.error(`[AdvancedBuildRunner] Failed to fetch configurations! Server says: ${errorMessage}`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to find matches! ${errorMessage}`,
      };
    } finally {
      loading = false;
    }
  }

  $: resetFilterTimer(searchTerm);

  function resetFilterTimer(searchText) {
    clearTimeout(debounce);

    results = [];
    selectedRowIds = [];

    if (!searchText) {
      loading = false;
      return;
    }

    resultNotification = null;
    loading = true;

    debounce = setTimeout(async () => {
      try {
        results = await getMatchedBuildConfigs({ type: 'parameter', searchText });
      } catch (error) {
        const errorMessage = getServerErrorMessage(error) ?? 'Could not fetch configurations!';
        console.error(`[AdvancedBuildRunner] Failed to fetch configurations! Server says: ${errorMessage}`, error);

        resultNotification = {
          kind: 'error',
          title: 'Error:',
          subtitle: `Failed to find matches! ${errorMessage}`,
        };
      } finally {
        loading = false;
      }
    }, DEBOUNCE_MS);
  }

  function handleSearchTextChange(e) {
    searchTerm = e?.detail ?? '';
  }

  $: selectedConfigs = results.reduce((selections, { id, ownerId, type, isDefault }) => {
    if (!selectedRowIds.includes(id)) {
      return selections;
    }

    return [
      ...selections,
      {
        configId: id,
        entityId: ownerId,
        entityType: type,
        isDefaultConfig: isDefault,
      },
    ];
  }, []);
  $: advancedFiltersOverview = advancedSearchEnabled ? createFilterOverview(filters) : [];
</script>

{#if resultNotification}
  <CustomInlineNotification {...resultNotification} />
{/if}
{#if advancedSearchEnabled}
  <div class="table-area filters">
    <h4>Custom Search</h4>
    <div class="filter-wrapper">
      {#each filters as filter, index}
        <div class="filter-row">
          <div class="filter-elements">
            {#each Object.keys(filter) as field}
              <TextInput
                labelText={FILTERS_DETAILS[field].labelText}
                placeholder={FILTERS_DETAILS[field].placeholder}
                name={field}
                autocomplete="off"
                value={filters[index][field]}
                on:change={handleInputChange(index, field)}
                size="sm"
              />
              {#if field === 'parameterName' && Object.keys(filter).length === 1}
                <Button size="small" kind="ghost" on:click={handleValueAddition(index)}>Add matching value</Button>
              {/if}
            {/each}
          </div>
          <Button
            kind="danger-tertiary"
            size="field"
            iconDescription="Remove filter"
            icon={TrashCan16}
            on:click={handleFilterRemoval(index)}
          />
        </div>
      {/each}
    </div>
    <div class="filters-menu">
      <Select
        inline
        class="filter-selector"
        labelText="Search by"
        size="sm"
        selected={selectedSearchType}
        on:change={handleFilterSelection}
      >
        <SelectItem value="" text="Select type" disabled hidden />
        <SelectItem value="parameterName" text="Parameter name" />
        <SelectItem value="parameterValue" text="Parameter value" />
      </Select>
      <Button icon={Add16} size="small" on:click={handleFilterAddition} disabled={!selectedSearchType}>Add filter</Button>
      {#if filters.length}
        <Button class="action-button" size="small" disabled={!advancedFiltersOverview.length || loading} on:click={findMatches}>
          Find matches
        </Button>
      {/if}
    </div>
  </div>
{/if}
<AdvancedBuildRunnerTable
  bind:selectedRowIds
  {results}
  bind:searchTerm
  {loading}
  {advancedFiltersOverview}
  {advancedSearchEnabled}
  {handleSearchTextChange}
  {handleModeChange}
  {handleClickForModal}
  {handleFilterRemoval}
/>
{#if modals.build.open}
  <RunBuildModal
    open
    advanced
    configurations={selectedConfigs}
    on:close={({ detail: data }) =>
      closeModal('build', {
        action: 'build-result',
        data,
      })}
  />
{/if}

<style>
  .table-area {
    background-color: var(--cds-layer);
  }

  .filters :global(h4) {
    padding: 1rem 2rem;
    font-size: 1.1rem;
    background-color: var(--cds-skeleton-01);
  }

  .filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-wrapper:not(:empty) {
    padding: 0.5rem 2rem;
  }

  .filter-wrapper .filter-row:not(:first-child) {
    margin-top: 1rem;
  }

  .filter-wrapper .filter-row:last-child {
    margin-bottom: 2rem;
  }

  .filter-elements {
    display: flex;
    align-items: flex-end;
  }

  .filter-elements :global(.bx--text-input__field-outer-wrapper) {
    width: 40%;
    min-width: 200px;
    margin-right: 0.5rem;
  }

  .filter-elements :global(.bx--btn) {
    font-size: 12px;
    min-height: unset;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .filters-menu {
    display: flex;
    align-items: center;
    padding: 0.5rem 0 0.5rem 2rem;
    background-color: var(--cds-skeleton-01);
    border-bottom: 3px solid var(--cds-border-strong);
  }

  .filters-menu :global(.filter-selector) {
    flex: 0;
    margin-right: 1rem;
  }

  .filters-menu :global(.action-button) {
    margin-left: auto;
  }
</style>
