<script>
  import { TabContent } from 'carbon-components-svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';

  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
  import DataFeedInventory from './DataFeedInventory.svelte';
  import ServiceStatusList from './service-state/ServiceStatusList.svelte';
</script>

<TabsWithRouting
  defaultHash={'#data-inventory'}
  tabsWrapperProps={{ 'aria-label': 'Data Feed module tabs' }}
  tabs={[
    { label: 'Inventory', hash: '#data-inventory' },
    { label: 'Service Status', hash: '#service-status' },
  ]}
>
  <LazyLoadedTabContent hash="#data-inventory"><DataFeedInventory /></LazyLoadedTabContent>
  <TabContent><ServiceStatusList /></TabContent>
</TabsWithRouting>
