<script>
  import copy from 'clipboard-copy';
  import { onMount } from 'svelte';
  import { CodeSnippet } from 'carbon-components-svelte';

  import { getAccount } from '../services';

  export let roleName;
  let account;

  onMount(async () => {
    account = await getAccount();
  });

  $: roleArn = account?.account ? `arn:aws:iam::${account.account}:role/${roleName}` : '';
</script>

<CodeSnippet code={roleArn} {copy} />
