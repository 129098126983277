<script>
  import { createEventDispatcher } from 'svelte';
  import { TextInput } from 'carbon-components-svelte';
  import { quill } from 'svelte-quill';

  import LoadingSpinner from '../LoadingSpinner.svelte';

  const dispatch = createEventDispatcher();
  const QUILL_OPTIONS = {
    placeholder: 'Email body',
    modules: {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };

  export let body = null,
    subject = null,
    emailFormReady = false;

  let editorEl = null;

  function handleChange(field) {
    return function onChange({ detail: value }) {
      dispatch('change', { field, value });
    };
  }

  function getBodyHtml() {
    return body?.html ?? '';
  }

  $: if (editorEl) {
    editorEl.innerHTML = getBodyHtml();
    quill(editorEl, QUILL_OPTIONS);
  }
</script>

<LoadingSpinner loading={!emailFormReady}>
  {#if emailFormReady}
    <div class="email-content">
      <TextInput autocomplete="off" labelText="Subject" placeholder="Email subject" value={subject} on:change={handleChange('subject')} />
      <div class="body-editor">
        <label class="bx--label" for="editor">Body</label>
        <div bind:this={editorEl} role="textbox" class="editor" on:text-change={handleChange('body')} />
      </div>
    </div>
  {/if}
</LoadingSpinner>

<style>
  .body-editor {
    margin-top: 1rem;
  }

  .body-editor :global(.ql-toolbar) {
    background-color: var(--cds-hover-ui);
    border: none;
  }

  .body-editor :global(.editor.ql-container) {
    border: none;
    border-bottom: 1px solid var(--cds-ui-04);
  }

  .body-editor :global(.ql-editor) {
    background-color: var(--cds-field-01);
  }

  .body-editor :global(.ql-editor p) {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
  }

  .body-editor :global(.ql-editor)::before {
    color: var(--cds-text-placeholder);
    font-style: normal;
  }

  .body-editor :global(.ql-header.ql-picker) {
    color: var(--cds-text-02);
  }

  .body-editor :global(.ql-picker-label svg polygon),
  .body-editor :global(.ql-formats button svg path),
  .body-editor :global(.ql-formats button svg line),
  .body-editor :global(.ql-formats button svg polyline),
  .body-editor :global(.ql-formats button svg rect) {
    stroke: var(--cds-text-02);
  }
</style>
