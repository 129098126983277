<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import { Accordion, AccordionItem, Checkbox, InlineNotification, Modal, TextArea, TooltipDefinition } from 'carbon-components-svelte';
  import uniqBy from 'lodash/uniqBy';

  import FinalBuildTable from './FinalBuildTable.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { createBuildNote, getFinalParameters, runBuilds } from '../../services';

  export let configurations,
    open = false,
    advanced = false,
    runningPreviousBuild = false;

  let loading = false,
    resultNotification,
    configs = [],
    canRunBuild = false,
    selectedConfigIds = new Set(),
    builds = {};

  const dispatch = createEventDispatcher();
  const currentConfig = !advanced ? { ...configurations[0] } : null;

  async function runBuild() {
    if (!selectedConfigIds.size) {
      resultNotification = {
        hideCloseButton: false,
        kind: 'warning',
        title: 'No selected configurations',
        subtitle: 'Please select at least one configuration to trigger build process',
      };

      return;
    }

    resultNotification = null;
    loading = true;
    let buildResults = { done: false };

    try {
      const finalBuilds = [...selectedConfigIds].reduce((cfgs, id) => ({ ...cfgs, [id]: builds[id] }), {});
      buildResults = await runBuilds({ builds: finalBuilds });
      await Promise.all(
        buildResults.builds.map(async (buildResult) => {
          try {
            const config = configs.find((c) => c.id === buildResult.configId);
            if (config && config.note.value) {
              await createBuildNote({
                entityId: buildResult.id,
                title: '',
                value: config.note.value,
              });
            }
          } catch (error) {
            console.error(`[RunBuildModal] Failed to create note for build ${buildResult.id}!`, error);
          }
        })
      );
    } catch (error) {
      console.error('[RunBuildModal] Failed to run build!', error);
    } finally {
      dispatch('close', { done: buildResults.done });
      loading = false;
    }
  }

  async function getParameters() {
    if (runningPreviousBuild) {
      configs = normalizePreviousParameters();
    }

    if (configs.length) {
      selectedConfigIds = new Set(configs.map(({ id }) => id));
      return;
    }

    loading = true;

    try {
      const allConfigs = await Promise.all(
        configurations.map(async ({ configId, entityId, entityType, isDefaultConfig }) =>
          getFinalParameters({
            configId,
            configOwnerId: entityId,
            configOwnerType: entityType,
            isDefaultConfig: !!isDefaultConfig,
          })
        )
      );

      configs = uniqBy(allConfigs.flat(), 'id');
      configs = configs.map((c) => ({
        ...c,
        note: {},
      }));
      selectedConfigIds = new Set(configs.filter(({ disabled }) => !disabled).map(({ id }) => id));
    } catch (error) {
      console.error('[RunBuildModal] Failed to get related parameters!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to get related parameters! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function normalizePreviousParameters() {
    if (!currentConfig) {
      console.warn('[RunBuildModal] Cannot get parameters added to previous run when there are multiple configs!');
      return [];
    }

    const { triggeredConfig } = currentConfig;
    if (!triggeredConfig) {
      console.warn('[RunBuildModal] Triggered config not found on previous build configuration!');
      return [];
    }

    return [
      {
        id: currentConfig.configId,
        parameters: Object.entries(triggeredConfig).map(([parameterName, parameterValue], index) => ({
          id: `${parameterName}-${index}`,
          parameterName,
          parameterValue,
        })),
        note: {},
      },
    ];
  }

  function stopPropagation(event) {
    event.stopPropagation();
  }

  function handleConfigCheck(id) {
    return function onCheck({ detail: checked }) {
      const action = checked ? 'add' : 'delete';
      selectedConfigIds[action](id);
    };
  }

  function handleBuildChange({ detail: config }) {
    builds = {
      ...builds,
      [config.id]: config.parameters,
    };
  }

  onMount(getParameters);
</script>

<Modal
  modalHeading={runningPreviousBuild ? 'Re-run build' : 'Run build'}
  preventCloseOnClickOutside={true}
  primaryButtonText="Run"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={!canRunBuild || loading}
  shouldSubmitOnEnter={false}
  bind:open
  size="lg"
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={runBuild}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <div class="intro">
      {#if advanced}
        <p>You are about to run all the selected builds below.</p>
      {:else}
        {#if currentConfig.entityType === 'organization'}
          <p>You are about to run builds for all selected groups & targets of the following organization:</p>
          <h4>{currentConfig.entityName}</h4>
        {/if}
        {#if currentConfig.entityType === 'group'}
          {#if runningPreviousBuild}
            <InlineNotification
              lowContrast
              kind="info"
              title="Info:"
              subtitle="The build configuration below does not reflect any changes made to the target since the initial execution. Any parameters which have been added or removed since that time do not appear here. Parameters can still be added or removed to this specific run."
              hideCloseButton
            />
          {/if}
          <p>You are about to run a build for the following entity:</p>
          <h4>{currentConfig.entityName}</h4>
          <em>Target name: <strong>{currentConfig.targetName}</strong></em>
        {/if}
      {/if}
    </div>
    {#if configs.length}
      <div class="padded-bottom">
        {#if !advanced && currentConfig.entityType === 'group'}
          <FinalBuildTable entityId={currentConfig.configId} parameters={configs[0].parameters} on:parametersChange={handleBuildChange} />
          <h5 class="notes-header">Note</h5>
          <TextArea name="value" placeholder="Enter note..." bind:value={configs[0].note.value} />
        {:else}
          <Accordion class="custom-accordion">
            {#each configs as config}
              <AccordionItem>
                <div slot="title">
                  <div class="accordion-header">
                    <Checkbox
                      checked={selectedConfigIds.has(config.id)}
                      on:click={stopPropagation}
                      on:check={handleConfigCheck(config.id)}
                    />
                    <div class="accordion-title">
                      <div class="config-name">
                        <h5>{config.name}</h5>
                        {#if config.isDefault}
                          <TooltipDefinition tooltipText={`This is the default build target for ${config.ownerName}.`} align="start">
                            <em>(Default)</em>
                          </TooltipDefinition>
                        {/if}
                      </div>
                      <div>{config.ownerName}</div>
                    </div>
                  </div>
                </div>
                <FinalBuildTable entityId={config.id} parameters={config.parameters} on:parametersChange={handleBuildChange} />
                <h5 class="notes-header">Note</h5>
                <TextArea name="value" placeholder="Enter note..." bind:value={config.note.value} />
              </AccordionItem>
            {/each}
          </Accordion>
        {/if}
      </div>
    {:else if !loading}
      <InlineNotification
        hideCloseButton
        lowContrast
        kind="warning"
        title="Cannot run a build"
        subtitle={!advanced
          ? currentConfig.entityType === 'organization'
            ? 'A build cannot be executed because there are no group/target build configurations within the selected organization.'
            : 'A build cannot be executed because there are no parameters within the selected target.'
          : null}
      />
    {/if}
    <div class="footer">
      <Checkbox
        labelText={`I understand that when I click "Run" the ${
          configs.length > 1 ? 'builds' : 'build'
        } will be executed with the selected configuration and parameters.`}
        disabled={!configs.length}
        bind:checked={canRunBuild}
      />
    </div>
  </LoadingSpinner>
</Modal>

<style>
  .intro {
    margin-bottom: 1rem;
  }

  .intro :global(h4) {
    border-left: 2px solid;
    padding-left: 1rem;
  }

  .intro :global(em) {
    padding-left: 1rem;
  }

  :global(.padded-bottom) {
    padding-bottom: 1rem;
  }

  :global(.custom-accordion .bx--accordion__content) {
    padding-right: unset;
  }

  .accordion-header {
    display: flex;
  }

  .accordion-header :global(.bx--form-item) {
    flex: 0;
    margin-right: 1rem;
  }

  .accordion-title {
    display: flex;
    flex-direction: column;
  }

  .config-name {
    display: flex;
  }

  .config-name :global(em) {
    font-size: 11px;
    margin-left: 0.2rem;
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 4rem;
    left: 0;
    padding: 1rem;
    background-color: var(--cds-ui-01);
  }

  .notes-header {
    padding-top: 1rem;
  }
</style>
