<script>
  export let email = {};
</script>

<div class="preview">
  <span class="bx--label">Subject</span>
  <div class="preview-area">
    {#if email.subject}
      {email.subject}
    {:else}
      <em class="info">No subject defined</em>
    {/if}
  </div>
  <span class="bx--label">Body</span>
  <div class="preview-area">{@html email.body}</div>
</div>

<style>
  .preview :global(.bx--label) {
    margin-top: 0.5rem;
  }

  .preview-area {
    border: 1px solid var(--cds-ui-04);
    padding: 0.3rem 1rem;
  }

  .info {
    color: var(--cds-text-02);
  }
</style>
