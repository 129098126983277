<script>
  import { createEventDispatcher } from 'svelte';

  import { OverflowMenu, OverflowMenuItem } from 'carbon-components-svelte';

  import CheckmarkIcon from 'carbon-icons-svelte/lib/CheckmarkOutline16';
  import ClockIcon from 'carbon-icons-svelte/lib/Time20';
  import WarningIcon from 'carbon-icons-svelte/lib/WarningAlt16';

  import { convertToLocalDisplayTime, downloadCsvStringAsFile, downloadJsonAsFile } from '../../../utils';
  import { getServicePermissionSummary } from '../../../services';

  export let service;

  const dispatch = createEventDispatcher();

  function onShowRegression() {
    if (!service?.hasRegression) {
      return;
    }
    dispatch('reviewRegression', { serviceId: service?.id });
  }

  async function handleDownloadServicePermissionSummary({ isCsv }) {
    try {
      const { permissions } = await getServicePermissionSummary(service.id, { isCsv });
      const downloadFile = isCsv ? downloadCsvStringAsFile : downloadJsonAsFile;
      downloadFile(`${service.name}-permission-summary-${service.synchronizedAt.slice(0, 10)}`, permissions);
    } catch (error) {
      console.error('[ServiceStatusCard] Failed to download permission summary!', error);
    }
  }
</script>

<div class="service-status-card" class:warning={service?.hasRegression}>
  <div class="status-indicator">
    {#if service?.hasRegression}
      <WarningIcon /> Needs attention
    {:else}
      <CheckmarkIcon /> Synchronized
    {/if}
  </div>
  <h3 class="title">
    {service?.name}
    {#if service}
      <OverflowMenu light>
        <OverflowMenuItem text="Download Permission Summary (JSON)" on:click={handleDownloadServicePermissionSummary} />
        <OverflowMenuItem
          text="Download Permission Summary (CSV)"
          on:click={() => handleDownloadServicePermissionSummary({ isCsv: true })}
        />
        <OverflowMenuItem text="Resolve Regression" disabled={!service?.hasRegression} on:click={onShowRegression} />
      </OverflowMenu>
    {/if}
  </h3>
  <div class="details">
    <div class="info">
      <ClockIcon />
      <div class="label">
        <strong>Last successful sync</strong>
        <span>{service?.synchronizedAt ? convertToLocalDisplayTime(service?.synchronizedAt) : 'Unknown'}</span>
      </div>
    </div>
    <div class="info">
      <ClockIcon />
      <div class="label">
        <strong>Last regression resolution</strong>
        <span>{service?.regressionResolvedAt ? convertToLocalDisplayTime(service?.regressionResolvedAt) : 'Unknown'}</span>
      </div>
    </div>
  </div>
</div>

<style>
  .service-status-card {
    background-color: var(--cds-ui-01);
    border: 3px solid var(--cds-support-success);
    display: flex;
    flex-direction: column;
    margin: 0.3rem;
    min-height: 15rem;
    padding: 1.5rem;
    position: relative;
  }
  .service-status-card.warning {
    border-color: var(--outline-warning-color);
  }
  :global(html[theme='g90']) .service-status-card:not(.warning) {
    border-color: var(--cds-support-success-inverse);
  }

  .status-indicator {
    align-items: center;
    background-color: var(--cds-support-success);
    border-radius: 2px 2px 0 0;
    color: var(--cds-text-04);
    display: flex;
    font-size: 0.725rem;
    left: 0.5rem;
    padding: 0.2rem 0.5rem 0.11rem;
    position: absolute;
    top: -1.5rem;
  }
  .status-indicator :global(svg) {
    margin-right: 0.3rem;
  }
  .service-status-card.warning .status-indicator {
    background-color: var(--outline-warning-color);
  }
  :global(html[theme='g90']) .service-status-card:not(.warning) .status-indicator {
    background-color: var(--cds-support-success-inverse);
  }

  /* For accessibility, keep the section-heading element as an h3 tag, but reduce size */
  .service-status-card .title {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }

  .service-status-card .details {
    margin: 0.5rem 0;
  }
  .service-status-card .details .info {
    display: flex;
    align-items: center;
    margin: 1rem 0 1.5rem;
  }
  .service-status-card .details .info :global(svg):not(.icon-success):not(.icon-error) {
    fill: var(--cds-text-02);
  }
  .service-status-card .details .info :global(strong) {
    margin-bottom: 0.2rem;
  }

  .service-status-card .details .info :global(span) {
    color: var(--cds-text-02);
  }
  .service-status-card .details .label {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  .service-status-card :global(.bx--overflow-menu-options),
  .service-status-card :global(.bx--overflow-menu-options__option > button) {
    min-width: 18rem;
  }
</style>
