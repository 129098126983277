<script>
  import { Button, DataTable, InlineNotification, Modal } from 'carbon-components-svelte';
  import { JsonView } from '@zerodevx/svelte-json-view';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';

  import TaskExecutionDuration from './TaskExecutionDuration.svelte';
  import TaskExecutionDetails from './TaskExecutionDetails.svelte';
  import { cancelExecution, getExecutions } from '../../services';
  import { convertToLocalDisplayTime } from '../../utils';
  import { messagesStore } from '../../stores';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';

  let executionDetailsModal;
  let pageData = { loading: true };
  let query = { currentPage: 1, pageSize: 25 };

  $: runningTaskExecutionsCount = $messagesStore.runningTaskExecutions?.length ?? 0;
  // We are loading loadExecutions function on `runningTaskExecutionsCount` change
  $: loadExecutions(runningTaskExecutionsCount);

  async function loadExecutions() {
    pageData = { ...pageData, loading: true };
    try {
      const { currentPage, pageSize: limit } = query;
      const { count: executionCount, results: executions } = await getExecutions({ limit, offset: (currentPage - 1) * limit });
      pageData = { executionCount, executions, loading: false };
    } catch (error) {
      console.error('[TaskList] Failed to load executions!', error);
      pageData = { loading: false, error };
    }
  }

  function onPaginationUpdate({ detail: { pageSize, page: currentPage } }) {
    query = { ...query, pageSize, currentPage };
    loadExecutions();
  }

  function cancelTaskExecution(executionId) {
    return async function cancel() {
      pageData = { ...pageData, loading: true, cancelationError: null };

      try {
        await cancelExecution(executionId);
        pageData = { ...pageData, loading: false };
      } catch (error) {
        console.error('[TaskList] Failed to cancel execution!', error);
        pageData = { ...pageData, loading: false, cancelationError: error };
      }
    };
  }
</script>

{#if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load executions! Please try again later."
  />
{:else}
  {#if pageData.cancelationError}
    <InlineNotification kind="error" lowContrast title="Error:" subtitle="Failed to cancel execution! Please try again later." />
  {/if}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'task.name', value: 'Task Name' },
        { key: 'statusDescription', value: 'Status' },
        { key: 'createdByDisplayName', value: 'Triggered By' },
        { key: 'createdAt', value: 'Triggered At', display: convertToLocalDisplayTime },
        { key: 'executionTime', value: 'Execution Time' },
        { key: 'actions', empty: true },
      ]}
      rows={pageData?.executions ?? []}
    >
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          {#if row.data}
            <TaskExecutionDetails
              {row}
              on:showExecutionDetailsModal={({ detail: data }) => {
                executionDetailsModal = data;
              }}
            />
          {:else if row.status === 'running'}
            <Button kind="ghost" on:click={cancelTaskExecution(row.id)}>Cancel</Button>
          {/if}
        {:else if cell.key === 'executionTime'}
          <TaskExecutionDuration {row} />
        {:else if cell.key === 'statusDescription' && cell.value === 'Running'}
          <div class="running-cell-wrapper">
            <div>Running...</div>
            <LoadingSpinner centered={false} compact inlineLeftMargin withOverlay={false} />
          </div>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
    </DataTable>
    <PaginationWithRouting
      bind:page={query.currentPage}
      bind:pageSize={query.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={pageData.executionCount ?? 0}
      on:update={onPaginationUpdate}
    />
  </LoadingSpinner>
{/if}

<Modal
  modalHeading="Execution Details"
  preventCloseOnClickOutside={true}
  size="lg"
  passiveModal
  on:click:button--secondary={() => {
    executionDetailsModal = false;
  }}
  on:close={() => {
    executionDetailsModal = false;
  }}
  bind:open={executionDetailsModal}
>
  <JsonView json={executionDetailsModal} />
</Modal>

<style>
  .running-cell-wrapper {
    display: inline-flex;
  }
</style>
