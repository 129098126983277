<script>
  import { TabContent } from 'carbon-components-svelte';

  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
  import EmailTemplates from './EmailTemplates.svelte';
</script>

<TabsWithRouting tabsWrapperProps={{ 'aria-label': 'Emails tabs' }} tabs={[{ label: 'Email templates', hash: '#email-templates' }]}>
  <TabContent><EmailTemplates /></TabContent>
</TabsWithRouting>
