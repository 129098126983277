import { ROOT_SERVER_URL } from '../config';
import { collectQueryParams, request } from '../utils';

export function createFeature(data) {
  return request(`${ROOT_SERVER_URL}/api/licenses/features`, {
    data,
    method: 'POST',
  });
}

export function createFeatureGroup(data) {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/groups`, {
    data,
    method: 'POST',
  });
}

export function createLicenseTemplate(data) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates`, {
    data,
    method: 'POST',
  });
}

export function deleteFeature(featureId) {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/${featureId}`, {
    method: 'DELETE',
  });
}

export function deleteFeatureGroup(groupId) {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/groups/${groupId}`, {
    method: 'DELETE',
  });
}

export function deleteLicenseTemplate(templateId) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/${templateId}`, {
    method: 'DELETE',
  });
}

export function editFeature(id, changes) {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/${id}`, {
    data: changes,
    method: 'PUT',
  });
}

export function editFeatureGroup(id, changes) {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/groups/${id}`, {
    data: changes,
    method: 'PUT',
  });
}

export function editFeatureAndGroupDependencies(changes) {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/dependencies`, {
    data: changes,
    method: 'POST',
  });
}

export function editLicense(licenseId, changes) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/${licenseId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function editLicenseTemplate({ edit, templateId }, changes) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/${edit}/${templateId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function generateLicense(data) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/generate`, {
    data,
    method: 'POST',
  });
}

export function getGroupedFeatures() {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/grouped`);
}

export function getAllFeaturesAndGroups() {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/all`);
}

export function getFeatureGroups() {
  return request(`${ROOT_SERVER_URL}/api/licenses/features/groups`);
}

export function getFeatures() {
  return request(`${ROOT_SERVER_URL}/api/licenses/features`);
}

export function getGeneratedLicenses(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/generated/?${urlSearchParams}`);
}

export function getLicenseTemplate(templateId) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/${templateId}`);
}

export function getLicenseTemplateFeaturesAndGroups(templateId) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/features/${templateId}`);
}

export function getLicenseTemplates(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/?${urlSearchParams}`);
}

export function uploadLicenseFile(data) {
  return request(`${ROOT_SERVER_URL}/api/licenses/templates/upload`, {
    data,
    method: 'POST',
  });
}
