<script>
  import { onMount } from 'svelte';

  import { Button, InlineNotification, Link, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getOrganizations } from '../../services';
  import { asOptionalTextColumn, convertToLocalDisplayTime, rowContainsText } from '../../utils';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  let pageData = { loading: true };
  let pagination = { currentPage: 1, pageSize: 25 };
  let searchText = '';
  let filteredRowCount = 0;
  let filteredRows = [];

  const navigate = useNavigate();

  onMount(async () => {
    try {
      const organizations = await getOrganizations();
      pageData = { organizations, loading: false };
    } catch (error) {
      console.error('[OrganizationList] Failed to load organizations!', error);
      pageData = { loading: false, error };
    }
  });

  function onSearchTextChange(e) {
    // Note: we intentionally fall back on an empty string here since the event raised by clicking the "Clear" button
    // will not contain a value.
    searchText = e?.detail ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  function viewOrganizationDetails(event, organizationId) {
    event.preventDefault();
    navigate(`/organization/${organizationId}`);
  }

  $: if (pageData.organizations) {
    filteredRows = searchText
      ? pageData.organizations.filter((organization) => rowContainsText(organization, searchText))
      : pageData.organizations;
    filteredRowCount = filteredRows.length;
  }
</script>

{#if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load organizations! Please try again later."
  />
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'name', value: 'Name' },
        { key: 'shortName', value: 'Short Name', ...asOptionalTextColumn },
        { key: 'aliases', value: 'Aliases', ...asOptionalTextColumn },
        { key: 'salesforceId', value: 'Salesforce ID', ...asOptionalTextColumn },
        { key: 'createdAt', value: 'Created', display: convertToLocalDisplayTime },
        { key: 'updatedAt', value: 'Updated', display: convertToLocalDisplayTime },
      ]}
      rows={filteredRows}
      sortable
      page={pagination.currentPage}
      pageSize={pagination.pageSize}
    >
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'name'}
          <Link href={`/organization/${row.id}`} on:click={(event) => viewOrganizationDetails(event, row.id)}>{row.name}</Link>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearchWithRouting
            labelText="Search for organizations"
            persistent
            bind:searchText
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          <Button on:click={() => navigate('/organization/create')}>Create organization</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:page={pagination.currentPage}
      bind:pageSize={pagination.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={filteredRowCount}
    />
  </LoadingSpinner>
{/if}
