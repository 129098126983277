<script>
  import { createEventDispatcher } from 'svelte';

  import {
    InlineNotification,
    Modal,
    OutboundLink,
    StructuredList,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
  } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { getSshKey, removeSshKey } from '../../services';

  export let sshKeyId, ownerName, ownerType;
  export let currentGroupId = null;
  export let open = false;

  let loading = false;
  let resultNotification;
  let sshKey;

  const dispatch = createEventDispatcher();

  async function getKey() {
    loading = true;
    try {
      sshKey = await getSshKey(sshKeyId);
    } catch (error) {
      console.error('[RemoveSshKeyModal] Failed to retrieve SSH key!', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to retrieve SSH key! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  async function removeKey() {
    loading = true;
    try {
      await removeSshKey(sshKeyId);

      dispatch('submit');
      dispatch('close');
    } catch (error) {
      console.error('[RemoveSshKeyModal] Failed to remove SSH key!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to remove SSH key! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  $: if (open) {
    getKey(sshKeyId);
  }

  $: associationsByServiceName =
    sshKey?.dataServiceAssociations.reduce((acc, association) => {
      if (!acc[association.serviceName]) {
        return { ...acc, [association.serviceName]: [association.group] };
      }

      const groupsAssociatedWithService = acc[association.serviceName];
      const groupInList = groupsAssociatedWithService.some((group) => group.id === association.groupId);
      return {
        ...acc,
        [association.serviceName]: groupInList ? groupsAssociatedWithService : [...groupsAssociatedWithService, association.group],
      };
    }, {}) ?? {};
</script>

<Modal
  danger
  modalHeading="Remove SSH Key"
  preventCloseOnClickOutside={true}
  primaryButtonText="Remove"
  secondaryButtonText="Cancel"
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={removeKey}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p class="paragraph-info-message">
      You are about to remove an SSH key for {ownerName}.
      {#if ownerType === 'group'}
        Once removed, this key will no longer appear under the group's SSH key list.
      {:else if ownerType === 'user'}
        Once removed, this key will no longer appear on the user's SSH key list. If the user belongs to any groups, the SSH key will no
        longer appear under the group's key list.
      {/if}
    </p>
    {#if ownerType === 'user' && Object.keys(associationsByServiceName).length > 0}
      <p class="paragraph-info-message">This key has service associations through the following groups:</p>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head noWrap>Service Name</StructuredListCell>
            <StructuredListCell head>Groups</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          {#each Object.entries(associationsByServiceName) as [serviceName, associatedGroups]}
            <StructuredListRow>
              <StructuredListCell noWrap>{serviceName}</StructuredListCell>
              <StructuredListCell>
                <div class="association-group">
                  {#each associatedGroups as { id, name }}
                    {#if currentGroupId === id}
                      <span>{name}</span>
                    {:else}
                      <OutboundLink href={`/group/${id}#ssh-keys`}>{name}</OutboundLink>
                    {/if}
                  {/each}
                </div>
              </StructuredListCell>
            </StructuredListRow>
          {/each}
        </StructuredListBody>
      </StructuredList>
    {/if}
  </LoadingSpinner>
</Modal>

<style>
  .paragraph-info-message {
    margin-bottom: 1rem;
  }
  .association-group {
    display: flex;
    flex-direction: column;
  }
</style>
