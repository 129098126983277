<script>
  import { createEventDispatcher } from 'svelte';

  import { Button, Search, Tag, TooltipIcon } from 'carbon-components-svelte';
  import LoadBalancerGlobal from 'carbon-icons-svelte/lib/LoadBalancerGlobal16';
  import Close from 'carbon-icons-svelte/lib/Close16';

  export let items = [],
    level = 1,
    active = false,
    itemId = null,
    itemName = null,
    isFeedHandler = false,
    parentId = null,
    filteredIds = new Set(),
    notifications,
    supportedTypes = '',
    interactiveAfterLevel = 1;

  let timer = null,
    notification = null,
    searchTerm = '';

  const dispatch = createEventDispatcher();
  const NOTIFICATIONS = {
    exists: 'Item already in group',
    nested: 'Cannot nest same groups',
    parent: 'Selected group is a parent',
    notCustom: 'Only features that are marked as "Custom" can be under a feed handler group',
    alreadyFeedHandler: 'Feature already under another feed handler group',
    exceedsAllowedNestingLevel: 'Max nesting level reached for current group',
  };

  function itemAlreadyInGroup(groups, itemToFind) {
    if (groups.some((group) => group.id === itemToFind || (group.children && itemAlreadyInGroup(group.children, itemToFind)))) {
      return true;
    }

    return false;
  }

  /**
   * @description On dragover of an item, checks if the item complies with the conditions of current box
   * @param event
   */
  function handleDragOver(event) {
    event.stopPropagation();

    const [type] = event.dataTransfer.types;
    if ('droppable' in event.target.dataset === false || !event.target.dataset.accepts.split('|').includes(type)) {
      return;
    }

    event.target.classList.add('active');
    event.preventDefault();
  }

  function handleDragLeave(event) {
    event.stopPropagation();
    event.target.classList.remove('active');
  }

  function handleDrop(event) {
    event.stopPropagation();
    notification = null;

    const destinationAccepts = event.target.dataset.accepts;
    const [dropItemType] = event.dataTransfer.types;

    if (!destinationAccepts.split('|').includes(dropItemType)) {
      event.dataTransfer.clearData();
      event.target.classList.remove('active');

      return;
    }

    const dropItemId = event.dataTransfer.getData(dropItemType);
    const { itemId: destinationItemId } = event.target.dataset;

    if (itemAlreadyInGroup(items, dropItemId)) {
      event.dataTransfer.clearData();
      event.target.classList.remove('active');
      notification = 'exists';

      return;
    }

    if (dropItemId === destinationItemId) {
      event.dataTransfer.clearData();
      event.target.classList.remove('active');
      notification = 'nested';

      return;
    }

    dispatch('item-drop', {
      action: event.dataTransfer.effectAllowed,
      data: { itemId: dropItemId, itemType: dropItemType, destinationItemId },
    });

    event.dataTransfer.clearData();
    event.target.classList.remove('active');
  }

  function handleItemRemove(toRemoveId, itemType) {
    return function onItemRemove() {
      const destinations = {
        features: itemId,
        groups: parentId,
      };

      dispatch('item-remove', {
        action: 'remove',
        data: {
          itemId: toRemoveId,
          itemType,
          destinationItemId: destinations[itemType],
        },
      });
    };
  }

  function handleSearchChange({ target }) {
    searchTerm = target?.value?.trim() ?? '';
    dispatch('filter', searchTerm);
  }

  function showFeedHandler(array, elementName) {
    if (!array?.length) {
      return '';
    }

    const feedHandler = array.find((item) => item.isFeedHandler);
    if (!feedHandler) {
      return '';
    }

    return feedHandler.name !== elementName ? `(${feedHandler.name})` : '';
  }

  $: notification = notifications?.[itemId];
  $: if (notification) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      notification = null;
      delete notifications[itemId];
    }, 3000);
  }

  $: interactiveBox = level > interactiveAfterLevel;
</script>

{#if level === 1}
  <Search value={searchTerm} on:input={handleSearchChange} on:clear={handleSearchChange} />
{/if}

{#if !filteredIds.has(itemId)}
  <div
    class="box"
    tabindex={interactiveBox ? 0 : -1}
    data-item-id={itemId}
    data-parent-id={parentId}
    data-accepts={interactiveBox ? supportedTypes : ''}
    data-droppable={interactiveBox}
    on:dragover={handleDragOver}
    on:dragleave={handleDragLeave}
    on:drop={handleDrop}
  >
    {#if itemName}
      <div class="bar">
        <span class="title">
          {itemName}
          {#if isFeedHandler}
            <TooltipIcon tooltipText="Feed handler" align="end" direction="right" icon={LoadBalancerGlobal} />
          {/if}
          {#if notification}
            <Tag class="warning" size="sm">{NOTIFICATIONS[notification]}</Tag>
          {/if}
        </span>
        <div class="action">
          {#if active && level > 2}
            <div class="xs-btn">
              <Button
                size="small"
                tooltipPosition="left"
                tooltipAlignment="end"
                kind="danger-ghost"
                iconDescription="Remove"
                icon={Close}
                on:click={handleItemRemove(itemId, 'groups')}
              />
            </div>
          {/if}
        </div>
      </div>
    {/if}
    {#if !items.length}
      <em class="message">
        {level === 1
          ? 'Create your first group from the list on the left, so you can start configuring the structure.'
          : `${active ? 'Drag' : 'After unlocking the board, drag'} and drop items from the lists on your left to configure the format`}
      </em>
    {:else}
      {#each items as item}
        {#if item.children}
          <svelte:self
            {active}
            {filteredIds}
            {supportedTypes}
            bind:notifications
            items={item.children}
            level={level + 1}
            itemName={item.name}
            isFeedHandler={item.isFeedHandler}
            itemId={item.id}
            parentId={itemId}
            on:item-drop
            on:item-remove
          />
        {:else}
          <div class="item" tabindex={active ? 0 : -1}>
            <span>
              {item.name}
              <strong>{showFeedHandler(item.featureGroups, itemName)}</strong>
            </span>
            <div class="action">
              {#if active}
                <div class="xs-btn">
                  <Button
                    size="small"
                    tooltipPosition="left"
                    tooltipAlignment="end"
                    kind="danger-ghost"
                    iconDescription="Remove"
                    icon={Close}
                    on:click={handleItemRemove(item.id, 'features')}
                  />
                </div>
              {/if}
            </div>
          </div>
        {/if}
      {/each}
    {/if}
  </div>
{/if}

<style>
  .box {
    display: flex;
    flex-direction: column;
    min-height: 7rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    background-color: rgba(100, 100, 100, 0.1);
  }

  .box:global(.active) {
    box-shadow: inset 0px 0px 0px 2px var(--cds-active-01);
  }

  .box .message {
    color: var(--cds-text-helper);
    pointer-events: none;
    width: 70%;
    font-size: 0.75rem;
  }

  .bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0 0.5rem 0;
    margin-bottom: 0.5rem;
    pointer-events: none;
  }

  .bar :global(button) {
    pointer-events: all;
  }

  .title {
    font-size: 1.25rem;
  }

  .title :global(.bx--tag.warning) {
    background-color: var(--outline-warning-color);
    color: var(--cds-text-04);
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: rgba(100, 100, 100, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    pointer-events: none;
  }

  .action {
    pointer-events: all;
  }

  .xs-btn :global(.bx--btn.bx--btn--sm) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    min-height: unset;
  }

  .xs-btn :global(svg) {
    margin-left: unset;
  }
</style>
