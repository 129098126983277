<script>
  import { onMount } from 'svelte';
  import { format } from 'date-fns';

  import { InlineNotification, Modal, Select, SelectItem, SelectItemGroup } from 'carbon-components-svelte';

  import EmailPreview from './EmailPreview.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { getLicenseTemplates, parseEmailTemplate } from '../../services';

  export let template,
    emailCategoryId,
    open = false;

  let loading = false,
    resultNotification,
    licenseTemplates = {},
    parsedHtmlEmail = { subject: template.subject, body: template.body.html };

  async function fetchLicenceTemplates() {
    loading = true;

    try {
      const rawLicenseTempaltes = await getLicenseTemplates();

      if (!rawLicenseTempaltes.length) {
        resultNotification = {
          hideCloseButton: true,
          kind: 'warning',
          title: 'Warning:',
          subtitle: 'No license templates found for preview.',
        };

        return;
      }

      licenseTemplates = normalizeLicenseTemplates(rawLicenseTempaltes);
    } catch (error) {
      console.error('[PreviewEmailTemplateModal] Failed to fetch license templates!', error);
      resultNotification = {
        hideCloseButton: true,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to fetch license templates! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function normalizeLicenseTemplates(rawLicenseTempaltes) {
    return rawLicenseTempaltes.reduce((all, licenseTemplate) => {
      const { id: ownerId } = licenseTemplate.licenseOwner;

      if (!all[ownerId]) {
        return {
          ...all,
          [ownerId]: {
            ...licenseTemplate.licenseOwner,
            templates: [{ id: licenseTemplate.id, name: licenseTemplate.name }],
          },
        };
      }

      const newTemplates = [...all[ownerId].templates, { id: licenseTemplate.id, name: licenseTemplate.name }];

      return {
        ...all,
        [ownerId]: {
          ...all[ownerId],
          templates: newTemplates,
        },
      };
    }, {});
  }

  async function handleLicenseTemplateChange({ detail: selection }) {
    if (selection === '0') {
      return;
    }

    loading = true;

    try {
      parsedHtmlEmail =
        (await parseEmailTemplate({
          emailCategoryId,
          relatedEntityId: selection,
          subject: template.subject,
          body: template.body.html,
          userReplacements: {
            expiration_date: format(new Date(), 'yyyy-MM-dd'),
            expiration_date_ext: format(new Date(), 'dd-MMM-yyyy'),
          },
        })) ?? parsedHtmlEmail;
    } catch (error) {
      console.error('[PreviewEmailTemplateModal] Failed to parse email template!', error);
      resultNotification = {
        hideCloseButton: true,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to parse email template! Please check if replacements are valid and try again.',
      };
    } finally {
      loading = false;
    }
  }

  onMount(fetchLicenceTemplates);
</script>

<Modal passiveModal modalHeading="Preview Email Template" bind:open on:close>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
        hideCloseButton={resultNotification.hideCloseButton}
      />
    {:else}
      <div class="wrapper">
        <Select labelText="License Template" selected="0" on:change={handleLicenseTemplateChange}>
          <SelectItem value="0" text="Select a license template" disabled hidden />
          {#each Object.keys(licenseTemplates) as licenseTemplateGroupId}
            <SelectItemGroup label={licenseTemplates[licenseTemplateGroupId].name}>
              {#each licenseTemplates[licenseTemplateGroupId].templates as licenseTemplate}
                <SelectItem value={licenseTemplate.id} text={licenseTemplate.name} />
              {/each}
            </SelectItemGroup>
          {/each}
        </Select>
        <EmailPreview email={{ subject: parsedHtmlEmail.subject, body: parsedHtmlEmail.body }} />
      </div>
    {/if}
  </LoadingSpinner>
</Modal>
