<script>
  import * as yup from 'yup';
  import { createEventDispatcher } from 'svelte';

  import { Form } from '@mst-fe/sveltejs-forms';
  import { FormGroup, TextArea } from '@mst-fe/carbon-components-svelte';
  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { editLicenseTemplate, editLicense } from '../../services';

  export let license,
    isForTemplate = false,
    open = false;

  let loading = false,
    resultNotification,
    formSubmitButtonRef;

  const initialValues = license?.note ? { note: license.note } : null;

  const dispatch = createEventDispatcher();

  async function handleFormSubmission({ detail: { values } }) {
    loading = true;

    try {
      const updatedEntry = await (isForTemplate
        ? editLicenseTemplate({ edit: 'note', templateId: license.id }, { note: values.note })
        : editLicense(license.id, { note: values.note }));

      dispatch('close', updatedEntry);
    } catch (error) {
      console.error('[AddOrEditLicenseNoteModal] Failed to edit license template note!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to edit license template note! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function handleChange({ key, setValue }) {
    return function onChange({ target: { value } }) {
      setValue(key, value);
    };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: schema = yup.object().shape({
    note: yup.string().nullable(),
  });
</script>

<Modal
  modalHeading={`${license?.note ? 'Edit' : 'Add'} Note`}
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <Form {initialValues} {schema} let:submitForm let:setValue let:touched let:values let:errors on:submit={handleFormSubmission}>
      <FormGroup>
        <TextArea
          name="note"
          invalid={touched.note && !!errors.note}
          invalidText={errors.note}
          labelText="Note"
          placeholder="License note"
          value={values.note}
          on:change={handleChange({ key: 'note', setValue })}
        />
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>
