<script>
  /* eslint-disable no-underscore-dangle */
  import { createEventDispatcher } from 'svelte';

  import { Button, ButtonSet, TextInput, TextArea } from 'carbon-components-svelte';

  import Add16 from 'carbon-icons-svelte/lib/Add16';
  import Close16 from 'carbon-icons-svelte/lib/Close16';
  import Edit16 from 'carbon-icons-svelte/lib/Edit16';
  import Save16 from 'carbon-icons-svelte/lib/Save16';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';

  import { addParameterValueData, updateParameterValue, deleteParameterValue } from '../../services';

  export let cell, row, parameterValues, valueModificationActive, parameterId, loading, resultNotification;

  let valueInputElement, deletionConfirmed;

  const dispatch = createEventDispatcher();

  function handleValueChange({ detail, target }) {
    parameterValues = parameterValues.map((param) => {
      if (!param.editing && !param.creating) {
        return param;
      }

      // holding ref to initial row so when user cancel edit we can go back to initial value
      const __ref = { ...param };

      delete __ref.editing;
      delete __ref.creating;

      return {
        ...param,
        __ref: param.__ref ?? __ref,
        // the event source can be either a TextArea (use "target") or a TextInput (use the ref for the name and "detail" for the value)
        [target?.name ?? valueInputElement.name]: target?.value ?? detail,
      };
    });
  }

  function editParameter(id) {
    return function handleParameterEdit() {
      parameterValues = parameterValues.map((param) => (param.id === id ? { ...param, editing: true } : param));
      valueModificationActive = true;
    };
  }

  function cancelParameterEdit(action) {
    return function cancelAddOrEdit() {
      parameterValues =
        action === 'editing'
          ? parameterValues.map(({ editing, __ref, ...rest }) => (editing ? __ref ?? rest : rest))
          : parameterValues.filter((param) => !param.creating);

      valueModificationActive = false;
    };
  }

  function deleteParameterCancel() {
    deletionConfirmed = false;
    valueModificationActive = false;
  }

  function deleteParameter(paramId) {
    return async function handleParameterDelete() {
      if (!deletionConfirmed) {
        deletionConfirmed = true;
        valueModificationActive = true;
        return;
      }

      loading = true;

      try {
        await deleteParameterValue(paramId);
        parameterValues = parameterValues.filter((param) => param.id !== paramId);

        dispatch('update-values', {
          id: parameterId,
          valuesCount: parameterValues.length,
        });
      } catch (error) {
        console.error('[ParameterValueTableRow] Failed to delete parameter', error);

        resultNotification = {
          kind: 'error',
          title: 'Error:',
          subtitle: 'Failed to delete parameter value!',
        };
      } finally {
        deletionConfirmed = false;
        valueModificationActive = false;
        loading = false;
      }
    };
  }

  async function addOrModifyParameterValue() {
    const valueToAddOrModify = { ...parameterValues.find((param) => param.editing || param.creating) };
    const action = valueToAddOrModify.editing ? 'edit' : 'add';

    delete valueToAddOrModify.editing;
    delete valueToAddOrModify.creating;
    delete valueToAddOrModify.__ref;

    loading = true;

    try {
      const newParameter = await (action === 'add'
        ? addParameterValueData({ ...valueToAddOrModify, parameterId })
        : updateParameterValue(valueToAddOrModify.id, {
            parameterId,
            value: valueToAddOrModify.value,
            description: valueToAddOrModify.description,
          }));

      parameterValues = parameterValues.reduce((all, param) => {
        if (param.creating && newParameter) {
          return [newParameter, ...all];
        }

        if (param.editing && newParameter) {
          return [...all, newParameter];
        }

        return [...all, param];
      }, []);

      valueModificationActive = false;

      dispatch('update-values', {
        id: parameterId,
        valuesCount: parameterValues.length,
      });
    } catch (error) {
      console.error(`[ParameterValueTableRow] Failed to ${action} parameter value`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to ${action} parameter value!`,
      };
    } finally {
      loading = false;
    }
  }

  $: if (valueModificationActive && valueInputElement) {
    valueInputElement.focus();
  }
</script>

<div class="custom-cell">
  {#if row.editing || row.creating}
    {#if cell.key === 'value'}
      <TextInput name="value" bind:ref={valueInputElement} placeholder="Parameter value" value={row.value} on:change={handleValueChange} />
    {:else if cell.key === 'description'}
      <TextArea name="description" placeholder="Description" rows="2" value={row.description} on:change={handleValueChange} />
    {:else if cell.key === 'actions'}
      <ButtonSet>
        <Button
          icon={Close16}
          iconDescription="Cancel"
          size="small"
          kind="secondary"
          on:click={cancelParameterEdit(row.editing ? 'editing' : 'creating')}
        />
        <Button
          icon={row.editing ? Save16 : Add16}
          iconDescription="Add"
          size="small"
          on:click={addOrModifyParameterValue}
          disabled={!row.value}
        />
      </ButtonSet>
    {/if}
  {:else if cell.key === 'actions'}
    <ButtonSet>
      <Button icon={Edit16} iconDescription="Edit" size="small" disabled={valueModificationActive} on:click={editParameter(row.id)} />
      {#if deletionConfirmed}
        <div class="delete-button-set">
          <Button icon={Close16} iconDescription="Cancel" size="small" kind="tertiary" on:click={deleteParameterCancel} />
          <Button size="small" kind="danger" on:click={deleteParameter(row.id)}>Delete</Button>
        </div>
      {:else}
        <Button
          icon={TrashCan16}
          iconDescription="Delete"
          size="small"
          kind="danger-tertiary"
          disabled={valueModificationActive}
          on:click={deleteParameter(row.id)}
        />
      {/if}
    </ButtonSet>
  {:else}
    {cell.display ? cell.display(cell.value, row) : cell.value}
  {/if}
</div>

<style>
  .custom-cell :global(.bx--btn-set .bx--btn) {
    width: unset;
    margin-left: 0.2rem;
  }

  .delete-button-set {
    margin-left: 1rem;
  }
</style>
