<script>
  import { createEventDispatcher } from 'svelte';

  import Add from 'carbon-icons-svelte/lib/Add16';
  import { Button, Search, TabContent, TooltipIcon } from 'carbon-components-svelte';
  import Edit from 'carbon-icons-svelte/lib/Edit16';
  import LoadBalancerGlobal from 'carbon-icons-svelte/lib/LoadBalancerGlobal16';
  import TrashCan from 'carbon-icons-svelte/lib/TrashCan16';

  import DraggableList from './DraggableList.svelte';

  export let listItems = [],
    listType = 'internal',
    searchTerm = '',
    locked;

  const dispatch = createEventDispatcher();

  function handleSearchChange({ target }) {
    searchTerm = target?.value.trim() ?? '';
  }

  function handleDragStart(event) {
    event.dataTransfer.setData(event.target.dataset.type, event.target.dataset.itemId);

    /* eslint-disable no-param-reassign */
    event.dataTransfer.dropEffect = 'copy';
    event.dataTransfer.effectAllowed = 'copy';
    /* eslint-enable */

    event.target.classList.add('dragging');
  }

  function handleDragEnd(event) {
    event.target.classList.remove('dragging');
    event.dataTransfer.clearData();
  }

  function handleItemCreation(event) {
    if (!locked) {
      return;
    }

    dispatch('item-create', event);
  }

  function handleItemEdit(item) {
    return function onItemEdit() {
      if (!locked) {
        return;
      }

      dispatch('item-edit', item);
    };
  }

  function handleItemDelete(item) {
    return function onItemDelete() {
      if (!locked) {
        return;
      }

      dispatch('item-delete', item);
    };
  }

  $: items = searchTerm.length
    ? listItems.filter((item) => item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
    : listItems;
</script>

<div class="tab">
  <TabContent>
    <div class="actions">
      <Search value={searchTerm} on:input={handleSearchChange} on:clear={handleSearchChange} />
      <div class:hidden={!locked}>
        <Button icon={Add} on:click={handleItemCreation}>Create</Button>
      </div>
    </div>
    <div class="content">
      <DraggableList active={!locked} on:dragstart={handleDragStart} on:dragend={handleDragEnd} type={listType} {items} let:item>
        {item.name}
        {#if listType === 'groups' && item.isFeedHandler}
          <TooltipIcon tooltipText="Feed handler" align="end" direction="right" icon={LoadBalancerGlobal} />
        {:else if listType === 'features' && item.isCustom && item.featureGroups.find(({ isFeedHandler }) => isFeedHandler)}
          <TooltipIcon
            tooltipText={item.featureGroups.find(({ isFeedHandler }) => isFeedHandler).name}
            align="end"
            direction="right"
            icon={LoadBalancerGlobal}
          />
        {/if}
        <div class="tile-action" class:hidden={!locked}>
          <Button
            size="field"
            kind="danger-ghost"
            icon={TrashCan}
            iconDescription="Delete"
            tooltipPosition="left"
            tooltipAlignment="end"
            on:click={handleItemDelete(item)}
          />
          <Button class="no-tooltip" size="field" kind="primary" iconDescription="Edit" icon={Edit} on:click={handleItemEdit(item)} />
        </div>
      </DraggableList>
    </div>
  </TabContent>
</div>

<style>
  .hidden {
    display: none;
  }

  .tab .actions {
    display: flex;
    padding-bottom: 0.5rem;
  }

  .tile-action {
    justify-self: flex-end;
    margin-left: auto;
  }

  .tile-action :global(.bx--btn--danger-ghost) {
    padding-right: 0.6875rem;
    padding-left: 0.6875rem;
  }

  .tile-action :global(.no-tooltip)::before,
  .tile-action :global(.no-tooltip .bx--assistive-text) {
    display: none;
  }

  .tab .content {
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
  }

  .tab .content :global(.dragging) {
    opacity: 0.5;
  }

  .tab .content :global(svg) {
    margin-left: 0.3rem;
  }

  .tab :global(.bx--tab-content) {
    padding: unset;
    padding-top: 1rem;
  }
</style>
