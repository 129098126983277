<script>
  import { onMount } from 'svelte';
  import { useNavigate } from 'svelte-navigator';

  import Box32 from 'carbon-icons-svelte/lib/Box32';

  import { InlineNotification, OverflowMenu, OverflowMenuItem, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import StatusIcon from '../../components/StatusIcon.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';
  import WbPlanDetails from '../../components/organizations/WbPlanDetails.svelte';

  import { getAllWbPlans } from '../../services';
  import { convertToLocalDisplayTime, rowContainsText } from '../../utils';

  const navigate = useNavigate();
  const tableHeaders = [
    { key: 'organization.name', value: 'Organization' },
    { key: 'startDate', value: 'Start Date' },
    {
      key: 'purchasedResourcesTotal',
      value: 'Total Purchased Resources',
      display: (_, row) => `$${calculateTotalSelections(row.awsResources)}`,
    },
    { key: 'active', value: 'Active' },
    { key: 'createdAt', value: 'Created At', display: convertToLocalDisplayTime },
    { key: 'createdByDisplayName', value: 'Created By' },
    { key: 'actions', empty: true, sort: false },
  ];

  let loading = false,
    resultNotification = null,
    plans = [],
    searchText = '';

  function calculateTotalSelections(resources) {
    return resources.reduce((all, resource) => all + resource.value * resource.unitCharge, 0);
  }

  async function fetchPlans() {
    loading = true;
    resultNotification = null;

    try {
      plans = await getAllWbPlans();
    } catch (error) {
      console.error('[GeneratedPlanList] Failed to get wb plans...', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to get Workbench plans. Please try again later!',
      };
    } finally {
      loading = false;
    }
  }

  function onSearchTextChange(e) {
    searchText = e?.detail ?? '';
  }

  function handleClickForPageChange(destination) {
    return function goTo() {
      navigate(destination);
    };
  }

  onMount(fetchPlans);

  $: filteredRows = searchText ? plans.filter((param) => rowContainsText(param, searchText)) : plans;
</script>

<LoadingSpinner {loading}>
  {#if resultNotification}
    <InlineNotification
      lowContrast
      kind={resultNotification.kind}
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  {#if plans.length}
    <DataTable headers={tableHeaders} rows={filteredRows} sortable expandable>
      <svelte:fragment slot="expanded-row" let:row>
        <WbPlanDetails plan={row} />
      </svelte:fragment>
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem
              text="Go to plan"
              on:click={handleClickForPageChange(`/organization/${row.organizationId}?filter=${row.id}#wb-plans`)}
            />
          </OverflowMenu>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else if cell.key === 'active'}
          <StatusIcon valid={cell.value} />
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar class="table-toolbar">
        {#if loading}
          <div class="data-table-spinner">
            <LoadingSpinner small withOverlay={false} {loading} />
          </div>
        {/if}
        <ToolbarContent>
          <ToolbarSearchWithRouting
            placeholder="Search for plans"
            persistent
            bind:searchText
            on:toolbarSearchValueChange={onSearchTextChange}
          />
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    {#if searchText && !filteredRows.length}
      <div class="empty-table">
        <div class="icon">
          <Box32 />
        </div>
        <em>No results for your search</em>
      </div>
    {/if}
  {:else}
    <InlineNotification lowContrast hideCloseButton kind="info" title="Info:" subtitle="No generated plans found" />
  {/if}
</LoadingSpinner>

<style>
  .action-cell :global(li.activate button:hover) {
    background-color: #24a148;
    color: var(--cds-text-04);
    transition: filter 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9),
      border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  :global(html[theme='g90']) .action-cell :global(li.activate button:hover) {
    background-color: var(--cds-inverse-support-02);
  }

  .empty-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
    user-select: none;
    background-color: var(--cds-layer);
    padding: 2rem 0;
  }

  .empty-table .icon {
    padding: 0.35rem;
  }
</style>
