<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import { InlineNotification, Modal, DataTable } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { addUsersToGroup, getAllUsers } from '../../services';
  import { asOptionalTextColumn } from '../../utils';

  export let organizationId;
  export let groupId;
  export let open = false;
  export let existingGroupUsers = [];

  let resultNotification;
  let loading = false;
  let query = { loading: true };
  let allUsers = [];
  let selectedRowIds;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    try {
      allUsers = await getAllUsers();
      query = { loading: false };
    } catch (e) {
      console.error('[AddUsersToGroupModal] Failed to retrieve users!', e);
    }
  });

  $: users = allUsers.filter(({ organizationId: userOrganizationId, id }) => {
    return organizationId === userOrganizationId && !existingGroupUsers.find((user) => user.id === id);
  });

  async function onModalSubmit() {
    if (!selectedRowIds.length) {
      dispatch('close');
      return;
    }
    resultNotification = null;
    loading = true;
    try {
      await addUsersToGroup(selectedRowIds, groupId);

      loading = false;
      dispatch('submit');
      dispatch('close');
    } catch (error) {
      console.error('[AddUsersToGroupModal] Failed to add users to group!', error);

      const errorMessage = error?.response?.data?.message ?? 'Verify your submission and try again!';
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to add users to group! ${errorMessage}`,
      };
      loading = false;
    } finally {
      selectedRowIds = [];
    }
  }
</script>

<Modal
  hasScrollingContent={true}
  modalHeading="Add Users to Group"
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  size="lg"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p class="paragraph">
      The table below lists users which belong to this group's parent organization. Select one or more users to be added to the group.
    </p>

    <DataTable
      headers={[
        { key: 'statusDescription', value: 'Status' },
        { key: 'email', value: 'Email Address' },
        { key: 'name', value: 'Name', ...asOptionalTextColumn },
      ]}
      rows={users ?? []}
      selectable
      bind:selectedRowIds
      sortable
    />
  </LoadingSpinner>
</Modal>

<style>
  .paragraph {
    padding-bottom: 2rem;
  }
</style>
