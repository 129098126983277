<script>
  import { InlineNotification } from 'carbon-components-svelte';

  export let instancePermissionsTableNotifications;
</script>

{#if instancePermissionsTableNotifications.saved}
  <InlineNotification
    kind="success"
    lowContrast
    title="Saved:"
    subtitle={`
        The updated instance entitlements were successfully saved.
      `}
  />
{/if}
