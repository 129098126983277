<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LicenseTemplateBasicForm from './LicenseTemplateBasicForm.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { editLicenseTemplate, getLicenseTemplate } from '../../services';

  export let license,
    open = false;

  let loading = false,
    formSubmitButtonRef,
    template = null,
    resultNotification;

  const dispatch = createEventDispatcher();

  async function editTemplate({ detail: { values } }) {
    loading = true;

    try {
      const updatedTemplate = await editLicenseTemplate({ edit: 'basic', templateId: license.id }, values);
      dispatch('close', updatedTemplate);
    } catch (error) {
      console.error('[EditLicenseBasicInfoModal] Failed to edit template basic info!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to edit template basic information! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  function normalizeTemplateData(templateData) {
    const { id, cycle, diff, hosts, name, uploadDestinations, isDemo } = templateData;
    const destinations = uploadDestinations.map(({ destination, path }) => `${destination}:${path}`);

    return { id, cycle, diff, hosts, name, destinations, isDemo };
  }

  async function fetchDependencies() {
    loading = true;

    try {
      const rawTemplate = await getLicenseTemplate(license.id);
      template = normalizeTemplateData(rawTemplate);
    } catch (error) {
      console.error("[EditLicenseBasicInfoModal] Failed to fetch template's information!", error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: "Failed to get template's information! Please try again later.",
      };
    } finally {
      loading = false;
    }
  }

  onMount(fetchDependencies);
</script>

<Modal
  modalHeading="Edit Template"
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  shouldSubmitOnEnter={false}
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>You are now editing license template <strong>{license.name}</strong>.</p>
    {#if template}
      <LicenseTemplateBasicForm edit initialValues={template} on:submit={editTemplate}>
        <div slot="submit" let:submitForm>
          <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
        </div>
      </LicenseTemplateBasicForm>
    {/if}
  </LoadingSpinner>
</Modal>
