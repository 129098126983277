<script>
  import startCase from 'lodash/startCase';

  export let eventData;

  $: changedKeys = Object.entries(eventData).filter(([, value]) => (Array.isArray(value) ? value.length : value));
</script>

{#each changedKeys as [key, keyChanges]}
  <div class="changed-key">
    <h6>{startCase(key)}</h6>
    {#if ['createdRoutes', 'deletedRoutes'].includes(key)}
      {#each keyChanges as { method, route }}
        <li>{method}: {route}</li>
      {/each}
    {:else}
      <span>{keyChanges}</span>
    {/if}
  </div>
{/each}

<style>
  .changed-key {
    margin-bottom: 0.5rem;
  }
</style>
