<script>
  import CheckmarkOutline20 from 'carbon-icons-svelte/lib/CheckmarkOutline20';
  import Error20 from 'carbon-icons-svelte/lib/Error20';
  import Undefined20 from 'carbon-icons-svelte/lib/Undefined20';

  export let valid,
    inline = false;
</script>

<div class="status-icon" class:inline>
  {#if valid}
    <CheckmarkOutline20 class="icon-success" />
  {:else if valid === false}
    <Error20 class="icon-error" />
  {:else}
    <Undefined20 />
  {/if}
</div>

<style>
  .status-icon :global(svg.icon-error) {
    fill: var(--cds-danger-01);
  }
  .status-icon :global(svg.icon-success) {
    fill: var(--cds-support-02);
  }

  .inline {
    display: inline;
  }
</style>
