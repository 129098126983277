export const getServerErrorMessage = (error, shouldReturnGenericMessage = false) => {
  if (!error) {
    return null;
  }

  /**
   * 1. message returned as response e.g. res.status(409).json({ message: 'Entity already exists.' })
   * 2. message returned by Joi
   */
  let errorMessage = error.response?.data?.message ?? error.response?.data?.error?.details?.[0]?.message;

  // this message might not be something we want to return to UI, e.g. "Request failed with status code 400"
  if (!errorMessage && shouldReturnGenericMessage) {
    errorMessage = error.respose?.statusText ?? error.message;
  }

  return errorMessage;
};
