export const downloadJsonAsFile = (filename, jsonObject, addSpacing = true) => {
  const stringifiedJson = JSON.stringify(jsonObject, undefined, addSpacing ? 2 : undefined);
  const blob = new Blob([stringifiedJson], { type: 'text/json' });
  return downloadFile(assureExtension(filename, '.json'), blob);
};

export const downloadCsvStringAsFile = (filename, csvString) => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  return downloadFile(assureExtension(filename, '.csv'), blob);
};

export const downloadLicenseFile = (filename, content) => {
  const blob = new Blob([content], { type: 'text/plain' });
  return downloadFile(assureExtension(filename, '.lic'), blob);
};

const assureExtension = (filename, extension) => `${filename}${filename.endsWith(extension) ? '' : extension}`;

const downloadFile = (filename, blob) => {
  const link = document.createElement('a');

  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

  const eventToTrigger = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(eventToTrigger);
  link.remove();
};
