import { get } from 'svelte/store';

import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js/dist/amazon-cognito-identity';

import { cognitoUserStore, userSession } from '../stores';

export async function login(username, password) {
  return new Promise((resolve, reject) => {
    const { userPool, storage } = get(userSession);

    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Storage: storage,
      Username: username,
    });
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (tokens) => {
        resolve({ resetRequired: false, tokens });
      },
      onFailure: (error) => {
        reject(error);
      },
      newPasswordRequired: () => {
        cognitoUserStore.set(cognitoUser);
        resolve({ resetRequired: true, tokens: null });
      },
    });
  });
}
