<script>
  import { onMount, createEventDispatcher } from 'svelte';

  import { Checkbox, FormGroup, InlineNotification, Modal } from 'carbon-components-svelte';
  import * as yup from 'yup';

  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getGroupRoles, updateUserGroupRoles } from '../../services';

  export let groupId;
  export let open = false;
  export let user;

  let formSubmitButtonRef, resultNotification;
  let allGroupRoles = [];
  let values = {};
  let loading = false;

  const dispatch = createEventDispatcher();

  async function loadUserRoles() {
    loading = true;
    try {
      allGroupRoles = await getGroupRoles();
      values = allGroupRoles.reduce(
        (acc, { id, name }) => ({
          ...acc,
          [id]: user.roles.some((roleName) => roleName === name),
        }),
        {}
      );
    } catch (error) {
      console.error('[EditUserGroupRolesModal] Failed to retrieve available roles!', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to retrieve available roles! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  async function onFormSubmit() {
    resultNotification = null;
    loading = true;

    try {
      const groupRoleIds = Object.entries(values).reduce((acc, [key, value]) => (value ? [...acc, key] : acc), []);
      await updateUserGroupRoles(groupId, user.id, groupRoleIds);

      dispatch('submit');
      dispatch('close');
    } catch (error) {
      console.error('[EditUserGroupRolesModal] Failed to edit user roles!', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to edit user roles! Verify your submission and try again.',
      };
    } finally {
      loading = false;
    }
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  onMount(loadUserRoles);

  $: schema = yup.object().shape({
    ...allGroupRoles.reduce((acc, { id }) => ({ ...acc, [id]: yup.boolean() }), {}),
  });
</script>

<Modal
  modalHeading="Edit User Roles"
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are editing roles for {user?.name} ({user?.email}) within this group.
    </p>
    <Form validateOnBlur={false} validateOnChange={false} {schema} on:submit={onFormSubmit}>
      <FormGroup legendText="Available Roles">
        {#each allGroupRoles as { id: groupRoleId, name } (groupRoleId)}
          <Checkbox labelText={name} name={groupRoleId} bind:checked={values[groupRoleId]} />
        {/each}
      </FormGroup>
      <button hidden type="submit" bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>
