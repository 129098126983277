<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import { extractFeaturesAndGroups } from './AddLicenseModal.svelte';
  import FeaturesTreeSelect from './FeaturesTreeSelect.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import SelectedFeatureList from './SelectedFeatureList.svelte';

  import { editLicenseTemplate, getAllFeaturesAndGroups, getLicenseTemplateFeaturesAndGroups } from '../../services';

  export let license,
    open = false;

  let loading = false,
    checkedItemIds = [],
    treeData = [],
    resultNotification;

  const dispatch = createEventDispatcher();

  async function editTemplate() {
    loading = true;

    try {
      const updatedTemplate = await editLicenseTemplate(
        { edit: 'features', templateId: license.id },
        extractFeaturesAndGroups(checkedItemIds, treeData)
      );

      dispatch('close', updatedTemplate);
    } catch (error) {
      console.error('[EditLicenseFeatureModal] Failed to edit template features!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to edit template features! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  async function fetchDependencies() {
    loading = true;

    try {
      const [{ features, groups }, groupedFeatures] = await Promise.all([
        getLicenseTemplateFeaturesAndGroups(license.id),
        getAllFeaturesAndGroups(),
      ]);

      checkedItemIds = [...features.map(({ featureId }) => featureId), ...groups.map(({ featureGroupId }) => featureGroupId)];
      treeData = groupedFeatures ?? [];
    } catch (error) {
      console.error("[EditLicenseBasicInfoModal] Failed to fetch template's information!", error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: "Failed to get template's information! Please try again later.",
      };
    } finally {
      loading = false;
    }
  }

  onMount(fetchDependencies);
</script>

<Modal
  modalHeading="Edit Features"
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="lg"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={editTemplate}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p class="intro">You are about to edit selected features for license template <strong>{license.name}</strong>.</p>
    <div class="features">
      <div class="tree">
        <FeaturesTreeSelect {treeData} bind:checkedItemIds />
      </div>
      <div class="selections">
        <SelectedFeatureList {treeData} {checkedItemIds} />
      </div>
    </div>
  </LoadingSpinner>
</Modal>

<style>
  .intro {
    margin-bottom: 1rem;
  }

  .features {
    display: flex;
  }

  .tree {
    flex-grow: 2;
  }

  .selections {
    flex-grow: 1;
  }
</style>
