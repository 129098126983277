<script>
  import { onMount } from 'svelte';
  import { DataTable } from 'carbon-components-svelte';
  import { v4 as uuidv4 } from 'uuid';
  import unionBy from 'lodash/unionBy';
  import InstancePermissionsTableRow from './InstancePermissionsTableRow.svelte';
  import { getGroupWorkbenchInstancePermissions } from '../../../../services';
  import SaveInstancePermissions from './SaveInstancePermissions.svelte';

  export let selectedInstances,
    permissionTableLoading,
    dbWorkbenchInstancePermissions,
    fetchError,
    permissionId,
    groupId,
    workbenchInstanceInventory,
    instancePermissionsTableNotifications;

  let dbInstances = [];
  let groupWorkbenchInstancePermissions = [];

  onMount(async () => {
    try {
      groupWorkbenchInstancePermissions = await getGroupWorkbenchInstancePermissions(permissionId);
    } catch (error) {
      console.error('[AddOrEditWorkbenchInstanceModal] Failed to load workbench instances', error);
      fetchError = 'Failed to load workbench instances';
    } finally {
      permissionTableLoading = false;
    }
  });

  function updateSelectedInstances(instances) {
    selectedInstances = unionBy(selectedInstances, instances, 'id');
  }

  function getDbInstancePermissions(groupWorkbenchInstancePermission, dbInstancePermissionNames) {
    const { instancePermissions } = groupWorkbenchInstancePermission;
    if (!instancePermissions || !instancePermissions.length) {
      return [];
    }

    return instancePermissions.map(({ id, workbenchInstancePermission }) => {
      dbInstancePermissionNames.push(workbenchInstancePermission.name);
      return {
        ...workbenchInstancePermission,
        id,
        workbenchInstancePermissionId: workbenchInstancePermission.id,
        checked: true,
      };
    });
  }

  function getAvailableInstancePermissions(groupWorkbenchInstancePermission, dbInstancePermissionNames) {
    const { workbenchInstanceId } = groupWorkbenchInstancePermission;
    const selectableInstancePermissions = workbenchInstanceInventory.find(({ id }) => workbenchInstanceId === id).instancePermissions;
    if (!selectableInstancePermissions || !selectableInstancePermissions.length) {
      return [];
    }

    return selectableInstancePermissions
      .filter(({ name }) => !dbInstancePermissionNames.includes(name))
      .map((workbenchInstancePermission) => ({
        ...workbenchInstancePermission,
        id: uuidv4(),
        workbenchInstancePermissionId: workbenchInstancePermission.id,
        checked: false,
      }));
  }

  $: dbInstances = groupWorkbenchInstancePermissions.reduce((aggregate, groupWorkbenchInstancePermission) => {
    const dbInstancePermissionNames = [];
    const dbInstancePermissions = getDbInstancePermissions(groupWorkbenchInstancePermission, dbInstancePermissionNames);
    const availableInstancePermissions = getAvailableInstancePermissions(groupWorkbenchInstancePermission, dbInstancePermissionNames);

    return [
      ...aggregate,
      {
        id: groupWorkbenchInstancePermission.id,
        workbenchInstanceId: groupWorkbenchInstancePermission.workbenchInstanceId,
        workbenchInstanceName: groupWorkbenchInstancePermission.workbenchInstance.name,
        instancePermissions: [...dbInstancePermissions, ...availableInstancePermissions].sort((a, b) => a.name.localeCompare(b.name)),
      },
    ];
  }, []);

  $: updateSelectedInstances(dbInstances);

  $: dbWorkbenchInstancePermissions = groupWorkbenchInstancePermissions.reduce((aggregate, groupWorkbenchInstancePermission) => {
    return {
      ...aggregate,
      [groupWorkbenchInstancePermission.id]: {
        workbenchInstanceId: groupWorkbenchInstancePermission.workbenchInstanceId,
        workbenchInstanceName: groupWorkbenchInstancePermission.workbenchInstance.name,
        instancePermissions: groupWorkbenchInstancePermission.instancePermissions.map((instancePermission) => ({
          instancePermissionId: instancePermission.id,
          workbenchInstancePermissionId: instancePermission.workbenchInstancePermissionId,
        })),
      },
    };
  }, {});
</script>

<div class="instance-permissions-wrapper">
  <h5 class="section-heading">Entitlements</h5>
  <DataTable
    headers={[
      { key: 'workbenchInstanceName', value: 'Permission' },
      { key: 'instancePermissions', value: 'Instance Permissions' },
      { key: 'actions', empty: true, sort: false },
    ]}
    rows={selectedInstances}
    sortable
    size="short"
  >
    <span slot="cell" class="workbench-instance" let:cell let:row>
      <InstancePermissionsTableRow {cell} {row} bind:selectedInstances />
    </span>
  </DataTable>
</div>

<SaveInstancePermissions
  {groupId}
  {permissionId}
  bind:fetchError
  bind:selectedInstances
  bind:permissionTableLoading
  bind:dbWorkbenchInstancePermissions
  bind:groupWorkbenchInstancePermissions
  bind:instancePermissionsTableNotifications
/>

<style>
  .instance-permissions-wrapper {
    margin: 2rem 0 0;
  }
  .workbench-instance {
    display: flex;
  }
</style>
