<script>
  import { createEventDispatcher } from 'svelte';

  import { Checkbox, InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getServerErrorMessage } from '../../utils';
  import { syncPrivileges } from '../../services';

  export let groupId;
  export let open = false;

  let loading = false;
  let confirm = false;
  let resultNotification;

  const dispatch = createEventDispatcher();

  async function onSubmit() {
    resultNotification = null;
    loading = true;
    try {
      await syncPrivileges(groupId);
      loading = false;
      dispatch('submit');
      dispatch('close', { id: groupId });
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Please try again later.';
      console.error('[GroupSyncPrivilegesModal] Failed to sync privileges!', errorMessage);

      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to sync privileges! ${errorMessage}`,
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Sync Group Privileges"
  primaryButtonText="Sync"
  primaryButtonDisabled={!confirm}
  secondaryButtonText="Cancel"
  preventCloseOnClickOutside={true}
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p class="locked">You are about to sync the AWS privileges for this group.</p>
    <strong>This is the old sync method that does not support day-based entitlements.</strong>
    <p class="confirm">Are you sure?</p>
    <Checkbox labelText="I understand and I would like to proceed with permission synchronization" bind:checked={confirm} />
  </LoadingSpinner>
</Modal>

<style>
  .locked {
    user-select: none;
  }

  .confirm {
    padding: 1rem 0;
  }
</style>
