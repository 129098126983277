<script>
  import { TooltipIcon } from 'carbon-components-svelte';

  import CheckmarkOutline16 from 'carbon-icons-svelte/lib/CheckmarkOutline16';
  import ErrorOutline16 from 'carbon-icons-svelte/lib/ErrorOutline16';
  import Hourglass16 from 'carbon-icons-svelte/lib/Hourglass16';
  import StopSign16 from 'carbon-icons-svelte/lib/StopSign16';
  import WarningAlt16 from 'carbon-icons-svelte/lib/WarningAlt16';

  import Undefined16 from 'carbon-icons-svelte/lib/Undefined16';

  export let status, details;

  const STATUSES = {
    ready: {
      info: 'Build is ready and will shortly be sent to Jenkins',
      component: Hourglass16,
    },
    triggered: {
      info: 'Build sent to Jenkins',
      component: CheckmarkOutline16, // TODO: Change this icon to a different one when we introduce "finished" status
    },
    skipped: {
      info: details.skippedReason ?? 'Build has been skipped',
      component: WarningAlt16,
    },
    finished: {
      info: 'Build finished',
      component: CheckmarkOutline16,
    },
    failed: {
      info: 'Build failed',
      component: ErrorOutline16,
    },
    cancelled: {
      info: 'Build cancelled',
      component: StopSign16,
    },
  };
</script>

<div class="status-tooltip">
  <TooltipIcon
    class={`${status}-tooltip`}
    tooltipText={STATUSES[status].info ?? `Unknown status "${status}"`}
    direction="left"
    align="start"
    icon={STATUSES[status].component ?? Undefined16}
  />
</div>

<style>
  .status-tooltip :global(svg) {
    margin-top: 1px;
    margin-right: 0.5rem;
  }

  .status-tooltip :global(button.triggered-tooltip > svg),
  .status-tooltip :global(button.finished-tooltip > svg) {
    fill: var(--cds-support-success);
  }

  .status-tooltip :global(button.ready-tooltip > svg) {
    fill: var(--cds-support-info);
  }

  .status-tooltip :global(button.skipped-tooltip > svg) {
    fill: var(--cds-support-warning);
  }

  .status-tooltip :global(button.failed-tooltip > svg) {
    fill: var(--cds-support-error);
  }

  .status-tooltip :global(button.cancelled-tooltip > svg) {
    fill: var(--cds-support-error-inverse);
  }
</style>
