<script>
  import AuditTableEntityIdCell from './AuditTableEntityIdCell.svelte';

  export let entityId, entityType, row;

  const entityIdAffectedEntities = [
    entityType === 'group' && 'user-roles',
    'ssh-key',
    entityType === 'group' && 'users-groups',
    entityType === 'group' && 'ssh-keys-data-services-user',
    'ssh-key-group',
    'ssh-key-user',
    entityType === 'group' && 'build-parameter',
    entityType === 'group' && 'external-service-key',
    'license-template',
  ];

  const relatedEntityIdAffectedEntities = [
    entityType === 'user' && 'users-groups',
    entityType === 'user' && 'ssh-keys-data-services-user',
    'user-roles',
    entityType === 'api-key' && 'api-key',
  ];

  $: rowEntityType = row?.entityType;
</script>

{#if entityIdAffectedEntities.includes(rowEntityType)}
  <AuditTableEntityIdCell {row} {entityId} />
{:else if relatedEntityIdAffectedEntities.includes(rowEntityType)}
  {row.relatedEntityId}
{/if}
