module.exports = {
  adminDashboard: 'admin',
  customerBuilds: 'customer-builds',
  organizationCreate: 'organization/create',
  organizationDetails: 'organization/:organizationId',
  organizations: 'organizations',
  groups: 'groups',
  groupCreate: 'group/create',
  groupDetails: 'group/:groupId',
  dataFeeds: 'data-feeds',
  dataFeedDetails: 'data-feed/:dataFeedId',
  licenses: 'licenses',
  login: 'login',
  resetPassword: 'reset-password',
  setNewPassword: 'set-new-password',
  tasks: 'tasks',
  userDetails: 'user/:userId',
  users: 'users',
  userCreate: 'user/create',
  emails: 'emails',
  workbench: 'workbench',
};
