<script>
  import { onMount } from 'svelte';
  import dayjs from 'dayjs';

  import Add16 from 'carbon-icons-svelte/lib/Add16';
  import { Button, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { DataTable } from '@mst-fe/carbon-components-svelte';
  import BulkAddModal from './BulkAddModal.svelte';
  import PaginationWithRouting from '../../../../components/PaginationWithRouting.svelte';
  import ToolbarSearchWithRouting from '../../../../components/ToolbarSearchWithRouting.svelte';

  import { DataInventoryServiceIds } from '../../../../../shared/constants';
  import { getServiceDataFeeds } from '../../../../services';
  import { addRandomId, rowContainsText } from '../../../../utils';

  export let feedInventory, fetchError, inventoryTableLoading, serviceId, visibleFeeds;

  let bulkAddModalOpen = false;
  let query = { pageSize: 5, currentPage: 1, searchText: '' };

  const headers = [{ key: 'name', value: 'Feed Name' }];

  headers.push(
    ...(serviceId === DataInventoryServiceIds.SFTP05
      ? [{ key: 'actions', empty: true, sort: false }]
      : [
          { key: 'availabilityStartMonth', value: 'Availability Start Month' },
          { key: 'availabilityEndMonth', value: 'Availability End Month' },
          { key: 'actions', empty: true, sort: false },
        ])
  );

  onMount(async () => {
    try {
      const dataFeeds = await getServiceDataFeeds(serviceId);
      feedInventory = dataFeeds;
    } catch (error) {
      console.error('[AddOrEditDataFeedModal] Failed to load feeds!', error);
      fetchError = 'Failed to load feed inventory.';
    } finally {
      inventoryTableLoading = false;
    }
  });

  const addFeeds = (addedFeeds) => {
    const newFeeds = addedFeeds.map(
      ({
        id,
        name,
        availabilityStartDate = dayjs('2000-01-01').format('YYYY-MM-DD'),
        availabilityEndDate = dayjs().format('YYYY-MM-DD'),
        isOngoing,
        expiresAt = '',
        isEdited = true,
      }) => {
        return addRandomId({
          feedId: id,
          name,
          startDate: expiresAt ? availabilityStartDate : availabilityStartDate.slice(0, 7),
          endDate: expiresAt ? availabilityEndDate : availabilityEndDate.slice(0, 7),
          availabilityStartDate,
          availabilityEndDate,
          isOngoing,
          expiresAt,
          isEdited,
        });
      }
    );

    visibleFeeds = [...visibleFeeds, ...newFeeds];
  };

  $: filteredFeedInventory = query.searchText ? feedInventory.filter((feed) => rowContainsText(feed, query.searchText)) : feedInventory;

  const onSearchTextChange = (e) => {
    query = { ...query, currentPage: 1, searchText: e?.detail ?? '' };
  };
</script>

<h5 class="section-heading">Data Inventory</h5>

<DataTable {headers} pageSize={query.pageSize} page={query.currentPage} rows={filteredFeedInventory ?? []} size="short" sortable>
  <span slot="cell" let:cell let:row>
    {#if cell.key === 'actions'}
      <Button
        kind="tertiary"
        size="sm"
        iconDescription="Add entitlement"
        icon={Add16}
        tooltipPosition="left"
        on:click={() => addFeeds([row])}
      />
    {:else if cell.display}
      {cell.display(cell.value, row)}
    {:else}
      {cell.value}
    {/if}
  </span>
  <Toolbar>
    <ToolbarContent>
      <ToolbarSearchWithRouting
        labelText="Search for feeds"
        persistent
        bind:searchText={query.searchText}
        on:toolbarSearchValueChange={onSearchTextChange}
      />
      <Button
        on:click={() => {
          bulkAddModalOpen = true;
        }}
      >
        Bulk add feeds
      </Button>
    </ToolbarContent>
  </Toolbar>
</DataTable>
<PaginationWithRouting
  class="compact"
  bind:page={query.currentPage}
  bind:pageSize={query.pageSize}
  pageSizes={[5, 10, 25, 50]}
  totalItems={filteredFeedInventory.length}
/>

<BulkAddModal {addFeeds} {serviceId} {feedInventory} bind:bulkAddModalOpen />

<style>
  .section-heading {
    margin: 0 0 0.5rem;
  }
</style>
