<script>
  import * as yup from 'yup';
  import { createEventDispatcher } from 'svelte';

  import { FormGroup, InlineNotification, Modal } from 'carbon-components-svelte';
  import { TextArea, TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { addDataFeed } from '../../services';
  import { getServerErrorMessage } from '../../utils';

  export let open = false;

  let loading = false,
    formSubmitButtonRef,
    initialValues,
    resultNotification = null;

  const dispatch = createEventDispatcher();

  async function handleFormSubmission({ detail: { values } }) {
    resultNotification = null;
    loading = true;

    try {
      const feed = await addDataFeed(values);
      dispatch('close', feed);
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Please try again later.';
      console.error('[AddDataFeedModal] Failed to add feed!', errorMessage);

      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to add feed! ${errorMessage}`,
      };
    } finally {
      loading = false;
    }
  }

  function handleCustomChangeEvent({ key, setValue }) {
    return function onCustomChange({ detail: value }) {
      setValue(key, value);
    };
  }

  function handleChangeEvent({ key, setValue }) {
    return function onChange({ target: { value } }) {
      setValue(key, value);
    };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: schema = yup.object().shape({
    name: yup.string().required('Feed name cannot be empty').matches(/^\S*$/, 'Feed name cannot contain spaces'),
    sftpCustomFolderPath: yup.string().nullable(),
    notes: yup.string().nullable(),
  });
</script>

<Modal
  hasForm
  modalHeading="Add Feed"
  primaryButtonText="Add"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  preventCloseOnClickOutside
  shouldSubmitOnEnter={false}
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <InlineNotification
      lowContrast
      hideCloseButton
      kind="info"
      subtitle="Manual adding a feed is currently only supported for SFTP05/CrushFTP"
    />
    <Form
      validateOnBlur={false}
      validateOnChange={false}
      {initialValues}
      {schema}
      let:submitForm
      let:errors
      let:setValue
      let:touched
      let:values
      on:submit={handleFormSubmission}
    >
      <FormGroup>
        <TextInput
          name="name"
          autocomplete="off"
          invalid={touched.name && !!errors.name}
          invalidText={errors.name}
          labelText="Name"
          placeholder="Feed name"
          value={values.name}
          required
          on:change={handleCustomChangeEvent({ key: 'name', setValue })}
        />
        <TextInput
          helperText="Useful when feeds have been renamed, sourced from NY4, or prepared for a specific client."
          invalid={touched.sftpCustomFolderPath && !!errors.sftpCustomFolderPath}
          invalidText={errors.sftpCustomFolderPath}
          labelText="Custom Folder Path"
          name="sftpCustomFolderPath"
          placeholder="Custom Folder Path"
          type="text"
          value={values.sftpCustomFolderPath}
          on:change={handleCustomChangeEvent({ key: 'sftpCustomFolderPath', setValue })}
        />
        <TextArea
          invalid={touched.notes && !!errors.notes}
          invalidText={errors.notes}
          labelText="Notes"
          name="notes"
          placeholder="Additional notes"
          type="text"
          value={values.notes}
          on:change={handleChangeEvent({ key: 'notes', setValue })}
        />
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>

<style>
</style>
