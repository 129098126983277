<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { deleteFeatureGroup } from '../../services';

  export let group,
    open = false;

  let loading = false,
    resultNotification;

  const dispatch = createEventDispatcher();

  async function deleteLicenseFeatureGroup() {
    loading = true;

    try {
      await deleteFeatureGroup(group.id);

      dispatch('close', { id: group.id });
    } catch (error) {
      console.error('[DeleteFeatureModal] Failed to delete license feature group!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to delete license feature group! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Delete License Feature Group"
  preventCloseOnClickOutside={true}
  primaryButtonText="Delete"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={deleteLicenseFeatureGroup}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>You are about to delete license feature group <strong>{group.name}</strong>.</p>
    <br />
    <p>Are you sure?</p>
  </LoadingSpinner>
</Modal>
