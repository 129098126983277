<script>
  import { Link } from 'carbon-components-svelte';

  import StatusTooltip from '../StatusTooltip.svelte';

  export let dataServiceAssociations = [];
  export let ownerType, serviceName;
</script>

<StatusTooltip valid={dataServiceAssociations.some((association) => association.serviceName === serviceName)}>
  <span slot="errorText">
    This SSH key is not associated with the service '{serviceName}'.
    {#if ownerType === 'user'}
      To associate it with this service, navigate to one of the user's groups.
    {/if}
  </span>
  <div class="service-association-tooltip" slot="validText">
    <span>This SSH key is associated with the service '{serviceName}' through the following group(s):</span>
    {#each dataServiceAssociations.filter((association) => association.serviceName === serviceName) as { group }}
      <Link href={`/group/${group.id}#ssh-keys`} target="_blank">
        {group.name}
      </Link>
    {/each}
  </div>
</StatusTooltip>

<style>
  .service-association-tooltip :global(.bx--link) {
    margin-top: 0.25rem;
  }
</style>
