<script>
  import { FormGroup, InlineNotification, TextInput, Tile } from 'carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import { resetPassword } from '../../services';

  const schema = yup.object().shape({
    username: yup.string().email().required('Enter your email address!'),
  });

  const navigate = useNavigate();

  let errorMessage, isLoading;

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    errorMessage = null;
    isLoading = true;
    try {
      await resetPassword(values.username);
      navigate('/set-new-password', { state: { fromLogin: false, username: values.username } });
    } catch (error) {
      setSubmitting(false);
      if (error.code === 'InternalFailure' || error.code === 'ServiceUnavailable') {
        errorMessage = 'An error occurred. Please try again later.';
      } else {
        errorMessage = 'Failed to initiate password reset. Please verify your submission and try again.';
      }
    } finally {
      isLoading = false;
    }
  }
</script>

<Tile light>
  <div class="mst-global-custom-css-form">
    <h1>Reset password</h1>
    {#if errorMessage}
      <InlineNotification kind="error" title="Error:" subtitle={errorMessage} lowContrast />
    {/if}
    <Form
      {schema}
      validateOnBlur={true}
      validateOnChange={true}
      on:submit={onFormSubmit}
      let:isSubmitting
      let:setValue
      let:errors
      let:touched
    >
      <FormGroup>
        <TextInput
          autocomplete="email"
          invalid={touched.username && !!errors.username}
          invalidText={errors.username}
          labelText="Email address"
          name="username"
          placeholder="Email address"
          type="email"
          on:change={({ detail: text }) => setValue('username', text)}
        />
      </FormGroup>
      <div class="form-controls">
        <LoadingButton kind="primary" type="submit" disabled={isSubmitting} {isLoading}>Reset</LoadingButton>
      </div>
    </Form>
  </div>
</Tile>

<style>
  .mst-global-custom-css-form > h1 {
    display: block;
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .mst-global-custom-css-form .form-controls {
    display: flex;
    justify-content: flex-end;
  }
</style>
