<script>
  import { onMount, onDestroy } from 'svelte';

  import {
    InlineNotification,
    Link,
    OverflowMenu,
    OverflowMenuItem,
    Toolbar,
    ToolbarContent,
    TooltipDefinition,
  } from 'carbon-components-svelte';
  import startCase from 'lodash/startCase';
  import { useNavigate } from 'svelte-navigator';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import Badge from '../../components/Badge.svelte';
  import BuildNotesModal from '../../components/build-parameters/BuildNotesModal.svelte';
  import BuildOverviewModal from '../../components/build-parameters/BuildOverviewModal.svelte';
  import RunBuildModal from '../../components/build-parameters/RunBuildModal.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import StatusIcon from './BuildStatusIcon.svelte';
  import JsonPassiveModal from './JsonPassiveModal.svelte';

  import { getTriggeredBuilds } from '../../services';
  import { appConfig } from '../../stores';
  import { convertToLocalDisplayTime, rowContainsText } from '../../utils';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  let loading = false,
    resultNotification,
    builds = [],
    searchText = '',
    fetchInterval,
    pagination = { currentPage: 1, pageSize: 25 };

  let modals = {
    logs: { open: false },
    config: { open: false },
    notes: { open: false },
    overview: { open: false },
    runBuild: { open: false },
  };

  const navigate = useNavigate();
  const FETCH_HISTORY_INTERVAL_MS = 3000;
  const tableHeaders = [
    { key: 'id', value: 'Group' },
    { key: 'targetName', value: 'Target' },
    { key: 'status', value: 'Status', display: (status) => startCase(status) },
    { key: 'createdBy', value: 'Triggered By' },
    { key: 'createdAt', value: 'Triggered At', display: convertToLocalDisplayTime },
    { key: 'actions', empty: true, sort: false },
  ];

  function onSearchTextChange(e) {
    pagination = { ...pagination, currentPage: 1 };
    searchText = e?.detail ?? '';
  }

  function goToBuild({ organization, group, target }) {
    return function openWindow() {
      window.open(
        `${$appConfig.data.jenkinsUrl}/job/customer_builds/job/${organization}/job/${group}/job/${organization}-${group}-${target}/`,
        '_blank'
      );
    };
  }

  function goToEntity(id, targetId, type = 'group') {
    return function go(event) {
      event.preventDefault();
      navigate(`/${type}/${id}?target=${targetId}#parameters`);
    };
  }

  async function fetchBuildsHistory(shouldShowLoader = false) {
    resultNotification = null;
    loading = shouldShowLoader;

    try {
      builds = await getTriggeredBuilds();

      if (!builds.length) {
        resultNotification = {
          hideCloseButton: true,
          kind: 'warning',
          title: 'No builds:',
          subtitle: 'There are not any builds triggered yet',
        };
      }
    } catch (error) {
      console.error('[TriggeredBuildsList] Failed to fetch triggered builds', error);

      resultNotification = {
        hideCloseButton: true,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to get/refresh builds history. Please try refreshing the page',
      };

      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
    } finally {
      loading = false;
    }
  }

  function updateBuildData(modification) {
    const { action, data } = modification;
    let entityToEdit;
    let updatedNotes;

    switch (action) {
      case 'note-deletion':
        if (!data) {
          break;
        }

        entityToEdit = builds.find(({ id }) => id === data.entityId);
        updatedNotes = entityToEdit.buildNotes.filter(({ id }) => id !== data.noteId);

        entityToEdit = {
          ...entityToEdit,
          buildNotes: updatedNotes,
        };

        builds = builds.map((build) => (build.id !== data.entityId ? build : entityToEdit));

        modals = {
          ...modals,
          notes: {
            ...modals.notes,
            data: updatedNotes,
          },
        };

        break;
      case 'note-creation':
        if (!data) {
          break;
        }

        entityToEdit = builds.find(({ id }) => id === data.entityId);
        updatedNotes = [...entityToEdit.buildNotes, data.note];

        entityToEdit = {
          ...entityToEdit,
          buildNotes: updatedNotes,
        };

        builds = builds.map((build) => (build.id !== data.entityId ? build : entityToEdit));

        modals = {
          ...modals,
          notes: {
            ...modals.notes,
            data: updatedNotes,
          },
        };

        break;

      case 'note-update':
        if (!data) {
          break;
        }

        entityToEdit = builds.find(({ id }) => id === data.entityId);
        updatedNotes = entityToEdit.buildNotes.map((note) => (note.id !== data.note.id ? note : data.note));

        entityToEdit = {
          ...entityToEdit,
          buildNotes: updatedNotes,
        };

        builds = builds.map((build) => (build.id !== data.entityId ? build : entityToEdit));

        modals = {
          ...modals,
          notes: {
            ...modals.notes,
            data: updatedNotes,
          },
        };

        break;
      default:
        console.warn(`[TriggeredBuildsList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function closeModal(name) {
    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function handleClickForModal(name, forwardData = {}) {
    return function openModal() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  fetchInterval = setInterval(fetchBuildsHistory, FETCH_HISTORY_INTERVAL_MS);

  onMount(() => fetchBuildsHistory(true));
  onDestroy(() => clearInterval(fetchInterval));

  $: filteredRows = searchText ? builds.filter((build) => rowContainsText(build, searchText)) : builds;
</script>

<LoadingSpinner {loading}>
  {#if resultNotification}
    <InlineNotification
      hideCloseButton={resultNotification.hideCloseButton}
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  <DataTable headers={tableHeaders} rows={filteredRows ?? []} pageSize={pagination.pageSize} page={pagination.currentPage}>
    <div class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
      {#if cell.key === 'actions'}
        <Badge active={!!row.buildNotes.length} value={row.buildNotes.length}>
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem
              text="View build overview"
              on:click={handleClickForModal('overview', {
                jsonConfig: row.triggeredConfig,
                targetName: row.targetName,
                entityName: row.groupName,
              })}
            />
            <OverflowMenuItem text="View logs" on:click={handleClickForModal('logs', { data: row.extra })} />
            <OverflowMenuItem text="View triggered JSON" on:click={handleClickForModal('config', { data: row.triggeredConfig })} />
            <OverflowMenuItem on:click={handleClickForModal('notes', { data: row.buildNotes, entityId: row.id })}>
              <Badge active={!!row.buildNotes.length} value={row.buildNotes.length} position="middle-right">Notes</Badge>
            </OverflowMenuItem>
            <OverflowMenuItem text="Go to Jenkins" on:click={goToBuild(row.extra.sanitizedNames)} />
            <OverflowMenuItem text="Re-run build" on:click={handleClickForModal('runBuild', { data: row })} disabled={row.deletedAt} />
          </OverflowMenu>
        </Badge>
      {:else if cell.key === 'status'}
        <div class="flex-cell">
          <StatusIcon status={cell.value} details={row.extra} />
          {startCase(cell.value)}
        </div>
      {:else if cell.key === 'createdBy'}
        <div class="flex-cell flex-column">
          <span>{row.userFullName ?? '-'}</span>
          {#if row.userFullName !== row.userEmail}
            <em>{row.userEmail}</em>
          {/if}
        </div>
      {:else if cell.key === 'id'}
        <div class="flex-cell flex-column">
          <strong>{row.groupName}</strong>
          <em>{row.organizationName}</em>
        </div>
      {:else if cell.key === 'targetName' && row.targetName}
        {#if row.deletedAt}
          <TooltipDefinition tooltipText="Deleted target">
            {row.targetName}
          </TooltipDefinition>
        {:else}
          <Link href={`/group/${row.groupId}?target=${row.configId}#parameters`} on:click={goToEntity(row.groupId, row.configId)}>
            {row.targetName}
          </Link>
        {/if}
      {:else}
        {cell.display ? cell.display(cell.value, row) : cell.value ?? ''}
      {/if}
    </div>
    <Toolbar>
      <ToolbarContent>
        <ToolbarSearchWithRouting
          placeholder="Search for build"
          persistent
          bind:searchText
          on:toolbarSearchValueChange={onSearchTextChange}
        />
      </ToolbarContent>
    </Toolbar>
  </DataTable>
  <PaginationWithRouting
    bind:page={pagination.currentPage}
    bind:pageSize={pagination.pageSize}
    pageSizes={[10, 25, 50]}
    totalItems={filteredRows?.length ?? 0}
  />
  {#if modals.logs.open}
    <JsonPassiveModal open name="Build Logs" data={modals.logs.data} on:close={() => closeModal('logs')} />
  {/if}
  {#if modals.config.open}
    <JsonPassiveModal open name="Triggered Build Configuration" data={modals.config.data} on:close={() => closeModal('config')} />
  {/if}
  {#if modals.notes.open}
    <BuildNotesModal
      open
      notes={modals.notes.data}
      entityId={modals.notes.entityId}
      on:refresh={({ detail: data }) => updateBuildData(data)}
      on:close={() => closeModal('notes')}
    />
  {/if}
  {#if modals.overview.open}
    <BuildOverviewModal
      open
      jsonConfig={modals.overview.jsonConfig}
      targetName={modals.overview.targetName}
      entityName={modals.overview.entityName}
      on:close={() => closeModal('overview')}
    />
  {/if}
  {#if modals.runBuild.open}
    <RunBuildModal
      open
      runningPreviousBuild
      configurations={[
        {
          ...modals.runBuild.data,
          entityId: modals.runBuild.data.groupId,
          entityName: modals.runBuild.data.groupName,
          entityType: 'group',
        },
      ]}
      on:close={() => closeModal('runBuild')}
    />
  {/if}
</LoadingSpinner>

<style>
  .flex-cell {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-cell :global(svg) {
    margin-left: 0.5rem;
  }

  .flex-cell :global(em) {
    font-size: 12px;
  }

  .action-cell {
    width: 40px;
  }

  .action-cell :global(ul button) {
    display: block;
  }

  .action-cell :global(.bx--overflow-menu) {
    min-height: 2.5rem;
  }

  .action-cell :global(.bx--overflow-menu-options) {
    min-width: 11rem;
  }
</style>
