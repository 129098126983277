<script>
  import { useNavigate } from 'svelte-navigator';
  import { Link } from 'carbon-components-svelte';

  const navigate = useNavigate();

  export let row, entityId;

  const { eventData, entityId: templateId } = row;

  $: templateName = eventData.entry?.name ?? eventData.basicTemplateInfo?.name ?? 'Affected Template';
</script>

<div>
  <Link
    href={`/group/${entityId}/?template=${templateId}#licenses`}
    on:click={() => navigate(`/group/${entityId}/?template=${templateId}#licenses`)}
  >
    {templateName}
  </Link>
</div>
