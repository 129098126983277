<script>
  import { ToolbarSearch } from 'carbon-components-svelte';
  import { createEventDispatcher } from 'svelte';

  export let searchText,
    labelText,
    persistent,
    placeholder = '';

  const MAX_FILTER_LENGTH = 128;
  const dispatch = createEventDispatcher();

  const onToolBarValueChange = ({ target }) => {
    dispatch('toolbarSearchValueChange', target?.value);
  };

  const handlePopstate = () => {
    const url = new URL(window.location);
    searchText = url.searchParams.get('filter') || '';
  };

  const synchronizeFilter = (isInitial) => {
    const url = new URL(window.location);
    const urlFilter = (url.searchParams.get('filter') || '').substring(0, MAX_FILTER_LENGTH);

    if (isInitial) {
      searchText = urlFilter;
      return;
    }

    if (searchText?.length > MAX_FILTER_LENGTH) {
      searchText = searchText.substring(0, MAX_FILTER_LENGTH);
    }

    if (searchText === '' || typeof searchText !== 'string') {
      url.searchParams.delete('filter');
      window.history.replaceState({}, '', url);
    } else if (urlFilter !== searchText) {
      url.searchParams.set('filter', searchText);
      window.history.pushState({}, '', url);
    }
  };

  synchronizeFilter(true);
  // Update filter, searchText variable used to trigger update
  // eslint-disable-next-line no-unused-expressions
  $: synchronizeFilter(false) && searchText;
</script>

<svelte:window on:popstate={handlePopstate} />

<ToolbarSearch
  {placeholder}
  {labelText}
  {persistent}
  bind:value={searchText}
  on:clear={onToolBarValueChange}
  on:input={onToolBarValueChange}
/>
