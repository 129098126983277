<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { deleteParameterName } from '../../services';

  export let parameter,
    open = false;

  let loading = false,
    resultNotification;

  const dispatch = createEventDispatcher();

  async function removeParameter() {
    loading = true;

    try {
      await deleteParameterName(parameter.id);

      dispatch('close', { id: parameter.id });
    } catch (error) {
      console.error('[DeleteParameterName] Failed to delete parameter!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to delete parameter! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Delete Parameter"
  preventCloseOnClickOutside={true}
  primaryButtonText="Delete"
  secondaryButtonText="Cancel"
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={removeParameter}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are about to delete parameter <em>{parameter.name}</em>
      {#if parameter.type !== 'boolean'}
        and <strong>all its related values</strong>
      {/if}
    </p>
    <br />
    <p>Are you sure?</p>
  </LoadingSpinner>
</Modal>
