const {
  DataInventoryServiceIdToName,
  DataInventoryServiceIdToCodeName,
  DataInventoryServiceIds,
  DataInventoryServiceNames,
  DataInventoryServiceCodeNames,
} = require('../../shared/constants');
const { reverseMap } = require('../utils/reverseMap');

const Sftp05RelatedTasks = {
  SFTP05_DISCONNECT_GROUP: 'disconnect-group-sftp05',
  SFTP05_RECONCILIATION: 'sftp05-reconciliation',
  SFTP05_GROUP_RECONCILIATION: 'sftp05-group-reconciliation',
};

const IndependentSystemTasks = {
  COGNITO_USER_SYNC: 'cognito-users-sync',
  DATA_INVENTORY_SYNC_AWS_LAKE: 'aws-lake-inventory-sync',
  DATA_INVENTORY_SYNC_ARBITRATED_PCAPS: 'aws-arbitrated-pcaps-inventory-sync',
  DATA_INVENTORY_SYNC_SFTP05: 'data-inventory-sync',
  CRUSHFTP_GROUP_RECONCILIATION: 'crushftp-group-reconciliation',
  CRUSHFTP_RECONCILIATION: 'crushftp-reconciliation',
  CRUSHFTP_DISCONNECT_GROUP: 'disconnect-group-crushftp',
  AWS_GROUP_RECONCILIATION: 'aws-group-reconciliation',
  AWS_RECONCILIATION: 'aws-reconciliation',
  SFTP05_ALLOWLIST_SYNC: 'sftp-allowlist-sync',
  SFTP05_UPDATE_DATA_FEED_SET: 'update-data-feed-set-on-sftp05',
};

const InventoryEvents = {
  DATA_INVENTORY_CACHE_UPDATED: 'data-inventory-cache-updated',
};

const EmailNotificationTemplateNames = {
  TASK_FAILURE: 'task-failure',
};

const EmailNotificationSubjects = {
  [EmailNotificationTemplateNames.TASK_FAILURE]: '[Error] MACE Task Failed',
};

const SystemEvents = {
  APPLICATION_READY: 'application:ready',
};

const SystemFilePaths = {
  DATA_INVENTORY_CACHE_PATH: 'tmp/data-feed-inventory-cache.json',
};

const PermissionTypes = {
  DATA_FEEDS_LAKE: 'data-feeds-lake',
  DATA_FEEDS_AWS_PCAP: 'data-feeds-aws-arbitrated-pcaps',
  DATA_FEEDS_SFTP05: 'data-feeds-sftp05',
  DATA_FEEDS_CRUSHFTP: 'data-feeds-crushftp',
  WORKBENCH: 'workbench',
};

const PermissionTypesToInventoryServiceIds = {
  // comment is the old permissions
  // [PermissionTypes.DATA_FEEDS_SFTP05]: DataInventoryServiceIds.SFTP05,
  [PermissionTypes.DATA_FEEDS_CRUSHFTP]: DataInventoryServiceIds.SFTP05,
  [PermissionTypes.DATA_FEEDS_LAKE]: DataInventoryServiceIds.AWS_LAKE,
  [PermissionTypes.DATA_FEEDS_AWS_PCAP]: DataInventoryServiceIds.AWS_ARBITRATED_PCAPS,
  [PermissionTypes.WORKBENCH]: null,
};

const InventoryServiceIdsToPermissionTypes = reverseMap(PermissionTypesToInventoryServiceIds);

module.exports = {
  DataInventoryServiceIds,
  DataInventoryServiceNames,
  DataInventoryServiceCodeNames,
  DataInventoryServiceIdToName,
  DataInventoryServiceIdToCodeName,
  EmailNotificationTemplateNames,
  EmailNotificationSubjects,
  PermissionTypes,
  PermissionTypesToInventoryServiceIds,
  SystemTasks: {
    ...IndependentSystemTasks,
    ...Sftp05RelatedTasks,
  },
  IndependentSystemTasks,
  InventoryServiceIdsToPermissionTypes,
  Sftp05RelatedTasks,
  SystemEvents,
  InventoryEvents,
  SystemFilePaths,
};
