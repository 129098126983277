<script>
  import { TooltipIcon } from 'carbon-components-svelte';

  import StatusIcon from './StatusIcon.svelte';

  export let valid;
</script>

<div class="status-tooltip-wrapper">
  <TooltipIcon>
    <StatusIcon {valid} />
    <div slot="tooltipText">
      {#if valid}
        <slot name="validText" />
      {:else if valid === false}
        <slot name="errorText" />
      {:else if !valid}
        <slot name="undefinedText" />
      {/if}
    </div>
  </TooltipIcon>
</div>

<style>
  .status-tooltip-wrapper :global(.bx--assistive-text) {
    background-color: var(--cds-inverse-02);
  }

  .status-tooltip-wrapper :global(.bx--assistive-text) {
    padding: 0.5rem 1rem;
  }

  .status-tooltip-wrapper :global(.bx--link) {
    color: var(--cds-inverse-link);
  }
  .status-tooltip-wrapper :global(.bx--link:hover) {
    color: var(--cds-inverse-link);
  }
  .status-tooltip-wrapper :global(.bx--link:visited) {
    color: var(--cds-inverse-link);
  }
</style>
