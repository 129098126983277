<script>
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';
  import CustomParameterValueView from '../build-parameters/CustomParameterValueCell.svelte';

  export let eventData, eventType;

  const getEventChanges = () => {
    const { original, changes } = eventData;
    return Object.keys(original)
      .filter((key) => !['updatedBy'].includes(key))
      .map((key) => ({ key, original: original[key], changed: changes[key] }));
  };

  const UPDATE_KEYS_TEXT = {
    parameterName: 'Name',
    parameterValue: 'Value',
    name: 'Name',
    isDefault: 'Default Target',
  };
</script>

{#if eventData.entry}
  <div class:padded={eventType !== 'deleted'}>
    {#each Object.entries(eventData.entry) as [key, value]}
      {#if (eventType === 'deleted' && ['parameterName', 'name'].includes(key)) || eventType !== 'deleted'}
        <h6 class="key-heading">{UPDATE_KEYS_TEXT[key] || key}</h6>
        {#if key === 'parameterValue'}
          <CustomParameterValueView {value} />
        {:else}
          <span>{value}</span>
        {/if}
      {/if}
    {/each}
  </div>
{:else}
  <div class:padded={Object.keys(eventData.original).length > 1}>
    {#each getEventChanges(eventData) as { key, original, changed }}
      <h6 class="key-heading">{UPDATE_KEYS_TEXT[key] || key}</h6>
      <span class="flex">
        {#if key === 'parameterValue'}
          <CustomParameterValueView value={original} />
        {:else}
          {original}
        {/if}
        {#if changed}
          <ArrowRight16 class="changed-key-arrow" />
          {#if key === 'parameterValue'}
            <CustomParameterValueView value={changed} />
          {:else}
            {changed}
          {/if}
        {/if}
      </span>
    {/each}
  </div>
{/if}

<style>
  .flex {
    display: flex;
  }

  .padded {
    padding: 0.5rem 0;
  }

  .key-heading:not(:first-child) {
    margin-top: 0.75rem;
  }

  :global(.changed-key-arrow) {
    margin-top: 0.1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
</style>
