<script>
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';

  import TabsWithRouting from '../../components/TabsWithRouting.svelte';

  import AdvancedBuildRunner from './AdvancedBuildRunner.svelte';
  import SystemParameterList from './SystemParameterList.svelte';
  import SystemParameterMeta from './SystemParameterMeta.svelte';
  import TriggeredBuildsList from './TriggeredBuildsList.svelte';
</script>

<div class="system-tabs">
  <TabsWithRouting
    defaultHash={'#build-runner'}
    tabsWrapperProps={{ 'aria-label': 'Customer builds tabs' }}
    tabs={[
      { label: 'Advanced Build Runner', hash: '#build-runner' },
      { label: 'Build History', hash: '#builds-history' },
      { label: 'Global Build Parameters', hash: '#build-parameters' },
      { label: 'Known Parameters', hash: '#parameters-metadata' },
    ]}
  >
    <LazyLoadedTabContent hash="#build-runner"><AdvancedBuildRunner /></LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#builds-history"><TriggeredBuildsList /></LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#build-parameters"><SystemParameterList /></LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#parameters-metadata"><SystemParameterMeta /></LazyLoadedTabContent>
  </TabsWithRouting>
</div>

<style>
  .system-tabs :global(.bx--tabs__nav-link) {
    width: 13rem;
  }
</style>
