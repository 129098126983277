<script>
  import { StructuredList, StructuredListCell, StructuredListHead, StructuredListRow, Tag, TextArea } from 'carbon-components-svelte';
  import DestinationLabel from './DestinationLabel.svelte';

  import { BASIC_INFO_LABELS, SUPPORTED_RENEWAL_CYCLES } from './LicenseTemplateBasicForm.svelte';
  import { appConfig } from '../../stores';

  export let info = {},
    hideLabel = false;

  const SHOULD_BE_LIST = ['emails'];
  const valueFormatters = {
    cycle: formatCycle,
    destinations: formatDestination,
  };

  function formatCycle(value) {
    return SUPPORTED_RENEWAL_CYCLES[value];
  }

  function formatDestination(value) {
    const [destination, path] = value.split(':');

    return `${$appConfig.data.supportedUploadDestinations[destination]} ${path}`;
  }

  function valueFormatter(key, value) {
    return valueFormatters[key]?.(value) ?? value;
  }
</script>

<div class="basic-info">
  {#if !hideLabel}
    <span class="bx--label">Basic information</span>
  {/if}
  <StructuredList condensed flush>
    <StructuredListHead>
      <StructuredListRow head>
        <StructuredListCell head>Attribute</StructuredListCell>
        <StructuredListCell head>Value</StructuredListCell>
      </StructuredListRow>
    </StructuredListHead>
    {#each Object.entries(info) as [key, value]}
      <StructuredListRow>
        <StructuredListCell noWrap>{BASIC_INFO_LABELS[key]}</StructuredListCell>
        <StructuredListCell>
          {#if SHOULD_BE_LIST.includes(key)}
            <ul class="value-list">
              {#each value as item}
                <li>{valueFormatter(key, item)}</li>
              {/each}
            </ul>
          {:else if key === 'hosts'}
            <TextArea disabled value={valueFormatter(key, value)} />
          {:else if key === 'destinations'}
            <div class="selections">
              {#each value as item}
                <div class="destination-tag">
                  <Tag><DestinationLabel fullPath={item} /></Tag>
                </div>
              {/each}
            </div>
          {:else}
            {valueFormatter(key, value)}
          {/if}
        </StructuredListCell>
      </StructuredListRow>
    {/each}
  </StructuredList>
</div>

<style>
  .basic-info {
    flex: 1;
    max-height: 400px;
    overflow-x: auto;
  }

  .basic-info :global(.bx--structured-list) {
    margin-bottom: 3rem;
  }

  .basic-info :global(textarea) {
    white-space: pre;
  }

  .selections {
    min-height: 100px;
  }

  .destination-tag :global(.bx--tag) {
    position: relative;
  }

  .value-list :global(li) {
    line-break: anywhere;
  }
</style>
