<script>
  import { InlineNotification } from 'carbon-components-svelte';

  import InstanceInventoryTable from './InstanceInventoryTable.svelte';
  import InstancePermissionsTable from './InstancePermissionsTable.svelte';
  import InstancePermissionsTableNotifications from './InstancePermissionsTableNotifications.svelte';
  import LoadingSpinner from '../../../../components/LoadingSpinner.svelte';

  export let groupId;

  let inventoryTableLoading = true;
  let permissionTableLoading = false;
  let fetchError = null;
  let selectedInstances = [];
  let dbWorkbenchInstancePermissions = [];
  let workbenchInstanceInventory = [];
  let instancePermissionsTableNotifications = {};
  const permissionId = new URLSearchParams(location.search).get('permissionId');
</script>

{#if fetchError}
  <InlineNotification kind="error" lowContrast title="Error:" subtitle={fetchError} />
{:else}
  <LoadingSpinner loading={inventoryTableLoading || permissionTableLoading}>
    <h4 class="subpage-heading">Workbench</h4>
    <InstancePermissionsTableNotifications bind:instancePermissionsTableNotifications />
    <InstanceInventoryTable bind:workbenchInstanceInventory bind:selectedInstances bind:inventoryTableLoading bind:fetchError />
    <InstancePermissionsTable
      {workbenchInstanceInventory}
      {permissionId}
      {groupId}
      bind:fetchError
      bind:selectedInstances
      bind:permissionTableLoading
      bind:dbWorkbenchInstancePermissions
      bind:instancePermissionsTableNotifications
    />
  </LoadingSpinner>
{/if}

<style>
  .subpage-heading {
    margin-bottom: 2rem;
  }
</style>
