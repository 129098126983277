<script>
  import { onMount } from 'svelte';

  import { InlineNotification } from 'carbon-components-svelte';
  import isEqual from 'lodash/isEqual';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import AuditTableAffectedEntityCell from './affected-entity-cell/AuditTableAffectedEntityCell.svelte';
  import AuditTableEventDataCell from './AuditTableEventDataCell.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { convertToLocalDisplayTime } from '../../utils';
  import { getAuditEvents } from '../../services';
  import PaginationWithRouting from '../PaginationWithRouting.svelte';

  export let entityId = null,
    entityType,
    queryParams;

  let pageData = { loading: true };
  let query = { currentPage: 1, pageSize: 25, order: ['eventTimestamp', 'DESC'] };
  let previousQuery = { ...query };

  const NOTIFICATIONS = {
    error: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load audit events! Please try again later.',
    },
    noAudits: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no audit records for this entity.',
    },
  };

  async function loadAuditEvents() {
    pageData = { ...pageData, loading: true };
    try {
      const { results: auditEvents } = await getAuditEvents(queryParams ?? { entityId });
      pageData = { auditEvents, loading: false, displayedNotification: !auditEvents.length && 'noAudits' };
    } catch (error) {
      console.error('[AuditTable] Failed to load audit events!', error);
      pageData = { loading: false, displayedNotification: 'error' };
    }
  }

  onMount(loadAuditEvents);

  function onPaginationUpdate({ detail: { pageSize, page: currentPage } }) {
    previousQuery = { ...query };
    query = { ...query, pageSize, currentPage };
  }

  $: (async () => {
    if (pageData.loading || isEqual(previousQuery, query)) {
      return;
    }

    previousQuery = { ...query };
    loadAuditEvents();
  })();
</script>

{#if NOTIFICATIONS[pageData.displayedNotification]}
  <InlineNotification hideCloseButton lowContrast {...NOTIFICATIONS[pageData.displayedNotification]} />
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'eventLabel', value: 'Event Type' },
        { key: 'eventData', value: 'Changes' },
        ['api-key', 'group', 'user'].includes(entityType) && { key: 'affectedEntity', value: 'Affected Entity' },
        { key: 'userId', value: 'Changed By' },
        { key: 'eventTimestamp', value: 'Updated', display: convertToLocalDisplayTime },
      ].filter(Boolean)}
      rows={pageData.auditEvents ?? []}
      sortable
      page={query.currentPage}
      pageSize={query.pageSize}
    >
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'eventData' && row?.eventData}
          <AuditTableEventDataCell {row} />
        {:else if cell.key === 'affectedEntity'}
          <AuditTableAffectedEntityCell {row} {entityId} {entityType} />
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value || ''}
        {/if}
      </span>
    </DataTable>
    <PaginationWithRouting
      bind:page={query.currentPage}
      bind:pageSize={query.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={pageData.auditEvents?.length}
      on:update={onPaginationUpdate}
    />
  </LoadingSpinner>
{/if}
