<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import {
    Button,
    DataTable,
    InlineNotification,
    Modal,
    NotificationActionButton,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
  } from 'carbon-components-svelte';
  import truncate from 'lodash/truncate';

  import ParameterValueTableRow from './ParameterValueTableRow.svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getParameterValues } from '../../services';
  import { rowContainsText } from '../../utils';

  export let parameter,
    open = false;

  let loading = false,
    resultNotification,
    parameterValues = [],
    searchText = '',
    pagination = { currentPage: 1, pageSize: 25 },
    filteredRows = [],
    valueModificationActive = false;

  const dispatch = createEventDispatcher();

  function onSearchTextChange({ target }) {
    searchText = target?.value?.trim() ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  function initAddValue() {
    searchText = '';
    valueModificationActive = true;

    parameterValues = [{ value: '', description: '', creating: true }, ...parameterValues];
  }

  function handleUpdatedValues({ detail: data }) {
    searchText = '';
    dispatch('update-values', data);
  }

  async function getParameters() {
    resultNotification = null;
    loading = true;

    try {
      parameterValues = await getParameterValues({ parameterId: parameter.id });
    } catch (error) {
      console.error('[ParameterValuesModal] Failed to load parameter values!', error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to load parameter values!',
      };
    } finally {
      loading = false;
    }
  }

  onMount(getParameters);

  $: if (parameterValues.length) {
    filteredRows = searchText ? parameterValues.filter((param) => rowContainsText(param, searchText)) : parameterValues;
  }
</script>

<div class="parameter-modal">
  <Modal passiveModal modalHeading="Parameter Values" preventCloseOnClickOutside={true} bind:open on:close>
    <LoadingSpinner {loading}>
      {#if resultNotification}
        <InlineNotification
          hideCloseButton
          kind={resultNotification.kind}
          lowContrast
          title={resultNotification.title}
          subtitle={resultNotification.subtitle}
        />
      {/if}
      {#if valueModificationActive}
        <InlineNotification
          hideCloseButton
          kind="warning"
          lowContrast
          title="Important"
          subtitle="Any changes on parameter values will not automatically update any build configurations currently using them."
        />
      {/if}
      {#if parameterValues.length || valueModificationActive}
        {#if parameterValues.length}
          <p>Below a list of all the known parameter values related to <em>{parameter.name}</em>.</p>
          <br />
        {/if}
        <DataTable
          headers={[
            { key: 'value', value: 'Value' },
            { key: 'description', value: 'Description', display: (description) => truncate(description || '-') },
            { key: 'actions', empty: true, sort: false },
          ]}
          rows={filteredRows}
          sortable={!valueModificationActive}
          pageSize={pagination.pageSize}
          page={pagination.currentPage}
        >
          <span slot="cell" let:cell let:row>
            <ParameterValueTableRow
              {cell}
              {row}
              bind:parameterValues
              bind:resultNotification
              bind:valueModificationActive
              bind:loading
              parameterId={parameter.id}
              on:update-values={handleUpdatedValues}
            />
          </span>
          <Toolbar>
            <ToolbarContent>
              <ToolbarSearch
                disabled={valueModificationActive}
                labelText="Search for parameter values"
                persistent
                value={searchText}
                on:clear={onSearchTextChange}
                on:input={onSearchTextChange}
              />
              <Button disabled={valueModificationActive} on:click={initAddValue}>Add value</Button>
            </ToolbarContent>
          </Toolbar>
        </DataTable>
      {:else if !resultNotification}
        <InlineNotification hideCloseButton lowContrast kind="info" subtitle="There are no parameter values yet for current parameter.">
          <div slot="actions">
            <NotificationActionButton on:click={initAddValue}>Add value</NotificationActionButton>
          </div>
        </InlineNotification>
      {/if}
    </LoadingSpinner>
  </Modal>
</div>

<style>
  .parameter-modal :global(.bx--data-table-container) {
    margin-bottom: 2rem;
  }
</style>
