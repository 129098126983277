<script>
  import { createEventDispatcher } from 'svelte';

  import { Accordion, AccordionItem, Button, FormGroup, Modal } from 'carbon-components-svelte';
  import Close16 from 'carbon-icons-svelte/lib/Close16';
  import Edit16 from 'carbon-icons-svelte/lib/Edit16';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';
  import truncate from 'lodash/truncate';
  import * as yup from 'yup';

  import { TextInput, TextArea } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import CustomInlineNotification from '../CustomInlineNotification.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { createBuildNote, updateBuildNote, deleteBuildNote } from '../../services';
  import { convertToLocalDisplayTime } from '../../utils';

  export let open = false,
    notes = [],
    entityId;

  const dispatch = createEventDispatcher();

  const Mode = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete',
    VIEW: 'view',
  };

  const PageErrors = {
    EMPTY_NOTE: 'Note cannot be empty',
  };

  const Notification = {
    EMPTY: {
      dismissible: false,
      kind: 'info',
      subtitle: 'There are no notes for this entity yet',
      actions: [
        {
          text: 'Add note',
          onClick: noteAction(),
        },
      ],
    },
    UNKNOWN_ENTITY: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Unable to find note. Refresh page and try again.',
    },
    CREATE_ERROR: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to create note! Verify your submission and try again.',
    },
    UPDATE_ERROR: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to update note! Verify your submission and try again.',
    },
    DELETE_ERROR: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to delete note! Verify your submission and try again.',
    },
  };

  let modalData = {
      loading: false,
      notifications: new Set(),
      pageErrors: new Set(),
      mode: Mode.VIEW,
      elementRef: null,
    },
    editingNote,
    inputElement;

  async function deleteNote() {
    if (!editingNote?.id) {
      modalData.notifications.add('UNKNOWN_ENTITY');
      editingNote = null;

      modalData = {
        ...modalData,
        mode: Mode.VIEW,
      };

      return;
    }

    modalData = {
      ...modalData,
      loading: true,
    };

    try {
      await deleteBuildNote(editingNote.id);
      dispatch('refresh', {
        action: 'note-deletion',
        data: { entityId, noteId: editingNote.id },
      });
    } catch (error) {
      console.error('[BuildNotesModal] Cannot delete note', error);
      modalData.notifications.add('DELETE_ERROR');
    } finally {
      modalData.notifications.delete('DELETE_ERROR');
      modalData.notifications.delete('UNKNOWN_ENTITY');

      editingNote = null;
      modalData = {
        ...modalData,
        loading: false,
        mode: Mode.VIEW,
      };
    }
  }

  async function saveNote({ detail }) {
    const isCreation = detail.values && !editingNote;
    const dataToSubmit = detail?.values || {
      title: editingNote.title,
      value: editingNote.value,
    };

    if (!isCreation && !editingNote?.value) {
      modalData.pageErrors.add('EMPTY_NOTE');
      modalData = { ...modalData };
      return;
    }

    modalData = {
      ...modalData,
      loading: true,
    };

    try {
      const note = await (isCreation ? createBuildNote({ entityId, ...dataToSubmit }) : updateBuildNote(editingNote.id, dataToSubmit));
      dispatch('refresh', {
        action: isCreation ? 'note-creation' : 'note-update',
        data: { entityId, note },
      });
    } catch (error) {
      console.error(`[BuildNotesModal] Cannot ${isCreation ? 'create' : 'update'} note`, error);
      modalData.notifications.add(isCreation ? 'CREATE_ERROR' : 'UPDATE_ERROR');
    } finally {
      modalData.notifications.delete(isCreation ? 'CREATE_ERROR' : 'UPDATE_ERROR');

      editingNote = null;
      modalData = {
        ...modalData,
        loading: false,
        mode: Mode.VIEW,
      };
    }
  }

  function editNote(idToEdit, mode = Mode.EDIT) {
    return function switchMode() {
      if (!idToEdit) {
        editingNote = null;
        modalData = {
          ...modalData,
          mode: Mode.VIEW,
        };

        return;
      }

      const noteToEdit = notes.find(({ id }) => id === idToEdit);

      modalData = {
        ...modalData,
        mode,
      };
      editingNote = {
        ...noteToEdit,
      };
    };
  }

  function noteAction(mode = Mode.CREATE) {
    return function handleAction() {
      modalData = {
        ...modalData,
        mode,
      };

      if (modalData.elementRef) {
        setTimeout(() => {
          const contentContainer = modalData.elementRef.querySelector('.bx--modal-container .bx--modal-content');
          contentContainer.scrollTo({
            top: mode === Mode.CREATE ? contentContainer.scrollHeight : 0,
            left: 0,
            behavior: 'smooth',
          });
        }, 0);
      }
    };
  }

  function handleNoteChange({ target }) {
    const { name, value } = target;
    editingNote = {
      ...editingNote,
      [name]: value,
    };
  }

  $: {
    modalData.notifications[!notes.length && modalData.mode !== Mode.CREATE ? 'add' : 'delete']('EMPTY');
    modalData = { ...modalData };
  }

  $: schema = yup.object().shape({
    title: yup.string(),
    value: yup.string().required(PageErrors.EMPTY_NOTE),
  });

  $: if (inputElement) {
    inputElement.focus();
  }
</script>

<Modal
  bind:ref={modalData.elementRef}
  passiveModal
  modalHeading="Notes"
  preventCloseOnClickOutside={true}
  size={!notes.length ? 'sm' : 'lg'}
  bind:open
  on:close
>
  <LoadingSpinner small loading={modalData.loading}>
    {#each Object.keys(Notification) as notification}
      {#if modalData.notifications.has(notification)}
        <div class="notification">
          <CustomInlineNotification {...Notification[notification]} />
        </div>
      {/if}
    {/each}
    {#if notes.length}
      {#if modalData.mode !== Mode.CREATE}
        <div class="toolbar">
          <Button size="field" disabled={modalData.mode !== Mode.VIEW} on:click={noteAction()}>Add note</Button>
        </div>
      {/if}
      <div class={`notes${modalData.mode !== Mode.CREATE ? ' padded' : ''}`}>
        <Accordion disabled={!!editingNote}>
          {#each notes as { id, title, value, updatedAt, noteCreator }}
            <AccordionItem open={id === editingNote?.id}>
              <svelte:fragment slot="title">
                {#if modalData.mode === Mode.EDIT && editingNote?.id === id}
                  <TextInput
                    name="title"
                    labelText="Title"
                    placeholder="Enter note title..."
                    value={editingNote.title}
                    bind:ref={inputElement}
                    on:change={({ detail: text }) => handleNoteChange({ target: { name: 'title', value: text } })}
                  />
                {:else}
                  <h5>{title || truncate(value)}</h5>
                  <div class="note-subtitle">
                    <span>{noteCreator?.name ?? '-'}</span>
                    <span class="timestamp">Last updated: <em>{convertToLocalDisplayTime(updatedAt)}</em></span>
                  </div>
                {/if}
              </svelte:fragment>
              {#if modalData.mode === Mode.EDIT && editingNote?.id === id}
                <TextArea
                  name="value"
                  labelText="Note"
                  placeholder="Enter note..."
                  {value}
                  required
                  on:change={handleNoteChange}
                  invalid={modalData.pageErrors.has('EMPTY_NOTE')}
                  invalidText={modalData.pageErrors.has('EMPTY_NOTE') ? PageErrors.EMPTY_NOTE : null}
                />
              {:else}
                <p>{value}</p>
              {/if}
              {#if noteCreator?.isCurrentUser}
                <div class="actions">
                  {#if editingNote?.id === id}
                    {#if modalData.mode === Mode.EDIT}
                      <Button size="small" kind="secondary" on:click={editNote(null)}>Cancel</Button>
                      <Button size="small" on:click={saveNote}>Save</Button>
                    {:else if modalData.mode === Mode.DELETE}
                      <Button icon={Close16} iconDescription="Cancel" size="small" kind="tertiary" on:click={editNote(null)} />
                      <Button size="small" kind="danger" on:click={deleteNote}>Verify deletion</Button>
                    {/if}
                  {:else}
                    <Button
                      size="small"
                      kind="tertiary"
                      iconDescription="Edit"
                      icon={Edit16}
                      disabled={modalData.mode !== Mode.VIEW}
                      on:click={editNote(id)}
                    />
                    <Button
                      size="small"
                      kind="danger-tertiary"
                      iconDescription="Delete"
                      icon={TrashCan16}
                      disabled={modalData.mode !== Mode.VIEW}
                      on:click={editNote(id, Mode.DELETE)}
                    />
                  {/if}
                </div>
              {/if}
            </AccordionItem>
          {/each}
        </Accordion>
      </div>
    {/if}
    {#if modalData.mode === Mode.CREATE}
      <div class="form">
        <Form
          validateOnBlur={false}
          validateOnChange={false}
          {schema}
          let:submitForm
          let:errors
          let:setValue
          let:touched
          let:values
          on:submit={saveNote}
        >
          <FormGroup>
            <TextInput
              size="sm"
              name="title"
              invalid={touched.title && !!errors.title}
              invalidText={errors.title}
              labelText="Title"
              placeholder="Title..."
              value={values.title}
              bind:ref={inputElement}
              on:change={({ detail: text }) => setValue('title', text)}
            />
            <TextArea
              size="sm"
              required
              invalid={touched.value && !!errors.value}
              invalidText={errors.value}
              labelText="Note"
              name="value"
              placeholder="Notes..."
              type="text"
              value={values.value}
              on:change={({ target }) => setValue('value', target.value)}
            />
          </FormGroup>
          <div class="actions">
            <Button size="small" kind="secondary" on:click={noteAction(Mode.VIEW)}>Cancel</Button>
            <Button size="small" on:click={submitForm}>Add</Button>
          </div>
        </Form>
      </div>
    {/if}
  </LoadingSpinner>
</Modal>

<style>
  .notification :global(.bx--inline-notification) {
    max-width: unset;
  }

  .toolbar,
  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
  }

  .actions :global(button) {
    margin-left: 0.5rem;
  }

  .notes :global(.bx--accordion__content) {
    padding-right: 4%;
  }

  .notes :global(.bx--accordion__content p) {
    padding-right: 10%;
  }

  .padded {
    padding-bottom: 1rem;
  }

  .note-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
  }

  :global(.bx--accordion__heading:not([disabled])) .timestamp {
    font-size: 0.75rem;
    color: var(--cds-text-secondary);
  }

  .form {
    padding: 0 2rem;
  }
</style>
