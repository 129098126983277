import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function createUser(data) {
  return request(`${ROOT_SERVER_URL}/api/users`, {
    data,
    method: 'POST',
  });
}

export async function getAllUsers() {
  return await request(`${ROOT_SERVER_URL}/api/users`);
}

export function getUser(userId) {
  return request(`${ROOT_SERVER_URL}/api/users/${userId}`);
}

export function updateUser(userId, changes) {
  return request(`${ROOT_SERVER_URL}/api/users/${userId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function confirmUser(userId) {
  return request(`${ROOT_SERVER_URL}/api/users/${userId}/confirmation`, {
    method: 'PUT',
  });
}

export function disableUser(userId) {
  return request(`${ROOT_SERVER_URL}/api/users/${userId}/status`, {
    method: 'DELETE',
  });
}

export function enableUser(userId) {
  return request(`${ROOT_SERVER_URL}/api/users/${userId}/status`, {
    method: 'PUT',
  });
}

export function sendInvitationEmail(userId) {
  return request(`${ROOT_SERVER_URL}/api/users/${userId}/invitation`, {
    method: 'POST',
  });
}
