<script>
  import { createEventDispatcher } from 'svelte';

  import { Checkbox, InlineNotification, Modal } from 'carbon-components-svelte';

  import AffectedPermissions from './AffectedPermissions.svelte';
  import DeletedFeedsList from './DeletedFeedsList.svelte';
  import LoadingSpinner from '../../../../components/LoadingSpinner.svelte';
  import { getDataFeedInventoryRegression, resolveDataFeedInventoryRegression } from '../../../../services';
  import { DataInventoryServiceIdToName } from '../../../../../shared/constants';
  import { getServerErrorMessage } from '../../../../utils';

  export let open, serviceId;

  const dispatch = createEventDispatcher();

  let confirmationCheck,
    displayedNotification,
    fetchError,
    loading = true,
    regression;

  function closeModal() {
    if (!open) {
      // Guard against the event firing twice (on secondary button click in particular).
      return;
    }
    dispatch('close');
  }

  async function getRegressionDetails() {
    loading = true;
    fetchError = undefined;

    try {
      regression = await getDataFeedInventoryRegression(serviceId);
    } catch (error) {
      console.error('[DataFeedInventoryRegression] Failed to load feed regression details!', error);
      displayedNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to retrieve regression details! Please notify the system administrator.',
      };
    } finally {
      loading = false;
    }
  }

  async function resolveRegression() {
    loading = true;
    fetchError = undefined;

    try {
      const triggeredTasks = await resolveDataFeedInventoryRegression(regression, serviceId);
      dispatch('regressionResolved', { serviceId, triggeredTasks });
    } catch (error) {
      console.error('[DataFeedInventoryRegression] Failed to run feed inventory regression!', error);
      displayedNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: getServerErrorMessage(error) || 'Failed to resolve regression! Please notify the system administrator.',
      };
    } finally {
      loading = false;
    }
  }

  $: if (open && serviceId) {
    getRegressionDetails();
  }

  $: modalHeading = DataInventoryServiceIdToName?.[serviceId]
    ? `Resolve Regression (${DataInventoryServiceIdToName?.[serviceId]})`
    : 'Resolve Regression';

  $: affectedPermissionsByGroup = regression?.affectedPermissions
    ?.map(
      ({
        permission: {
          group: { name: groupName },
        },
        feed: { name: feedName },
        startDate,
        endDate,
        isActive,
        newDateRanges,
        isOngoing,
        expiresAt,
        updatedByDisplayName,
      }) => {
        const dateLength = expiresAt ? 10 : 7;
        return {
          groupName,
          feedName,
          startDate: startDate.slice(0, dateLength),
          endDate: endDate.slice(0, dateLength),
          isActive,
          newDateRanges: newDateRanges.map(({ startDate: rangeStartDate, endDate: rangeEndDate }) => ({
            startDate: rangeStartDate.slice(0, dateLength),
            endDate: rangeEndDate.slice(0, dateLength),
          })),
          isOngoing,
          expiresAt,
          updatedByDisplayName,
        };
      }
    )
    ?.reduce((obj, curr) => ({ ...obj, [curr.groupName]: [...(obj[curr.groupName] ?? []), curr] }), {});
</script>

<Modal
  {modalHeading}
  {open}
  preventCloseOnClickOutside={true}
  primaryButtonDisabled={!confirmationCheck || loading || fetchError || !regression}
  primaryButtonText="Resolve regression"
  secondaryButtonText="Cancel"
  size="lg"
  on:click:button--secondary={closeModal}
  on:close={closeModal}
  on:submit={resolveRegression}
>
  {#if displayedNotification}
    <InlineNotification lowContrast {...displayedNotification} />
  {/if}
  <LoadingSpinner {loading}>
    <div class="regression-details">
      <p class="text-info">
        MACE maintains an inventory of day-precise data feed availability for each service. A recent scheduled task execution found that
        data tracked by MACE is no longer available on the service. Resolving the problem(s) listed below will align MACE with the service
        by soft-deleting inventory data in the MACE database.
      </p>
      {#if regression}
        <h4>Problems</h4>
        <DeletedFeedsList
          fullyDeletedDataFeeds={regression.fullyDeletedDataFeeds}
          deletedDates={regression.deletedDates}
          regressedMonths={regression.regressedMonths}
        />
        {#if regression?.affectedPermissions?.length > 0}
          <h4>Affected Permissions</h4>
          <AffectedPermissions {affectedPermissionsByGroup} />
        {/if}
      {/if}
      <h4>Confirmation</h4>
      <Checkbox
        labelText="I understand the above output and confirm that the system should perform these actions."
        bind:checked={confirmationCheck}
      />
    </div>
  </LoadingSpinner>
</Modal>

<style>
  /* Style like h5, but use h4 for accessibility */
  .regression-details :global(h4) {
    font-size: var(--cds-productive-heading-02-font-size, 1rem);
    font-weight: var(--cds-productive-heading-02-font-weight, 600);
    line-height: var(--cds-productive-heading-02-line-height, 1.375);
    letter-spacing: var(--cds-productive-heading-02-letter-spacing, 0);
    margin: 1rem 0 0.5rem;
  }

  .regression-details :global(.bx--accordion__content) {
    padding: 0.25rem 0;
  }
  /* The wrapping accordion has item borders on top, between elements, and on the bottom of each element. Use those. */
  .regression-details :global(.bx--accordion__content .bx--data-table tr:last-child > td) {
    border-bottom: 0;
  }

  .regression-details :global(.bx--list--ordered) {
    margin: 0.625rem 0 0.625rem 2.5rem;
  }
</style>
