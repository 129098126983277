<script>
  import { createEventDispatcher, tick } from 'svelte';
  import noop from 'lodash/noop';

  import { Button, SideNav, SideNavDivider, SideNavItems, SideNavLink, TooltipDefinition, TooltipIcon } from 'carbon-components-svelte';

  import AddIcon from 'carbon-icons-svelte/lib/Add16';
  import Edit from 'carbon-icons-svelte/lib/Edit16';
  import Save from 'carbon-icons-svelte/lib/Save16';
  import Star from 'carbon-icons-svelte/lib/Star16';
  import StarFilled from 'carbon-icons-svelte/lib/StarFilled16';
  import TrashCan from 'carbon-icons-svelte/lib/TrashCan16';
  import View from 'carbon-icons-svelte/lib/View16';

  import AddOrEditEmailTemplateModal from './AddOrEditEmailTemplateModal.svelte';
  import CustomInlineNotification from '../CustomInlineNotification.svelte';
  import EmailReplacementsList from './EmailReplacementsList.svelte';
  import EmailTemplateForm from './EmailTemplateForm.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import PreviewLicenseEmailTemplateModal from './PreviewLicenseEmailTemplateModal.svelte';
  import RemoveEmailTemplateModal from './RemoveEmailTemplateModal.svelte';

  import { getEmailReplacementsForCategory, getEmailTemplates, updateEmailTemplate } from '../../services';

  const dispatch = createEventDispatcher();

  const Notifications = {
    NO_TEMPLATE: {
      id: 'no-templates',
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no templates for this category yet.',
      actions: [
        {
          text: 'Add Template',
          onClick: () => openModal('addOrEditTemplate'),
        },
      ],
    },
    ERROR_TEMPLATES: {
      id: 'error-templates',
      hideCloseButton: true,
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to get email templates! Please try again later.',
    },
  };

  const PREVIEW_MODALS = {
    licenses: PreviewLicenseEmailTemplateModal,
  };

  export let selectedCategoryId = null,
    defaultTemplateId = null;

  let mainNotification = null,
    loading = false,
    templates = [],
    replacements = [],
    selectedTemplate = null,
    emailData = null,
    emailFormReady = false,
    isSynced = true;

  let modals = {
    addOrEditTemplate: { open: false },
    deleteTemplate: { open: false },
    previewTemplate: { open: false },
  };

  async function fetchTemplates(categoryId) {
    mainNotification = null;
    loading = true;

    try {
      [templates, replacements] = await Promise.all([getEmailTemplates(categoryId), getEmailReplacementsForCategory(categoryId)]);

      if (!templates.length) {
        mainNotification = { ...Notifications.NO_TEMPLATE };
      } else {
        selectedTemplate = null; // unmount email form to refresh rich text editor
        await tick();
        [selectedTemplate] = templates;
      }
    } catch (error) {
      console.error('[EmailTemplateBoard] Failed to load templates!', error);
      mainNotification = { ...Notifications.ERROR_TEMPLATES };
    } finally {
      loading = false;
    }
  }

  async function selectTemplate(templateIdToSelect) {
    isSynced = true;
    selectedTemplate = null; // unmount email form to refresh rich text editor
    await tick();

    selectedTemplate = templates.find(({ id }) => id === templateIdToSelect);
  }

  function handleEmailFormChange({ detail }) {
    const { field, value } = detail;
    emailData = {
      ...emailData,
      [field]: value,
    };

    isSynced = !(emailData.subject !== selectedTemplate.subject || emailData.body !== selectedTemplate.body);
  }

  async function saveTemplateChanges() {
    loading = true;

    try {
      await updateEmailTemplate(selectedTemplate.id, { subject: emailData.subject, body: JSON.stringify(emailData.body) });
      selectedTemplate = {
        ...selectedTemplate,
        ...emailData,
      };

      templates = templates.map((template) => (template.id === selectedTemplate.id ? selectedTemplate : template));
      isSynced = true;
    } catch (error) {
      console.error('[EmailTemplateBoard] Failed to update email template data!', error);
    } finally {
      loading = false;
    }
  }

  function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'add-template':
        templates = [...templates, data];
        selectTemplate(data.id);

        if (mainNotification?.id === 'no-templates') {
          mainNotification = null;
        }
        break;

      case 'remove-template':
        templates = templates.filter((template) => template.id !== data.id);
        isSynced = true;

        if (!templates.length) {
          mainNotification = { ...Notifications.NO_TEMPLATE };
        } else {
          selectTemplate(templates[0].id);
        }
        break;

      case 'edit-template':
        templates = templates.map((template) => (template.id === data.id ? data : template));
        selectTemplate(data.id);
        break;

      default:
        console.warn(`[EmailTemplateBoard] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function handleDefaultTemplateChange(id) {
    return function changeDefaultTemplate() {
      dispatch('default', { id });
    };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  $: if (selectedCategoryId) {
    fetchTemplates(selectedCategoryId);
  }

  $: if (selectedTemplate) {
    emailData = {
      subject: selectedTemplate.subject,
      body: selectedTemplate.body,
    };
  }
</script>

<svelte:head>
  <link
    href="./styles/quill.css"
    rel="stylesheet"
    on:load={() => {
      emailFormReady = true;
    }}
  />
</svelte:head>

<LoadingSpinner withOverlay={false} {loading}>
  {#if mainNotification}
    <CustomInlineNotification {...mainNotification} />
  {:else}
    <div class="wrapper">
      <div class="vertical-tabs">
        <SideNav isOpen fixed aria-label="Email Templates">
          <SideNavItems>
            {#each templates as template}
              <SideNavLink
                text={template.name}
                on:click={() => selectTemplate(template.id)}
                isSelected={selectedTemplate?.id === template.id}
              />
            {/each}
            <SideNavDivider />
            <div class="tabs-action">
              <SideNavLink icon={AddIcon} text="Create template" on:click={() => openModal('addOrEditTemplate')} />
            </div>
          </SideNavItems>
        </SideNav>
        <div class="tabs-content">
          {#if selectedTemplate}
            <div class="tab-header">
              <div class="tab-info">
                {#if !selectedTemplate.isPrivate}
                  <div class:clickable={selectedTemplate.id !== defaultTemplateId}>
                    <TooltipIcon
                      tooltipText={selectedTemplate.id === defaultTemplateId
                        ? 'Template is selected as default'
                        : 'Set template as default'}
                      icon={selectedTemplate.id === defaultTemplateId ? StarFilled : Star}
                      on:click={selectedTemplate.id === defaultTemplateId ? noop : handleDefaultTemplateChange(selectedTemplate.id)}
                    />
                  </div>
                {/if}
                <h3 class="h3">{selectedTemplate.name}</h3>
                <TooltipDefinition
                  tooltipText={selectedTemplate.isPrivate
                    ? 'This template can only been seen and edited by you.'
                    : 'This is a public template. Any changes will affect any other users using it.'}
                >
                  {selectedTemplate.isPrivate ? 'Private' : 'Public'}
                </TooltipDefinition>
              </div>
              <div class="tab-actions">
                <Button
                  size="field"
                  kind="ghost"
                  iconDescription="Edit Template"
                  icon={Edit}
                  on:click={() => openModal('addOrEditTemplate', { data: selectedTemplate })}
                />
                <Button
                  size="field"
                  kind="danger-tertiary"
                  iconDescription="Delete Template"
                  icon={TrashCan}
                  on:click={() => openModal('deleteTemplate', { data: selectedTemplate })}
                />
                <div class="main-action">
                  {#if PREVIEW_MODALS[selectedCategoryId]}
                    <Button
                      size="field"
                      icon={View}
                      disabled={!emailData?.body}
                      on:click={() => openModal('previewTemplate', { data: emailData })}>Preview</Button
                    >
                  {/if}
                  <Button class="main" size="field" disabled={isSynced} icon={Save} on:click={saveTemplateChanges}>Save</Button>
                </div>
              </div>
            </div>
            <EmailTemplateForm subject={emailData.subject} body={emailData.body} on:change={handleEmailFormChange} {emailFormReady} />
          {/if}
        </div>
      </div>
      <div class="replacements-list">
        <EmailReplacementsList {replacements} />
      </div>
    </div>
  {/if}
</LoadingSpinner>

{#if modals.addOrEditTemplate.open}
  <AddOrEditEmailTemplateModal
    open
    categoryId={selectedCategoryId}
    template={modals.addOrEditTemplate.data}
    on:close={({ detail: data }) =>
      closeModal('addOrEditTemplate', { action: modals.addOrEditTemplate.data ? 'edit-template' : 'add-template', data })}
  />
{/if}

{#if modals.deleteTemplate.open}
  <RemoveEmailTemplateModal
    open
    categoryId={selectedCategoryId}
    template={modals.deleteTemplate.data}
    on:close={({ detail: data }) => closeModal('deleteTemplate', { action: 'remove-template', data })}
  />
{/if}

{#if modals.previewTemplate.open}
  <svelte:component
    this={PREVIEW_MODALS[selectedCategoryId]}
    open
    emailCategoryId={selectedCategoryId}
    template={modals.previewTemplate.data}
    on:close={() => closeModal('previewTemplate')}
  />
{/if}

<style>
  .h3 {
    font-size: 1.25rem;
    margin: 0 0.5rem;
  }

  .wrapper {
    display: flex;
  }

  .vertical-tabs {
    display: flex;
    margin-top: 0.5rem;
    flex: 4;
  }

  .vertical-tabs :global(nav) {
    background-color: var(--cds-ui-background);
    min-width: 100px;
    position: relative;
    top: 0;
    z-index: 1;
  }

  .clickable :global(svg) {
    cursor: pointer;
  }

  .vertical-tabs :global(.bx--side-nav__item:not(.bx--side-nav__item--active) > .bx--side-nav__link:hover) {
    background-color: var(--cds-ui-01);
    cursor: pointer;
  }

  .vertical-tabs :global(.bx--side-nav__item:not(.bx--side-nav__item--active) > .bx--side-nav__link:hover > span) {
    color: var(--cds-text-01);
  }

  .vertical-tabs :global(.bx--side-nav__link-text) {
    color: var(--cds-text-02);
  }

  .vertical-tabs :global(.bx--side-nav__link--current) {
    background-color: var(--cds-ui-01);
  }

  .vertical-tabs :global(.bx--side-nav__link--current .bx--side-nav__link-text) {
    color: var(--cds-text-01);
  }

  .tabs-content {
    width: 100%;
    padding-left: 1rem;
  }

  .tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--cds-ui-04);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    min-height: 50px;
  }

  .tab-info {
    display: flex;
    align-items: center;
  }

  .tab-info :global(.bx--tooltip__trigger) {
    font-style: italic;
  }

  .tab-actions {
    display: flex;
  }

  .main-action {
    padding-left: 0.75rem;
    margin-left: 0.75rem;
    border-left: 1px solid var(--cds-ui-04);
  }

  :global(html[theme='g90']) .main-action :global(.main.bx--btn.bx--btn--primary:not(.bx--btn:disabled)) {
    background-color: var(--cds-inverse-support-02);
  }

  .main-action :global(.main.bx--btn.bx--btn--primary:not(.bx--btn:disabled)) {
    background-color: #24a148;
    /* use same transition effect from carbon button but animate "filter" instead of "background" */
    transition: filter 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9),
      border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  .main-action :global(.main.bx--btn.bx--btn--primary:hover:not(.bx--btn:disabled)) {
    /* carbon doesn't offer a darker green accent */
    filter: brightness(95%);
  }

  .tabs-action :global(.bx--side-nav__icon > svg) {
    fill: var(--cds-text-02);
  }

  .replacements-list {
    flex: 1;
  }
</style>
