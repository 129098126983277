<script>
  import { createEventDispatcher } from 'svelte';

  import { FormGroup, InlineNotification, Modal, Tag } from 'carbon-components-svelte';
  import * as yup from 'yup';

  import { Select, SelectItem, Toggle } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import CustomInput from '../CustomInput.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import {
    addBuildParameter,
    addParameterName,
    addParameterValueData,
    createBuildConfig,
    getParameterNames,
    getParameterValues,
    getSuggestions,
    updateBuildParameter,
  } from '../../services';
  import { getServerErrorMessage } from '../../utils';
  import { PARAMETER_MERGE_SUFFIX } from '../../../shared/constants';

  export let entityId,
    entityType,
    entityName,
    configId,
    parameter,
    targetName = null,
    open;

  const DEBOUNCE_MS = 500;
  const MESSAGES = {
    errorSelections: 'Please add at least one value',
    warningSelections: 'One or more of the typed values are already under selections',
  };
  const TYPE_HELP_TEXTS = {
    single: 'A single value can only be related to this parameter.',
    multiple: 'Multiple values can be related to this parameter.',
    boolean: 'The parameter has a true/false value.',
  };

  let loading = false,
    formSubmitButtonRef,
    resultNotification,
    initialValues,
    isEdit = false,
    mergeParameter = null,
    debounce,
    formExtras = {
      type: 'single',
      selections: new Set(),
      selectionsIssue: null,
    };

  let autocompletesState = {
    parameterName: {
      loading: false,
      suggestions: [],
    },
    parameterValue: {
      loading: false,
      suggestions: [],
    },
  };

  const dispatch = createEventDispatcher();

  function handleAutocompleteKeydown(key, setValue) {
    return ({ detail: event }) => {
      if (['Enter', 'Escape'].includes(event.key)) {
        autocompletesState = {
          ...autocompletesState,
          [key]: {
            ...autocompletesState[key],
            suggestions: [],
          },
        };
      }

      const { value: rawValue } = event.target;

      if (key !== 'parameterValue' || formExtras.type !== 'multiple' || rawValue === '') {
        return;
      }

      if (event.key !== 'Enter') {
        setValue('parameterValue', rawValue);
        return;
      }

      // prevent form submission
      event.preventDefault();

      const values = rawValue.split(',');
      let foundSameSelection = false;

      /* eslint-disable no-continue */
      for (const value of values) {
        if (!value) {
          continue;
        }

        if (!formExtras.selections.has(value)) {
          formExtras.selections.add(value);
          continue;
        }

        foundSameSelection = true;

        formExtras = {
          ...formExtras,
          selectionsIssue: 'warning',
        };
      }
      /* eslint-enable */

      if (!foundSameSelection) {
        formExtras = {
          ...formExtras,
          selectionsIssue: null,
        };
      }

      setValue('parameterValue', '');
    };
  }

  function handleParameterValueChange(setValue) {
    return ({ detail: value }) => {
      if (formExtras.type === 'multiple' || !value) {
        return;
      }

      setValue('parameterValue', value);
    };
  }

  function handleParameterRemoval(removedParameter) {
    return function removeParameter() {
      formExtras.selections.delete(removedParameter);

      formExtras = {
        ...formExtras,
      };
    };
  }

  async function createDefaultConfig() {
    try {
      const buildConfig = await createBuildConfig({
        ownerType: entityType,
        ownerId: entityId,
        name: 'Default',
        isDefault: true,
      });

      return buildConfig;
    } catch (error) {
      console.error('[AddOrEditParameterModal] Failed to create default build config!', error);
      return {};
    }
  }

  async function handleFormSubmission({ detail: { values } }) {
    resultNotification = null;
    loading = true;

    try {
      if (!configId) {
        const configDetails = await createDefaultConfig();
        configId = configDetails.id;
      }

      const parameterValue =
        formExtras.type === 'multiple'
          ? formExtras.selections.size
            ? JSON.stringify([...formExtras.selections])
            : ''
          : values.parameterValue;

      const parameterData = {
        parameterName: values.parameterName,
        parameterValue,
        configId,
      };

      const parameterDetails = await (isEdit ? updateBuildParameter(parameter.id, parameterData) : addBuildParameter(parameterData));

      dispatch('close', parameterDetails ? { ...parameterDetails } : null);
      tryAddingToKnownParameters(parameterData);
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Verify your submission and try again!';

      console.error(`[AddOrEditParameterModal] Failed to add parameter! Server says: ${errorMessage}`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to add parameter! ${errorMessage}`,
      };
    } finally {
      loading = false;
    }
  }

  async function tryAddingToKnownParameters({ parameterName, parameterValue }) {
    try {
      const knownParameters = await getParameterNames();
      const existingKnownParameter = knownParameters.find(({ name }) => name === parameterName);
      let extendedParameterId = existingKnownParameter?.id;
      if (!extendedParameterId) {
        const createdParameter = await addParameterName({ name: parameterName, type: formExtras.type });
        extendedParameterId = createdParameter.id;
      }

      // we don't want to save value of boolean type
      if (formExtras.type === 'boolean') {
        return;
      }

      let sanitizedParameterValue = parameterValue;

      if (formExtras.type === 'multiple') {
        try {
          sanitizedParameterValue = JSON.parse(parameterValue);
        } catch (_) {} // eslint-disable-line
      }

      const existingParameterValues = existingKnownParameter ? await getParameterValues({ parameterId: extendedParameterId }) : [];
      const valueToSubmit = Array.isArray(sanitizedParameterValue)
        ? sanitizedParameterValue.filter((value) => !existingParameterValues.includes(value))
        : existingParameterValues.every(({ value }) => value !== sanitizedParameterValue)
        ? sanitizedParameterValue
        : null;

      if ((Array.isArray(valueToSubmit) && valueToSubmit.length) || valueToSubmit) {
        await addParameterValueData({ parameterId: extendedParameterId, value: valueToSubmit });
      }
    } catch (e) {
      console.error('[AddOrEditParameterModal] Failed to add known parameter/parameter value', e);
    }
  }

  function handleAutocompleteInput(key, values) {
    return function onInput({ detail: value }) {
      clearTimeout(debounce);

      if (!value) {
        autocompletesState = {
          ...autocompletesState,
          [key]: {
            ...autocompletesState[key],
            suggestions: [],
            loading: false,
          },
        };

        return;
      }

      autocompletesState = {
        ...autocompletesState,
        [key]: {
          ...autocompletesState[key],
          loading: true,
        },
      };

      debounce = setTimeout(async () => {
        try {
          const suggestions = await getSuggestions({
            type: key,
            value,
            parameterName: key === 'parameterValue' ? values.parameterName : '',
          });

          autocompletesState = {
            ...autocompletesState,
            [key]: {
              ...autocompletesState[key],
              suggestions,
            },
          };
        } catch (error) {
          const errorMessage = getServerErrorMessage(error) ?? 'Could not fetch suggestions!';
          console.error(`[AddOrEditParameterModal] Failed to fetch suggestions! Server says: ${errorMessage}`, error);
        } finally {
          autocompletesState = {
            ...autocompletesState,
            [key]: {
              ...autocompletesState[key],
              loading: false,
            },
          };
        }
      }, DEBOUNCE_MS);
    };
  }

  function getMergeParameter(key, value) {
    return key === 'parameterName' && value.endsWith(PARAMETER_MERGE_SUFFIX) ? value : null;
  }

  function handleAutocompleteChange(key, setValue) {
    return function onChange({ detail: text }) {
      mergeParameter = getMergeParameter(key, text);
      setValue(key, text);
    };
  }

  function handleAutocompleteSelect(key, setValue) {
    return function onSelect({ detail: data }) {
      const { id, value } = data;

      setValue(key, value);

      mergeParameter = getMergeParameter(key, value);

      if (key === 'parameterName') {
        const { type } = autocompletesState.parameterName.suggestions.find((suggestion) => id === suggestion.id);

        formExtras = {
          ...formExtras,
          type,
        };
      }

      autocompletesState = {
        ...autocompletesState,
        [key]: {
          ...autocompletesState[key],
          suggestions: [],
        },
      };
    };
  }

  function handleAutocompleteBlur(key) {
    return function onBlur() {
      autocompletesState = {
        ...autocompletesState,
        [key]: {
          ...autocompletesState[key],
          suggestions: [],
        },
      };
    };
  }

  function handleTypeSelect(setValue, currentParameterValue) {
    let normalizedValue;

    try {
      normalizedValue = JSON.parse(currentParameterValue);
    } catch (_) {
      normalizedValue = currentParameterValue;
    }

    return function onTypeChange({ detail: type }) {
      formExtras = {
        ...formExtras,
        type,
      };

      const shouldResetValue =
        (typeof normalizedValue === 'boolean' && ['single', 'multiple'].includes(type)) ||
        (typeof normalizedValue !== 'boolean' && type === 'boolean');
      if (shouldResetValue) {
        setValue('parameterValue', type === 'boolean' ? false : '');
      }
    };
  }

  function handlePaste(event) {
    event.stopPropagation();
    event.preventDefault();

    const clipboardData = event.clipboardData || window.clipboardData;
    const possibleValuesRaw = clipboardData.getData('Text');

    if (!possibleValuesRaw) {
      return;
    }

    const values = possibleValuesRaw.trim().split(/\s+/);
    let foundSameSelection = false;

    /* eslint-disable no-continue */
    for (const value of values) {
      if (!value) {
        continue;
      }

      if (!formExtras.selections.has(value)) {
        formExtras.selections.add(value);
        continue;
      }

      foundSameSelection = true;

      formExtras = {
        ...formExtras,
        selectionsIssue: 'warning',
      };
    }

    if (!foundSameSelection) {
      formExtras = {
        ...formExtras,
        selectionsIssue: null,
      };
    }

    formExtras = { ...formExtras };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: if (parameter && !initialValues) {
    isEdit = true;

    const { parameterName, parameterValue } = parameter;
    let value;

    try {
      value = JSON.parse(parameterValue);
    } catch (_) {} // eslint-disable-line

    if (Array.isArray(value)) {
      formExtras = {
        ...formExtras,
        type: 'multiple',
        selections: new Set(value),
      };

      initialValues = { parameterName };
    } else if (typeof value === 'boolean') {
      formExtras = {
        ...formExtras,
        type: 'boolean',
      };

      initialValues = { parameterName, parameterValue: value };
    } else {
      initialValues = { parameterName, parameterValue };
    }

    mergeParameter = parameterName.endsWith(PARAMETER_MERGE_SUFFIX) ? parameterName : null;
  }

  $: schema = yup.object().shape({
    parameterName: yup.string().required('Parameter name cannot be empty'),
    ...(formExtras.type !== 'multiple' ? { parameterValue: yup.string() } : {}),
  });
</script>

<Modal
  hasForm
  class="parameters-modal"
  modalHeading={`${isEdit ? 'Edit' : 'Add'} Parameter`}
  preventCloseOnClickOutside
  primaryButtonText={isEdit ? 'Save' : 'Add'}
  secondaryButtonText="Cancel"
  shouldSubmitOnEnter={false}
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close={() => dispatch('close')}
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are about to {isEdit ? 'edit' : 'add'} a build parameter under the {entityType} <strong>{entityName}</strong>
      {#if targetName}
        for the <strong>{targetName}</strong> target
      {/if}
    </p>
    {#if mergeParameter && formExtras.type === 'multiple'}
      <InlineNotification
        kind="info"
        lowContrast
        title="Parameter will be merged"
        subtitle={`${mergeParameter} will be merged with ${mergeParameter.substring(
          0,
          mergeParameter.length - PARAMETER_MERGE_SUFFIX.length
        )}`}
      />
    {/if}
    <Form
      validateOnBlur={false}
      validateOnChange={false}
      {initialValues}
      {schema}
      let:submitForm
      let:errors
      let:setValue
      let:touched
      let:values
      on:submit={handleFormSubmission}
    >
      <FormGroup>
        <CustomInput
          name="parameterName"
          invalid={touched.parameterName && !!errors.parameterName}
          invalidText={errors.parameterName}
          labelText="Parameter Name"
          placeholder="Parameter Name"
          value={values.parameterName}
          required
          suggestions={autocompletesState.parameterName.suggestions}
          loading={autocompletesState.parameterName.loading}
          on:input={handleAutocompleteInput('parameterName', values)}
          on:change={handleAutocompleteChange('parameterName', setValue)}
          on:select={handleAutocompleteSelect('parameterName', setValue)}
          on:keydown={handleAutocompleteKeydown('parameterName', setValue)}
          on:blur={handleAutocompleteBlur('parameterName')}
          autocomplete="off"
        />
        <Select
          required
          name="type"
          selected={formExtras.type}
          invalid={!formExtras.type}
          invalidText="Please specify parameter type"
          helperText={TYPE_HELP_TEXTS[formExtras.type]}
          labelText="Type"
          on:change={handleTypeSelect(setValue, values.parameterValue)}
        >
          <SelectItem value="single" text="Single-valued" />
          <SelectItem value="multiple" text="Multi-valued" />
          <SelectItem value="boolean" text="Boolean" />
        </Select>
        {#if formExtras.type === 'boolean'}
          <Toggle
            required
            class="parameter-toggle"
            name="parameterValue"
            invalid={touched.parameterValue && !!errors.parameterValue}
            invalidText={errors.parameterValue}
            labelText="Parameter Value"
            toggled={values.parameterValue}
            labelA="FALSE"
            labelB="TRUE"
            on:toggle={({ detail: { toggled } }) => setValue('parameterValue', toggled)}
          />
        {:else}
          <CustomInput
            name="parameterValue"
            invalid={touched.parameterValue && !!errors.parameterValue}
            invalidText={errors.parameterValue}
            labelText="Parameter Value"
            placeholder={formExtras.type === 'multiple' ? 'Add Parameter Value' : 'Parameter Value'}
            value={values.parameterValue}
            required={formExtras.type !== 'multiple'}
            suggestions={autocompletesState.parameterValue.suggestions}
            loading={autocompletesState.parameterValue.loading}
            on:input={handleAutocompleteInput('parameterValue', values)}
            on:change={handleParameterValueChange(setValue)}
            on:select={handleAutocompleteSelect('parameterValue', setValue)}
            on:keydown={handleAutocompleteKeydown('parameterValue', setValue)}
            on:blur={handleAutocompleteBlur('parameterValue')}
            autocomplete="off"
          />
        {/if}
        {#if formExtras.type === 'multiple'}
          <div class="selections-wrapper">
            <span class="bx--label">Selections</span>
            <div
              class={formExtras.selectionsIssue ? `${formExtras.selectionsIssue} selections` : 'selections'}
              aria-label="Parameter selections"
              on:paste={handlePaste}
              tabindex="0"
            >
              {#if !formExtras.selections.size}
                <span>Use input above to add values. Your selections will appear here.</span>
              {:else}
                {#each [...formExtras.selections] as value}
                  <Tag filter on:close={handleParameterRemoval(value)} on:click={(e) => e.preventDefault()}>
                    {value}
                  </Tag>
                {/each}
              {/if}
            </div>
            {#if formExtras.selectionsIssue}
              <span class="{formExtras.selectionsIssue} bx--label">{MESSAGES[`${formExtras.selectionsIssue}Selections`]}</span>
            {/if}
          </div>
        {/if}
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>

<style>
  @media (min-width: 42rem) {
    :global(.parameters-modal.bx--modal) {
      overflow: auto;
    }

    :global(.parameters-modal .bx--modal-content) {
      overflow-y: unset;
    }
    :global(.parameters-modal .bx--modal-container) {
      overflow: unset;
      min-height: 500px;
    }
  }

  :global(.parameter-toggle .bx--toggle__text--on),
  :global(.parameter-toggle .bx--toggle__text--off) {
    font-size: 0.75rem;
  }

  .selections-wrapper {
    margin-top: 0.75rem;
  }

  .selections {
    background-color: var(--cds-field-02);
    padding: 0.5rem;
    min-height: 100px;
    max-height: 185px;
    overflow-x: auto;
    border: 1px solid var(--cds-ui-04);
    outline-offset: -2px;
  }

  .selections:focus {
    outline: 2px solid var(--cds-focus) !important;
  }

  .selections > span {
    color: var(--cds-text-02);
    font-style: italic;
    font-size: 12px;
  }

  .selections :global(.bx--tag--filter) {
    border: 1px solid var(--cds-ui-04);
  }

  .warning.bx--label {
    color: var(--outline-warning-color);
  }

  .warning.selections {
    outline: 2px solid var(--outline-warning-color);
  }

  .error.bx--label {
    color: var(--cds-support-01);
  }

  .error.selections {
    outline: 2px solid var(--cds-support-01);
  }
</style>
