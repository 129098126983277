<script>
  import { onMount } from 'svelte';

  import BuildParameterDataTable from '../../components/build-parameters/DataTable.svelte';
  import { getDefaultBuildWithParameters } from '../../services';

  let pageData = { loading: true, buildConfig: {}, notifications: new Set() };

  const entityId = 'system';
  const entityType = 'system';

  function updatePageData(modification) {
    const { action, data } = modification;
    let updatedParameters;

    switch (action) {
      case 'add-parameter':
        if (!data) {
          break;
        }

        pageData.notifications.delete('noParameters');

        // eslint-disable-next-line no-case-declarations
        const newParameters = [...(pageData.buildConfig.data ?? []), data];

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            id: data.configId,
            data: newParameters,
          },
        };

        break;

      case 'update-parameter':
        if (!data) {
          break;
        }

        updatedParameters = pageData.buildConfig.data.map((param) => (param.id === data.id ? data : param));

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            data: updatedParameters,
          },
        };

        break;

      case 'remove-parameter':
        if (!data) {
          break;
        }

        updatedParameters = pageData.buildConfig.data.filter((param) => param.id !== data.id);

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            data: updatedParameters,
          },
        };

        break;

      case 'add-parameters':
        if (!data) {
          break;
        }

        if (data.parameters.length) {
          pageData.notifications.delete('noParameters');
        }

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            id: data.id,
            data: data.parameters,
          },
        };

        break;
      default:
        console.warn(`[SystemParameterList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  async function loadGlobalParameters() {
    pageData = { ...pageData, loading: true };
    try {
      const buildConfig = await getDefaultBuildWithParameters({
        ownerId: entityId,
        ownerType: entityType,
      });

      if (!buildConfig.data.length) {
        pageData.notifications.add('noParameters');
      }

      pageData = { ...pageData, buildConfig, loading: false };
    } catch (error) {
      console.error('[SystemParameterList] Failed to load parameters!', error);

      pageData.notifications.add('errorParameters');
      pageData = { ...pageData, loading: false };
    }
  }

  function handleNotificationChange({ action, value }) {
    pageData.notifications[action](value);
    pageData = { ...pageData };
  }

  onMount(loadGlobalParameters);
</script>

<BuildParameterDataTable
  loading={pageData.loading}
  buildConfig={pageData.buildConfig}
  entityName="Global Configuration"
  {entityType}
  {entityId}
  activeNotifications={pageData.notifications}
  on:parameter={({ detail: data }) => updatePageData(data)}
  on:removeParameter={({ detail: data }) => updatePageData(data)}
  on:notification={({ detail: data }) => handleNotificationChange(data)}
  on:jsonInput={({ detail: data }) => updatePageData(data)}
/>
