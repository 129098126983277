<script>
  import startCase from 'lodash/startCase';
  import {
    NumberInput,
    OutboundLink,
    RadioButton,
    RadioButtonGroup,
    StructuredList,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
  } from 'carbon-components-svelte';

  import { WB_PLAN_EXCEED_ACTIONS } from '../../../shared/constants';

  export let plan = {};

  const units = {
    USD: '$',
    GB: 'GB',
  };

  function calculateTotalSelections(resources) {
    return resources.reduce((all, resource) => all + resource.value * resource.unitCharge, 0);
  }

  $: totalAmount = calculateTotalSelections(plan.awsResources);
</script>

<div class="preview">
  <div class="resources">
    {#each plan.awsResources as resource}
      <NumberInput
        readonly
        size="sm"
        value={resource.value}
        label={`${startCase(resource.resourceId)} (amount of ${units[resource.unit]})`}
        helperText={`Minimum overage charge is $${resource.minimumOverageCharge}`}
      />
    {/each}
    <hr />
    <NumberInput
      readonly
      size="sm"
      value={plan.cushion}
      label="Cushion %"
      helperText={!plan.cushion
        ? 'Extra percent that can go over the agreed amount, without any extra charges'
        : `Client can go up $${Math.ceil(totalAmount * (1 + plan.cushion / 100))} but still charged $${totalAmount}`}
    />
    <hr />
    <div class="exceed">
      <RadioButtonGroup disabled legendText="Resource Exceed Action" selected={plan.exceedAction}>
        {#each Object.entries(WB_PLAN_EXCEED_ACTIONS) as [value, labelText]}
          <div class="radio">
            <RadioButton {labelText} {value} />
            {#if value === 'charge' && plan.exceedAction === 'charge'}
              <OutboundLink href="/workbench#prices">Check charges</OutboundLink>
            {/if}
          </div>
        {/each}
      </RadioButtonGroup>
      <div class:hidden={plan.exceedAction !== 'charge'}>
        <NumberInput
          readonly
          size="sm"
          label="Hard Limit (amount of $)"
          value={plan.hardLimit}
          helperText="An amount that the client won't be able to exceed (compute + storage + egress)"
        />
      </div>
    </div>
  </div>
  <div class="users">
    <span class="bx--label">User limits</span>
    {#if plan.awsLoginResources.length}
      <div class="list">
        <StructuredList>
          <StructuredListBody>
            {#each Object.values(plan.awsLoginLimitsPerUser) as user}
              <StructuredListRow>
                <StructuredListCell>
                  <h5>{user.name}</h5>
                  <div class="user-resources">
                    {#each plan.awsResources as { resourceId, unit, loginDefaultValue }}
                      <div class="user-resource">
                        <strong>{startCase(resourceId)}</strong>
                        <div class="default">
                          <span>Default</span>
                          <em>
                            {#if unit === 'USD'}
                              $
                            {/if}
                            {loginDefaultValue}
                            {#if unit !== 'USD'}
                              {unit}
                            {/if}
                          </em>
                        </div>
                        {#if user.limits[resourceId]}
                          <div class="extra">
                            <span>Extra</span>
                            <em>
                              {#if unit === 'USD'}
                                $
                              {/if}
                              {user.limits[resourceId].value}
                              {#if unit !== 'USD'}
                                {unit}
                              {/if}
                            </em>
                          </div>
                        {/if}
                      </div>
                    {/each}
                  </div>
                </StructuredListCell>
              </StructuredListRow>
            {/each}
          </StructuredListBody>
        </StructuredList>
      </div>
    {:else}
      <p><em>No limits are introduced per user.</em></p>
    {/if}
  </div>
</div>

<style>
  .preview {
    padding: 1rem;
    display: flex;
  }

  .preview :global(hr) {
    margin: 1rem;
  }

  .resources,
  .users {
    padding: 1rem;
    flex: 1;
  }

  .resources :global(.bx--form-item) {
    padding-bottom: 1rem;
  }

  .users :global(em),
  .resources :global(.bx--form__helper-text) {
    color: var(--cds-text-helper);
  }

  .resources :global(a.bx--link) {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
  }

  .resources :global(a.bx--link svg) {
    width: 12px;
  }

  .user-resources {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 0.85rem;
  }

  .user-resource {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.1rem;
  }

  .exceed .hidden {
    display: none;
  }

  .exceed :global(fieldset) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .exceed .radio {
    display: flex;
    margin-bottom: 0.25rem;
  }

  .exceed .radio :global(a) {
    margin-left: 0.5rem;
  }

  .exceed :global(.bx--form-item:last-child) {
    margin-top: 0.5rem;
  }

  .list {
    max-height: 400px;
    overflow: auto;
    border: 1px solid var(--cds-active-ui);
    border-radius: 10px;
    padding: 0.5rem;
  }

  .list :global(.bx--structured-list) {
    margin-bottom: 0;
  }
</style>
