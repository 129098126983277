<script>
  import { Modal, MultiSelect } from 'carbon-components-svelte';
  import EmailPreview from '../emails/EmailPreview.svelte';

  import { convertToLocalDisplayTime } from '../../utils';

  export let email,
    open = false;

  $: to = email.toAddresses?.map((address) => ({ id: address, text: address, disabled: true })) ?? [];
</script>

<Modal modalHeading="Email Preview" passiveModal preventCloseOnClickOutside={true} bind:open on:close>
  <div class="disabled">
    <MultiSelect titleText="To" items={to} selectedIds={email.toAddresses} />
  </div>
  <span class="bx--label">From</span>
  <div class="preview-area">
    {email.createdByDisplayName}
  </div>
  <EmailPreview {email} />
  <em class="info">{convertToLocalDisplayTime(email.createdAt)}</em>
</Modal>

<style>
  .preview-area {
    border: 1px solid var(--cds-ui-04);
    padding: 0.3rem 1rem;
  }

  .disabled :global(.bx--list-box__menu-item) {
    pointer-events: none;
  }

  .info {
    color: var(--cds-text-02);
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
</style>
