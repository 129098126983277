<script>
  import { onMount } from 'svelte';

  import { Button } from 'carbon-components-svelte';

  import Play16 from 'carbon-icons-svelte/lib/Play16';

  import BuildParameterDataTable from '../../components/build-parameters/DataTable.svelte';
  import RunBuildModal from '../../components/build-parameters/RunBuildModal.svelte';

  import { getDefaultBuildWithParameters } from '../../services';

  export let organizationId, organizationName;

  let pageData = { loading: true, buildConfig: {}, notifications: new Set() };
  let modals = {
    build: { open: false },
  };

  function updatePageData(modification) {
    const { action, data } = modification;
    let updatedParameters;

    switch (action) {
      case 'add-parameter':
        if (!data) {
          break;
        }

        pageData.notifications.delete('noParameters');

        // eslint-disable-next-line no-case-declarations
        const newParameters = [...(pageData.buildConfig.data ?? []), data];

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            id: data.configId,
            data: newParameters,
          },
        };

        break;

      case 'update-parameter':
        if (!data) {
          break;
        }

        updatedParameters = pageData.buildConfig.data.map((param) => (param.id === data.id ? data : param));

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            data: updatedParameters,
          },
        };

        break;

      case 'remove-parameter':
        if (!data) {
          break;
        }

        updatedParameters = pageData.buildConfig.data.filter((param) => param.id !== data.id);

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            data: updatedParameters,
          },
        };

        break;

      case 'remove-parameters':
        if (!data) {
          break;
        }

        pageData = {
          ...pageData,
          buildConfig: {
            ...pageData.buildConfig,
            data: [],
          },
        };

        break;

      case 'add-parameters':
      case 'clone-config':
        if (!data) {
          break;
        }

        if (data.parameters.length) {
          pageData.notifications.delete('noParameters');
        }

        pageData = { ...pageData, buildConfig: { id: data.id, data: data.parameters } };

        break;

      case 'build-result':
        if (!data) {
          break;
        }

        pageData.notifications.add(data.done ? (typeof data.done === 'boolean' ? 'buildSuccess' : 'buildPartially') : 'buildFailure');

        pageData = { ...pageData };

        break;

      default:
        console.warn(`[OrgParameterList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  async function loadOrganizationParameters() {
    pageData = { ...pageData, loading: true };
    try {
      const buildConfig = await getDefaultBuildWithParameters({
        ownerId: organizationId,
        ownerType: 'organization',
      });

      if (!buildConfig.data.length) {
        pageData.notifications.add('noParameters');
      }

      pageData = { ...pageData, buildConfig, loading: false };
    } catch (error) {
      console.error('[OrganizationParameterList] Failed to load parameters!', error);

      pageData.notifications.add('errorParameters');
      pageData = { ...pageData, loading: false };
    }
  }

  function handleNotificationChange({ action, value }) {
    pageData.notifications[action](value);
    pageData = { ...pageData };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  onMount(loadOrganizationParameters);
</script>

{#if pageData.buildConfig.data?.length}
  <div class="intro">
    <em>
      You are currently viewing build configuration at the <strong>organization</strong> level. Clicking "Run build(s)" will allow you to trigger
      builds for one or more targets within this organization.
    </em>
    <div class="actions">
      <Button size="field" icon={Play16} on:click={() => openModal('build')}>Run build(s)</Button>
    </div>
  </div>
  {#if modals.build.open}
    <RunBuildModal
      open
      configurations={[
        {
          configId: pageData.buildConfig.id,
          isDefault: true,
          entityType: 'organization',
          entityName: organizationName,
          entityId: organizationId,
        },
      ]}
      on:close={({ detail: data }) =>
        closeModal('build', {
          action: 'build-result',
          data,
        })}
    />
  {/if}
{/if}

<BuildParameterDataTable
  entityType="organization"
  entityName={organizationName}
  entityId={organizationId}
  loading={pageData.loading}
  buildConfig={pageData.buildConfig}
  activeNotifications={pageData.notifications}
  on:parameter={({ detail: data }) => updatePageData(data)}
  on:removeParameter={({ detail: data }) => updatePageData(data)}
  on:removeParameters={({ detail: data }) => updatePageData(data)}
  on:clone={({ detail: data }) => updatePageData(data)}
  on:jsonInput={({ detail: data }) => updatePageData(data)}
  on:build={({ detail: data }) => updatePageData(data)}
  on:notification={({ detail: data }) => handleNotificationChange(data)}
/>

<style>
  .intro {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid var(--cds-ui-04);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  .intro :global(em) {
    font-size: 12px;
    color: var(--cds-text-helper);
  }

  :global(html[theme='g90']) .actions :global(.bx--btn.bx--btn--primary:not(.bx--btn:disabled)) {
    background-color: var(--cds-inverse-support-02);
  }
  .actions :global(.bx--btn.bx--btn--primary:not(.bx--btn:disabled)) {
    background-color: #24a148;
    /* use same transition effect from carbon button but animate "filter" instead of "background" */
    transition: filter 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9),
      border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  .actions :global(.bx--btn.bx--btn--primary:hover:not(.bx--btn:disabled)) {
    /* carbon doesn't offer a darker green accent */
    filter: brightness(95%);
  }
</style>
