<script>
  import { Button, Modal, TextArea } from 'carbon-components-svelte';
  import DownloadIcon from 'carbon-icons-svelte/lib/Download16';

  import { downloadLicenseFile } from '../../utils/downloadFile';

  export let license,
    open = false;
</script>

<Modal modalHeading="License Preview" passiveModal preventCloseOnClickOutside={true} bind:open on:close>
  <div class="preview">
    <Button
      icon={DownloadIcon}
      size="field"
      on:click={() => downloadLicenseFile(license.newestGeneratedLicense.filename, license.newestGeneratedLicense.file)}
    >
      Download
    </Button>
    <TextArea light disabled rows={20} labelText="License file" value={license.newestGeneratedLicense.file} />
  </div>
</Modal>

<style>
  .preview {
    display: flex;
    flex-direction: column;
  }

  .preview :global(button) {
    justify-self: flex-end;
    margin-left: auto;
    top: 24px;
  }

  .preview :global(.bx--label),
  .preview :global(textarea) {
    color: unset;
  }
</style>
