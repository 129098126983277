<script>
  import * as yup from 'yup';
  import { createEventDispatcher } from 'svelte';

  import { Form } from '@mst-fe/sveltejs-forms';
  import { FormGroup, Checkbox, TextArea, TextInput } from '@mst-fe/carbon-components-svelte';
  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { createFeature, editFeature } from '../../services';

  export let feature,
    open = false;

  let loading = false,
    resultNotification,
    formSubmitButtonRef;

  const initialValues = feature
    ? {
        name: feature.name,
        isCustom: feature.isCustom,
        longName: feature.longName,
        description: feature.description,
      }
    : { isCustom: false };

  const dispatch = createEventDispatcher();

  async function handleFormSubmission({ detail: { values } }) {
    loading = true;

    try {
      const data = await (feature ? editFeature(feature.id, values) : createFeature(values));

      dispatch('close', data);
    } catch (error) {
      console.error('[AddOrEditFeatureModal] Failed to create/edit license feature!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to create/edit license feature! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function handleChange({ key, setValue }) {
    return function onChange({ target: { value } }) {
      setValue(key, value);
    };
  }

  function handleCustomChange({ key, setValue }) {
    return function onCustomChange({ detail: value }) {
      setValue(key, value);
    };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: schema = yup.object().shape({
    name: yup.string().required('Feature name cannot be empty'),
    longName: yup.string().nullable(),
    description: yup.string().nullable(),
    isCustom: yup.boolean(),
  });
</script>

<Modal
  modalHeading={`${feature ? 'Edit' : 'Create'} Feature`}
  preventCloseOnClickOutside={true}
  primaryButtonText={feature ? 'Save' : 'Create'}
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <Form
      fieldDomString="input,textarea,select,div[name]"
      {initialValues}
      {schema}
      let:submitForm
      let:setValue
      let:touched
      let:values
      let:errors
      on:submit={handleFormSubmission}
    >
      <FormGroup>
        <TextInput
          name="name"
          autocomplete="off"
          invalid={touched.name && !!errors.name}
          invalidText={errors.name}
          labelText="Name"
          placeholder="Feature name"
          value={values.name}
          required
          on:change={handleCustomChange({ key: 'name', setValue })}
        />
        <TextInput
          name="longName"
          autocomplete="off"
          invalid={touched.longName && !!errors.longName}
          invalidText={errors.longName}
          labelText="Long name"
          placeholder="Feature long name"
          value={values.longName}
          on:change={handleCustomChange({ key: 'longName', setValue })}
        />
        <TextArea
          name="description"
          invalid={touched.description && !!errors.description}
          invalidText={errors.description}
          labelText="Description"
          placeholder="Feature description"
          value={values.description}
          on:change={handleChange({ key: 'description', setValue })}
        />
        <Checkbox
          name="isCustom"
          labelText="Custom"
          checked={values.isCustom}
          on:check={handleCustomChange({ key: 'isCustom', setValue })}
        />
        <span class="bx--label">
          A feature marked as "Custom" will not be included under a license. Instead, the system will search for related group marked as
          "Feed Handler", and include its name under the license.
        </span>
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>
