<script>
  import { onDestroy } from 'svelte';

  import { Loading } from 'carbon-components-svelte';

  export let loading = true;
  export let spinnerDelay = 150;
  export let withOverlay = true;
  export let small = false;
  export let compact = false;
  export let centered = true;
  export let description = 'Active loading indicator';
  export let inlineLeftMargin = false;

  let showOverlay = false;
  let timeout = null;

  onDestroy(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
  });

  $: if (loading && !timeout) {
    timeout = setTimeout(() => {
      showOverlay = true;
    }, spinnerDelay);
  }

  $: if (!loading) {
    showOverlay = false;

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }
</script>

<div class="loading-container">
  <slot default />
  {#if showOverlay}
    <div class:bx--loading-overlay={withOverlay} />
    <div class="direct-spinner-parent" class:spinner-wrapper={centered} class:inline-left-margin={inlineLeftMargin}>
      <slot name="description" />
      <Loading withOverlay={false} class={compact ? 'compact' : small ? 'small' : ''} {description} />
    </div>
  {/if}
</div>

<style>
  .loading-container {
    height: 100%;
    position: relative;
  }

  .loading-container :global(.bx--loading-overlay) {
    position: absolute;
    top: 0;
  }

  .direct-spinner-parent > :global(.bx--loading) {
    height: 4rem;
    width: 4rem;
    margin: 0 auto;
  }

  .direct-spinner-parent > :global(.bx--loading.small) {
    height: 2rem;
    width: 2rem;
  }

  .direct-spinner-parent > :global(.bx--loading.compact) {
    height: 1rem;
    width: 1rem;
  }

  .direct-spinner-parent > :global(.bx--loading:not(:first-child)) {
    margin-top: 10px;
  }

  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
  }

  .inline-left-margin {
    margin-left: 0.3rem;
  }
</style>
