<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { format, addMonths, addYears } from 'date-fns';
  import sanitizeString from 'sanitize-filename';
  import { Checkbox, DatePicker, DatePickerInput, TextInput, Tooltip } from '@mst-fe/carbon-components-svelte';

  import LicenseInfoAccordion from './LicenseInfoAccordion.svelte';
  import WizardStep from '../wizard/WizardStep.svelte';

  import {
    DISPLAY_ONLY_LICENSE_EXPIRATION_DATE_FORMAT,
    LICENSE_DATE_FORMAT,
    LICENSE_PERMANENT_MUST_HAVE_FIELDS,
  } from '../../../shared/constants';

  export let licenseTemplate,
    features = [],
    generatedLicenses = [],
    isRenewal,
    groupName,
    groupShortName;

  let licenseGenerationInfo = { date: '', filename: '', skipUpload: false, isPermanent: false },
    filenameTouched = false;

  const dispatch = createEventDispatcher();

  function submitStepChanges(stepIndex) {
    dispatch('step-complete', { stepIndex, values: licenseGenerationInfo });
  }

  function handleCustomChange(field) {
    const VALUE_KEYS = { date: 'dateStr' };
    const valueKey = VALUE_KEYS[field];

    return function onChange({ detail }) {
      if (field === 'date' && !filenameTouched && detail.selectedDates?.[0]) {
        const dateObj = detail.selectedDates[0];
        const formatedDate = format(dateObj, LICENSE_DATE_FORMAT);
        const normalizedName = licenseTemplate.name.split(' ').join('-');
        const normalizedGroupName = sanitizeString((groupShortName || groupName).toLocaleLowerCase()).replace(/[\s)(]/g, (match) =>
          match === ' ' ? '_' : ''
        );
        const generatedWithSameDate = generatedLicenses.filter((license) => license.expirationDate === formatedDate);
        const fileVersion = generatedWithSameDate.length ? `.v${generatedWithSameDate.length + 1}` : '';

        licenseGenerationInfo = {
          ...licenseGenerationInfo,
          date: dateObj,
          filename: `${normalizedGroupName}.${normalizedName}.${formatedDate}${fileVersion}`,
        };

        return;
      }

      if (field === 'isPermanent' && !filenameTouched) {
        const normalizedName = licenseTemplate.name.split(' ').join('-');
        const normalizedGroupName = sanitizeString((groupShortName || groupName).toLocaleLowerCase()).replace(/[\s)(]/g, (match) =>
          match === ' ' ? '_' : ''
        );
        const generatedWithSameDate = generatedLicenses.filter((license) => license.isPermanent);
        const fileVersion = generatedWithSameDate.length ? `.v${generatedWithSameDate.length + 1}` : '';

        licenseGenerationInfo = {
          ...licenseGenerationInfo,
          isPermanent: detail,
          filename: detail ? `${normalizedGroupName}.${normalizedName}.permanent${fileVersion}` : '',
        };

        return;
      }

      filenameTouched = filenameTouched || field === 'filename';

      licenseGenerationInfo = {
        ...licenseGenerationInfo,
        [field]: valueKey ? detail[valueKey] : detail,
      };
    };
  }

  function handlePrefill() {
    const { cycle } = licenseTemplate;
    const { expirationDate, isPermanent } = licenseTemplate.newestGeneratedLicense ?? {};

    if (!isRenewal || !cycle || cycle === 'once' || isPermanent) {
      return;
    }

    const currentDate = format(new Date(), LICENSE_DATE_FORMAT);
    const dateToHandle = currentDate > expirationDate ? currentDate : expirationDate;

    const renewalDate = format(
      cycle === 'yearly' ? addYears(new Date(dateToHandle), 1) : addMonths(new Date(dateToHandle), cycle === 'monthly' ? 1 : 3),
      DISPLAY_ONLY_LICENSE_EXPIRATION_DATE_FORMAT
    );

    handleCustomChange('date')({ detail: { dateStr: renewalDate, selectedDates: [new Date(renewalDate)] } });
  }

  onMount(handlePrefill);

  $: isNodeLocked = LICENSE_PERMANENT_MUST_HAVE_FIELDS.every((part) => licenseTemplate.hosts.includes(part));
</script>

<WizardStep
  onBeforeForward={submitStepChanges}
  nextButtonActive={(licenseGenerationInfo.date || licenseGenerationInfo.isPermanent) && licenseGenerationInfo.filename}
>
  <div class="info-step">
    <div class="license-info">
      <h4>Generation Info</h4>
      <div class="form">
        <div class="date">
          <DatePicker
            value={licenseGenerationInfo.date}
            flatpickrProps={{ static: true }}
            datePickerType="single"
            dateFormat="m-d-Y"
            minDate={new Date()}
            on:change={handleCustomChange('date')}
          >
            <DatePickerInput required labelText="Expiration date" placeholder="mm-dd-yyyy" disabled={licenseGenerationInfo.isPermanent} />
          </DatePicker>
          <Checkbox
            name="isPermanent"
            disabled={!isNodeLocked || licenseTemplate.cycle !== 'once'}
            labelText="Permanent license"
            checked={licenseGenerationInfo.isPermanent}
            on:check={handleCustomChange('isPermanent')}
          />
          {#if !isNodeLocked || licenseTemplate.cycle !== 'once'}
            <div class="tooltip">
              <Tooltip>
                <p>
                  Permanent license can only be generated when multiple hosts exists (node-locked) and "One-time" license cycle is selected.
                </p>
              </Tooltip>
            </div>
          {/if}
        </div>
        <div class="filename">
          <TextInput
            value={licenseGenerationInfo.filename}
            required
            labelText="License filename"
            placeholder="Enter license name..."
            on:change={handleCustomChange('filename')}
          />
          <span>.lic</span>
        </div>
        <Checkbox
          name="skipUpload"
          labelText="Skip license file upload for now"
          checked={licenseGenerationInfo.skipUpload}
          on:check={handleCustomChange('skipUpload')}
        />
      </div>
    </div>
    <div class="license-preview">
      <h4 class="h4">License Details</h4>
      <LicenseInfoAccordion license={licenseTemplate} {features} />
    </div>
  </div>
</WizardStep>

<style>
  .h4 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .info-step {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .license-info {
    margin-top: 1rem;
    min-height: 250px;
  }

  .license-preview {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .form :global(.bx--date-picker__input) {
    width: 20rem;
  }

  .form :global(.bx--form-item) {
    margin-top: 1rem;
    max-width: 20rem;
  }

  .date {
    display: flex;
    align-items: flex-end;
  }

  .date :global(.bx--checkbox-wrapper) {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    max-width: 10rem;
  }

  .date .tooltip {
    margin-bottom: 0.4rem;
    margin-left: -0.75rem;
  }

  .filename {
    display: flex;
    align-items: flex-end;
  }

  .filename :global(span) {
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
    color: var(--cds-text-02);
    user-select: none;
  }
</style>
