<script>
  import { getContext } from 'svelte';
  import { ProgressIndicator } from 'carbon-components-svelte';

  import WizardProgressStep from './WizardProgressStep.svelte';

  export let currentIndex, steps;

  const { content, setStep } = getContext('wizard');

  const handleProgressBarClick = (stepToGo) => {
    return () => {
      setStep(stepToGo);
    };
  };
</script>

<div class="progress-indicator">
  <ProgressIndicator spaceEqually bind:currentIndex>
    {#each steps as { label, description }, index}
      <WizardProgressStep
        {label}
        {description}
        current={index === currentIndex}
        complete={currentIndex > index}
        upcoming={currentIndex < index}
        content={$content[index]}
        on:click={handleProgressBarClick(index)}
        clickable={(index > currentIndex && $content[index - 1]?.nextButtonActive) ||
          (index < currentIndex && $content[index + 1]?.previousButtonActive)}
      />
    {/each}
  </ProgressIndicator>
</div>
