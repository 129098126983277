<script>
  import { createEventDispatcher } from 'svelte';

  import { Checkbox, InlineNotification, Modal, TextInput } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { deleteGroup } from '../../services';
  import { getServerErrorMessage } from '../../utils';

  export let groupName,
    groupId,
    open = false;

  let loading = false,
    confirm = false,
    resultNotification = null,
    groupNameToDelete = '';

  const dispatch = createEventDispatcher();

  async function onSubmit() {
    loading = true;
    resultNotification = null;

    try {
      await deleteGroup(groupId);
      dispatch('close', { id: groupId, name: groupName });
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Please try again later.';
      console.error('[DeleteGroupModal] Failed to delete group!', errorMessage);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to delete group! ${errorMessage}`,
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Delete Group"
  primaryButtonText="Delete"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={!confirm || groupName !== groupNameToDelete}
  preventCloseOnClickOutside={true}
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        hideCloseButton
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p class="locked">You are about to delete the group <strong>{groupName}</strong>.</p>
    <strong>All related entities (SSH keys, build targets, licenses etc.) will be deleted as well.</strong>
    <p class="confirm">Are you sure?</p>
    <Checkbox
      labelText="I understand and I would like to proceed with the group deletion"
      bind:checked={confirm}
      disabled={groupName === groupNameToDelete}
    />
    {#if confirm}
      <TextInput size="sm" placeholder="Enter the name of the group to proceed with deletion" bind:value={groupNameToDelete} />
    {/if}
  </LoadingSpinner>
</Modal>

<style>
  .locked {
    user-select: none;
  }

  .confirm {
    padding: 1rem 0;
  }
</style>
