<script>
  import { setContext } from 'svelte';
  import { writable } from 'svelte/store';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import WizardProgressIndicator from './WizardProgressBar/WizardProgressIndicator.svelte';

  export let confirmationBeforeSubmit = true,
    confirmationMessage = 'I confirm the selections are correct',
    initialStep = 0,
    finalStepButtonLabel = 'Finish',
    loading = false,
    onFinish = () => {},
    steps = [];

  const content = writable([]);
  const currentStep = writable(initialStep);

  setContext('wizard', {
    content,
    confirmationBeforeSubmit,
    confirmationMessage,
    currentStep,
    finalStepButtonLabel,
    steps,

    addContent: (data) => {
      let index;

      content.update((wizardContent) => {
        index = wizardContent.length;

        return [...wizardContent, { ...data }];
      });

      return index;
    },
    getContentUpdater: (updateIndex) => {
      return function updateContent(updater) {
        content.update((wizardContent) => wizardContent.map((item, itemIndex) => (updateIndex !== itemIndex ? item : updater(item))));
      };
    },
    goBack: () => {
      currentStep.update((step) => (step ? step - 1 : step));
    },
    goForward: () => {
      currentStep.update((step) => (step < steps.length - 1 ? step + 1 : step));
    },
    onFinish,
    setStep: (stepToGo) => {
      currentStep.set(stepToGo);
    },
  });
</script>

<div class="wizard">
  <WizardProgressIndicator bind:currentIndex={$currentStep} {steps} />
  <LoadingSpinner {loading}>
    <slot />
  </LoadingSpinner>
</div>
