<script>
  import { onMount } from 'svelte';

  import {
    Column,
    Grid,
    InlineNotification,
    Row,
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
  } from 'carbon-components-svelte';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { clearAuthorizationCache, getCacheStats } from '../../services';
  import { appConfig, messagesStore } from '../../stores';

  let pageData = { loading: true };
  let submissionData = { loading: false };

  onMount(retrieveCacheStats);

  async function clearUserAuthorizationCache() {
    submissionData = { loading: true };
    try {
      await clearAuthorizationCache();
      submissionData = { loading: false };
      await retrieveCacheStats();
    } catch (error) {
      console.error('[AdminDashboard] Failed to clear authorization cache!', error);
      submissionData = { ...submissionData, error, errorMessage: 'Failed to clear authorization cache!', loading: false };
    }
  }

  async function retrieveCacheStats() {
    try {
      const cacheStats = await getCacheStats();
      pageData = { cacheStats, loading: false };
    } catch (error) {
      console.error('[AdminDashboard] Failed to load stats!', error);
      pageData = { loading: false, error };
    }
  }

  $: activeUserCount = Object.values($messagesStore?.usersActivity ?? {}).filter((user) => user.status === 'ONLINE').length;
  $: idleUserCount = Object.keys($messagesStore?.usersActivity ?? {}).length - activeUserCount;
</script>

{#if pageData.error}
  <InlineNotification hideCloseButton kind="error" lowContrast title="Error:" subtitle="Failed to load stats!" />
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <Grid noGutterLeft noGutterRight padding>
      <Row>
        <Column sm={4} md={8} lg={8}>
          <h2 class="column-heading">Quick Stats</h2>
          <StructuredList condensed flush>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Key</StructuredListCell>
                <StructuredListCell head>Value</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              <StructuredListRow>
                <StructuredListCell noWrap>Application Version</StructuredListCell>
                <StructuredListCell>{$appConfig.data.version}</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Active Users</StructuredListCell>
                <StructuredListCell>{activeUserCount}</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Idle Users</StructuredListCell>
                <StructuredListCell>{idleUserCount}</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Cached Data Feeds</StructuredListCell>
                <StructuredListCell>{pageData?.cacheStats?.dataFeeds}</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Cached Tasks</StructuredListCell>
                <StructuredListCell>{pageData?.cacheStats?.tasks}</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Cached Users</StructuredListCell>
                <StructuredListCell>{pageData?.cacheStats?.users}</StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </StructuredList>
        </Column>
        <Column sm={4} md={8} lg={8}>
          {#if submissionData.error}
            <InlineNotification hideCloseButton kind="error" lowContrast title="Error:" subtitle={submissionData.errorMessage} />
          {/if}
          <h2 class="column-heading column-heading-form">Actions</h2>
          <p class="text-info">
            The server maintains a cache of user subject IDs authorized to access the application, as well as user display names for general
            use. Although cache entries expire in 5 minutes, it can sometimes be desirable to clear this, for example when manually adding
            authorization records to the database.
          </p>
          <LoadingButton on:click={clearUserAuthorizationCache} disabled={submissionData.loading} isLoading={submissionData.loading}>
            Clear user cache
          </LoadingButton>
        </Column>
      </Row>
    </Grid>
  </LoadingSpinner>
{/if}

<style>
  /* For accessibility, keep the column-heading elements as h2 tags, but style them similar to h4 */
  .column-heading {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .text-info {
    color: var(--cds-text-02);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
</style>
