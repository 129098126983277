<script>
  import { TabContent } from 'carbon-components-svelte';
  import { useLocation } from 'svelte-navigator';

  export let hash = '#';

  const location = useLocation();
  $: currentHash = $location?.hash || window.location.hash;
</script>

<TabContent>
  {#if currentHash === hash}
    <slot />
  {/if}
</TabContent>
