<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../../../components/LoadingSpinner.svelte';

  import { deleteApiKey } from '../../../services';

  export let apiKeyIdToRemove;

  const dispatch = createEventDispatcher();

  let loading = false,
    resultNotification = null;

  async function onModalSubmit() {
    loading = true;
    try {
      await deleteApiKey(apiKeyIdToRemove);
      apiKeyIdToRemove = null;
      dispatch('keyDeleted');
    } catch (error) {
      console.error(`[ApiKeyRemoveConfirmationModal] Failed to delete API key! Server says:`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to delete API key!`,
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  modalHeading="Confirm action"
  preventCloseOnClickOutside
  primaryButtonText="Delete"
  secondaryButtonText="Cancel"
  size="xs"
  shouldSubmitOnEnter={false}
  bind:open={apiKeyIdToRemove}
  on:click:button--secondary={() => {
    apiKeyIdToRemove = null;
  }}
  on:close={() => {
    apiKeyIdToRemove = null;
  }}
  on:submit={onModalSubmit}
>
  {#if resultNotification}
    <InlineNotification
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
      hideCloseButton
    />
  {/if}

  <LoadingSpinner {loading}><p>Are you sure you want to delete this API key?</p></LoadingSpinner>
</Modal>
