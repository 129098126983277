import { getFirstDayOfTheMonth, toLocalHyphenatedDateString } from '../../../../../shared/convertDates';
import { getPickedMissingDates } from '../../../../../shared/getPickedMissingDates';

const DATE_MUST_BE_BEETWEEN = 'Date exceeds availability range!';
const DATE_MUST_BE_CORRECT = 'Date must have format yyyy-mm!';
const DEMO_DATE_MUST_BE_CORRECT = 'Demo access date have format yyyy-mm-dd!';
const EXPIRES_AT_DATE = 'Expiration date must be in the future and have format yyyy-mm-dd!';
const START_DATE_MUST_BE_EARLIER = 'Start date must be before end date!';
const END_DATE_MUST_BE_PAST = 'End date must be before start date!';
const MAX_DEMO_DURATION = 'Demo duration cannot exceed 14 days!';

const getMissingDatesError = (rangeMissingChunks, chunkType) => {
  const missingDateDisplayList =
    rangeMissingChunks.length <= 5 ? rangeMissingChunks.join(', ') : `${rangeMissingChunks.slice(0, 5).join(', ')}, and more`;

  const plural = rangeMissingChunks.length > 1;
  let typeDescription;
  if (chunkType === 'days') {
    typeDescription = `day${plural ? 's' : ''}`;
  }
  if (chunkType === 'months') {
    typeDescription = `month${plural ? 's' : ''}`;
  }
  return `
    There ${plural ? 'are' : 'is'} ${rangeMissingChunks.length} missing ${typeDescription} in the selected range
    (${missingDateDisplayList}).
  `;
};

export const getAvailabilityDates = (
  { availabilityStartDate, availabilityEndDate, availabilityEndMonth, missingDays, missingMonths },
  isDemo
) => {
  return {
    availableStartDate: new Date(isDemo ? availabilityStartDate : getFirstDayOfTheMonth(availabilityStartDate)),
    availableEndDate: new Date(isDemo ? availabilityEndDate : getFirstDayOfTheMonth(availabilityEndMonth)),
    missingDays,
    missingMonths,
  };
};

export const validateDateInput = ({
  value,
  availableStartDate,
  availableEndDate,
  isDemo,
  key,
  oppositeDate,
  missingDays,
  missingMonths,
}) => {
  const isStartDate = key === 'startDate';
  const [startDate, endDate] = isStartDate ? [value, oppositeDate] : [oppositeDate, value];
  if (oppositeDate && startDate > endDate) {
    return isStartDate ? START_DATE_MUST_BE_EARLIER : END_DATE_MUST_BE_PAST;
  }

  const { chunks: pickedRangeMissingDates, chunkType } = getPickedMissingDates({ isDemo, missingMonths, missingDays, startDate, endDate });
  if (isStartDate && pickedRangeMissingDates.length) {
    return getMissingDatesError(pickedRangeMissingDates, chunkType);
  }

  const pickedDate = isDemo ? new Date(value) : new Date(getFirstDayOfTheMonth(value));
  if (pickedDate.toString() === 'Invalid Date' || value?.length !== (isDemo ? 10 : 7)) {
    return isDemo ? DEMO_DATE_MUST_BE_CORRECT : DATE_MUST_BE_CORRECT;
  }

  const numberOfDaysOfPermission = Math.ceil(Math.abs(pickedDate - new Date(oppositeDate)) / (1000 * 60 * 60 * 24));
  if (isDemo && numberOfDaysOfPermission > 14) {
    return MAX_DEMO_DURATION;
  }

  const isValid = pickedDate >= availableStartDate && pickedDate <= availableEndDate;
  if (!isValid) {
    return DATE_MUST_BE_BEETWEEN;
  }
};

export const validateDemoDate = (value) => {
  const pickedDate = new Date(value);
  const dateIsIncorrect =
    pickedDate.toString() === 'Invalid Date' || value < toLocalHyphenatedDateString(new Date()) || value.length !== 10;
  return dateIsIncorrect ? EXPIRES_AT_DATE : null;
};
