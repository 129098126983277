<script>
  import { format } from 'date-fns';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { Checkbox, MultiSelect, Select, SelectItem } from 'carbon-components-svelte';

  import EmailTemplateForm from '../emails/EmailTemplateForm.svelte';
  import WizardStep from '../wizard/WizardStep.svelte';

  import { getGroupUsers, parseEmailTemplates } from '../../services';
  import { LICENSES_INTERNAL_EMAIL_ADDRESS } from '../../../shared/constants';

  export let licenseTemplate, generationInfo, loading;

  let emailTemplates = [],
    groupUsers = [],
    availableEmails = [],
    selectedEmailTemplate = null,
    emailFormReady = false,
    toEmails = [],
    shouldSkipNotification = false,
    emailData = null;

  const dispatch = createEventDispatcher();

  async function fetchDependencies() {
    loading = true;

    try {
      groupUsers = await getGroupUsers(licenseTemplate.ownerId);

      [availableEmails, toEmails] = groupUsers.reduce(
        (all, user) => {
          const { id, name, email: text } = user;

          all[0].push({ id, name, text });

          if (user.licenseUpdates) {
            all[1].push(id);
          }

          return all;
        },
        [
          [{ id: LICENSES_INTERNAL_EMAIL_ADDRESS, name: LICENSES_INTERNAL_EMAIL_ADDRESS, text: LICENSES_INTERNAL_EMAIL_ADDRESS }],
          [LICENSES_INTERNAL_EMAIL_ADDRESS],
        ]
      );
    } catch (error) {
      console.error('[LicenseGenerationNotifyStep] Failed to load dependencies..', error);
    } finally {
      loading = false;
    }
  }

  function submitStepChanges(stepIndex) {
    dispatch('step-complete', {
      stepIndex,
      values: {
        ...emailData,
        shouldSkipNotification,
        toEmails: availableEmails.reduce((all, { id, text: email }) => {
          if (!toEmails.includes(id)) {
            return all;
          }

          return [...all, { id, email }];
        }, []),
      },
    });
  }

  async function handleEmailTemplateSelection({ detail: selectionId }) {
    if (selectionId === selectedEmailTemplate?.id) {
      return;
    }

    selectedEmailTemplate = null;
    await tick();
    selectedEmailTemplate = emailTemplates.find(({ id }) => id === selectionId);
  }

  function handleEmailFormChange({ detail }) {
    const { field, value } = detail;
    emailData = {
      ...emailData,
      [field]: value,
    };
  }

  async function parseTemplates() {
    loading = true;

    try {
      emailTemplates = await parseEmailTemplates('licenses', licenseTemplate.id, {
        replacements: {
          expiration_date: generationInfo.isPermanent ? 'Permanent' : format(new Date(generationInfo.date), 'MM-dd-yyyy'),
          expiration_date_ext: generationInfo.isPermanent ? 'Permanent' : format(new Date(generationInfo.date), 'dd-MMM-yyyy'),
        },
      });

      selectedEmailTemplate = null;
      await tick();
      selectedEmailTemplate = emailTemplates.find((emailTemplate) => emailTemplate.isDefault) ?? emailTemplates[0];
    } catch (error) {
      console.error('[LicenseGenerationNotifyStep] Failed to load email templates..', error);
    } finally {
      loading = false;
    }
  }

  onMount(fetchDependencies);

  $: emailData = selectedEmailTemplate
    ? {
        subject: selectedEmailTemplate.subject,
        body: selectedEmailTemplate.body,
      }
    : null;
  $: isStepReady = (emailData?.subject && emailData?.body && toEmails.length) || shouldSkipNotification;
  $: if (generationInfo) {
    parseTemplates();
  }
</script>

<svelte:head>
  <link
    href="../styles/quill.css"
    rel="stylesheet"
    on:load={() => {
      emailFormReady = true;
    }}
  />
</svelte:head>

<WizardStep onBeforeForward={submitStepChanges} nextButtonActive={isStepReady}>
  <div class="selector" class:disabled={shouldSkipNotification}>
    {#if emailTemplates.length && selectedEmailTemplate}
      <Select labelText="Email template" selected={selectedEmailTemplate?.id} on:change={handleEmailTemplateSelection}>
        {#each emailTemplates as template}
          <SelectItem value={template.id} text={template.name} />
        {/each}
      </Select>
    {/if}
  </div>
  <div class="email-form" class:disabled={shouldSkipNotification}>
    <h4 class="h4">Email</h4>
    <div class="multi-select">
      <MultiSelect disabled={!availableEmails.length} titleText="To" items={availableEmails} bind:selectedIds={toEmails} let:item>
        <div>
          <strong>{item.text}</strong>
        </div>
        {#if item.text !== item.name}
          <div>{item.name}</div>
        {/if}
      </MultiSelect>
      {#if !availableEmails.length}
        <span class="bx--label">No connected users to group. You can only proceed without sending any emails.</span>
      {/if}
    </div>
    {#if emailData}
      <EmailTemplateForm subject={emailData.subject} body={emailData.body} on:change={handleEmailFormChange} {emailFormReady} />
    {/if}
  </div>
  <em class="info">
    {#if shouldSkipNotification}
      <span>The above email will not be sent until someone manualy triggers it</span>
    {:else}
      <span>The email will not be sent until the last step of the process</span>
    {/if}
  </em>
  <Checkbox labelText="Skip email notification for now" bind:checked={shouldSkipNotification} />
</WizardStep>

<style>
  .h4 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .selector {
    margin-bottom: 2rem;
  }

  .email-form {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    background-color: var(--cds-background);
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }

  .disabled > :global(*) {
    pointer-events: none;
  }

  .info {
    color: var(--cds-text-02);
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .multi-select :global(.bx--list-box__menu-item__option),
  .multi-select :global(.bx--list-box__menu-item) {
    height: auto;
  }

  .multi-select :global(.bx--checkbox-label-text) {
    display: block;
  }
</style>
