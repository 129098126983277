<script>
  import { onMount } from 'svelte';

  import { InlineNotification } from 'carbon-components-svelte';

  import AuditTable from '../../components/audit-table/AuditTable.svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
  import UserBasicInformation from './UserBasicInformation.svelte';
  import UserKeyList from './UserKeyList.svelte';
  import { getOrganizations, getUser } from '../../services';
  import { cacheEntityName } from '../../stores';

  export let userId;
  let pageData = { loading: true };

  const loadUserData = async () => {
    pageData = { ...pageData, loading: true };
    try {
      const [userProfile, organizations] = await Promise.all([
        userId ? getUser(userId) : Promise.reject(new Error('No user ID provided in URL!')),
        getOrganizations(),
      ]);
      pageData = { userProfile, organizations, loading: false };
      cacheEntityName(userId, userProfile.name);
    } catch (error) {
      console.error('[UserDetails] Failed to retrieve user profile and organization data!', error);
      pageData = { loading: false, error };
    }
  };

  onMount(loadUserData);
</script>

{#if pageData.loading}
  <LoadingSpinner withOverlay={false} />
{:else if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load user data! Please try again later."
  />
{/if}

{#if !pageData.loading || pageData.userProfile}
  <TabsWithRouting
    defaultHash={'#basic-information'}
    tabsWrapperProps={{ 'aria-label': 'User details tabs' }}
    tabs={[
      { label: 'Basic Information', hash: '#basic-information' },
      { label: 'SSH Keys', hash: '#ssh-keys' },
      { label: 'History', hash: '#history' },
    ]}
  >
    <LazyLoadedTabContent hash="#basic-information">
      <UserBasicInformation
        organizations={pageData.organizations}
        userProfile={pageData.userProfile}
        on:userUpdated={loadUserData}
        {userId}
      />
    </LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#ssh-keys"><UserKeyList userFullName={pageData.userProfile?.name} {userId} /></LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#history"><AuditTable entityId={userId} entityType="user" /></LazyLoadedTabContent>
  </TabsWithRouting>
{/if}
