<script>
  import FeatureAndGroupList from './FeatureAndGroupList.svelte';
  import GeneratedLicensesList from './GeneratedLicensesList.svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';
  import LicenseTemplatesList from './LicenseTemplatesList.svelte';
  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
</script>

<div class="system-tabs">
  <TabsWithRouting
    defaultHash="#licenses"
    tabsWrapperProps={{ 'aria-label': 'Licenses tabs' }}
    tabs={[
      { label: 'Generated Licenses', hash: '#licenses' },
      { label: 'License Templates', hash: '#templates' },
      { label: 'Features & Groups', hash: '#features-and-groups' },
    ]}
  >
    <LazyLoadedTabContent hash="#licenses"><GeneratedLicensesList /></LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#templates"><LicenseTemplatesList /></LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#features-and-groups"><FeatureAndGroupList /></LazyLoadedTabContent>
  </TabsWithRouting>
</div>

<style>
  .system-tabs :global(.bx--tabs__nav-link) {
    width: 11rem;
  }
</style>
