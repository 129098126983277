<script>
  import AddLicenseModal from './AddLicenseModal.svelte';
  import AddOrEditLicenseNoteModal from './AddOrEditLicenseNoteModal.svelte';
  import CopyLicenseModal from './CopyLicenseModal.svelte';
  import GenerateLicenseModal from './GenerateLicenseModal.svelte';
  import DeleteLicenseTemplateModal from './DeleteLicenseTemplateModal.svelte';
  import EditLicenseBasicInfoModal from './EditLicenseBasicInfoModal.svelte';
  import EditLicenseFeatureModal from './EditLicenseFeatureModal.svelte';
  import ViewLicenseModal from './ViewLicenseModal.svelte';

  export let closeModal, defaultDestinations, group, modals, licenses;

  const { groupId, groupName, licenseLookbackDays, shortName, sftp05Username } = group;

  function handleModalClose(modalToClose, action) {
    return function onClose({ detail: data }) {
      closeModal(modalToClose, action ? { action, data } : null);
    };
  }
</script>

{#if modals.copyLicense.open}
  <CopyLicenseModal open {licenses} on:close={handleModalClose('copyLicense', 'copy-license')} />
{/if}
{#if modals.createLicense.open}
  <AddLicenseModal
    open
    licenseDetails={modals.createLicense.licenseDetails ?? []}
    {defaultDestinations}
    entityId={groupId}
    entityName={groupName}
    on:close={handleModalClose('createLicense', 'create-license')}
  />
{/if}
{#if modals.addOrEditNote.open}
  <AddOrEditLicenseNoteModal
    open
    isForTemplate
    license={modals.addOrEditNote.license}
    on:close={handleModalClose('addOrEditNote', 'edit-license-note')}
  />
{/if}
{#if modals.generateLicense.open}
  <GenerateLicenseModal
    open
    {groupId}
    {groupName}
    {sftp05Username}
    groupShortName={shortName}
    {licenseLookbackDays}
    license={modals.generateLicense.license}
    on:close={handleModalClose('generateLicense', 'generate-license')}
  />
{/if}
{#if modals.editBasicLicenseInfo.open}
  <div class="license-template-modal">
    <EditLicenseBasicInfoModal
      open
      license={modals.editBasicLicenseInfo.license}
      on:close={handleModalClose('editBasicLicenseInfo', 'edit-license-basic-info')}
    />
  </div>
{/if}
{#if modals.editFeatures.open}
  <EditLicenseFeatureModal open license={modals.editFeatures.license} on:close={handleModalClose('editFeatures', 'edit-license-feature')} />
{/if}
{#if modals.deleteTemplate.open}
  <DeleteLicenseTemplateModal
    open
    license={modals.deleteTemplate.license}
    on:close={handleModalClose('deleteTemplate', 'delete-template')}
  />
{/if}
{#if modals.viewLicense.open}
  <ViewLicenseModal open license={modals.viewLicense.license} on:close={handleModalClose('viewLicense')} />
{/if}
