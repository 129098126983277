<script>
  import { Modal } from 'carbon-components-svelte';

  import { convertToLocalDisplayTime } from '../../utils';

  export let target, open;

  const readOnlyFields = {
    createdAt: {
      label: 'Created',
      formatter: convertToLocalDisplayTime,
    },
    updatedAt: {
      label: 'Updated',
      formatter: convertToLocalDisplayTime,
    },
  };
</script>

<Modal hasScrollingContent modalHeading="Target Details" passiveModal size="sm" bind:open on:close>
  <div class="readonly-fields">
    {#each Object.entries(target) as [key, value]}
      {#if readOnlyFields[key]}
        <div class="field">
          <span>{readOnlyFields[key].label}</span>
          <h4>{readOnlyFields[key].formatter?.(value) ?? value}</h4>
        </div>
      {/if}
    {/each}
  </div>
</Modal>

<style>
  .readonly-fields {
    display: flex;
    flex-direction: column;
  }

  .field {
    padding-bottom: 1rem;
  }
</style>
