<script>
  import isEqual from 'lodash/isEqual';
  import sortBy from 'lodash/sortBy';

  import { Accordion, AccordionItem } from '@mst-fe/carbon-components-svelte';
  import WarningIcon from 'carbon-icons-svelte/lib/WarningAltFilled16';

  import ArrayDiff from './ArrayDiff.svelte';
  import LicenseBasicInfoPreview from './LicenseBasicInfoPreview.svelte';
  import SelectedFeatureList from './SelectedFeatureList.svelte';

  export let license, features;

  $: basicLicenseInfo = {
    name: license.name,
    cycle: license.cycle,
    diff: license.diff,
    hosts: license.hosts,
    destinations: license.uploadDestinations.map(({ destination, path }) => `${destination}:${path}`),
  };
  $: selectedFeatures = [...license.explicitlySelectedFeatures.map(({ id }) => id), ...license.flatSelectedGroups.map(({ id }) => id)];
  $: changedSinceLastGeneration =
    license.newestGeneratedLicense?.features && license.fullFeatureList
      ? !isEqual(sortBy(license.fullFeatureList, ['id']), sortBy(license.newestGeneratedLicense.features, ['id']))
      : false;
  $: generatedFeatures = license.newestGeneratedLicense?.features.map(({ name }) => name).sort() ?? [];
  $: newFeatures = license.fullFeatureList?.map(({ name }) => name).sort() ?? [];
</script>

<Accordion>
  <AccordionItem title="Basic information">
    <LicenseBasicInfoPreview info={basicLicenseInfo} hideLabel />
  </AccordionItem>
  <AccordionItem>
    <div class="title" slot="title">
      Selected features
      {#if changedSinceLastGeneration}
        <WarningIcon />
      {/if}
    </div>
    {#if changedSinceLastGeneration}
      <div class="header">
        <h4 class="heading">Generated license</h4>
        <h4 class="heading">New License</h4>
      </div>
      <ArrayDiff originalArray={generatedFeatures} modifiedArray={newFeatures} />
    {:else}
      <SelectedFeatureList treeData={features} checkedItemIds={selectedFeatures} />
    {/if}
  </AccordionItem>
</Accordion>

<style>
  .title {
    display: flex;
    align-items: center;
  }

  .title :global(svg) {
    margin-left: 0.5rem;
    fill: var(--outline-warning-color);
  }

  .header {
    display: flex;
  }

  .heading {
    font-size: 1rem;
    font-weight: bold;
    flex: 1;
  }
</style>
