import { get } from 'svelte/store';

import { CognitoUser } from 'amazon-cognito-identity-js/dist/amazon-cognito-identity';

import { cognitoUserStore, userSession } from '../stores';

export function completeNewPasswordChallenge(newPassword) {
  return new Promise((resolve, reject) => {
    const cognitoUser = get(cognitoUserStore);
    if (!cognitoUser) {
      const error = new Error('No Cognito user present in store!');
      console.error('No Cognito user provided!', error);
      reject(error);
    }

    cognitoUserStore.set(undefined);
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          // We can technically just log the user in now (first parameter to this callback is a set of tokens), but as a
          // best practice they should log in first.
          cognitoUser.signOut();
          resolve();
        },
      }
    );
  });
}

export function setNewPassword(username, verificationCode, newPassword) {
  const { userPool, storage } = get(userSession);

  const cognitoUser = new CognitoUser({
    Pool: userPool,
    Storage: storage,
    Username: username,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure(err) {
        reject(err);
      },
      onSuccess() {
        resolve();
      },
    });
  });
}
