<script>
  import { onMount } from 'svelte';

  import { Button, DataTable, InlineNotification, NotificationActionButton, Toolbar, ToolbarContent, Link } from 'carbon-components-svelte';
  import identity from 'lodash/identity';
  import { useNavigate } from 'svelte-navigator';

  import AddDataFeedModal from './AddDataFeedModal.svelte';
  import DataFeedSubscribersModal from './DataFeedSubscribersModal.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import StatusIcon from '../../components/StatusIcon.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  import { getDataFeedInventorySummary, refreshAndGetDataFeedInventorySummary } from '../../services';
  import { asBooleanStatusColumn, rowContainsText } from '../../utils';

  const navigate = useNavigate();
  const tableHeaders = [
    { key: 'name', value: 'Feed Name' },
    { key: 'minAvailable', value: 'Earliest Start Date' },
    { key: 'maxAvailable', value: 'Latest End Date' },
    { key: 'awsLakeAvailable', value: 'AWS Lake Available', ...asBooleanStatusColumn },
    { key: 'awsArbitratedPcapsAvailable', value: 'AWS Arbitrated PCAPs Available', ...asBooleanStatusColumn },
    { key: 'sftp05Available', value: 'SFTP05/CrushFTP Available', ...asBooleanStatusColumn },
  ];

  let pageData = { loading: true };
  let query = { currentPage: 1, pageSize: 50, searchText: '' };
  let shouldUpdate = false;

  let modals = {
    addFeed: { open: false },
    dataFeedSubscribers: { open: false },
  };

  const permissionTypeMap = {
    awsLakeAvailable: 'data-feeds-lake',
    awsArbitratedPcapsAvailable: 'data-feeds-aws-arbitrated-pcaps',
    sftp05Available: 'data-feeds-crushftp',
  };

  onMount(async () => {
    try {
      const dataFeeds = await getDataFeedInventorySummary();
      pageData = { dataFeeds, loading: false };
    } catch (error) {
      console.error('[DataFeedInventory] Failed to load feed inventory!', error);
      pageData = { loading: false, error };
    }
  });

  async function refreshInventory() {
    pageData = { ...pageData, loading: true };
    shouldUpdate = false;

    try {
      const refreshedDataFeeds = await refreshAndGetDataFeedInventorySummary();
      pageData = { ...pageData, dataFeeds: refreshedDataFeeds, loading: false };
    } catch (error) {
      console.error('[DataFeedInventory] Failed to refresh feed inventory!', error);
      pageData = { loading: false, error };
      shouldUpdate = true;
    }
  }

  function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'add-feed':
        if (data.id) {
          shouldUpdate = true;
        }
        break;

      default:
        console.warn(`[DataFeedInventory] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function onSearchTextChange(e) {
    query = { ...query, currentPage: 1, searchText: e?.detail ?? '' };
  }

  function viewDataFeedDetails(event, dataFeedId) {
    event.preventDefault();
    navigate(`/data-feed/${dataFeedId}`);
  }

  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, data: forwardData },
    };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  $: filteredFeeds = pageData.dataFeeds?.filter(query.searchText ? (feed) => rowContainsText(feed, query.searchText) : identity);

  $: currentNotification = (() => {
    if (pageData.error) {
      return {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to load feed inventory! Please try again later.',
      };
    }
    if (pageData.dataFeeds?.length === 0) {
      return {
        kind: 'info',
        title: 'Info:',
        subtitle: 'No data feeds available. Data inventory sync required.',
        action: {
          text: 'View tasks',
          onClick: () => navigate('/tasks'),
        },
      };
    }

    return null;
  })();

  $: activeNotification = (() => {
    if (shouldUpdate) {
      return {
        kind: 'info',
        title: 'Info:',
        subtitle: 'Data feed created! You need to refresh inventory in order to see your addition in the list below',
        action: {
          text: 'Refresh',
          onClick: () => refreshInventory(),
        },
      };
    }

    return null;
  })();
</script>

{#if currentNotification}
  <InlineNotification hideCloseButton lowContrast {...currentNotification}>
    <div slot="actions">
      {#if currentNotification.action}
        <NotificationActionButton on:click={currentNotification.action?.onClick}>
          {currentNotification.action?.text}
        </NotificationActionButton>
      {/if}
    </div>
  </InlineNotification>
{:else}
  {#if activeNotification}
    <InlineNotification hideCloseButton lowContrast {...activeNotification}>
      <div slot="actions">
        {#if activeNotification.action}
          <NotificationActionButton on:click={activeNotification.action?.onClick}>
            {activeNotification.action?.text}
          </NotificationActionButton>
        {/if}
      </div>
    </InlineNotification>
  {/if}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable headers={tableHeaders} page={query.currentPage} pageSize={query.pageSize} rows={filteredFeeds ?? []} sortable>
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'name'}
          <Link href={`/data-feed/${row.id}`} on:click={(event) => viewDataFeedDetails(event, row.id)}>{row.name}</Link>
        {:else if cell.key === 'minAvailable'}
          {cell.value && cell.value !== '0000-00-00' ? cell.value : '—'}
        {:else if ['sftp05Available', 'awsLakeAvailable', 'awsArbitratedPcapsAvailable'].includes(cell.key)}
          <span
            on:click={() => {
              if (!cell.value) {
                return;
              }
              openModal('dataFeedSubscribers', {
                dataFeedId: row.id,
                dataFeedName: row.name,
                permissionType: permissionTypeMap[cell.key],
              });
            }}><StatusIcon valid={cell.value} /></span
          >
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value ?? '—'}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearchWithRouting
            labelText="Search for feeds"
            persistent
            bind:searchText={query.searchText}
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          <Button on:click={() => openModal('addFeed')}>Add feed</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      pageSizes={[10, 25, 50]}
      totalItems={filteredFeeds?.length ?? 0}
      bind:page={query.currentPage}
      bind:pageSize={query.pageSize}
    />
  </LoadingSpinner>
{/if}

{#if modals.dataFeedSubscribers.open}
  <DataFeedSubscribersModal open {...modals.dataFeedSubscribers.data} on:close={() => closeModal('dataFeedSubscribers')} />
{/if}

{#if modals.addFeed.open}
  <AddDataFeedModal open on:close={({ detail: data }) => closeModal('addFeed', { action: 'add-feed', data })} />
{/if}
