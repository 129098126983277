<script>
  import { createEventDispatcher } from 'svelte';

  import { Button, ButtonSet, Toolbar, ToolbarContent, ToolbarMenu, ToolbarMenuItem, ToolbarSearch } from 'carbon-components-svelte';
  import { JsonView } from '@zerodevx/svelte-json-view';
  import Add16 from 'carbon-icons-svelte/lib/Add16';
  import Close16 from 'carbon-icons-svelte/lib/Close16';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';
  import copy from 'clipboard-copy';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import CustomInput from '../CustomInput.svelte';
  import CustomParameterValueCell from './CustomParameterValueCell.svelte';
  import { getServerErrorMessage, rowContainsText } from '../../utils';
  import { getSuggestions } from '../../services';

  export let parameters, entityId;

  let selectedRowIds = parameters.map(({ id }) => id),
    rawViewActive = false,
    createdParameters = [],
    searchText = '',
    filteredRows = [],
    valueModificationActive = false,
    debounce;

  let autocompletesState = {
    parameterName: {
      loading: false,
      suggestions: [],
    },
    parameterValue: {
      loading: false,
      suggestions: [],
    },
  };

  const dispatch = createEventDispatcher();
  const DEBOUNCE_MS = 500;
  const EDIT_DETAILS = {
    parameterName: {
      placeholder: 'Parameter Name',
      ref: null,
    },
    parameterValue: {
      placeholder: 'Parameter Value',
      ref: null,
    },
  };
  const CUSTOM_CELL_COMPONENTS = {
    parameterValue: CustomParameterValueCell,
  };
  const tableHeaders = [
    { key: 'parameterName', value: 'Parameter Name' },
    { key: 'parameterValue', value: 'Parameter Value' },
    { key: 'inheritedFrom', value: 'Inherited From', display: (value) => value ?? '-' },
    { key: 'actions', empty: true, sort: false },
  ];

  function initValueAddition() {
    valueModificationActive = true;
    searchText = '';

    createdParameters = [
      {
        id: new Date().valueOf(),
        parameterName: '',
        parameterValue: '',
        inheritedFrom: 'Manually added for current build',
        creating: true,
      },
      ...createdParameters,
    ];
  }

  function handleValueChange({ name, value }) {
    createdParameters = createdParameters.map((param) => {
      if (!param.creating) {
        return param;
      }

      return {
        ...param,
        [name]: value,
      };
    });
  }

  function clearAutocompleteSuggestions(key) {
    autocompletesState = {
      ...autocompletesState,
      [key]: {
        ...autocompletesState[key],
        suggestions: [],
      },
    };
  }

  function handleAutocompleteSelect(key) {
    return function onSelect({ detail: data }) {
      const { value } = data;

      handleValueChange({ name: key, value });
      clearAutocompleteSuggestions(key);
    };
  }

  function handleAutocompleteKeydown(key) {
    return ({ detail: event }) => {
      if (['Enter', 'Escape'].includes(event.key)) {
        clearAutocompleteSuggestions(key);
      }
    };
  }

  function handleAutocompleteBlur(key) {
    return function onBlur() {
      clearAutocompleteSuggestions(key);
    };
  }

  function handleAutocompleteInput(key) {
    return function onInput({ detail: value }) {
      clearTimeout(debounce);

      if (!value) {
        autocompletesState = {
          ...autocompletesState,
          [key]: {
            ...autocompletesState[key],
            suggestions: [],
            loading: false,
          },
        };

        return;
      }

      autocompletesState = {
        ...autocompletesState,
        [key]: {
          ...autocompletesState[key],
          loading: true,
        },
      };

      debounce = setTimeout(async () => {
        const selectedParameterName = createdParameters.find((param) => param.creating)?.parameterName;

        try {
          const suggestions = await getSuggestions({
            type: key,
            value,
            parameterName: key === 'parameterValue' && selectedParameterName ? selectedParameterName : '',
          });

          autocompletesState = {
            ...autocompletesState,
            [key]: {
              ...autocompletesState[key],
              suggestions,
            },
          };
        } catch (error) {
          const errorMessage = getServerErrorMessage(error) ?? 'Could not fetch suggestions!';
          console.error(`[FinalBuildTable] Failed to fetch suggestions! Server says: ${errorMessage}`, error);
        } finally {
          autocompletesState = {
            ...autocompletesState,
            [key]: {
              ...autocompletesState[key],
              loading: false,
            },
          };
        }
      }, DEBOUNCE_MS);
    };
  }

  function cancelValueAddition() {
    createdParameters = createdParameters.filter((param) => !param.creating);
    valueModificationActive = false;
  }

  function confirmValueAddition() {
    let activeId;
    createdParameters = createdParameters.map(({ creating, ...param }) => {
      if (creating) {
        activeId = param.id;
      }

      return { ...param };
    });
    valueModificationActive = false;

    selectedRowIds = [...selectedRowIds, activeId];
  }

  function onSearchTextChange({ target }) {
    searchText = target?.value?.trim() ?? '';
  }

  $: computedParameters = [...createdParameters, ...parameters];

  $: filteredRows = searchText ? computedParameters.filter((param) => rowContainsText(param, searchText)) : computedParameters;

  $: rawParameters = selectedRowIds.reduce((params, id) => {
    const { parameterName, parameterValue } = computedParameters.find(({ id: parameterId }) => parameterId === id);

    let normalizedValue;

    try {
      const possiblyArray = JSON.parse(parameterValue);
      normalizedValue = Array.isArray(possiblyArray) ? possiblyArray : parameterValue;
    } catch (_) {
      normalizedValue = parameterValue;
    }

    return {
      ...params,
      [parameterName]: normalizedValue,
    };
  }, {});

  $: {
    dispatch('parametersChange', { id: entityId, parameters: rawParameters });
  }

  $: tableRowClasses =
    parameters.reduce((classes, param) => {
      const addedParameters = createdParameters.filter(({ parameterName, creating }) => parameterName === param.parameterName && !creating);
      if (!addedParameters.length) {
        return classes;
      }

      const overlappedParameterIds = [...addedParameters.map(({ id }) => id), param.id];

      const selectedOverlappedIndexes = overlappedParameterIds.map((id) => selectedRowIds.findIndex((selectedId) => selectedId === id));

      if (selectedOverlappedIndexes.every((index) => index === -1)) {
        return classes;
      }

      const lastSelectedIndex = Math.max(...selectedOverlappedIndexes);
      const lastSelectedId = selectedRowIds[lastSelectedIndex];
      const overlappedIdsToRemove = overlappedParameterIds.filter((id) => id !== lastSelectedId);

      selectedRowIds = selectedRowIds.filter((id) => !overlappedIdsToRemove.includes(id));

      return {
        ...classes,
        ...overlappedIdsToRemove.reduce((ids, id) => ({ ...ids, [id]: 'overwritten' }), {}),
      };
    }, {}) ?? {};

  $: if (valueModificationActive && EDIT_DETAILS.parameterName.ref) {
    EDIT_DETAILS.parameterName.ref.focus();
  }
</script>

<DataTable
  class={rawViewActive ? 'custom-table collapsed' : 'custom-table'}
  selectable
  sortable={!valueModificationActive}
  rows={rawViewActive ? [] : filteredRows}
  headers={rawViewActive ? [] : tableHeaders}
  rowClasses={tableRowClasses}
  bind:selectedRowIds
>
  <span class:active-row={row.creating} slot="cell" let:cell let:row>
    {#if row.creating}
      {#if ['parameterName', 'parameterValue'].includes(cell.key)}
        <CustomInput
          name={cell.key}
          placeholder={EDIT_DETAILS[cell.key].placeholder}
          focusOnMount={cell.key === 'parameterName'}
          value={row[cell.key]}
          suggestions={autocompletesState[cell.key].suggestions}
          loading={autocompletesState[cell.key].loading}
          on:change={({ detail: text }) => handleValueChange({ name: cell.key, value: text })}
          on:select={handleAutocompleteSelect(cell.key)}
          on:keydown={handleAutocompleteKeydown(cell.key)}
          on:blur={handleAutocompleteBlur(cell.key)}
          on:input={handleAutocompleteInput(cell.key)}
          autocomplete="off"
        />
      {:else if cell.key === 'actions'}
        <ButtonSet class="custom-buttons">
          <Button icon={Close16} iconDescription="Cancel" size="small" kind="secondary" on:click={cancelValueAddition} />
          <Button
            icon={Add16}
            iconDescription="Add"
            size="small"
            on:click={confirmValueAddition}
            disabled={!row.parameterName || !row.parameterValue}
          />
        </ButtonSet>
      {/if}
    {:else if CUSTOM_CELL_COMPONENTS[cell.key]}
      <svelte:component this={CUSTOM_CELL_COMPONENTS[cell.key]} value={cell.value} />
    {:else}
      {cell.display ? cell.display(cell.value) : cell.value ?? ''}
    {/if}
  </span>
  <Toolbar>
    <ToolbarContent>
      <ToolbarSearch
        placeholder="Search for parameters"
        disabled={rawViewActive || valueModificationActive}
        persistent
        value={searchText}
        on:clear={onSearchTextChange}
        on:input={onSearchTextChange}
      />
      <ToolbarMenu>
        <ToolbarMenuItem
          primaryFocus
          on:click={() => {
            rawViewActive = !rawViewActive;
          }}
          disabled={valueModificationActive}
        >
          {rawViewActive ? 'Table' : 'RAW'} view
        </ToolbarMenuItem>
      </ToolbarMenu>
      <Button on:click={initValueAddition} disabled={rawViewActive || valueModificationActive}>Add parameter for current run</Button>
    </ToolbarContent>
  </Toolbar>
</DataTable>
{#if rawViewActive}
  <div class="action-bar">
    <Button
      iconDescription="Copy to clipboard"
      icon={Copy16}
      kind="ghost"
      tooltipPosition="right"
      on:click={() => copy(JSON.stringify(rawParameters, null, 2))}
    />
  </div>
  <div class="margined-bottom raw-view">
    <JsonView json={rawParameters} />
  </div>
{/if}

<style>
  :global(.custom-table.collapsed thead) {
    display: none;
  }

  :global(.custom-buttons.bx--btn-set .bx--btn) {
    width: unset;
    margin-left: 0.2rem;
  }

  :global(.custom-table .bx--toolbar-content) {
    z-index: 1;
  }

  .raw-view {
    --nodePaddingLeft: 1.5rem; /* css variable for svelte-json-view indentation; only available under .raw-view container */
    font-size: 18px;
    padding-left: 2rem;
  }

  .raw-view :global(.key) {
    color: var(--cds-text-01);
  }

  .action-bar {
    height: 48px;
    background-color: var(--cds-layer-accent);
  }
</style>
