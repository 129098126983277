const TASK_COLLISIONS = {
  'crushftp-group-reconciliation': { tasks: ['crushftp-reconciliation', 'disconnect-group-crushftp'] },
  'crushftp-reconciliation': {
    tasks: ['crushftp-group-reconciliation', 'disconnect-group-crushftp'],
    noRelatedEntity: true,
  },
  'disconnect-group-crushftp': { tasks: ['crushftp-reconciliation', 'crushftp-group-reconciliation'] },
};

const wouldCauseTaskCollision = (collidingTaskId, collidingRelatedEntityId, noRelatedEntity, runningTasks) => {
  const prohibitsRunningTheExecution = ({ taskId, relatedEntityId }) => {
    const ignoreRelatedEntity = noRelatedEntity || !relatedEntityId;
    const collidesWithRelatedEntityId = ignoreRelatedEntity || relatedEntityId === collidingRelatedEntityId;
    return taskId === collidingTaskId && collidesWithRelatedEntityId;
  };
  return runningTasks.some(prohibitsRunningTheExecution);
};

const isExecutionColliding = ({ taskId, relatedEntityId }, runningTasks = []) => {
  const { tasks: collidingTasks = [], noRelatedEntity } = TASK_COLLISIONS[taskId] || {};
  const collidingTaskIds = [taskId, ...collidingTasks];
  return collidingTaskIds.some((collidingTaskId) =>
    wouldCauseTaskCollision(collidingTaskId, relatedEntityId, noRelatedEntity, runningTasks)
  );
};

module.exports = {
  isExecutionColliding,
};
