<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { deleteEmailTemplate } from '../../services';

  export let template,
    open = false;

  let loading = false,
    resultNotification;

  const dispatch = createEventDispatcher();

  async function removeEmailTemplate() {
    loading = true;

    try {
      await deleteEmailTemplate(template.id);

      dispatch('close', { id: template.id });
    } catch (error) {
      console.error('[RemoveEmailTemplateModal] Failed to delete email template!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to delete email template! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Delete Email Template"
  preventCloseOnClickOutside={true}
  primaryButtonText="Delete"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={removeEmailTemplate}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <div class="details">
      <p>You are about to delete {template.owner ? 'private' : ''} email template <strong>{template.name}</strong>.</p>
      {#if !template.owner}
        <p>This a public template and no one will have access to it after this action.</p>
      {/if}
      <p>Are you sure?</p>
    </div>
  </LoadingSpinner>
</Modal>

<style>
  .details :global(p) {
    margin-bottom: 1rem;
  }
</style>
