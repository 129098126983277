<script>
  import { Tabs, Tab } from 'carbon-components-svelte';

  import { useNavigate, useLocation } from 'svelte-navigator';

  export let tabsWrapperProps, tabs, defaultHash;

  const navigate = useNavigate();
  const location = useLocation();

  $: currentHash = $location?.hash || defaultHash || '';

  $: {
    const url = new URL(window.location);
    url.hash = currentHash;
    window.history.replaceState({}, '', url);
  }

  $: selectedTabIndex = currentHash
    ? tabs?.findIndex(({ hash, activeForChildHashes }) => {
        return hash === currentHash || (activeForChildHashes && currentHash.startsWith(hash));
      })
    : 0;
</script>

<Tabs {...tabsWrapperProps} selected={selectedTabIndex}>
  {#each tabs as { hash, ...props }, i}
    <Tab on:click={() => navigate($location.pathname + hash)} {...props} />
  {/each}

  <div slot="content">
    <slot />
  </div>
</Tabs>
