<script>
  import { Checkbox, NumberInput, OutboundLink, RadioButton, RadioButtonGroup } from 'carbon-components-svelte';

  import { WB_PLAN_EXCEED_ACTIONS } from '../../../shared/constants';

  export let user,
    planData = {},
    onExceed,
    selectedAmounts = {};

  let limitsEnabled = {
    compute: !!planData.users[user.id]?.compute,
    storage: !!planData.users[user.id]?.storage,
    egress: !!planData.users[user.id]?.egress,
  };

  function handleCheckboxChange(field) {
    return function onCheckboxChange({ target: { checked } }) {
      limitsEnabled = {
        ...limitsEnabled,
        [field]: checked,
      };

      if (!checked) {
        handleCustomInputChange(field)({ detail: 0 });
      }
    };
  }

  function handleCustomInputChange(field) {
    return function onNumberInputChange({ detail: value }) {
      const currentUserData = planData.users[user.id] ?? {};
      planData = { ...planData, users: { ...planData.users, [user.id]: { ...currentUserData, [field]: value } } };
    };
  }

  function validate() {
    if (!planData.users[user.id]) {
      return;
    }

    for (const [resource, value] of Object.entries(planData.users[user.id])) {
      if (selectedAmounts[resource] - restUserLimits[resource] < value) {
        planData = { ...planData, invalid: true };
        return;
      }
      planData = { ...planData, invalid: false };
    }
  }

  function computeRestUserLimits(users) {
    let total = { compute: 0, storage: 0, egress: 0 };

    for (const [userId, userLimits] of Object.entries(users)) {
      if (userId === user.id) {
        // eslint-disable-next-line no-continue
        continue;
      }

      for (const [key, value] of Object.entries(userLimits)) {
        total = { ...total, [key]: total[key] + value };
      }
    }

    return total;
  }

  $: restUserLimits = computeRestUserLimits(planData.users);
  $: validate(planData);
  $: userHasNoLimits = !planData.users[user.id]?.compute && !planData.users[user.id]?.storage && !planData.users[user.id]?.egress;
  $: if (userHasNoLimits && planData.users[user.id]?.exceedAction) {
    delete planData.users[user.id].exceedAction;
  }
</script>

<div>
  <div class="field">
    <Checkbox checked={limitsEnabled.compute} on:change={handleCheckboxChange('compute')}>
      <div slot="labelText">
        <span>Limit user's extra compute (amount of $)</span>
        {#if restUserLimits.compute}
          <em>{restUserLimits.compute}/{selectedAmounts.compute} already allocated to other users</em>
        {/if}
      </div>
    </Checkbox>
    <NumberInput
      size="sm"
      disabled={!limitsEnabled.compute}
      value={planData.users[user.id]?.compute ?? 0}
      max={selectedAmounts.compute - restUserLimits.compute}
      min={0}
      invalidText="You cannot allocate this amount"
      helperText="of extra purchased compute to use"
      on:change={handleCustomInputChange('compute')}
    />
  </div>
  <div class="field">
    <Checkbox checked={limitsEnabled.storage} on:change={handleCheckboxChange('storage')}>
      <div slot="labelText">
        <span>Limit user's extra storage (amount of GB)</span>
        {#if restUserLimits.storage}
          <em>{restUserLimits.storage}/{selectedAmounts.storage} already allocated to other users</em>
        {/if}
      </div>
    </Checkbox>
    <NumberInput
      size="sm"
      disabled={!limitsEnabled.storage}
      value={planData.users[user.id]?.storage ?? 0}
      max={selectedAmounts.storage - restUserLimits.storage}
      min={0}
      invalidText="You cannot allocate this amount"
      helperText="of extra purchased storage to use"
      on:change={handleCustomInputChange('storage')}
    />
  </div>
  <div class="field">
    <Checkbox checked={limitsEnabled.egress} on:change={handleCheckboxChange('egress')}>
      <div slot="labelText">
        <span>Limit user's extra egress (amount of GB)</span>
        {#if restUserLimits.egress}
          <em>{restUserLimits.egress}/{selectedAmounts.egress} already allocated to other users</em>
        {/if}
      </div>
    </Checkbox>
    <NumberInput
      size="sm"
      disabled={!limitsEnabled.egress}
      value={planData.users[user.id]?.egress ?? 0}
      max={selectedAmounts.egress - restUserLimits.egress}
      min={0}
      invalidText="You cannot allocate this amount"
      helperText="of extra purchased egress to use"
      on:change={handleCustomInputChange('egress')}
    />
  </div>
  {#if onExceed !== 'shutdown'}
    <div class="exceed">
      <RadioButtonGroup
        legendText="Resource Exceed Action"
        selected={planData.users[user.id]?.exceedAction}
        name="exceedAction"
        disabled={userHasNoLimits}
        on:change={handleCustomInputChange('exceedAction')}
      >
        {#each Object.entries(WB_PLAN_EXCEED_ACTIONS) as [value, labelText]}
          <div class="radio">
            <RadioButton {labelText} {value} />
            {#if value === 'charge'}
              <OutboundLink href="/workbench#prices">Check charges</OutboundLink>
            {/if}
          </div>
        {/each}
      </RadioButtonGroup>
    </div>
  {/if}
</div>

<style>
  .field :global(em) {
    font-size: 0.65rem;
    color: var(--cds-text-error);
  }

  .exceed :global(fieldset) {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .exceed .radio {
    display: flex;
    margin-bottom: 0.25rem;
  }

  .exceed .radio :global(a) {
    margin-left: 0.5rem;
  }

  .exceed :global(a.bx--link) {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
  }
</style>
