<script>
  import { HeaderGlobalAction } from 'carbon-components-svelte';
  import Asleep20 from 'carbon-icons-svelte/lib/Asleep20';
  import Awake20 from 'carbon-icons-svelte/lib/Awake20';

  let theme = localStorage.getItem('theme') ?? 'white';

  const changeTheme = () => {
    theme = theme === 'white' ? 'g90' : 'white';
  };

  $: if (theme) {
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('theme', theme);
  }
</script>

<HeaderGlobalAction aria-label="Change Theme" icon={theme === 'white' ? Awake20 : Asleep20} on:click={changeTheme} />
