<script>
  import { createEventDispatcher } from 'svelte';
  import * as yup from 'yup';

  import { Form } from '@mst-fe/sveltejs-forms';
  import { FormGroup, InlineNotification, Modal } from 'carbon-components-svelte';
  import { Checkbox, Select, SelectItem, TextArea, TextInput } from '@mst-fe/carbon-components-svelte';

  import { KNOWN_KEYS } from '../../pages/groups/GroupExternalKeyList.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { createExternalKey, updateExternalKey } from '../../services';

  const DEFAULT_KEY = 'rth';
  const dispatch = createEventDispatcher();

  export let groupName,
    groupId,
    id,
    open = false,
    isEdit,
    initialValues = { key: DEFAULT_KEY };

  let loading = false,
    formSubmitButtonRef,
    resultNotification = null,
    isCustomKey = false;

  async function onFormSubmit({ detail: { values } }) {
    loading = true;
    resultNotification = null;

    try {
      const key = await (isEdit ? updateExternalKey(id, { ...values }) : createExternalKey({ groupId, ...values }));
      dispatch('close', { ...key });
    } catch (error) {
      console.error(`[AddOrEditKeyModal] Failed to ${isEdit ? 'edit' : 'add'} external key!`, error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to ${isEdit ? 'edit' : 'add'} external key!`,
      };
    } finally {
      loading = false;
    }
  }

  function handleCustomKeyChange(setValue) {
    return function onCheckChange() {
      isCustomKey = !isCustomKey;

      // empty key selection, or fallback to default
      setValue('key', isCustomKey ? '' : DEFAULT_KEY);
    };
  }

  // handles change events dispatched by svelte
  function handleCustomChangeEvent({ key, setValue, previousValue }) {
    return function onCustomChange({ detail }) {
      if (previousValue === detail) {
        return;
      }

      setValue(key, detail);
    };
  }

  // handles native change event
  function handleChangeEvent({ key, setValue }) {
    return async function onChange({ target: { value } }) {
      setValue(key, value);
    };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  const schema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
    ...(!isEdit
      ? {
          key: yup
            .string()
            .required('Key is a required field')
            .test('no-space', 'No space is allowed for key', (value) => /^\S*$/.test(value)),
          value: yup.string().required('Value is a required field'),
        }
      : {}),
  });
</script>

<Modal
  hasScrollingContent={true}
  modalHeading={isEdit ? 'Update External Key' : 'Add External Key'}
  preventCloseOnClickOutside={true}
  shouldSubmitOnEnter={false}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are {isEdit ? 'updating an existing' : 'creating a new'} external service key for <strong>{groupName}</strong>.
    </p>
    <Form
      fieldDomString="input,textarea,select,div[name]"
      {initialValues}
      {schema}
      let:submitForm
      let:errors
      let:setValue
      let:touched
      let:values
      on:submit={onFormSubmit}
    >
      <FormGroup>
        <TextInput
          name="name"
          autocomplete="off"
          invalid={touched.name && !!errors.name}
          invalidText={errors.name}
          labelText="Name"
          placeholder="Friendly name"
          value={values.name}
          required
          on:change={handleCustomChangeEvent({ key: 'name', setValue })}
        />
        {#if !isEdit}
          <div class="key-wrapper">
            {#if !isCustomKey}
              <Select
                name="key"
                invalid={touched.key && !!errors.key}
                invalidText={errors.key}
                labelText="Key"
                selected={values.key}
                required
                on:change={handleCustomChangeEvent({ key: 'key', setValue, previousValue: values.key })}
              >
                {#each Object.entries(KNOWN_KEYS) as [key, value]}}
                  <SelectItem value={key} text={value} />
                {/each}
              </Select>
            {:else}
              <TextInput
                name="key"
                autocomplete="off"
                invalid={touched.key && !!errors.key}
                invalidText={errors.key}
                labelText="Key"
                placeholder="Custom key"
                value={values.key}
                required
                on:change={handleCustomChangeEvent({ key: 'key', setValue })}
              />
            {/if}
            <Checkbox labelText="Custom?" checked={isCustomKey} on:check={handleCustomKeyChange(setValue)} />
          </div>
          <TextArea
            name="value"
            labelText="Value"
            placeholder="Value"
            value={values.value}
            required
            on:change={handleChangeEvent({ key: 'value', setValue })}
          />
        {/if}
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>

<style>
  .key-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
  }

  .key-wrapper :global(.bx--checkbox-wrapper) {
    margin-left: 1rem;
    margin-top: 1.75rem;
    max-width: 5.5rem;
  }
</style>
