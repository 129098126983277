<script>
  import { Tooltip } from 'carbon-components-svelte';
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';
  import View16 from 'carbon-icons-svelte/lib/View16';
  import startCase from 'lodash/startCase';

  export let triggerText = null,
    eventData;

  const getEventChanges = (data) => {
    const { original, changes } = data;

    if (!original && !changes) {
      return Object.keys(data).map((key) => ({ key, original: data[key] }));
    }

    return Object.keys(original).map((key) => ({ key, original: original[key], changed: changes[key] }));
  };
</script>

<div class="custom-diff">
  <Tooltip {triggerText} direction="right" icon={View16}>
    <div>
      {#each getEventChanges(eventData) as { key, original, changed }}
        <h6 class="key-heading">{startCase(key)}</h6>
        <span class="flex">
          {original}
          {#if changed}
            <ArrowRight16 class="changed-key-arrow" />
            {changed}
          {/if}
        </span>
      {/each}
    </div>
  </Tooltip>
</div>

<style>
  .custom-diff :global(.bx--tooltip) {
    max-width: 40rem;
  }

  .flex {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    max-height: 250px;
    overflow-y: auto;
  }

  .key-heading:not(:first-child) {
    margin-top: 0.75rem;
  }

  :global(.changed-key-arrow) {
    margin-top: 0.1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
</style>
