<script>
  import * as yup from 'yup';
  import { createEventDispatcher } from 'svelte';

  import { Form } from '@mst-fe/sveltejs-forms';
  import { Checkbox, FormGroup, TextArea, TextInput } from '@mst-fe/carbon-components-svelte';
  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { createFeatureGroup, editFeatureGroup } from '../../services';

  export let group,
    open = false;

  let loading = false,
    resultNotification,
    formSubmitButtonRef;

  const initialValues = group
    ? {
        name: group.name,
        shortName: group.shortName,
        description: group.description,
        isFeedHandler: group.isFeedHandler,
      }
    : { isFeedHandler: false };

  const dispatch = createEventDispatcher();

  async function handleFormSubmission({ detail: { values } }) {
    loading = true;

    try {
      const data = await (group ? editFeatureGroup(group.id, values) : createFeatureGroup(values));
      dispatch('close', data);
    } catch (error) {
      console.error('[AddOrEditFeatureGroupModal] Failed to create/edit license feature group!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to create/edit license feature group! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function handleCustomChangeEvent({ key, setValue }) {
    return function onCustomChange({ detail: value }) {
      setValue(key, value);
    };
  }

  function handleChangeEvent({ key, setValue }) {
    return function onChange({ target: { value } }) {
      setValue(key, value);
    };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: schema = yup.object().shape({
    name: yup.string().required('Feature group name cannot be empty'),
    shortName: yup.string().nullable(),
    description: yup.string().nullable(),
    isFeedHandler: yup.boolean(),
  });
</script>

<Modal
  modalHeading={`${group ? 'Edit' : 'Create'} Feature Group`}
  preventCloseOnClickOutside={true}
  primaryButtonText={group ? 'Save' : 'Create'}
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <Form
      fieldDomString="input,textarea,select,div[name]"
      {initialValues}
      {schema}
      let:submitForm
      let:setValue
      let:touched
      let:values
      let:errors
      on:submit={handleFormSubmission}
    >
      <FormGroup>
        <TextInput
          name="name"
          autocomplete="off"
          invalid={touched.name && !!errors.name}
          invalidText={errors.name}
          labelText="Name"
          placeholder="Group name"
          value={values.name}
          required
          on:change={handleCustomChangeEvent({ key: 'name', setValue })}
        />
        <TextInput
          name="shortName"
          autocomplete="off"
          invalid={touched.shortName && !!errors.shortName}
          invalidText={errors.shortName}
          labelText="Short name"
          placeholder="Group short name"
          value={values.shortName}
          on:change={handleCustomChangeEvent({ key: 'shortName', setValue })}
        />
        <TextArea
          name="description"
          invalid={touched.description && !!errors.description}
          invalidText={errors.description}
          labelText="Description"
          placeholder="Feature description"
          value={values.description}
          on:change={handleChangeEvent({ key: 'description', setValue })}
        />
        <Checkbox
          name="isFeedHandler"
          labelText="Feed handler"
          checked={values.isFeedHandler}
          on:check={handleCustomChangeEvent({ key: 'isFeedHandler', setValue })}
        />
        <span class="bx--label">
          "Feed Handler" groups will act as individual features. If any feature from a "Feed handler" group is selected, the name of the
          group will appear under license instead.
        </span>
      </FormGroup>
      <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
    </Form>
  </LoadingSpinner>
</Modal>
