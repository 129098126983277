<script>
  import { getContext } from 'svelte';
  import { Button, ButtonSet, Checkbox } from 'carbon-components-svelte';

  export let invalid = false,
    nextButtonActive = false,
    previousButtonActive = true,
    onBeforeForward = null,
    onBeforeBack = null,
    onBeforeFinish = null;

  let stepIsReady = false;

  const {
    addContent,
    confirmationBeforeSubmit,
    confirmationMessage,
    currentStep,
    finalStepButtonLabel,
    getContentUpdater,
    goBack,
    goForward,
    onFinish,
    setStep,
    steps,
  } = getContext('wizard');
  const stepIndex = addContent({ nextButtonActive, previousButtonActive, invalid });
  const updateContent = getContentUpdater(stepIndex);

  function handleGoForward() {
    if (onBeforeForward) {
      onBeforeForward(stepIndex);
    }

    goForward();
  }

  function handleGoBack() {
    if (onBeforeBack) {
      onBeforeBack(stepIndex);
    }

    goBack();
  }

  function handleFinish() {
    if (onBeforeFinish) {
      onBeforeFinish(stepIndex);
    }

    onFinish();
  }

  $: {
    // TODO: is there a better way to align store with prop changes?
    updateContent((currentContent) => ({
      ...currentContent,
      nextButtonActive,
      previousButtonActive,
      invalid,
    }));
  }
  $: isCurrentStep = $currentStep === stepIndex;
  $: isLastStep = stepIndex === steps.length - 1;
  $: stepIsReady = !(isLastStep && confirmationBeforeSubmit);
</script>

{#if isCurrentStep}
  <div class="wizard-step">
    <div class="content">
      <slot {stepIndex} {setStep} />
    </div>
    {#if confirmationBeforeSubmit && isLastStep}
      <Checkbox labelText={confirmationMessage ?? 'I verify the input is valid and I want to proceed.'} bind:checked={stepIsReady} />
    {/if}
    <slot name="navigation" {goForward} {goBack}>
      <div class="navigation">
        <ButtonSet>
          {#if stepIndex !== 0}
            <Button size="field" kind="secondary" disabled={!previousButtonActive} on:click={handleGoBack}>Previous</Button>
          {/if}
          {#if !isLastStep}
            <Button size="field" disabled={!nextButtonActive} on:click={handleGoForward}>Next</Button>
          {:else}
            <Button size="field" disabled={!stepIsReady} on:click={handleFinish}>{finalStepButtonLabel}</Button>
          {/if}
        </ButtonSet>
      </div>
    </slot>
  </div>
{/if}

<style>
  .content {
    margin: 1rem 0;
  }

  .navigation :global(.bx--btn-set) {
    justify-content: flex-end;
  }
</style>
