<script>
  import { Button, Loading } from 'carbon-components-svelte';
</script>

<Button {...$$props} on:click on:mouseover on:mouseenter on:mouseleave>
  <slot />
  {#if $$props.isLoading}
    <span class="spinner-wrapper">
      <Loading withOverlay={false} small={true} />
    </span>
  {/if}
</Button>

<style>
  .spinner-wrapper {
    padding-left: 0.5rem;
  }
</style>
