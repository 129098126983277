<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { removeConfigParameters } from '../../services';

  export let entityType,
    entityName,
    targetName,
    open = false,
    configId;

  let loading = false;
  let resultNotification;

  const dispatch = createEventDispatcher();

  async function removeParameters() {
    loading = true;

    try {
      await removeConfigParameters(configId);

      dispatch('close', { configId });
    } catch (error) {
      console.error('[RemoveConfigParameters] Failed to remove config parameters!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to remove config parameters! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Remove Parameters"
  preventCloseOnClickOutside={true}
  primaryButtonText="Remove"
  secondaryButtonText="Cancel"
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={removeParameters}
>
  <LoadingSpinner small {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are about to remove <strong>all parameters</strong>
      {#if targetName}
        from <strong>{targetName}</strong> target under <strong>{entityName}</strong> {entityType}.
      {:else}
        from {entityType} <strong>{entityName}</strong>.
      {/if}
      <br />
      Are you sure?
    </p>
  </LoadingSpinner>
</Modal>
