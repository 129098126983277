<script>
  import AuditTableEventDataCellDefault from './AuditTableEventDataCellDefault.svelte';
  import LongTextTooltipCell from '../LongTextTooltipCell.svelte';
  import AuditTableEventDataCellApiKeys from './AuditTableEventDataCellApiKeys.svelte';
  import AuditTableEventDataCellDataFeeds from './AuditTableEventDataCellDataFeeds.svelte';
  import AuditTableEventDataCellLicense from './AuditTableEventDataCellLicense.svelte';
  import AuditTableEventDataCellParams from './AuditTableEventDataCellParams.svelte';
  import AuditTableEventDataCellWbPlan from './AuditTableEventDataCellWbPlan.svelte';
  import AuditTableEventDataCellWorkbenchInstance from './AuditTableEventDataCellWorkbenchInstance.svelte';

  export let row;
  const { eventData, entityType, eventType } = row;
</script>

{#if entityType === 'user-roles'}
  {#if eventData.created.length}
    <h6>Added:</h6>
    <span>{eventData.created.join(', ')}</span>
  {/if}
  {#if eventData.deleted.length}
    <h6>Deleted:</h6>
    <span>{eventData.deleted.join(', ')}</span>
  {/if}
{:else if ['ssh-keys-data-services-group', 'ssh-keys-data-services-user'].includes(entityType)}
  <h6>Associated key:</h6>
  <span>{eventData.sshKeyId}</span>
{:else if ['ssh-key-group', 'ssh-key-user'].includes(entityType) && eventType !== 'updated'}
  <h6>Public key:</h6>
  <LongTextTooltipCell text={eventData.publicKey} />
{:else if entityType === 'api-key'}
  <AuditTableEventDataCellApiKeys {eventData} />
{:else if entityType === 'data-feeds-permission' && eventType === 'updated'}
  <AuditTableEventDataCellDataFeeds {eventData} />
{:else if entityType === 'workbench-instance-permission' && eventType === 'updated'}
  <AuditTableEventDataCellWorkbenchInstance {eventData} />
{:else if ['build-parameter', 'build-config'].includes(entityType)}
  <AuditTableEventDataCellParams {eventData} {eventType} />
{:else if entityType === 'regression'}
  <h6>Regression Data:</h6>
  <LongTextTooltipCell text={JSON.stringify(eventData)} />
{:else if entityType === 'license-template'}
  <AuditTableEventDataCellLicense {row} />
{:else if entityType === 'wb-plan'}
  <AuditTableEventDataCellWbPlan planId={row.entityId} {eventData} />
{:else}
  <AuditTableEventDataCellDefault {eventData} />
{/if}
