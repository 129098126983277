<script>
  import { onMount } from 'svelte';
  import startCase from 'lodash/startCase';

  import { Accordion, AccordionItem, Button, ButtonSet, InlineNotification, NumberInput, TooltipIcon } from 'carbon-components-svelte';

  import ChunkIcon from 'carbon-icons-svelte/lib/CarouselVertical16';
  import MinimumIcon from 'carbon-icons-svelte/lib/ChartMinimum16';
  import OverageIcon from 'carbon-icons-svelte/lib/ChartHistogram16';
  import ChargeIcon from 'carbon-icons-svelte/lib/CurrencyDollar16';
  import UserIcon from 'carbon-icons-svelte/lib/User16';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';

  import { getLoginDefaults, getResourceCharges, updateDefaults } from '../../services';

  const UNIT_SYMBOLS = { USD: '$', GB: 'GB' };

  let loading = false,
    resultNotification = null,
    awsLoginDefaults = [],
    awsResourceCharges = {},
    editing = null;

  async function fetchDependencies() {
    loading = true;
    resultNotification = null;

    try {
      [awsLoginDefaults, awsResourceCharges] = await Promise.all([getLoginDefaults(), getResourceCharges()]);
    } catch (error) {
      console.error('[ManageWbPricing] Failed to fetch dependencies', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to fetch dependencies. Please try refreshing the page!',
      };
    } finally {
      loading = false;
    }
  }

  async function handleSaveAction() {
    loading = true;
    resultNotification = null;

    try {
      const changes = {
        resource: editing.resource,
        login: editing.awsLoginDefault
          ? findDiff(
              awsLoginDefaults.find(({ resource }) => resource === editing.resource),
              editing.awsLoginDefault
            )
          : null,
        charge: editing.awsResourceCharge ? findDiff(awsResourceCharges[editing.resource], editing.awsResourceCharge) : null,
      };

      await updateDefaults(changes);
      editing = null;
      resultNotification = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Value(s) updated successfully.',
      };
    } catch (error) {
      console.error('[ManageWbPricing] Failed to save changes', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to save changes. Please try again later!',
      };
    } finally {
      loading = false;
    }
  }

  function handleClearAction() {
    if (editing.awsLoginDefault) {
      awsLoginDefaults = awsLoginDefaults.map((defaults) => (defaults.resource === editing.resource ? editing.awsLoginDefault : defaults));
    }

    if (editing.awsResourceCharge) {
      awsResourceCharges = { ...awsResourceCharges, [editing.resource]: editing.awsResourceCharge };
    }

    editing = null;
  }

  function handleInputChange(resource, field) {
    return function onChange({ detail: value }) {
      editing = {
        ...(editing ?? {}),
        resource,
      };

      if (field === 'loginDefault') {
        if (!editing.awsLoginDefault) {
          const awsLoginDefault = awsLoginDefaults.find(({ resource: resourceId }) => resourceId === resource);
          editing = {
            ...editing,
            awsLoginDefault,
          };
        }

        awsLoginDefaults = awsLoginDefaults.map((defaults) => (defaults.resource === resource ? { ...defaults, value } : defaults));

        return;
      }

      if (!editing.awsResourceCharge) {
        editing = {
          ...editing,
          awsResourceCharge: { ...awsResourceCharges[resource] },
        };
      }

      awsResourceCharges = {
        ...awsResourceCharges,
        [resource]: {
          ...awsResourceCharges[resource],
          [field]: value,
        },
      };
    };
  }

  function findDiff(newObject, oldObject) {
    return Object.keys(oldObject).reduce(
      (all, key) => {
        if (!(key in all)) {
          return all;
        }

        const { [key]: value, ...rest } = all;
        if (value === oldObject[key]) {
          return { ...rest };
        }

        return all;
      },
      { ...newObject }
    );
  }

  onMount(fetchDependencies);
</script>

<div class="pricing">
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        lowContrast
        kind={resultNotification.kind}
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      From this view, you can edit resource pricing and login defaults to be used on Workbench plans. Already generated plans will not be
      affected by any changes, as they keep a reference of these values at the time of their generation.
    </p>
    <Accordion>
      {#each awsLoginDefaults as loginDefault (loginDefault.resource)}
        <AccordionItem
          open={editing && editing.resource === loginDefault.resource}
          disabled={editing && editing.resource !== loginDefault.resource}
        >
          <svelte:fragment slot="title">
            <h5>{startCase(loginDefault.resource)}</h5>
            <div class="details">
              <div>
                <TooltipIcon icon={UserIcon} tooltipText="Login Default" />
                {#if loginDefault.unit === 'USD'}
                  ${loginDefault.value}
                {:else}
                  {loginDefault.value}{loginDefault.unit}
                {/if}
              </div>
              <div>
                <TooltipIcon icon={MinimumIcon} tooltipText="Minimum Overage Charge" />
                ${awsResourceCharges[loginDefault.resource].minimumOverageCharge}
              </div>
              {#if awsResourceCharges[loginDefault.resource].unitCharge !== 1}
                <div>
                  <TooltipIcon icon={ChargeIcon} tooltipText="Unit Charge" />
                  ${awsResourceCharges[loginDefault.resource].unitCharge}
                </div>
              {/if}
              {#if awsResourceCharges[loginDefault.resource].overageUnitCharge !== 1}
                <div>
                  <TooltipIcon icon={OverageIcon} tooltipText="Overage Unit Charge" />
                  ${awsResourceCharges[loginDefault.resource].overageUnitCharge}
                </div>
              {/if}
              <div>
                <TooltipIcon icon={ChunkIcon} tooltipText="Purchasable Chunk" />
                {#if loginDefault.unit === 'USD'}
                  ${awsResourceCharges[loginDefault.resource].purchasableChunk}
                {:else}
                  {awsResourceCharges[loginDefault.resource].purchasableChunk}{awsResourceCharges[loginDefault.resource].unit}
                {/if}
              </div>
            </div>
          </svelte:fragment>
          <div class="main">
            <NumberInput
              label={`Login Default (amount of ${UNIT_SYMBOLS[loginDefault.unit]})`}
              value={loginDefault.value}
              helperText="This is the value each user will get by default when purchasing a Workbench plan"
              disabled={editing && editing.resource !== loginDefault.resource}
              on:change={handleInputChange(loginDefault.resource, 'loginDefault')}
            />
            <NumberInput
              label={`Minimum Overage Charge (amount of $)`}
              value={awsResourceCharges[loginDefault.resource].minimumOverageCharge}
              helperText="Client cannot be charged less than that when they exceed this resource's limit"
              disabled={editing && editing.resource !== loginDefault.resource}
              on:change={handleInputChange(loginDefault.resource, 'minimumOverageCharge')}
            />
            {#if loginDefault.resource !== 'compute'}
              <NumberInput
                label="Unit Charge (amount of $)"
                value={awsResourceCharges[loginDefault.resource].unitCharge}
                helperText="Charge amount per unit of resource"
                disabled={editing && editing.resource !== loginDefault.resource}
                on:change={handleInputChange(loginDefault.resource, 'unitCharge')}
              />
              <NumberInput
                label="Overage Unit Charge (amount of $)"
                value={awsResourceCharges[loginDefault.resource].overageUnitCharge}
                helperText="Overage charge amount per unit of resource"
                disabled={editing && editing.resource !== loginDefault.resource}
                on:change={handleInputChange(loginDefault.resource, 'overageUnitCharge')}
              />
            {/if}
            <NumberInput
              label={`Purchasable Chunk (amount of ${UNIT_SYMBOLS[awsResourceCharges[loginDefault.resource].unit]})`}
              value={awsResourceCharges[loginDefault.resource].purchasableChunk}
              helperText="Client can only purchase chunks of this amount for this resource"
              disabled={editing && editing.resource !== loginDefault.resource}
              on:change={handleInputChange(loginDefault.resource, 'purchasableChunk')}
            />
            <div class="actions">
              <ButtonSet>
                <Button
                  kind="secondary"
                  size="field"
                  disabled={!editing || (editing && editing.resource !== loginDefault.resource)}
                  on:click={handleClearAction}>Clear</Button
                >
                <Button
                  size="field"
                  disabled={!editing || (editing && editing.resource !== loginDefault.resource)}
                  on:click={handleSaveAction}>Save</Button
                >
              </ButtonSet>
            </div>
          </div>
        </AccordionItem>
      {/each}
    </Accordion>
  </LoadingSpinner>
</div>

<style>
  .pricing :global(p) {
    margin-bottom: 1rem;
  }
  :global(.bx--accordion__item--disabled) .details,
  :global(.bx--accordion__item--disabled) .details > :global(div),
  :global(.bx--accordion__item--disabled) .details :global(svg) {
    color: var(--cds-text-disabled);
    fill: var(--cds-text-disabled);
  }

  .details,
  .details > :global(div) {
    display: flex;
    color: var(--cds-text-helper);
    margin: 0 0.5rem;
  }

  .details :global(.bx--tooltip__trigger) {
    margin-right: 0.5rem;
  }

  .main :global(.bx--form-item) {
    margin-top: 1rem;
  }

  .main :global(.bx--form__helper-text) {
    color: var(--cds-text-helper);
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
</style>
