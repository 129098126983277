<script>
  import { onMount } from 'svelte';
  import { useNavigate } from 'svelte-navigator';

  import { Link, InlineNotification, Tag, Toolbar, ToolbarContent, TooltipDefinition } from 'carbon-components-svelte';
  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import CustomLicenseFeaturesCell from '../../components/licenses/CustomLicenseFeaturesCell.svelte';
  import LicenseStatusCell from '../../components/licenses/LicenseStatusCell.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  import { getLicenseTemplates } from '../../services';
  import { rowContainsText } from '../../utils';

  import { SUPPORTED_RENEWAL_CYCLES } from '../../components/licenses/LicenseTemplateBasicForm.svelte';

  const navigate = useNavigate();

  let loading = false,
    pagination = { currentPage: 1, pageSize: 25 },
    licenseTemplates = [],
    searchText = '',
    filteredRows = [],
    resultNotification;

  async function fetchLicenseTemplates() {
    loading = true;

    try {
      licenseTemplates = await getLicenseTemplates();
    } catch (error) {
      console.error('[LicenseTemplatesList] Failed to get license templates...', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to get license templates! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  function onSearchTextChange(e) {
    searchText = e?.detail ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  function featuresContainsText(features, value) {
    return features.some(({ name }) => name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  }

  function generateNavigationFunction(groupId, licenseTemplateId) {
    return function go(event) {
      event.preventDefault();
      navigate(`/group/${groupId}?template=${licenseTemplateId}#licenses`);
    };
  }

  onMount(fetchLicenseTemplates);

  $: filteredRows = searchText
    ? licenseTemplates.filter(
        (license) =>
          featuresContainsText(license.fullFeatureList, searchText) ||
          rowContainsText(license, searchText) ||
          rowContainsText(license.licenseOwner, searchText) ||
          (license?.newestGeneratedLicense &&
            `${license.newestGeneratedLicense.filename}.lic`.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
      )
    : licenseTemplates;
</script>

<LoadingSpinner {loading}>
  {#if resultNotification}
    <InlineNotification
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  {#if licenseTemplates.length}
    <div class="table">
      <DataTable
        headers={[
          { key: 'licenseOwner.name', value: 'Group Name' },
          { key: 'name', value: 'License Name' },
          {
            key: 'newestGeneratedLicense',
            value: 'Expiration Date',
            display: (data) => (data?.isPermanent ? 'No expiration' : data?.expirationDate ?? '-'),
          },
          { key: 'cycle', value: 'Cycle', display: (cycle) => SUPPORTED_RENEWAL_CYCLES[cycle] },
          { key: 'features', value: 'Features', sort: false },
          { key: 'newestGeneratedLicense.expirationDate', value: 'Status' },
        ]}
        rows={filteredRows}
        sortable
        pageSize={pagination.pageSize}
        page={pagination.currentPage}
      >
        <div slot="cell" let:cell let:row>
          {#if cell.key === 'name'}
            <div>
              {#if row.isDemo}
                <Tag type="blue" size="sm">DEMO</Tag>
              {/if}
              {#if row.licenseOwner.deletedAt}
                <TooltipDefinition tooltipText="Deleted group">{row.name}</TooltipDefinition>
              {:else}
                <Link href={`/group/${row.ownerId}?template=${row.id}#licenses`} on:click={generateNavigationFunction(row.ownerId, row.id)}>
                  <strong>{row.name}</strong>
                </Link>
              {/if}
            </div>
          {:else if cell.key === 'features'}
            <CustomLicenseFeaturesCell features={row.fullFeatureList} />
          {:else if cell.key === 'newestGeneratedLicense.expirationDate'}
            <LicenseStatusCell license={row} />
          {:else if cell.display}
            {cell.display(cell.value, row)}
          {:else}
            {cell.value}
          {/if}
        </div>
        <Toolbar>
          <ToolbarContent>
            <ToolbarSearchWithRouting
              persistent
              placeholder="Search for templates"
              bind:searchText
              on:toolbarSearchValueChange={onSearchTextChange}
            />
          </ToolbarContent>
        </Toolbar>
      </DataTable>
      <PaginationWithRouting
        bind:page={pagination.currentPage}
        bind:pageSize={pagination.pageSize}
        pageSizes={[10, 25, 50]}
        totalItems={filteredRows?.length ?? 0}
      />
    </div>
  {:else}
    <InlineNotification lowContrast hideCloseButton kind="info" title="Info:" subtitle="No license templates found" />
  {/if}
</LoadingSpinner>
