<script>
  import truncate from 'lodash/truncate';
  import { appConfig } from '../../stores';

  export let fullPath;

  $: [destination, path] = fullPath.split(':');
</script>

<span class="destination-label">{$appConfig.data.supportedUploadDestinations[destination]}</span>
<strong class="path-label" {...path.length > 65 ? { 'data-path': path } : {}}>
  {truncate(path, { length: 65 })}
</strong>

<style>
  .destination-label {
    background-color: var(--cds-active-ui);
    border-radius: 0.9375rem;
    padding: 0.1rem 0.5rem;
    font-size: 0.85em;
    margin-right: 0.3rem;
    white-space: nowrap;
  }

  .path-label[data-path]:hover::after {
    content: attr(data-path);
    position: absolute;
    top: 1.25rem;
    left: -1rem;
    z-index: 1;
    background-color: var(--cds-tag-background-gray, #e0e0e0);
    border-radius: 0.9365rem;
    padding: 0.1rem 0.5rem;
    white-space: nowrap;
    cursor: default;
    border: 1px solid var(--cds-ui-04);
  }
</style>
