<script>
  import { onMount } from 'svelte';

  import { Accordion, AccordionItem, Button, InlineNotification, Modal, TooltipDefinition } from 'carbon-components-svelte';
  import { JsonView } from '@zerodevx/svelte-json-view';
  import copy from 'clipboard-copy';

  import Copy16 from 'carbon-icons-svelte/lib/Copy16';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { getFinalParameters } from '../../services';

  export let entityType,
    entityId,
    entityName,
    configId,
    isDefault,
    targetName,
    jsonConfig = null,
    open = false;

  let loading = false,
    resultNotification,
    configs = [];

  const OVERVIEW_CONFIG_PARAMETERS = {
    MST_CMAKE_COMPILER_FLAGS: 'Flags',
    MST_DOCKER_IMAGE: 'Env',
    MST_OPENONLOAD_VERSION: 'Onload',
    MST_PACKAGE_SUFFIX: 'Suffix',
  };

  function normalizeParameters(params) {
    return Object.keys(params).reduce(
      (overview, parameterName) => {
        if (!Object.keys(OVERVIEW_CONFIG_PARAMETERS).includes(parameterName)) {
          return overview;
        }

        const friendlyName = OVERVIEW_CONFIG_PARAMETERS[parameterName];

        return {
          ...overview,
          [friendlyName]: params[parameterName],
        };
      },
      Object.values(OVERVIEW_CONFIG_PARAMETERS).reduce((keys, key) => ({ ...keys, [key]: '' }), {})
    );
  }

  function normalizeConfigs(cfgs) {
    return cfgs.map(({ parameters, ...config }) => ({
      ...config,
      details: parameters.reduce(
        (params, { parameterName, parameterValue }) => {
          if (!Object.keys(OVERVIEW_CONFIG_PARAMETERS).includes(parameterName)) {
            return params;
          }

          const friendlyName = OVERVIEW_CONFIG_PARAMETERS[parameterName];

          return {
            ...params,
            [friendlyName]: parameterValue,
          };
        },
        Object.values(OVERVIEW_CONFIG_PARAMETERS).reduce((keys, key) => ({ ...keys, [key]: '' }), {})
      ),
    }));
  }

  async function getParameters() {
    // in this case, we already have the triggered config available
    if (jsonConfig) {
      configs = [{ details: normalizeParameters(jsonConfig) }];
      return;
    }

    loading = true;

    try {
      const rawConfigs = await getFinalParameters({
        configId,
        configOwnerId: entityId,
        configOwnerType: entityType,
        isDefaultConfig: isDefault,
      });

      configs = normalizeConfigs(entityType === 'group' ? [rawConfigs] : rawConfigs);

      if (!configs.length) {
        resultNotification = {
          hideCloseButton: true,
          kind: 'warning',
          title: 'No overview',
          subtitle:
            entityType === 'organization'
              ? 'An overview cannot be generated because there are no group/target build configurations within the selected organization.'
              : 'An overview cannot be generated because there are no parameters within the selected target.',
        };

        return;
      }
    } catch (error) {
      console.error('[BuildOverviewModal] Failed to get final build parameters!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to get build details! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  onMount(getParameters);
</script>

<Modal passiveModal modalHeading="Build Overview" bind:open on:close>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        hideCloseButton={resultNotification.hideCloseButton}
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    {#if configs.length}
      {#if configs.length === 1}
        <div class="intro">
          <h4>{entityName}</h4>
          <em>Target name: <strong>{targetName}</strong></em>
        </div>
        <div class="action-bar">
          <Button
            iconDescription="Copy to clipboard"
            icon={Copy16}
            kind="ghost"
            tooltipPosition="right"
            on:click={() => copy(JSON.stringify(configs[0].details, null, 2))}
          />
        </div>
        <div class="raw-view">
          <JsonView json={configs[0].details} />
        </div>
      {:else}
        <div class="intro">
          <h4>{entityName}</h4>
        </div>
        <Accordion class="custom-accordion">
          {#each configs as config}
            <AccordionItem>
              <div class="accordion-title" slot="title">
                <div class="config-name">
                  <h5>{config.name}</h5>
                  {#if config.isDefault}
                    <TooltipDefinition tooltipText={`This is the default build target for ${config.ownerName}.`} align="start">
                      <em>(Default)</em>
                    </TooltipDefinition>
                  {/if}
                </div>
                <div>{config.ownerName}</div>
              </div>
              <div class="action-bar">
                <Button
                  iconDescription="Copy to clipboard"
                  icon={Copy16}
                  kind="ghost"
                  tooltipPosition="right"
                  on:click={() => copy(JSON.stringify(config.details, null, 2))}
                />
              </div>
              <div class="raw-view">
                <JsonView json={config.details} />
              </div>
            </AccordionItem>
          {/each}
        </Accordion>
      {/if}
    {/if}
  </LoadingSpinner>
</Modal>

<style>
  .intro {
    margin-bottom: 1rem;
  }

  .intro :global(h4) {
    border-left: 2px solid;
    padding-left: 1rem;
  }

  .intro :global(em) {
    padding-left: 1rem;
  }

  :global(.custom-accordion .bx--accordion__content) {
    padding-right: unset;
  }

  .accordion-title {
    display: flex;
    flex-direction: column;
  }

  .config-name {
    display: flex;
  }

  .config-name :global(em) {
    font-size: 11px;
    margin-left: 0.2rem;
  }

  .action-bar {
    height: 48px;
    background-color: var(--cds-layer-accent);
  }

  .raw-view {
    --nodePaddingLeft: 1.5rem; /* css variable for svelte-json-view indentation; only available under .raw-view container */
    padding: 1rem 2rem;
    border: 1px solid var(--cds-layer-accent);
  }

  .raw-view :global(.key) {
    color: var(--cds-text-01);
  }
</style>
