<script>
  import { format } from 'date-fns';
  import { InlineNotification, TextInput } from 'carbon-components-svelte';

  import WizardStep from '../wizard/WizardStep.svelte';
  import LicenseInfoAccordion from './LicenseInfoAccordion.svelte';

  const FILENAME_SUFFIX = '.lic';
  const customDestinations = [];

  export let generationDetails, licenseTemplate, features;

  function computeDestinations(details) {
    if (!details.length) {
      return;
    }

    const [{ skipUpload }] = details;

    if (skipUpload) {
      return;
    }

    const {
      uploadDestinations,
      licenseOwner: { sftp05Username, crushftpUsername },
    } = licenseTemplate;

    for (const { destination, path } of uploadDestinations) {
      const { 3: extractedUsername } = path.split('/');

      if (destination === 'sftp05' && extractedUsername !== sftp05Username) {
        customDestinations.push({ destination, extractedUsername });
      }

      if (destination === 'crushFTP' && extractedUsername !== crushftpUsername) {
        customDestinations.push({ destination, extractedUsername });
      }
    }
  }

  $: computeDestinations(generationDetails);
</script>

<WizardStep>
  <div class="generation-preview">
    <InlineNotification
      hideCloseButton
      lowContrast
      kind="info"
      title="Info:"
      subtitle={`Group users will ${generationDetails[1].shouldSkipNotification ? 'not' : ''} be notified via email for this generation.`}
    />
    {#if generationDetails[0].skipUpload}
      <InlineNotification
        hideCloseButton
        lowContrast
        kind="info"
        title="Info:"
        subtitle="License file will not be uploaded to selected destinations"
      />
    {/if}
    {#if customDestinations.length}
      <InlineNotification hideCloseButton lowContrast kind="warning">
        <div slot="title">
          More than one upload destinations are detected. Apart from the user you are creating the license for, the file will also be
          uploaded to the following users:
        </div>
        <div class="upload-warn" slot="subtitle">
          <ul class="user-list">
            {#each customDestinations as { destination, extractedUsername }}
              <li><strong>({destination}) {extractedUsername}</strong></li>
            {/each}
          </ul>
        </div>
      </InlineNotification>
    {/if}
    <TextInput
      readonly
      labelText="Expiration date"
      value={generationDetails[0].isPermanent ? 'No expiration' : format(new Date(generationDetails[0].date), 'MM-dd-yyyy')}
    />
    <TextInput readonly labelText="License filename" value={`${generationDetails[0].filename}${FILENAME_SUFFIX}`} />
  </div>
  <div class="license-preview">
    <h4 class="h4">License Details</h4>
    <LicenseInfoAccordion license={licenseTemplate} {features} />
  </div>
</WizardStep>

<style>
  .h4 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .license-preview {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  .generation-preview :global(.bx--form-item) {
    margin-top: 0.5rem;
  }

  .upload-warn :global(ul) {
    list-style: unset;
    margin-left: 1rem;
  }
</style>
