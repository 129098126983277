<script>
  import { Tooltip } from 'carbon-components-svelte';
  import View16 from 'carbon-icons-svelte/lib/View16';
  import startCase from 'lodash/startCase';

  export let triggerText = null,
    list = [];
</script>

{#if list.length}
  <li>
    <Tooltip {triggerText} direction="right" icon={View16}>
      <ul>
        {#each list as item}
          <li>
            {#if typeof item === 'object'}
              <ul class="inner-ul">
                {#each Object.entries(item) as [key, value]}
                  <li>
                    {startCase(key)}: {value}
                  </li>
                {/each}
              </ul>
            {:else}
              {item}
            {/if}
          </li>
        {/each}
      </ul>
    </Tooltip>
  </li>
{/if}

<style>
  .inner-ul {
    border-top: 1px solid var(--cds-active-01);
    border-bottom: 1px solid var(--cds-active-01);
    padding: 0.3rem;
    margin: 0.2rem;
  }
</style>
