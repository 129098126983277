<script>
  import { Button } from 'carbon-components-svelte';

  import { putGroupWorkbenchInstancePermissions } from '../../../../services';
  import { getServerErrorMessage } from '../../../../utils';

  export let fetchError,
    groupId,
    permissionId,
    permissionTableLoading,
    selectedInstances,
    dbWorkbenchInstancePermissions,
    groupWorkbenchInstancePermissions,
    instancePermissionsTableNotifications;

  let dbWorkbenchInstanceIds = [];
  let updatedWorkbenchInstanceIds = [];
  let created = [];
  let deleted = [];
  let updated = [];
  let createdAndUpdated = [];

  const handleSubmit = async () => {
    permissionTableLoading = true;
    try {
      const instanceDiffs = getInstanceDifferences();
      groupWorkbenchInstancePermissions = await putGroupWorkbenchInstancePermissions({
        ...instanceDiffs,
        permissionId,
        groupId,
      });
      instancePermissionsTableNotifications = { saved: true };
    } catch (error) {
      console.error('[SaveInstancePermissions] Failed to put group instance permissions!', error);
      fetchError = getServerErrorMessage(error) || 'Failed to save group instance permissions.';
    } finally {
      permissionTableLoading = false;
    }
  };

  const getInstancePermissionDifferences = () => {
    const instancePermissionDiffs = {};
    createdAndUpdated.forEach((workbenchInstanceId) => {
      const { instancePermissions } = selectedInstances.find(({ id }) => id === workbenchInstanceId);
      const previousPermission = dbWorkbenchInstancePermissions[workbenchInstanceId];
      const previousPermissionIds = previousPermission
        ? previousPermission.instancePermissions.map(({ instancePermissionId }) => instancePermissionId)
        : [];
      const changedInstancePermissions = instancePermissions.filter(({ checked }) => checked);
      const changedInstancePermissionIds = changedInstancePermissions.map(({ id }) => id);

      if (
        changedInstancePermissionIds.length === previousPermissionIds.length &&
        changedInstancePermissionIds.every((id) => previousPermissionIds.includes(id))
      ) {
        instancePermissionDiffs[workbenchInstanceId] = null;
      } else {
        instancePermissionDiffs[workbenchInstanceId] = {
          previous: previousPermissionIds.map((instancePermissionId) => instancePermissions.find(({ id }) => id === instancePermissionId)),
          change: changedInstancePermissions,
        };
      }
    });
    return instancePermissionDiffs;
  };

  const getUpdatedWorkbenchInstance = (workbenchInstancePermissionId, instancePermissionDiffs) => {
    const selectedInstance = selectedInstances.find(({ id }) => id === workbenchInstancePermissionId);
    return {
      workbenchInstanceId: selectedInstance.workbenchInstanceId,
      workbenchInstanceName: selectedInstance.workbenchInstanceName,
      instancePermissionDiffs: instancePermissionDiffs[workbenchInstancePermissionId],
    };
  };

  const getInstanceDifferences = () => {
    const instancePermissionDiffs = getInstancePermissionDifferences();

    return {
      created: created.reduce((a, id) => {
        return {
          ...a,
          [id]: getUpdatedWorkbenchInstance(id, instancePermissionDiffs),
        };
      }, {}),
      updated: updated.reduce((a, id) => {
        return instancePermissionDiffs[id]
          ? {
              ...a,
              [id]: getUpdatedWorkbenchInstance(id, instancePermissionDiffs),
            }
          : a;
      }, {}),
      deleted: deleted.reduce((a, id) => ({ ...a, [id]: dbWorkbenchInstancePermissions[id].workbenchInstanceName }), {}),
    };
  };

  $: updatedWorkbenchInstanceIds = selectedInstances.map(({ id }) => id);
  $: dbWorkbenchInstanceIds = Object.keys(dbWorkbenchInstancePermissions);
  $: deleted = dbWorkbenchInstanceIds.filter((i) => !updatedWorkbenchInstanceIds.includes(i));
  $: created = updatedWorkbenchInstanceIds.filter((i) => !dbWorkbenchInstanceIds.includes(i));
  $: updated = updatedWorkbenchInstanceIds.filter((i) => dbWorkbenchInstanceIds.includes(i));
  $: createdAndUpdated = created.concat(updated);
</script>

<div class="save-button">
  <Button on:click={handleSubmit}>Save</Button>
</div>

<style>
  .save-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
</style>
