<script>
  import { createEventDispatcher } from 'svelte';
  import { TextArea } from 'carbon-components-svelte';

  import LicenseBasicInfoPreview from './LicenseBasicInfoPreview.svelte';
  import SelectedFeatureList from './SelectedFeatureList.svelte';
  import WizardStep from '../wizard/WizardStep.svelte';

  export let data, knownValues;

  let note = '';

  const dispatch = createEventDispatcher();

  function submitStepChanges(stepIndex) {
    dispatch('step-complete', { stepIndex, values: { note } });
  }

  $: [basicLicenseInfo, licenseFeatures] = data;
</script>

<WizardStep onBeforeFinish={submitStepChanges}>
  <div class="review-step">
    {#if basicLicenseInfo}
      <LicenseBasicInfoPreview info={basicLicenseInfo} />
    {/if}
    {#if licenseFeatures && knownValues.features?.length}
      <div class="feature-info">
        <span class="bx--label">Selected features</span>
        <SelectedFeatureList treeData={knownValues.features} checkedItemIds={licenseFeatures} />
      </div>
    {/if}
  </div>
  <TextArea bind:value={note} labelText="Note" placeholder="Enter general note..." />
</WizardStep>

<style>
  .review-step {
    display: flex;
    width: 100%;
  }

  .feature-info {
    flex: 1;
    margin-left: 1rem;
    max-height: 400px;
    overflow-y: auto;
  }
</style>
