<script>
  import { Button } from 'carbon-components-svelte';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';

  import { ComboBox } from '@mst-fe/carbon-components-svelte';

  export let availableRoutes, initialRoutes, setRoutes, validationError;

  const stringifyRoutes = (routes) => routes?.map(({ method, route }) => `${method}: ${route}`);
  let pickedRoutes = stringifyRoutes(initialRoutes) ?? [];

  const addRoute = () => {
    pickedRoutes = [...pickedRoutes, ''];
  };

  const deleteRoute = (deletedIndex) => {
    pickedRoutes = pickedRoutes.filter((route, index) => deletedIndex !== index);
  };

  const itemFiltering = ({ id: route }, value) => {
    return !pickedRoutes.includes(route) && route.includes(value);
  };

  $: stringifiedAvailableRoutes = stringifyRoutes(availableRoutes);
  $: convertedPickedRoutes = [...new Set(pickedRoutes)].filter(Boolean).map((pickedRoute) => {
    const [method, route] = pickedRoute.split(': ');
    return { method, route };
  });

  $: setRoutes('routes', convertedPickedRoutes);
</script>

{#if validationError}
  <div class="validation-error">{validationError}</div>
{/if}
{#each pickedRoutes as selectedRoute, i}
  <div class="route-row">
    <div class="route-select-wrapper">
      <ComboBox
        direction="top"
        items={stringifiedAvailableRoutes.map((convertedRoute) => ({ id: convertedRoute, text: convertedRoute }))}
        placeholder="Pick route"
        shouldFilterItem={itemFiltering}
        selectedId={selectedRoute}
        required
        on:clear={() => {
          pickedRoutes[i] = null;
        }}
        on:select={({ detail }) => {
          pickedRoutes[i] = detail?.selectedId;
        }}
      />
    </div>

    <Button
      class="delete-button-inline"
      kind="danger"
      size="field"
      icon={TrashCan16}
      iconDescription="Delete"
      on:click={() => deleteRoute(i)}
    />
  </div>
{/each}
<Button class="add-route-button-margin" size="field" on:click={addRoute}>Add route</Button>

<style>
  .route-row {
    display: flex;
    margin: 1rem 0;
  }

  .route-select-wrapper {
    width: calc(100% - 4rem);
    margin-right: 1rem;
  }

  .validation-error {
    color: var(--cds-text-error, #da1e28);
    font-size: var(--cds-caption-01-font-size, 0.75rem);
  }

  :global(.add-route-button-margin) {
    margin-top: 1rem;
  }
</style>
