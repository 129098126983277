<script>
  import { createEventDispatcher } from 'svelte';
  import * as yup from 'yup';

  import { InlineNotification, Modal, FormGroup } from 'carbon-components-svelte';
  import { Select, SelectItem, TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';
  import { addGroupAwsPrincipal } from '../../services';

  export let groupId;
  export let open = false;

  let formSubmitButtonRef;
  let loading = false;
  let resultNotification;

  const PRINCIPAL_TYPES = ['root', 'user', 'role'];
  const initialValues = { principalType: PRINCIPAL_TYPES[0] };
  const dispatch = createEventDispatcher();

  async function onFormSubmit({ detail: { values } }) {
    resultNotification = null;
    loading = true;
    try {
      await addGroupAwsPrincipal(groupId, values);

      loading = false;
      dispatch('submit');
      dispatch('close');
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? 'Verify your submission and try again!';
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to add cloud principal to group! ${errorMessage}`,
      };
      loading = false;
    }
  }

  function handleCustomInputChange(field, set) {
    return function onInputChange(event) {
      const { detail: value } = event;
      set(field, value);
    };
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  $: schema = yup.object().shape({
    account: yup
      .string()
      .matches(/^\d{12}$/, 'A 12-digit AWS account number is required.')
      .required('AWS account number is required.'),
    principalType: yup.mixed().oneOf(PRINCIPAL_TYPES).required('A principal type is required.'),
    principalIdentifier: yup.string().when('principalType', {
      is: 'root',
      then: yup.string().strip(),
      otherwise: yup.string().required('Principal identifier is required.'),
    }),
  });
</script>

<Modal
  hasScrollingContent={false}
  modalHeading="Add AWS Principal to Group"
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  size="lg"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  {#if resultNotification}
    <InlineNotification
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  <Form
    validateOnBlur={false}
    validateOnChange={false}
    {initialValues}
    {schema}
    let:submitForm
    let:errors
    let:setValue
    let:touched
    let:values
    on:submit={onFormSubmit}
  >
    <FormGroup>
      <TextInput
        required
        name="account"
        labelText="Account Number"
        placeholder="Account Number"
        value={values.account}
        invalid={touched.account && !!errors.account}
        invalidText={errors.account}
        on:change={handleCustomInputChange('account', setValue)}
      />
      <Select
        required
        name="principalType"
        labelText="Principal Type"
        selected={values.principalType}
        on:change={handleCustomInputChange('principalType', setValue)}
      >
        {#each PRINCIPAL_TYPES as type}
          <SelectItem value={type} text={type} />
        {/each}
      </Select>
      <div class:hidden={values.principalType === 'root'}>
        <TextInput
          required
          name="principalIdentifier"
          labelText="Principal Identifier"
          placeholder="Principal Identifier"
          value={values.principalIdentifier}
          invalid={touched.principalIdentifier && !!errors.principalIdentifier}
          invalidText={errors.principalIdentifier}
          on:change={handleCustomInputChange('principalIdentifier', setValue)}
        />
      </div>
    </FormGroup>
    <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
  </Form>
</Modal>

<style>
  .hidden {
    display: none;
  }
</style>
