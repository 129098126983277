<script>
  import { createEventDispatcher } from 'svelte';
  import { useNavigate } from 'svelte-navigator';
  import { InlineNotification, NotificationActionButton } from 'carbon-components-svelte';

  import FeaturesTreeSelect from './FeaturesTreeSelect.svelte';
  import SelectedFeatureList from './SelectedFeatureList.svelte';
  import WizardStep from '../wizard/WizardStep.svelte';

  export let data = [],
    knownFeatures;

  let checkedItemIds = data;

  const dispatch = createEventDispatcher();
  const navigate = useNavigate();

  function submitStepChanges(stepIndex) {
    dispatch('step-complete', { stepIndex, values: checkedItemIds });
  }

  $: hasSelections = !!checkedItemIds.length;
</script>

<WizardStep nextButtonActive={hasSelections} onBeforeForward={submitStepChanges}>
  {#if knownFeatures.length}
    <span class="bx--label">Available features</span>
    <div class="features">
      <div class="tree">
        <FeaturesTreeSelect treeData={knownFeatures} bind:checkedItemIds />
      </div>
      <div class="selections">
        <SelectedFeatureList treeData={knownFeatures} {checkedItemIds} />
      </div>
    </div>
  {:else}
    <InlineNotification
      hideCloseButton
      lowContrast
      kind="warning"
      title="Warning:"
      subtitle="No available features to create license template."
    >
      <svelte:fragment slot="actions">
        <NotificationActionButton on:click={() => navigate('/licenses#features-and-groups')}>Create feature</NotificationActionButton>
      </svelte:fragment>
    </InlineNotification>
  {/if}
</WizardStep>

<style>
  .features {
    display: flex;
  }

  .tree {
    flex-grow: 2;
  }

  .selections {
    flex-grow: 1;
  }
</style>
