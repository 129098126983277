<script>
  import SearchOutputPage from './SearchOutputPage.svelte';

  export let overlayHidden;

  // Prevents rendering of SearchOutputPage component on app reload
  let renderOutputPage;

  $: if (!overlayHidden) {
    renderOutputPage = true;
  }
</script>

{#if renderOutputPage}
  <div class={`wrapper ${overlayHidden ? 'hidden' : ''}`}>
    <SearchOutputPage />
  </div>
{/if}

<style>
  .wrapper {
    background-color: var(--cds-ui-background);
    height: calc(100vh - 6.5rem);
    overflow-y: auto;
    padding: 2rem;
    position: absolute;
    top: 5rem;
    width: calc(100vw - 4rem);
  }

  .hidden {
    display: none;
  }
</style>
