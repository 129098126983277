<script>
  import { onMount } from 'svelte';

  import {
    Button,
    ButtonSet,
    InlineNotification,
    NotificationActionButton,
    OverflowMenu,
    OverflowMenuItem,
    Toolbar,
    ToolbarContent,
  } from 'carbon-components-svelte';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import RoleArnValue from '../../components/RoleArnValue.svelte';
  import { getGroupAwsPrincipals, getGroup } from '../../services';

  import RemoveCloudPrincipalFromGroupModal from './RemoveCloudPrincipalFromGroupModal.svelte';
  import AddCloudPrincipalToGroupModal from './AddCloudPrincipalToGroupModal.svelte';
  import SyncPrivilegesModal from './SyncPrivilegesModal.svelte';

  export let groupId;

  let pageData = { loading: true };
  const query = { pageSize: 25, currentPage: 1 };
  let modals = {
    removeCloudPrincipalFromGroup: { open: false },
    addCloudPrincipalToGroup: { open: false },
    syncPrivileges: { open: false },
  };

  const notifications = {
    error: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load group cloud principals! Please try again later.',
    },
    noUsers: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no cloud principals in this group.',
      action: {
        text: 'Add principal to group',
        onClick: () => openModal('addCloudPrincipalToGroup'),
      },
    },
  };

  async function loadPageData() {
    pageData = { ...pageData, cloudPrincipals: [], group: null, loading: true };
    try {
      const group = await getGroup(groupId);
      const cloudPrincipals = await getGroupAwsPrincipals(groupId);
      pageData = { group, cloudPrincipals, loading: false, displayedNotification: !cloudPrincipals.length && 'noUsers' };
    } catch (error) {
      console.error('[GroupCloudPrincipals] Failed to load group cloud principals!', error);
      pageData = { loading: false, displayedNotification: 'error' };
    }
  }

  /**
   * @description Close a given modal by name. Since the modal components may forward the inner Carbon modal's "close"
   *    event multiple times, a single "toggle" method is more prone to unexpected behavior.
   * @param name
   */
  function closeModal(name) {
    modals = {
      ...modals,
      [name]: { ...modals[name], open: false },
    };
  }

  /**
   * @description Open a given modal by name, optionally passing an object of data to forward.
   * @param {string} name
   * @param {object?} forwardData
   */
  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  onMount(loadPageData);

  $: shouldShowSync = window.location.search.includes('showOldSync=1');
</script>

{#if notifications[pageData.displayedNotification]}
  <InlineNotification hideCloseButton lowContrast {...notifications[pageData.displayedNotification]}>
    <div slot="actions">
      {#if notifications[pageData.displayedNotification].action}
        <NotificationActionButton on:click={notifications[pageData.displayedNotification].action?.onClick}>
          {notifications[pageData.displayedNotification].action?.text}
        </NotificationActionButton>
        {#if shouldShowSync}
          <NotificationActionButton on:click={() => openModal('syncPrivileges')}>Sync permissions</NotificationActionButton>
        {/if}
      {/if}
    </div>
  </InlineNotification>
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'provider', value: 'Provider' },
        { key: 'account', value: 'Account' },
        { key: 'principalType', value: 'Principal Type' },
        { key: 'principalIdentifier', value: 'Principal Identifier' },
        { key: 'arn', value: 'ARN' },
        { key: 'actions', empty: true, sort: false },
      ]}
      rows={pageData.cloudPrincipals ?? []}
      sortable
      pageSize={query.pageSize}
      page={query.currentPage}
    >
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem
              danger
              text="Remove from group"
              on:click={() => openModal('removeCloudPrincipalFromGroup', { cloudPrincipal: row })}
            />
          </OverflowMenu>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>

      <Toolbar>
        <ToolbarContent>
          {#if pageData.group != null}
            {#if pageData.group.awsIamRoleName}
              <RoleArnValue roleName={pageData.group.awsIamRoleName} />
            {:else}
              <div class="note">
                <em>Role name will be generated on first sync.</em>
              </div>
            {/if}
            <ButtonSet>
              {#if shouldShowSync}
                <Button kind="secondary" on:click={() => openModal('syncPrivileges')}>Sync permissions</Button>
              {/if}
              <Button on:click={() => openModal('addCloudPrincipalToGroup')}>Add cloud principal</Button>
            </ButtonSet>
          {:else}
            <Button on:click={() => openModal('addCloudPrincipalToGroup')}>Add cloud principal</Button>
          {/if}
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:pageSize={query.pageSize}
      bind:page={query.currentPage}
      pageSizes={[10, 25, 50]}
      totalItems={pageData.users?.length}
    />
  </LoadingSpinner>
{/if}

{#if modals.removeCloudPrincipalFromGroup.open}
  <RemoveCloudPrincipalFromGroupModal
    open
    cloudPrincipal={modals.removeCloudPrincipalFromGroup.cloudPrincipal}
    on:close={() => closeModal('removeCloudPrincipalFromGroup')}
    on:submit={loadPageData}
  />
{/if}

{#if modals.addCloudPrincipalToGroup.open}
  <AddCloudPrincipalToGroupModal open {groupId} on:close={() => closeModal('addCloudPrincipalToGroup')} on:submit={loadPageData} />
{/if}

{#if modals.syncPrivileges.open}
  <SyncPrivilegesModal open {groupId} on:close={() => closeModal('syncPrivileges')} />
{/if}

<style>
  .note em {
    font-size: 12px;
    color: var(--cds-text-helper);
  }

  .action-cell :global(.bx--overflow-menu-options) {
    min-width: 11rem;
  }
</style>
