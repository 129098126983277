<script>
  import { onMount } from 'svelte';
  import { InlineNotification, Select, SelectItem } from 'carbon-components-svelte';

  import EmailTemplateBoard from '../../components/emails/EmailTemplateBoard.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getEmailTemplateCategories, changeCategoryDefault } from '../../services';

  let categories = [],
    loading = false,
    resultNotification = null,
    selectedCategoryId = null,
    defaultTemplateId = null;

  async function fetchEmailTemplateCategories() {
    loading = true;

    try {
      categories = await getEmailTemplateCategories();

      if (!categories.length) {
        resultNotification = {
          kind: 'warning',
          title: 'Warning:',
          subtitle: 'No email categories defined in the system.',
        };
      } else {
        [{ id: selectedCategoryId }] = categories;
      }
    } catch (error) {
      console.error('[EmailTemplates] Failed to get email template categories!', error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to get email template categories! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  async function handleDefaultEmailTemplateChange({ detail }) {
    loading = true;

    try {
      await changeCategoryDefault(selectedCategoryId, detail);
      defaultTemplateId = detail.id;
    } catch (error) {
      console.error('[EmailTemplates] Failed to change default email template!', error);
    } finally {
      loading = false;
    }
  }

  onMount(fetchEmailTemplateCategories);

  $: defaultTemplateId = categories.find((category) => category.id === selectedCategoryId)?.default ?? '';
</script>

<LoadingSpinner {loading}>
  {#if resultNotification}
    <InlineNotification
      hideCloseButton
      lowContrast
      kind={resultNotification.kind}
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  {#if !loading && categories.length}
    <Select labelText="Email categories" bind:selected={selectedCategoryId}>
      {#each categories as { id, name }}
        <SelectItem value={id} text={name} />
      {/each}
    </Select>
    <EmailTemplateBoard {selectedCategoryId} {defaultTemplateId} on:default={handleDefaultEmailTemplateChange} />
  {/if}
</LoadingSpinner>
