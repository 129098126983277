<script>
  import { onMount } from 'svelte';

  import Box32 from 'carbon-icons-svelte/lib/Box32';

  import { Button, OverflowMenu, OverflowMenuItem, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import CustomInlineNotification from '../../components/CustomInlineNotification.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import StatusIcon from '../../components/StatusIcon.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';
  import WbPlanDetails from '../../components/organizations/WbPlanDetails.svelte';
  import WbPlanEditModal from '../../components/organizations/WbPlanEditModal.svelte';
  import WbPlanEditLimitsModal from '../../components/organizations/WbPlanEditLimitsModal.svelte';
  import WbPlanModal from '../../components/organizations/WbPlanModal.svelte';

  import { getOrganizationWbPlans } from '../../services';
  import { convertToLocalDisplayTime, rowContainsText } from '../../utils';

  export let organizationId, organizationName;

  let tableData = [],
    pageData = {
      loading: false,
      searchText: '',
      activeNotifications: new Set(),
    },
    modals = {
      createPlan: { open: false },
      editPlan: { open: false },
      editLimits: { open: false },
    };

  const notifications = {
    noPlans: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no workbench plans for this organization.',
      actions: [
        {
          text: 'Create subscription plan',
          onClick: handleClickForModal('createPlan'),
        },
      ],
      extraActions: [],
    },
    errorPlans: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load list of workbench plans! Please try again later.',
    },
  };

  const tableHeaders = [
    { key: 'startDate', value: 'Start Date' },
    {
      key: 'purchasedResourcesTotal',
      value: 'Total Purchased Resources',
      display: (_, row) => `$${calculateTotalSelections(row.awsResources)}`,
    },
    { key: 'active', value: 'Active' },
    { key: 'createdAt', value: 'Created At', display: convertToLocalDisplayTime },
    { key: 'createdByDisplayName', value: 'Created By' },
    { key: 'actions', empty: true, sort: false },
  ];

  async function fetchDependencies() {
    pageData = { ...pageData, loading: true };

    try {
      tableData = await getOrganizationWbPlans(organizationId);

      if (!tableData.length) {
        pageData.activeNotifications.add('noPlans');
      }
    } catch (error) {
      console.error('[OrganizationWbPlanList] Failed to retrieve wb plans', error);
      pageData.activeNotifications.add('errorPlans');
    } finally {
      pageData = { ...pageData, loading: false };
    }
  }

  function calculateTotalSelections(resources) {
    return resources.reduce((all, resource) => all + resource.value * resource.unitCharge, 0);
  }

  function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'create-plan':
        pageData.activeNotifications.delete('noPlans');
        pageData = { ...pageData };
      // eslint-disable-next-line no-fallthrough
      case 'edit-plan':
      case 'edit-limits':
        tableData = [...data];
        break;

      default:
        console.warn(`[OrganizationWbPlanList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function handleClickForModal(name, forwardData = {}) {
    return function openModal() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  function onSearchTextChange(e) {
    pageData = {
      ...pageData,
      searchText: e?.detail ?? '',
    };
  }

  onMount(fetchDependencies);

  $: filteredRows = pageData.searchText ? tableData.filter((param) => rowContainsText(param, pageData.searchText)) : tableData;
</script>

<LoadingSpinner loading={pageData.loading}>
  {#each Object.keys(notifications) as notification}
    {#if pageData.activeNotifications.has(notification)}
      <div class="custom-notification">
        <CustomInlineNotification {...notifications[notification]} />
      </div>
    {/if}
  {/each}
  {#if tableData.length}
    <DataTable headers={tableHeaders} rows={filteredRows} sortable expandable>
      <svelte:fragment slot="expanded-row" let:row>
        <WbPlanDetails plan={row} />
      </svelte:fragment>
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem
              text={`${row.awsLoginResources.length ? 'Edit' : 'Create'} User Limits`}
              on:click={handleClickForModal('editLimits', { plan: row })}
            />
            <OverflowMenuItem
              class={!row.active ? 'activate' : ''}
              danger={row.active}
              text={`${row.active ? 'Deactivate' : 'Activate'} Plan`}
              on:click={handleClickForModal('editPlan', { plan: { startDate: row.startDate, active: row.active, id: row.id } })}
            />
          </OverflowMenu>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else if cell.key === 'active'}
          <StatusIcon valid={cell.value} />
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar class="table-toolbar">
        {#if pageData.loading}
          <div class="data-table-spinner">
            <LoadingSpinner small withOverlay={false} loading={pageData.loading} />
          </div>
        {/if}
        <ToolbarContent>
          <ToolbarSearchWithRouting
            placeholder="Search for plans"
            persistent
            bind:searchText={pageData.searchText}
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          <Button on:click={handleClickForModal('createPlan')}>Create subscription plan</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    {#if pageData.searchText && !filteredRows.length}
      <div class="empty-table">
        <div class="icon">
          <Box32 />
        </div>
        <em>No results for your search</em>
      </div>
    {/if}
  {/if}
</LoadingSpinner>
{#if modals.createPlan.open}
  <WbPlanModal
    open
    {organizationId}
    {organizationName}
    on:close={({ detail: data }) => closeModal('createPlan', { action: 'create-plan', data })}
  />
{/if}
{#if modals.editPlan.open}
  <WbPlanEditModal
    open
    plan={modals.editPlan.plan}
    {organizationName}
    on:close={({ detail: data }) => closeModal('editPlan', { action: 'edit-plan', data })}
  />
{/if}
{#if modals.editLimits.open}
  <WbPlanEditLimitsModal
    open
    {organizationId}
    {organizationName}
    plan={modals.editLimits.plan}
    on:close={({ detail: data }) => closeModal('editLimits', { action: 'edit-limits', data })}
  />
{/if}

<style>
  .action-cell :global(li.activate button:hover) {
    background-color: #24a148;
    color: var(--cds-text-04);
    transition: filter 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9),
      border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  :global(html[theme='g90']) .action-cell :global(li.activate button:hover) {
    background-color: var(--cds-inverse-support-02);
  }

  .empty-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
    user-select: none;
    background-color: var(--cds-layer);
    padding: 2rem 0;
  }

  .empty-table .icon {
    padding: 0.35rem;
  }
</style>
