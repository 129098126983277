<script>
  import { DataTable, Link, Tooltip } from 'carbon-components-svelte';
  import truncate from 'lodash/truncate';
  import { useNavigate } from 'svelte-navigator';

  import SshKeyAssociationStatus from '../../components/ssh-keys/SshKeyAssociationStatus.svelte';
  import { updateKeys } from '../../stores';

  export let title, headers, routeGenerator, data;

  const navigate = useNavigate();

  $: dataWithId = data?.map((item, id) => (item.id ? item : { ...item, id }));

  const viewEntityDetails = (event, row) => {
    event.preventDefault();

    navigate(routeGenerator(row));
    updateKeys({ overlayHidden: true });
  };
</script>

{#if data && data.length}
  <div class="entity-wrapper">
    <h4>{title}</h4>
    <DataTable {headers} rows={dataWithId} size="compact" stickyHeader={data.length > 7}>
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          <Link href={routeGenerator(row)} on:click={(event) => viewEntityDetails(event, row)}>View details</Link>
        {:else if cell.key === 'publicKey'}
          <div class="public-key-tooltip-wrapper">
            <Tooltip hideIcon tooltipBodyId={`tooltip-body-${row.id}`} triggerText={truncate(cell.value)}>
              <p id={`tooltip-body-${row.id}`}>{cell.value}</p>
            </Tooltip>
          </div>
        {:else if cell.key === 'sftp05Access'}
          <SshKeyAssociationStatus dataServiceAssociations={row.dataServiceAssociations} ownerType={row.ownerType} serviceName="sftp05" />
        {:else if cell.key === 'crushftpAccess'}
          <SshKeyAssociationStatus dataServiceAssociations={row.dataServiceAssociations} ownerType={row.ownerType} serviceName="crushftp" />
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
    </DataTable>
  </div>
{/if}

<style>
  .entity-wrapper {
    padding-top: 2rem;
  }

  .public-key-tooltip-wrapper {
    cursor: pointer;
  }
  .public-key-tooltip-wrapper :global(.bx--tooltip__label) {
    font-size: 0.875rem;
  }
</style>
