<script>
  import { onMount } from 'svelte';

  import { FormGroup, InlineNotification, MultiSelect, RadioButton, RadioButtonGroup, TextArea } from 'carbon-components-svelte';

  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import { ComboBox, TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { createUser, getOrganization, getOrganizations } from '../../services';
  import { rowContainsText } from '../../utils';

  let formSubmitMessage;
  let groupOptions = [];
  let groupOptionsLoading = false;
  let pageData = { loading: true, organizations: [] };
  let selectedOrganizationId = '';
  let sendInvitationEmail = false;

  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email address!').required("Enter the user's email address!"),
    groupIds: yup.array(),
    name: yup.string().required("Enter the user's full name!"),
    notes: yup.string(),
    lsegUuid: yup.string(),
    organizationId: yup.string().required('Choose an organization!'),
    sendInvitationEmail: yup.boolean().typeError('Select an invitation preference!').required(),
    id: yup.string().uuid().nullable(),
  });

  onMount(async () => {
    try {
      const organizations = (await getOrganizations()).map((org) => ({ ...org, text: org.name }));
      pageData = { organizations, loading: false };
    } catch (error) {
      console.error('[CreateUser] Failed to retrieve organizations!', error);
      pageData = { loading: false, error };
    }
  });

  function onChangeSendInvitationEmail({ detail: nextValue }, setFormValue) {
    sendInvitationEmail = nextValue;
    setFormValue('sendInvitationEmail', nextValue);
  }

  function onClearOrganization(setFormValue) {
    groupOptions = [];
    selectedOrganizationId = '';
    setFormValue('organizationId', '');
    setFormValue('groupIds', []);
  }

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    formSubmitMessage = undefined;

    try {
      const { id } = await createUser(values);

      formSubmitMessage = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'User created! Redirecting...',
      };
      setTimeout(() => navigate(`/user/${id}`), 2500);
    } catch (error) {
      console.error('[CreateUser] Failed to create user!', error);

      const errorMessage = error.response?.data?.message ?? 'Verify your submission and try again.';
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to create user! ${errorMessage}`,
      };
      setSubmitting(false);
    }
  }

  async function onSelectOrganization({ detail: { selectedId } }, setFormValue) {
    if (!selectedId) {
      return;
    }
    groupOptions = [];
    groupOptionsLoading = true;
    try {
      const { groups } = await getOrganization(selectedId);
      groupOptions = groups.map((group) => ({ id: group.id, text: group.name }));
    } catch (error) {
      console.error('[CreateUser] Failed to retrieve organization groups!', error);
    } finally {
      groupOptionsLoading = false;
    }

    selectedOrganizationId = selectedId;
    setFormValue('organizationId', selectedId);
    setFormValue('groupIds', []);
  }

  function shouldFilterOrganization(organization, value) {
    if (!value || value.length < 2) {
      return true;
    }

    return rowContainsText(organization, value);
  }

  $: groupSelectionDisabled = !selectedOrganizationId || groupOptions.length === 0;
  $: groupSelectionHelperText = (() => {
    if (groupOptionsLoading) {
      return undefined;
    }
    if (!selectedOrganizationId) {
      return 'Group selection is unavailable because no organization is selected.';
    }
    if (groupOptions.length === 0) {
      return 'Group selection is unavailable because this organization has no groups.';
    }
  })();

  $: userTypeHelperText = (() =>
    sendInvitationEmail
      ? `
        An invitation email will be sent to the user.
        If the user does not register within 30 days, their invitation will need to be re-sent.
      `
      : `
        An invitation email will NOT be sent to the user.
        The user can be granted login permissions by sending an invitation later.
      `)();
</script>

{#if pageData.loading}
  <LoadingSpinner withOverlay={false} />
{:else if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load organizations! Please try again later."
  />
{:else}
  {#if formSubmitMessage}
    <InlineNotification kind={formSubmitMessage.kind} lowContrast title={formSubmitMessage.title} subtitle={formSubmitMessage.subtitle} />
  {/if}
  <Form
    fieldDomString="input,textarea,select,div[name='organizationId'],div[name='groupIds'],div[name='sendInvitationEmail']"
    initialValues={{ id: null, name: '', groupIds: [], sendInvitationEmail: false }}
    validateOnBlur={true}
    validateOnChange={true}
    {schema}
    on:submit={onFormSubmit}
    let:submitForm
    let:isSubmitting
    let:setValue
    let:errors
    let:touched
    let:values
  >
    <FormGroup>
      <TextInput
        autocomplete="off"
        invalid={touched.email && !!errors.email}
        invalidText={errors.email}
        labelText="Email"
        name="email"
        placeholder="Email"
        type="text"
        value={values.email}
        required
        on:change={({ detail: text }) => setValue('email', text)}
      />
      <TextInput
        helperText="The value of this field will be visible to the user on the Customer Portal."
        invalid={touched.name && !!errors.name}
        invalidText={errors.name}
        labelText="Full Name"
        name="name"
        placeholder="Full name"
        required
        type="text"
        value={values.name}
        on:change={({ detail: text }) => setValue('name', text)}
      />
      <TextInput
        helperText="This value provides a link between MACE and LSEG's AAA/CARE authentication system; only modify this value if you know what you're doing!"
        invalid={touched.lsegUuid && !!errors.lsegUuid}
        invalidText={errors.lsegUuid}
        labelText="LSEG AAA/CARE/CIAM UUID"
        name="name"
        placeholder="UUID"
        type="text"
        value={values.lsegUuid}
        on:change={({ detail: text }) => setValue('lsegUuid', text)}
      />
      <TextArea
        invalid={touched.notes && !!errors.notes}
        invalidText={errors.notes}
        labelText="Notes"
        name="notes"
        placeholder="Additional notes"
        type="text"
        value={values.notes}
        on:change={({ target }) => setValue('notes', target.value)}
      />
      <div class="bx--form-item combobox-wrapper">
        <ComboBox
          invalid={touched.organizationId && !!errors.organizationId}
          invalidText={errors.organizationId}
          items={pageData.organizations}
          name="organizationId"
          placeholder="Organization name"
          required
          selectedId={selectedOrganizationId}
          shouldFilterItem={shouldFilterOrganization}
          titleText="Organization"
          on:clear={() => onClearOrganization(setValue)}
          on:select={(event) => onSelectOrganization(event, setValue)}
        />
      </div>
      <MultiSelect
        disabled={groupSelectionDisabled}
        filterable
        helperText={groupSelectionHelperText}
        items={groupOptions}
        name="groupIds"
        placeholder="Groups"
        spellcheck="false"
        titleText="Groups"
        on:select={({ detail: { selectedIds } }) => setValue('groupIds', selectedIds)}
      />
      {#if groupOptionsLoading}
        <div class="group-loading-indicator">
          <LoadingSpinner centered={false} compact withOverlay={false}>
            <span class="bx--form__helper-text">Loading groups...</span>
          </LoadingSpinner>
        </div>
      {/if}
      <TextInput
        autocomplete="off"
        invalid={touched.id && !!errors.id}
        invalidText={errors.id}
        labelText="UUID"
        name="id"
        helperText="For HPQ users a custom UUID v5 is expected. Ignore for every other case."
        placeholder="User ID"
        type="text"
        value={values.id}
        on:change={({ detail: text }) => setValue('id', text)}
      />
      <RadioButtonGroup
        legendText="Send Invitation Email"
        name="sendInvitationEmail"
        bind:selected={sendInvitationEmail}
        on:change={(event) => onChangeSendInvitationEmail(event, setValue)}
      >
        <RadioButton labelText="Yes" value={true} />
        <RadioButton labelText="No" value={false} />
      </RadioButtonGroup>
      <span class="radio-group-helper-text bx--form__helper-text">{userTypeHelperText}</span>
    </FormGroup>
    <div class="form-controls">
      <LoadingButton kind="primary" on:click={submitForm} disabled={isSubmitting} isLoading={isSubmitting}>Save</LoadingButton>
    </div>
  </Form>
{/if}

<style>
  .combobox-wrapper :global(.bx--list-box__wrapper) {
    width: 100%;
  }

  .group-loading-indicator :global(.loading-container) {
    align-items: center;
    display: flex;
    padding-top: 0.375rem;
  }

  .group-loading-indicator :global(.bx--form__helper-text) {
    margin-top: 0;
    padding-right: 0.25rem;
    width: auto;
  }

  .radio-group-helper-text {
    display: block;
    max-height: none;
    overflow: visible;
  }
</style>
