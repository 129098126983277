<script>
  import { createEventDispatcher } from 'svelte';

  import Locked from 'carbon-icons-svelte/lib/Locked16';
  import Unlocked from 'carbon-icons-svelte/lib/Unlocked16';

  import { Button } from 'carbon-components-svelte';

  import DroppableBox from './DroppableBox.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { findNotMatchingIds } from '../tree-select/TreeSelect.svelte';

  export let locked, loading, features, groups, groupedFeatures, changes;

  let searchTerm = '',
    droppableBoxNotifications = {};

  const dispatch = createEventDispatcher();
  const Entities = {
    FEATURES: 'features',
    GROUPS: 'groups',
  };

  function findGroup(groupsToSearch, idToFind) {
    for (const group of groupsToSearch) {
      if (group.id === idToFind) {
        return group;
      }

      let matchedGroup = null;

      if (group.children) {
        matchedGroup = findGroup(group.children, idToFind);
      }

      if (matchedGroup) {
        return matchedGroup;
      }
    }
  }

  function itemAlreadyInDataset(items, idToFind) {
    if (items.some((item) => item.id === idToFind || (item.children && itemAlreadyInDataset(item.children, idToFind)))) {
      return true;
    }

    return false;
  }

  function getAllParents(items, id) {
    const parents = [];

    for (const item of items) {
      if (item.id === id) {
        parents.push(item.id);
        // eslint-disable-next-line no-continue
        continue;
      }

      if (item.children?.length) {
        const childs = getAllParents(item.children, id);
        if (childs.length) {
          parents.push(...childs, item.id);
        }
      }
    }

    return parents;
  }

  function featureAlreadyUnderFeedHandler(items, id) {
    let isAlreadyUnderAnother = false;

    for (const item of items) {
      if (!item.isFeedHandler || !item.children?.length) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (item.children?.find((child) => child.id === id)) {
        isAlreadyUnderAnother = true;
        break;
      }

      isAlreadyUnderAnother = featureAlreadyUnderFeedHandler(item.children, id);
    }

    return isAlreadyUnderAnother;
  }

  function copyItem(items, itemToHandle, details) {
    const { destinationItemId } = details;

    return items.reduce((all, item) => {
      let { children } = item;

      if (item.id === destinationItemId) {
        children = [itemToHandle, ...(children ?? [])];
      } else if (children?.length) {
        children = copyItem(children, itemToHandle, details);
      }

      return [...all, { ...item, children }];
    }, []);
  }

  function removeItem(items, details) {
    const { itemId, destinationItemId } = details;

    return items.reduce((all, item) => {
      if (!item.children) {
        return [...all, item];
      }

      let { children } = item;

      if (item.id === destinationItemId && children.some(({ id }) => id === itemId)) {
        children = children.filter(({ id }) => id !== itemId);
      } else {
        children = removeItem(children, details);
      }

      return [...all, { ...item, children }];
    }, []);
  }

  function handleItemChange({ detail }) {
    const {
      action,
      data: { destinationItemId, itemId, itemType },
    } = detail;

    const items = {
      [Entities.FEATURES]: features,
      [Entities.GROUPS]: groups,
    };

    const itemToHandle = {
      ...items[itemType].find(({ id }) => id === itemId),
      ...(itemType === Entities.GROUPS ? { children: findGroup(groupedFeatures, itemId)?.children ?? [] } : {}),
    };

    const destinationIsFeedHandler = groups.find((group) => group.id === destinationItemId)?.isFeedHandler;

    let oppositeAction = null;

    switch (action) {
      case 'copy':
        if (destinationIsFeedHandler && (itemType === Entities.GROUPS || !itemToHandle.isCustom)) {
          droppableBoxNotifications = { [destinationItemId]: 'notCustom' };
          return;
        }

        if (
          destinationIsFeedHandler &&
          itemType === Entities.FEATURES &&
          featureAlreadyUnderFeedHandler(groupedFeatures, itemToHandle.id)
        ) {
          droppableBoxNotifications = { [destinationItemId]: 'alreadyFeedHandler' };
          return;
        }

        if (itemType === Entities.GROUPS && getAllParents(groupedFeatures, destinationItemId).includes(itemToHandle.id)) {
          droppableBoxNotifications = { [destinationItemId]: 'parent' };
          return;
        }

        oppositeAction = 'remove';
        groupedFeatures = copyItem(groupedFeatures, itemToHandle, { destinationItemId });
        // if copy of group is also on first level, change should act as move operation
        if (itemType === Entities.GROUPS) {
          groupedFeatures = groupedFeatures.filter(({ id }) => id !== itemId);
        }
        break;

      case 'remove':
        oppositeAction = 'copy';
        groupedFeatures = removeItem(groupedFeatures, { itemId, destinationItemId });
        // if item not under other group, we should bring it to first level
        if (itemType === Entities.GROUPS && !itemAlreadyInDataset(groupedFeatures, itemId)) {
          groupedFeatures = [...groupedFeatures, itemToHandle];
        }
        break;

      default:
        console.warn(`[FeatureAndGroup] Unknown action "${action}" on item drop`, detail);
        break;
    }

    // decide if an action is opposite from an already recorded action (to undo)
    const changeIsCanceledOut = changes.some(
      ({ action: savedAction, data }) =>
        data.itemId === itemId &&
        data.itemType === itemType &&
        data.destinationItemId === destinationItemId &&
        savedAction === oppositeAction
    );

    changes = changeIsCanceledOut
      ? changes.filter(
          ({ action: savedAction, data }) =>
            !(
              data.itemId === itemId &&
              data.itemType === itemType &&
              data.destinationItemId === destinationItemId &&
              savedAction === oppositeAction
            )
        )
      : [...changes, detail];
  }

  function handleFiltering({ detail: searchText }) {
    searchTerm = searchText;
  }

  $: filteredIds = new Set(searchTerm.length ? findNotMatchingIds(groupedFeatures, searchTerm) : []);
</script>

<div class="board">
  <div class="header">
    <h2 class="h2">Grouped features</h2>
    <div class="action">
      {#if locked}
        <Button
          kind="ghost"
          size="small"
          icon={Locked}
          iconDescription="Unlock"
          tooltipPosition="right"
          tooltipAlignment="end"
          disabled={loading}
          on:click={() => dispatch('lock-action', false)}
        />
      {:else}
        <Button
          kind="ghost"
          size="small"
          icon={Unlocked}
          iconDescription="Lock"
          tooltipPosition="right"
          tooltipAlignment="end"
          disabled={loading}
          on:click={() => dispatch('lock-action', true)}
        />
      {/if}
    </div>
  </div>
  <LoadingSpinner loading={loading.groupedFeatures}>
    <DroppableBox
      {filteredIds}
      bind:notifications={droppableBoxNotifications}
      active={!locked}
      items={groupedFeatures}
      supportedTypes="features|groups"
      on:item-drop={handleItemChange}
      on:item-remove={handleItemChange}
      on:filter={handleFiltering}
    />
  </LoadingSpinner>
</div>

<style>
  .h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .board {
    flex: 2;
    margin-left: 1rem;
  }

  .board .header {
    display: flex;
  }

  .board .header .action {
    margin-left: 0.5rem;
  }
</style>
