<script>
  import { tick } from 'svelte';

  import { InlineNotification, Tile } from 'carbon-components-svelte';
  import { Router, Route } from 'svelte-navigator';

  import Layout from './Layout.svelte';
  import BasicGrid from './components/BasicGrid.svelte';
  import RouteHeader from './components/RouteHeader.svelte';
  import ActivityMonitor from './components/ActivityMonitor.svelte';

  import Home from './pages/Home.svelte';
  import AdminDashboardModule from './pages/admin/AdminDashboardModule.svelte';
  import CustomerBuilds from './pages/customer-builds/CustomerBuilds.svelte';
  import CreateOrganization from './pages/organizations/CreateOrganization.svelte';
  import Emails from './pages/emails/Emails.svelte';
  import OrganizationDetails from './pages/organizations/OrganizationDetails.svelte';
  import OrganizationList from './pages/organizations/OrganizationList.svelte';
  import CreateGroup from './pages/groups/CreateGroup.svelte';
  import CreateUser from './pages/users/CreateUser.svelte';
  import DataFeedModule from './pages/data-feeds/DataFeedModule.svelte';
  import DataFeedDetails from './pages/data-feeds/details/DataFeedDetails.svelte';
  import GroupDetails from './pages/groups/GroupDetails.svelte';
  import GroupList from './pages/groups/GroupList.svelte';
  import Licenses from './pages/licenses/Licenses.svelte';
  import Login from './pages/login/Login.svelte';
  import ResetPassword from './pages/login/ResetPassword.svelte';
  import SetNewPassword from './pages/login/SetNewPassword.svelte';
  import TaskModule from './pages/tasks/TaskModule.svelte';
  import UserDetails from './pages/users/UserDetails.svelte';
  import UserList from './pages/users/UserList.svelte';
  import WorkbenchModule from './pages/workbench/WorkbenchModule.svelte';

  import routes from './routes';
  import { appConfig, userSession } from './stores';

  const noSessionPathnames = ['/login', '/reset-password', '/set-new-password'];

  $: tick().then(async () => {
    if ($appConfig.loading || $appConfig.error || $userSession.loading || $userSession.error) {
      return;
    }

    const pathName = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname;

    if (!$userSession.session && !noSessionPathnames.includes(pathName)) {
      console.warn('[App] User does not have a valid session! Redirecting...');
      localStorage.setItem('auth_redirect', window.location.href);
      window.location.href = `${window.location.origin}/login`;
      return;
    }

    if ($userSession.session && noSessionPathnames.includes(pathName)) {
      console.warn('[App] User has already a valid session! Redirecting...');
      window.location.href = window.location.origin;
      return;
    }

    window.mst.closeLoader();
  });
</script>

<Router primary={false}>
  <ActivityMonitor />
  <Layout>
    {#if $appConfig.error || $userSession.error}
      <Tile>
        <InlineNotification kind="error" title="Error:" subtitle="The service is unavailable." lowContrast={true} hideCloseButton={true} />
      </Tile>
    {:else if !$appConfig.loading && !$userSession.loading}
      {#if !$userSession.session}
        <Route path={routes.login}>
          <Login />
        </Route>
        <Route path={routes.resetPassword}>
          <ResetPassword />
        </Route>
        <Route path={routes.setNewPassword}>
          <SetNewPassword />
        </Route>
      {:else}
        <RouteHeader />
        <BasicGrid>
          <Route path={routes.organizationCreate}>
            <CreateOrganization />
          </Route>
          <Route path={routes.organizationDetails} let:params>
            {#key params.organizationId}
              <OrganizationDetails organizationId={params.organizationId} />
            {/key}
          </Route>
          <Route path={routes.organizations}>
            <OrganizationList />
          </Route>
          <Route path={routes.groups}>
            <GroupList />
          </Route>
          <Route path={routes.groupCreate}>
            <CreateGroup />
          </Route>
          <Route path={routes.groupDetails} let:params>
            {#key params.groupId}
              <GroupDetails groupId={params.groupId} />
            {/key}
          </Route>
          <Route path={routes.tasks}>
            <TaskModule />
          </Route>
          <Route path={routes.customerBuilds}>
            <CustomerBuilds />
          </Route>
          <Route path={routes.userDetails} let:params>
            {#key params.userId}
              <UserDetails userId={params.userId} />
            {/key}
          </Route>
          <Route path={routes.users}>
            <UserList />
          </Route>
          <Route path={routes.userCreate}>
            <CreateUser />
          </Route>
          <Route path={routes.dataFeeds}>
            <DataFeedModule />
          </Route>
          <Route path={routes.dataFeedDetails} let:params>
            <DataFeedDetails dataFeedId={params.dataFeedId} />
          </Route>
          <Route path={routes.adminDashboard}>
            <AdminDashboardModule />
          </Route>
          <Route path={routes.licenses}>
            <Licenses />
          </Route>
          <Route path={routes.workbench}>
            <WorkbenchModule />
          </Route>
          <Route path={routes.emails}>
            <Emails />
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </BasicGrid>
      {/if}
    {/if}
  </Layout>
</Router>
