<script>
  import ChangedFeedDifference from '../../pages/groups/group-permissions/data-feeds/ChangedFeedDifference.svelte';

  export let eventData;
</script>

{#if eventData.created?.length}
  <h6>Added:</h6>
  {#each eventData.created as { name, startDate, endDate }}
    <div>{name}: {startDate} - {endDate}</div>
  {/each}
{/if}
{#if eventData.updated?.length}
  <h6>Updated:</h6>
  {#each eventData.updated as { name, difference }}
    <div>{name}: <ChangedFeedDifference {difference} /></div>
  {/each}
{/if}
{#if eventData.deleted?.length}
  <h6>Deleted:</h6>
  <div>{eventData.deleted.join(', ')}</div>
{/if}
