<script>
  import { createEventDispatcher } from 'svelte';
  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { uploadLicenseFile } from '../../services';

  const dispatch = createEventDispatcher();

  export let license,
    open = false;

  let resultNotification = null,
    loading = false;

  async function onModalSubmit() {
    resultNotification = null;
    loading = true;

    try {
      const updatedLicense = await uploadLicenseFile({ licenseId: license.id });
      dispatch('close', updatedLicense);
    } catch (error) {
      console.error('[UploadLicenseModal] Failed to upload license file!', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to upload license file! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  modalHeading="Upload license"
  preventCloseOnClickOutside
  primaryButtonText="Upload"
  secondaryButtonText="Cancel"
  shouldSubmitOnEnter={false}
  bind:open
  on:close
  on:click:button--secondary={() => dispatch('close')}
  on:submit={onModalSubmit}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    License will be uploaded under the following destinations:
    <ul class="list">
      {#each license.licenseTemplate.uploadDestinations as destination}
        <li><strong>{destination.path}</strong></li>
      {/each}
    </ul>
  </LoadingSpinner>
</Modal>

<style>
  .list {
    list-style: unset;
  }

  .list :global(li) {
    margin-left: 2rem;
  }
</style>
