<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { format, addDays } from 'date-fns';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LicenseGenerationNotifyStep from './LicenseGenerationNotifyStep.svelte';
  import LicenseGenerationPreviewStep from './LicenseGenerationPreviewStep.svelte';
  import LicenseGenerationVerifyStep from './LicenseGenerationVerifyStep.svelte';
  import Wizard from '../wizard/Wizard.svelte';

  import { generateLicense, getAllFeaturesAndGroups, getGeneratedLicenses } from '../../services';
  import { getServerErrorMessage } from '../../utils';
  import { LICENSE_DATE_FORMAT } from '../../../shared/constants';

  export let license,
    open = false,
    licenseLookbackDays,
    sftp05Username,
    groupName,
    groupShortName;

  let loading = false,
    resultNotification,
    knownFeatures = [],
    generatedLicenses = [];

  const generationDetails = [];

  const WIZARD_STEPS = [{ label: 'Basic Information' }, { label: 'Notify' }, { label: 'Review' }];
  const dispatch = createEventDispatcher();

  const today = format(new Date(), LICENSE_DATE_FORMAT);
  const lookAheadDate = format(addDays(new Date(), licenseLookbackDays ?? 15), LICENSE_DATE_FORMAT);
  const isRenewal =
    !license.newestGeneratedLicense?.isPermanent &&
    (license.newestGeneratedLicense?.expirationDate <= today || lookAheadDate >= license.newestGeneratedLicense?.expirationDate);

  async function fetchDependencies() {
    loading = true;

    try {
      [knownFeatures, generatedLicenses] = await Promise.all([getAllFeaturesAndGroups(), getGeneratedLicenses({ templateId: license.id })]);
    } catch (error) {
      console.error('[GenerateLicenseModal] Failed to load dependencies for license generation..', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to load data for license generation! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  async function createLicense() {
    loading = true;
    const [generationInfo, emailInfo] = generationDetails;

    try {
      const updatedLicenseTemplate = await generateLicense({
        generationInfo: { ...generationInfo, sftp05Username, licenseTemplateId: license.id },
        emailInfo,
      });
      dispatch('close', updatedLicenseTemplate);
    } catch (error) {
      const errorMessage = getServerErrorMessage(error);
      console.error('[GenerateLicenseModal] Failed to generate license..', errorMessage ?? error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: errorMessage ? 'Failed to generate license. Cause:' : 'Error:',
        subtitle: errorMessage ?? 'Failed to generate license! Please try again later',
      };
    } finally {
      loading = false;
    }
  }

  function handleStepComplete({ detail: { stepIndex, values } }) {
    generationDetails[stepIndex] = values;
  }

  onMount(fetchDependencies);
</script>

<Modal
  modalHeading={isRenewal ? 'Renew license' : 'Generate license'}
  passiveModal
  preventCloseOnClickOutside={true}
  size="lg"
  bind:open
  on:close
>
  {#if resultNotification}
    <InlineNotification
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  <Wizard
    {loading}
    steps={WIZARD_STEPS}
    finalStepButtonLabel="Generate"
    confirmationMessage="I confirm the selections are correct and I am ready to create the license"
    onFinish={createLicense}
  >
    <LicenseGenerationPreviewStep
      bind:loading
      licenseTemplate={license}
      features={knownFeatures}
      {generatedLicenses}
      {isRenewal}
      {groupName}
      {groupShortName}
      on:step-complete={handleStepComplete}
    />
    <LicenseGenerationNotifyStep
      bind:loading
      licenseTemplate={license}
      generationInfo={generationDetails[0]}
      on:step-complete={handleStepComplete}
    />
    <LicenseGenerationVerifyStep licenseTemplate={license} features={knownFeatures} {groupShortName} {generationDetails} />
  </Wizard>
</Modal>
