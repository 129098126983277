<script>
  import { Button, Checkbox } from 'carbon-components-svelte';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';
  import Edit16 from 'carbon-icons-svelte/lib/Edit16';
  import Save16 from 'carbon-icons-svelte/lib/Save16';

  import StatusIcon from '../../../../components/StatusIcon.svelte';

  export let cell, row, selectedInstances;

  const handleDeleteInstance = () => {
    selectedInstances = selectedInstances.filter(({ id }) => id !== row.id);
  };

  const handleToggleInstance = () => {
    saveInstancePermissions({ isEdited: !row.isEdited });
  };

  const handleInstancePermissionClick = (instancePermissionId) => {
    const updatedInstancePermissions = selectedInstances
      .find(({ id }) => id === row.id)
      .instancePermissions.map((permission) => {
        return permission.id !== instancePermissionId ? permission : { ...permission, checked: !permission.checked };
      });
    saveInstancePermissions({ instancePermissions: updatedInstancePermissions });
  };

  const saveInstancePermissions = (obj) => {
    selectedInstances = selectedInstances.map((instance) => (instance.id !== row.id ? instance : { ...instance, ...obj }));
  };
</script>

{#if cell.key === 'actions'}
  <Button size="sm" kind="danger-tertiary" iconDescription="Delete entitlement" icon={TrashCan16} on:click={handleDeleteInstance} />
  {#if row.instancePermissions.length > 0}
    <Button
      size="sm"
      kind="tertiary"
      iconDescription={row.isEdited ? 'Save Instance' : 'Edit Instance'}
      icon={row.isEdited ? Save16 : Edit16}
      on:click={handleToggleInstance}
    />
  {/if}
{:else if cell.key === 'instancePermissions' && cell.value.length > 0}
  {#if !row.isEdited}
    {#each cell.value as val}
      <div class="instance-permission">
        <StatusIcon valid={val.checked} />
        <span>{val.name}</span>
      </div>
    {/each}
  {:else}
    {#each cell.value as val}
      <div class="instance-permission">
        <Checkbox
          name={val.name}
          labelText={val.name}
          value={val.id}
          checked={val.checked}
          on:change={() => handleInstancePermissionClick(val.id)}
        />
      </div>
    {/each}
  {/if}
{:else if cell.display}
  {cell.display(cell.value, row)}
{:else}
  {cell.value}
{/if}

<style>
  .instance-permission {
    display: flex;
    margin-right: 10px;
  }

  .instance-permission span {
    margin-left: 5px;
  }
</style>
