<script>
  import { createEventDispatcher } from 'svelte';
  import { InlineNotification, Modal, Button, ButtonSet } from 'carbon-components-svelte';
  import { deleteCrushFTPUser, getGroupPermissions, getSshKeys, updateCrushFTPUserProperty } from '../../services';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';

  export let open = false,
    userName,
    groupId;

  let loading = false,
    resetPassword = false,
    deleteUser = false,
    errorNotification;

  const dispatch = createEventDispatcher();

  function canDeleteUser(key) {
    if (!key || !key.dataServiceAssociations) {
      return true;
    }

    for (const association of key.dataServiceAssociations) {
      if (association.serviceName === 'crushftp') {
        return false;
      }
    }

    return true;
  }

  async function handleSubmit() {
    loading = true;
    let data;
    try {
      if (deleteUser) {
        const [sshKeys, groupPermissions] = await Promise.all([
          getSshKeys({ ownerId: groupId, ownerType: 'group' }),
          getGroupPermissions(groupId),
        ]);

        if (groupPermissions.some((permission) => permission.type === 'data-feeds-crushftp' && !permission.deletedAt && !permission.stub)) {
          errorNotification = {
            kind: 'error',
            title: 'Error:',
            subtitle: 'You have to disable CrushFTP permission for group before deleting user.',
          };
          return;
        }

        for (const sshKey of sshKeys) {
          if (!canDeleteUser(sshKey)) {
            errorNotification = {
              kind: 'error',
              title: 'Error:',
              subtitle: 'You have to revoke SSH key access of this user before deleting.',
            };
            return;
          }
        }
        await deleteCrushFTPUser(groupId, userName);
        data = { data: { userName }, action: 'delete-crushFtpUser' };
      } else if (resetPassword) {
        const result = await updateCrushFTPUserProperty(groupId, userName, 'resetPassword', true);
        data = { data: { userName, crushFtpPassword: result.crushFtpPassword }, action: 'resetPass-crushFtpUser' };
      }
      dispatch('confirm', data);
    } catch (error) {
      const errorMessage = `Failed to ${deleteUser ? 'delete user' : 'reset users password'}`;
      console.error(`[EditCrushFTPUserModal] Failed to ${errorMessage}`, error);
      errorNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: errorMessage,
      };
    } finally {
      loading = false;
    }
  }

  $: warningNotification = {
    kind: 'warning',
    title: 'Warning:',
    subtitle: deleteUser
      ? 'User and all user files will be deleted after the "Confirm" button is clicked!'
      : resetPassword
      ? 'User\'s password will be reset after the "Confirm" button is clicked! New temporary password will be provided after this action.'
      : null,
  };
</script>

<Modal
  modalHeading="Edit CrushFTP User"
  primaryButtonText="Confirm"
  secondaryButtonText="Cancel"
  preventCloseOnClickOutside
  primaryButtonDisabled={!resetPassword && !deleteUser}
  bind:open
  bind:userName
  bind:groupId
  on:click:button--secondary={() => dispatch('close')}
  on:close={() => dispatch('close')}
  on:submit={handleSubmit}
>
  <LoadingSpinner {loading}>
    <div style="margin: var(--cds-layout-03) 0">
      {#if warningNotification.subtitle || errorNotification}
        <InlineNotification
          kind={(errorNotification ?? warningNotification).kind}
          lowContrast
          title={(errorNotification ?? warningNotification).title}
          subtitle={(errorNotification ?? warningNotification).subtitle}
          hideCloseButton
        />
      {/if}
      <div class="reset-password-label">Do you want to reset user's password?</div>
      <div>
        <ButtonSet>
          <Button
            size="small"
            kind="secondary"
            disabled={!resetPassword}
            on:click={() => {
              resetPassword = false;
            }}
          >
            No
          </Button>
          <Button
            size="small"
            kind="danger"
            disabled={resetPassword || deleteUser}
            on:click={() => {
              resetPassword = true;
            }}
          >
            Yes
          </Button>
        </ButtonSet>
      </div>
    </div>
    <div style="margin: var(--cds-layout-03) 0">
      <div class="reset-password-label">Do you want to delete user?</div>
      <div>
        <ButtonSet>
          <Button
            size="small"
            kind="secondary"
            disabled={!deleteUser}
            on:click={() => {
              deleteUser = false;
            }}
          >
            No
          </Button>
          <Button
            size="small"
            kind="danger"
            disabled={deleteUser || resetPassword}
            on:click={() => {
              deleteUser = true;
            }}
          >
            Yes
          </Button>
        </ButtonSet>
      </div>
    </div>
  </LoadingSpinner>
</Modal>

<style>
  .reset-password-label {
    margin-bottom: 10px;
  }
</style>
