<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { changeBuildConfigStatus } from '../../services';

  export let targetName,
    targetId,
    disabled,
    open = false;

  let loading = false;
  let resultNotification;

  const dispatch = createEventDispatcher();

  async function disableTarget() {
    loading = true;

    try {
      await changeBuildConfigStatus(targetId, { disabled: !disabled });

      dispatch('close', { id: targetId, disabled: !disabled });
    } catch (error) {
      console.error('[DisableTargetModal] Failed to disable target!', error);

      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to disable target! ${error?.response?.data?.error || 'Please try again later.'}`,
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  {...disabled
    ? {
        modalHeading: 'Enable Target',
        primaryButtonText: 'Enable',
      }
    : {
        danger: true,
        modalHeading: 'Disable Target',
        primaryButtonText: 'Disable',
      }}
  secondaryButtonText="Cancel"
  preventCloseOnClickOutside={true}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={disableTarget}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>You are about to {disabled ? 'enable' : 'disable'} build target <strong>{targetName}</strong>.</p>
    <br />
    {#if !disabled}
      <p>Disabled build targets will by default be deselected when you use the "Advanced Build Runner".</p>
      <br />
      <p><strong>You can still build disabled targets.</strong></p>
    {/if}
  </LoadingSpinner>
</Modal>
