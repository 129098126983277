<script>
  import Close24 from 'carbon-icons-svelte/lib/Close24';
  import DocumentDownload24 from 'carbon-icons-svelte/lib/DocumentDownload24';
  import Download24 from 'carbon-icons-svelte/lib/Download24';
  import startCase from 'lodash/startCase';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import EntityOutput from './EntityOutput.svelte';

  import { appConfig, searchStore, updateKeys } from '../../stores';
  import { asOptionalTextColumn } from '../../utils';

  let iconWrapperRef;

  const { crushFTPEnabled } = $appConfig.data.flags;
  const sshKeysHeaders = [
    {
      key: 'ownerType',
      value: 'Owner',
      display: (val, row) => {
        const ownerType = startCase(val);
        const ownerName = (val === 'user' ? $searchStore.rawData?.users : $searchStore.rawData?.groups)?.find(
          ({ id }) => id === row.ownerId
        )?.name;
        return ownerName ? `${ownerType} (${ownerName})` : ownerType;
      },
    },
    { key: 'publicKey', value: 'Public Key' },
    { key: 'sftp05Access', value: 'SFTP05 Access' },
    ...(crushFTPEnabled
      ? [
          { key: 'crushftpAccess', value: 'CrushFTP Access' },
          { key: 'actions', value: '', sort: false },
        ]
      : [{ key: 'actions', value: '', sort: false }]),
  ];
  const download = (jsonContent, filename) => {
    const anchorElement = document.createElement('a');
    anchorElement.href = URL.createObjectURL(
      new Blob([JSON.stringify(jsonContent)], {
        type: 'text/json;',
      })
    );
    anchorElement.setAttribute('download', `${filename}-${new Date().toISOString().slice(0, 19)}.json`);
    iconWrapperRef.appendChild(anchorElement);
    anchorElement.click();
    iconWrapperRef.removeChild(anchorElement);
  };
</script>

<div class="search-close-icon" bind:this={iconWrapperRef}>
  {#if !$searchStore.loading}
    <DocumentDownload24 on:click={() => download($searchStore.filteredData, 'mace-search-output')} title="Download Current Search Output" />
    <Download24 on:click={() => download($searchStore.rawData, 'mace-system-output')} title="Download System Data Output" />
  {/if}
  <Close24 on:click={() => updateKeys({ overlayHidden: true })} title="Close Search Output Overlay" />
</div>
{#if $searchStore.loading}
  <LoadingSpinner spinnerDelay={0} withOverlay={false}>
    <p slot="description">Loading search results...</p>
  </LoadingSpinner>
{:else}
  <h1>Search output</h1>
  <EntityOutput
    data={$searchStore.filteredData?.users}
    title="Users"
    headers={[
      { key: 'name', value: 'Name' },
      { key: 'email', value: 'Email' },
      {
        key: 'organizationName',
        value: 'Organization Name',
        ...asOptionalTextColumn,
      },
      { key: 'actions', value: '' },
    ]}
    routeGenerator={({ id }) => `/user/${id}`}
  />
  <EntityOutput
    data={$searchStore.filteredData?.groups}
    title="Groups"
    headers={[
      { key: 'name', value: 'Name' },
      { key: 'shortName', value: 'Short Name', ...asOptionalTextColumn },
      { key: 'aliases', value: 'Aliases', ...asOptionalTextColumn },
      { key: 'organization.name', value: 'Organization' },
      { key: 'actions', value: '' },
    ]}
    routeGenerator={({ id }) => `/group/${id}`}
  />
  <EntityOutput
    data={$searchStore.filteredData?.organizations}
    title="Organizations"
    headers={[
      { key: 'name', value: 'Name' },
      { key: 'salesforceId', value: 'Salesforce ID', ...asOptionalTextColumn },
      { key: 'aliases', value: 'Aliases', ...asOptionalTextColumn },
      { key: 'shortName', value: 'Short Name', ...asOptionalTextColumn },
      { key: 'actions', empty: true, sort: false },
    ]}
    routeGenerator={({ id }) => `/organization/${id}`}
  />
  <EntityOutput
    data={$searchStore.filteredData?.sshKeys}
    title="SSH Keys"
    headers={sshKeysHeaders}
    routeGenerator={({ ownerId, ownerType }) => `/${ownerType}/${ownerId}#ssh-keys`}
  />
{/if}

<style>
  .search-close-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .search-close-icon :global(svg) {
    cursor: pointer;
    margin-left: 0.3rem;
  }
</style>
