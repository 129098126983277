<script>
  import { createEventDispatcher } from 'svelte';

  import { Button } from 'carbon-components-svelte';

  import LicenseTemplateBasicForm from './LicenseTemplateBasicForm.svelte';
  import WizardStep from '../wizard/WizardStep.svelte';

  export let data, defaultDestinations;

  const dispatch = createEventDispatcher();

  function handleFormSubmit(stepIndex, setStep) {
    const goForward = () => setStep(stepIndex + 1);

    return function onFormSubmit({ detail: { values } }) {
      dispatch('step-complete', { stepIndex, values });
      goForward();
    };
  }
</script>

<WizardStep let:stepIndex let:setStep>
  <LicenseTemplateBasicForm {defaultDestinations} initialValues={data} on:submit={handleFormSubmit(stepIndex, setStep)}>
    <div slot="submit" class="navigation" let:submitForm let:isValid>
      <Button size="field" disabled={(!data && !isValid) || isValid === false} on:click={submitForm}>Next</Button>
    </div>
  </LicenseTemplateBasicForm>
  <div slot="navigation" />
</WizardStep>

<style>
  .navigation {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    background-color: var(--cds-ui-01);
    padding: 1rem 0 0;
  }
</style>
