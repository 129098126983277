<script>
  import {
    Button,
    Column,
    FormGroup,
    Grid,
    InlineNotification,
    OutboundLink,
    Row,
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
    TextArea,
  } from 'carbon-components-svelte';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';
  import copy from 'clipboard-copy';
  import truncate from 'lodash/truncate';
  import * as yup from 'yup';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import { updateOrganization } from '../../services';
  import { convertToLocalDisplayTime } from '../../utils';

  export let loadOrganizationData, organizationId, organization;

  let formSubmitMessage;

  const schema = yup.object().shape({
    name: yup.string().required('Enter the organization name!'),
    shortName: yup.string().nullable(),
    aliases: yup.string().nullable(),
    notes: yup.string().nullable(),
  });

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    formSubmitMessage = undefined;

    try {
      await updateOrganization(organizationId, values);
      await loadOrganizationData();
      formSubmitMessage = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Organization updated successfully!',
      };
    } catch (error) {
      console.error('[OrganizationBasicInformation] Failed to update organization data!', error);
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to update organization! Verify your submission and try again.',
      };
    } finally {
      setSubmitting(false);
    }
  }
</script>

{#if formSubmitMessage}
  <InlineNotification kind={formSubmitMessage.kind} lowContrast title={formSubmitMessage.title} subtitle={formSubmitMessage.subtitle} />
{/if}
<Grid noGutterLeft noGutterRight padding>
  <Row>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading">Read-Only Fields</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Organization ID</StructuredListCell>
            <StructuredListCell>
              <div class="id-controls">
                {truncate(organization.id, { length: 20 })}
                <Button
                  class="copy-button"
                  hasIconOnly
                  icon={Copy16}
                  iconDescription="Copy to clipboard"
                  kind="ghost"
                  size="small"
                  on:click={() => copy(organization.id)}
                />
              </div>
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Salesforce ID</StructuredListCell>
            <StructuredListCell>
              <div class="id-controls">
                {#if !organization.salesforceId}
                  —
                {:else}
                  <OutboundLink href={`https://maystreet.lightning.force.com/lightning/r/Account/${organization.salesforceId}/view`}>
                    {organization.salesforceId}
                  </OutboundLink>
                {/if}
              </div>
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
      <h2 class="column-heading">Metadata</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Created</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(organization.createdAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Created By</StructuredListCell>
            <StructuredListCell>{organization.createdByDisplayName}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Last Updated</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(organization.updatedAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Updated By</StructuredListCell>
            <StructuredListCell>{organization.updatedByDisplayName}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
    </Column>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading column-heading-form">Editable Fields</h2>
      <Form
        {schema}
        initialValues={organization}
        validateOnBlur={true}
        validateOnChange={true}
        on:submit={onFormSubmit}
        let:submitForm
        let:isSubmitting
        let:setValue
        let:errors
        let:touched
        let:values
      >
        <FormGroup>
          <TextInput
            helperText="Capitalized, user-friendly name unless purposely stylized otherwise by the customer. Example: MayStreet."
            invalid={touched.name && !!errors.name}
            invalidText={errors.name}
            labelText="Organization Name"
            name="name"
            placeholder="Organization name"
            type="text"
            value={values.name}
            required
            on:change={({ detail: text }) => setValue('name', text)}
          />
          <TextInput
            helperText="Used to uniquely identify the organization in Jenkins. Example: mst."
            invalid={touched.shortName && !!errors.shortName}
            invalidText={errors.shortName}
            labelText="Short Name"
            name="shortName"
            placeholder="Short name"
            type="text"
            value={values.shortName}
            on:change={({ detail: text }) => setValue('shortName', text)}
          />
          <TextInput
            helperText="Alternative or previous names for this organization."
            invalid={touched.aliases && !!errors.aliases}
            invalidText={errors.aliases}
            labelText="Aliases"
            name="aliases"
            placeholder="Comma-separated aliases"
            type="text"
            value={values.aliases}
            on:change={({ detail: text }) => setValue('aliases', text)}
          />
          <TextArea
            invalid={touched.notes && !!errors.notes}
            invalidText={errors.notes}
            labelText="Notes"
            name="notes"
            placeholder="Additional notes"
            type="text"
            value={values.notes}
            on:change={({ target }) => setValue('notes', target.value)}
          />
        </FormGroup>
        <div class="form-controls">
          <LoadingButton kind="primary" on:click={submitForm} disabled={isSubmitting} isLoading={isSubmitting}>Save</LoadingButton>
        </div>
      </Form>
    </Column>
  </Row>
</Grid>

<style>
  /* For accessibility, keep the column-heading elements as h2 tags, but style them similar to h4 */
  .column-heading {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .column-heading + :global(.bx--structured-list) {
    margin-bottom: 2.5rem;
  }
  .column-heading + :global(.bx--structured-list:last-child) {
    margin-bottom: 0.5rem;
  }
  .column-heading + :global(.bx--structured-list .bx--structured-list-td) {
    width: 50%;
  }

  .column-heading-form + :global(.sveltejs-forms > .bx--fieldset > .bx--form-item:first-child) {
    margin: 0 0 0.75rem;
  }

  @media screen and (min-width: 66rem) {
    .column-heading-form {
      margin-bottom: 1.875rem;
    }
    .column-heading-form + :global(.sveltejs-forms) {
      margin-top: 0;
    }
  }

  .id-controls :global(.bx--btn) {
    min-height: unset;
    padding-top: 0;
  }
  .id-controls :global(.bx--btn svg > path) {
    fill: var(--cds-link-01) !important;
  }
</style>
