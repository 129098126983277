import { ROOT_SERVER_URL } from '../config';
import { collectQueryParams, request } from '../utils';

export function createBuildConfig(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs`, {
    data,
    method: 'POST',
  });
}

export function updateBuildConfig(configId, changes) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/${configId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function getBuildConfigs(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/configs/?${urlSearchParams}`);
}

export function removeBuildConfig(paramId) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/${paramId}`, {
    method: 'DELETE',
  });
}

export function changeBuildConfigStatus(configId, data) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/status/${configId}`, {
    data,
    method: 'PUT',
  });
}

export function addBuildParameter(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters`, {
    data,
    method: 'POST',
  });
}

export function addBuildParameters(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/bulk`, {
    data,
    method: 'POST',
  });
}

export function updateBuildParameter(paramId, changes) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/${paramId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function getBuildParameters(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/?${urlSearchParams}`);
}

export function removeBuildParameter(paramId) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/${paramId}`, {
    method: 'DELETE',
  });
}

export function removeConfigParameters(configId) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/bulk/${configId}`, {
    method: 'DELETE',
  });
}

export function getDefaultBuildWithParameters(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/configs/default/?${urlSearchParams}`);
}

export function getMatchedBuildConfigs(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/configs/matched?${urlSearchParams}`);
}

export function cloneBuildConfiguration(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/clone`, {
    data,
    method: 'POST',
  });
}

export function getInheritedBuildParameters(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/inheritance/?${urlSearchParams}`);
}

export function getSuggestions(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/suggestions/?${urlSearchParams}`);
}

export function getParameterNames() {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/dictionary`);
}

export function addParameterName(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/dictionary`, {
    data,
    method: 'POST',
  });
}

export function updateParameterNameData(paramId, changes) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/dictionary/${paramId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function deleteParameterName(paramId) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/dictionary/${paramId}`, {
    method: 'DELETE',
  });
}

export function getParameterValues(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/meta?${urlSearchParams}`);
}

export function addParameterValueData(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/meta`, {
    data,
    method: 'POST',
  });
}

export function updateParameterValue(paramId, changes) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/meta/${paramId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function deleteParameterValue(paramId) {
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/meta/${paramId}`, {
    method: 'DELETE',
  });
}

export function getFinalParameters(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/parameters/final/?${urlSearchParams}`);
}

export function runBuilds(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/run`, {
    data,
    method: 'POST',
  });
}

export function getTriggeredBuilds() {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/triggered`);
}

export function getParentBuildConfigs(urlParams) {
  const urlSearchParams = collectQueryParams(urlParams);
  return request(`${ROOT_SERVER_URL}/api/builds/configs/parents/?${urlSearchParams}`);
}

export function createBuildNote(data) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/notes`, {
    data,
    method: 'POST',
  });
}

export function updateBuildNote(noteId, changes) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/notes/${noteId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function deleteBuildNote(noteId) {
  return request(`${ROOT_SERVER_URL}/api/builds/configs/notes/${noteId}`, {
    method: 'DELETE',
  });
}
