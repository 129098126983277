import { get } from 'svelte/store';

import { CognitoUser } from 'amazon-cognito-identity-js/dist/amazon-cognito-identity';

import { userSession } from '../stores';

export const resetPassword = (username) => {
  const { userPool, storage } = get(userSession);

  const cognitoUser = new CognitoUser({
    Pool: userPool,
    Storage: storage,
    Username: username,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};
