<script>
  import { Column, Grid, Row } from 'carbon-components-svelte';
</script>

<Grid noGutterLeft noGutterRight padding>
  <Row>
    <Column sm={4}>
      <slot />
    </Column>
  </Row>
</Grid>
