<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { deleteLicenseTemplate } from '../../services';

  export let license,
    open = false;

  let loading = false,
    resultNotification;

  const dispatch = createEventDispatcher();

  async function deleteTemplate() {
    loading = true;

    try {
      await deleteLicenseTemplate(license.id);

      dispatch('close', { id: license.id });
    } catch (error) {
      console.error('[DeleteLicenseTemplateModal] Failed to delete license template!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to delete license template! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Delete License Template"
  preventCloseOnClickOutside={true}
  primaryButtonText="Delete"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={loading}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={deleteTemplate}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>You are about to delete license template <strong>{license.name}</strong>.</p>
    <!-- TODO: once licenses are ready -->
    <!-- <p>You can always inspect generated licenses for this template, even if it is deleted, under <Link...</p> -->
    <br />
    <p>Are you sure?</p>
  </LoadingSpinner>
</Modal>
