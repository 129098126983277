import { get, writable } from 'svelte/store';

import { CookieStorage, CognitoUserPool } from 'amazon-cognito-identity-js/dist/amazon-cognito-identity';
import defer from 'lodash/defer';

import { appConfig } from './appConfig';

const store = writable({ loading: true }, function start(set) {
  const unsubscribe = appConfig.subscribe((config) => {
    if (config.loading || config.error) {
      return;
    }

    try {
      const { cognitoClientId, cognitoCookieStorageDomain, cognitoUserPoolId, useSecureCookieStorage } = config.data;
      const storage = new CookieStorage({
        domain: cognitoCookieStorageDomain,
        secure: useSecureCookieStorage,
      });
      const userPool = new CognitoUserPool({
        ClientId: cognitoClientId,
        Storage: storage,
        UserPoolId: cognitoUserPoolId,
      });

      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        console.debug('[userSession] No user exists in storage. Redirect required!');
        set({ userPool, storage, loading: false });
        return;
      }

      defer(async () => {
        const session = await new Promise((resolve, reject) => {
          cognitoUser.getSession((error, cognitoSession) => {
            if (error) {
              return reject(error);
            }

            resolve(cognitoSession);
          });
        });
        const userProfile = session.getIdToken().decodePayload();
        set({ cognitoUser, session, userProfile, loading: false });
      });
    } catch (error) {
      console.error('[userSession] An error occurred while retrieving or refreshing the user session!', error);
      set({ loading: false, error });
    }
  });

  return () => {
    unsubscribe();
  };
});

const { subscribe, set, update } = store;

async function refresh() {
  console.debug('[userSession] Refreshing session...');
  update((values) => ({ ...values, loading: true }));

  try {
    const { cognitoUser, session: oldSession } = get(store);
    if (!cognitoUser) {
      console.debug('[userSession] No user found in storage while refreshing. Redirect required!');
      set({ loading: false });
      return;
    }

    const session = await new Promise((resolve, reject) => {
      const refreshToken = oldSession.getRefreshToken();
      cognitoUser.refreshSession(refreshToken, (error, newSession) => {
        if (error) {
          return reject(error);
        }

        resolve(newSession);
      });
    });
    const userProfile = session.getIdToken().decodePayload();
    set({ cognitoUser, session, userProfile, loading: false });

    console.debug('[userSession] Session refreshed successfully!');
    return session;
  } catch (error) {
    console.error('[userSession] An error occurred while retrieving or refreshing the user session!', error);
    set({ loading: false, error });
  }
}

function signOut() {
  const { cognitoUser } = get(store);
  cognitoUser?.signOut();
}

export const userSession = {
  subscribe,
  refresh,
  signOut,
};
