<script>
  import { createEventDispatcher } from 'svelte';

  import ArrowRight from 'carbon-icons-svelte/lib/ArrowRight16';
  import Close from 'carbon-icons-svelte/lib/Close16';
  import {
    Checkbox,
    Modal,
    StructuredList,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
  } from 'carbon-components-svelte';

  import { editFeatureAndGroupDependencies } from '../../services';

  export let open = false,
    changes = [],
    features = [],
    groups = [],
    fromLock = false;

  let verified = false;

  const dispatch = createEventDispatcher();
  const LABELS = {
    // actions
    move: 'Moved',
    copy: 'Added',
    remove: 'Removed',

    // entities
    groups: 'Group',
    features: 'Feature',
  };

  const entities = { features, groups };

  async function handleModalSubmit() {
    try {
      // TODO: loading + err message
      await editFeatureAndGroupDependencies(changes);
      dispatch('close', { success: true });
    } catch (error) {
      console.error('[ReviewFeatureChangesModal] Failed to save changes!', error);
    }
  }

  $: synced = !changes.length;
  $: normalizedChanges = changes.map(({ action, data: { itemId, itemType, destinationItemId } }) => ({
    action,
    affectedEntity: entities[itemType].find(({ id }) => id === itemId),
    affectedEntityType: itemType,
    modifiedEntity: groups.find(({ id }) => id === destinationItemId),
  }));
</script>

<div class="review-modal">
  <Modal
    passiveModal={synced}
    modalHeading={synced ? 'Synced' : 'Review Changes'}
    primaryButtonText={fromLock ? 'Save and Lock' : 'Save'}
    secondaryButtonText={fromLock ? 'Discard and Lock' : 'Cancel'}
    preventCloseOnClickOutside
    primaryButtonDisabled={!verified}
    size={synced ? 'xs' : 'small'}
    bind:open
    on:click:button--secondary={() => dispatch(fromLock ? 'discard' : 'close')}
    on:submit={handleModalSubmit}
    on:close
  >
    <div class="content">
      {#if !changes.length}
        <p>Current view is in sync with actual groups and features available to the system.</p>
        <p>Try dragging and dropping features/groups from the lists on the left to the board on the right, to modify current structure.</p>
        <p>Changes in structure will not be saved instantly, but require an extra verification step in the end.</p>
      {:else}
        {#if fromLock}
          <p>You have to save or discard the following changes in order to lock grouped features.</p>
        {:else}
          <p>Please verify and accept described changes below in order for them to take effect.</p>
        {/if}
        <div class="changes">
          <StructuredList condensed>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Item</StructuredListCell>
                <StructuredListCell head>Action</StructuredListCell>
                <StructuredListCell head>Destination</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {#each normalizedChanges as { action, affectedEntity, affectedEntityType, modifiedEntity }}
                <StructuredListRow>
                  <StructuredListCell>
                    <div class="cell">
                      {affectedEntity.name}
                      <em>({LABELS[affectedEntityType]})</em>
                    </div>
                  </StructuredListCell>
                  <StructuredListCell>
                    <div class="cell">
                      {#if ['move', 'copy'].includes(action)}
                        <ArrowRight />
                      {:else if action === 'remove'}
                        <Close />
                      {/if}
                      <em>({LABELS[action]})</em>
                    </div>
                  </StructuredListCell>
                  <StructuredListCell>
                    {modifiedEntity.name}
                  </StructuredListCell>
                </StructuredListRow>
              {/each}
            </StructuredListBody>
          </StructuredList>
        </div>
        <div class="verification">
          <Checkbox labelText="I verify the above changes and want to proceed" bind:checked={verified} />
        </div>
      {/if}
    </div>
  </Modal>
</div>

<style>
  .review-modal :global(.bx--modal-content) {
    margin-bottom: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .content :global(p) {
    margin-bottom: 0.5rem;
  }

  .changes {
    margin-bottom: 1rem;
    max-height: 300px;
    overflow-y: auto;
  }

  .changes :global(.bx--structured-list) {
    margin-bottom: unset;
  }

  .cell {
    display: flex;
    flex-direction: column;
  }

  .cell :global(em) {
    font-size: 0.75rem;
  }

  .verification {
    justify-self: flex-end;
    margin-top: auto;
  }
</style>
