import { get } from 'svelte/store';

import axios from 'axios';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { TOKEN_EXPIRY_TOLERANCE } from '../config';
import { userSession } from '../stores';

const axiosInstance = axios.create();

/**
 * @description Given some object, strip all key-value pairs containing null or undefined values. Return a new
 *    URLSearchParams object from the result.
 * @param {object} params
 * @returns {URLSearchParams}
 */
export function collectQueryParams(params) {
  const validParams = omitBy(params, isNil);
  return new URLSearchParams(validParams);
}

/**
 * @description Sends an API request. Not for general use, since credentials are added to the headers here. If there is no session, an error
 *    is thrown. If the session exists but the access token is expired, it will be refreshed here.
 * @param {string} url
 * @param {object} options
 * @returns {Promise}
 */
export async function request(url, options = { data: undefined, headers: undefined, method: 'GET', returnEntireResponse: false }) {
  let session = get(userSession)?.session;
  if (!session) {
    throw new Error('No session');
  }

  let accessToken = session.getAccessToken();
  const expirationSeconds = accessToken?.decodePayload()?.exp ?? 0;
  const tokenExpired = expirationSeconds * 1000 - new Date() < TOKEN_EXPIRY_TOLERANCE;
  if (tokenExpired) {
    session = await userSession.refresh();
    accessToken = session.getAccessToken();
  }

  const response = await axiosInstance({
    url,
    data: options.data,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken.getJwtToken()}`,
      'Content-Type': options.data ? 'application/json' : undefined,
      ...options.headers,
    },
    method: options.method,
  });

  return options.returnEntireResponse ? response : response.data;
}
