<script>
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';

  import LongTextTooltipCell from '../LongTextTooltipCell.svelte';

  export let eventData;
  const getEventChanges = () => {
    const { original, changes } = eventData;
    return Object.keys(original)
      .filter((key) => !['updatedBy'].includes(key))
      .map((key) => ({ key, original: original[key], changed: changes[key] }));
  };

  const UPDATE_KEYS_TEXT = {
    notes: 'Notes',
    aliases: 'Aliases',
    shortName: 'Short Name',
    name: 'Name',
    isEnabled: 'Enabled',
  };
</script>

{#if !eventData.original}
  {#each Object.entries(eventData) as [key, value]}
    <span class="property-change-details">{key}: {JSON.stringify(value)}</span>
  {/each}
{:else if JSON.stringify(eventData.original).length > 100}
  <LongTextTooltipCell text="Click to view changes">
    {#each getEventChanges(eventData) as { key, original, changed }, i}
      <h6 class="key-heading">{UPDATE_KEYS_TEXT[key] ?? key}</h6>
      <span class="property-change-details">{original} <ArrowRight16 /> {changed}</span>
    {/each}
  </LongTextTooltipCell>
{:else}
  {#each getEventChanges(eventData) as { key, original, changed }, i}
    <h6 class="key-heading">{UPDATE_KEYS_TEXT[key] ?? key}</h6>
    <span class="property-change-details">{original} <ArrowRight16 /> {changed}</span>
  {/each}
{/if}

<style>
  .property-change-details {
    display: block;
    margin-bottom: 0.5rem;
  }

  .property-change-details :global(svg[data-carbon-icon='ArrowRight16']) {
    margin-bottom: -2px;
  }
</style>
