<script>
  import { onMount } from 'svelte';

  import { Button, OverflowMenu, OverflowMenuItem, Toolbar, ToolbarContent } from 'carbon-components-svelte';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import AddOrEditApiKeyModal from './AddOrEditApiKeyModal.svelte';
  import ApiKeyRemoveConfirmationModal from './ApiKeyRemoveConfirmationModal.svelte';
  import CustomInlineNotification from '../../../components/CustomInlineNotification.svelte';
  import LoadingSpinner from '../../../components/LoadingSpinner.svelte';
  import { getApiKeys, getApiRoutes } from '../../../services';

  let modal = {},
    apiKeyIdToRemove,
    resultNotification,
    pageData = { loading: true };

  const loadApiKeys = async () => {
    pageData = { ...pageData, apiKeys: [] };
    resultNotification = null;
    try {
      const [apiKeys, availableRoutes] = await Promise.all([getApiKeys(), getApiRoutes()]);

      if (!apiKeys.length) {
        resultNotification = {
          kind: 'info',
          subtitle: 'There are no API keys added yet.',
          actions: [
            {
              text: 'Add API key',
              onClick: openAddKeyModal,
            },
          ],
        };
      }
      pageData = { ...pageData, loading: false, apiKeys, availableRoutes };
    } catch (error) {
      console.error('[AdminApiKeys] Failed to load api keys!', error);
      pageData = { ...pageData, loading: false };
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to load API keys!',
      };
    }
  };

  onMount(loadApiKeys);

  const openAddKeyModal = () => {
    modal = { open: true, isEdit: false };
  };

  const editApiKey = ({ id, userId, alias, description, routes: savedRoutes }) => {
    const routes = savedRoutes.map(({ route, method }) => ({ route, method }));
    modal = { open: true, isEdit: true, initialValues: { userId, alias, description, routes }, apiKeyId: id };
  };

  const removeApiKey = (apiKeyId) => {
    apiKeyIdToRemove = apiKeyId;
  };
</script>

<LoadingSpinner loading={pageData.loading}>
  {#if resultNotification}
    <CustomInlineNotification {...resultNotification} hideCloseButton />
  {:else}
    <DataTable
      headers={[
        { key: 'userIdDisplayName', value: 'User' },
        { key: 'alias', value: 'Alias' },
        { key: 'description', value: 'Description' },
        { key: 'createdByDisplayName', value: 'Created By' },
        { key: 'updatedByDisplayName', value: 'Updated By' },
        { key: 'actions', empty: true, sort: false },
      ]}
      rows={pageData.apiKeys ?? []}
      rowClasses={pageData.apiKeys?.reduce(
        (obj, { deletedAt, id }) => ({
          ...obj,
          [id]: deletedAt ? 'overwritten' : '',
        }),
        {}
      )}
      sortable
    >
      <span slot="cell" let:row let:cell>
        {#if cell.key === 'actions' && !row.deletedAt}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem text="Edit" on:click={() => editApiKey(row)} />
            <OverflowMenuItem danger text="Remove" on:click={() => removeApiKey(row.id)} />
          </OverflowMenu>
        {:else}
          {cell.display ? cell.display(cell.value) : cell.value ?? ''}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <Button on:click={openAddKeyModal}>Add key</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
  {/if}
</LoadingSpinner>

{#if modal.open}
  <AddOrEditApiKeyModal
    apiKeyId={modal.apiKeyId}
    isEdit={modal.isEdit}
    initialValues={modal.initialValues}
    availableRoutes={pageData.availableRoutes}
    bind:open={modal.open}
    on:keyAddedOrUpdated={loadApiKeys}
  />
{/if}

{#if apiKeyIdToRemove}
  <ApiKeyRemoveConfirmationModal on:keyDeleted={loadApiKeys} bind:apiKeyIdToRemove />
{/if}
