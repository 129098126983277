<script>
  import { FormGroup, InlineNotification, TextArea } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import { createOrganization } from '../../services';

  let formSubmitMessage;

  const navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required('Enter the organization name!'),
    salesforceId: yup.string(),
    shortName: yup.string(),
    aliases: yup.string(),
    notes: yup.string(),
  });

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    formSubmitMessage = undefined;

    try {
      const { id } = await createOrganization(values);

      formSubmitMessage = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Created organization! Redirecting...',
      };
      setTimeout(() => navigate(`/organization/${id}`), 2500);
    } catch (error) {
      console.error('[CreateOrganization] Failed to update organization data!', error);
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to create organization! Verify your submission and try again.',
      };
      setSubmitting(false);
    }
  }
</script>

{#if formSubmitMessage}
  <InlineNotification kind={formSubmitMessage.kind} lowContrast title={formSubmitMessage.title} subtitle={formSubmitMessage.subtitle} />
{/if}
<Form
  {schema}
  validateOnBlur={true}
  validateOnChange={true}
  on:submit={onFormSubmit}
  let:submitForm
  let:isSubmitting
  let:setValue
  let:errors
  let:touched
  let:values
>
  <FormGroup>
    <TextInput
      helperText="Capitalized, user-friendly name unless purposely stylized otherwise by the customer. Example: MayStreet."
      invalid={touched.name && !!errors.name}
      invalidText={errors.name}
      labelText="Organization Name"
      name="name"
      placeholder="Organization name"
      type="text"
      value={values.name}
      required
      on:change={({ detail: text }) => setValue('name', text)}
    />
    <TextInput
      helperText="Used to uniquely identify the organization in Jenkins. Example: mst."
      invalid={touched.shortName && !!errors.shortName}
      invalidText={errors.shortName}
      labelText="Short Name"
      name="shortName"
      placeholder="Short name"
      type="text"
      value={values.shortName}
      on:change={({ detail: text }) => setValue('shortName', text)}
    />
    <TextInput
      invalid={touched.salesforceId && !!errors.salesforceId}
      invalidText={errors.salesforceId}
      labelText="Salesforce ID"
      name="salesforceId"
      placeholder="Salesforce ID"
      type="text"
      value={values.salesforceId}
      on:change={({ detail: text }) => setValue('salesforceId', text)}
    />
    <TextInput
      helperText="Alternative or previous names for this organization."
      invalid={touched.aliases && !!errors.aliases}
      invalidText={errors.aliases}
      labelText="Aliases"
      name="aliases"
      placeholder="Comma-separated aliases"
      type="text"
      value={values.aliases}
      on:change={({ detail: text }) => setValue('aliases', text)}
    />
    <TextArea
      invalid={touched.notes && !!errors.notes}
      invalidText={errors.notes}
      labelText="Notes"
      name="notes"
      placeholder="Additional notes"
      type="text"
      value={values.notes}
      on:change={({ target }) => setValue('notes', target.value)}
    />
  </FormGroup>
  <div class="form-controls">
    <LoadingButton kind="primary" on:click={submitForm} disabled={isSubmitting} isLoading={isSubmitting}>Save</LoadingButton>
  </div>
</Form>
