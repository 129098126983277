<script context="module">
  function findFeatureOrGroupById(itemId, array) {
    for (const featureOrGroup of array) {
      if (featureOrGroup.id === itemId) {
        return featureOrGroup;
      }

      if (!featureOrGroup.children) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const item = findFeatureOrGroupById(itemId, featureOrGroup.children);
      if (item) {
        return item;
      }
    }
  }

  export function extractFeaturesAndGroups(selectedFeatureIds, allFeaturesAndGroups) {
    const features = [];
    const groups = [];

    for (const id of selectedFeatureIds) {
      const featureOrGroup = findFeatureOrGroupById(id, allFeaturesAndGroups);

      if (!featureOrGroup) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (featureOrGroup.children) {
        groups.push(featureOrGroup.id);
      } else {
        features.push(id);
      }
    }

    return { features, groups };
  }

  export function extractFeaturesAndGroupsWithDetails(selectedFeatureIds, allFeaturesAndGroups) {
    const features = [];
    const groups = [];

    for (const id of selectedFeatureIds) {
      const featureOrGroup = findFeatureOrGroupById(id, allFeaturesAndGroups);

      if (!featureOrGroup) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (featureOrGroup.children) {
        groups.push(featureOrGroup);
      } else {
        features.push(featureOrGroup);
      }
    }

    return { features, groups };
  }
</script>

<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import { Modal } from 'carbon-components-svelte';

  import CustomInlineNotification from '../CustomInlineNotification.svelte';
  import Wizard from '../wizard/Wizard.svelte';

  import LicenseTemplateBasicStep from './LicenseTemplateBasicStep.svelte';
  import LicenseTemplateFeatureStep from './LicenseTemplateFeatureStep.svelte';
  import LicenseTemplateReviewStep from './LicenseTemplateReviewStep.svelte';

  import { getAllFeaturesAndGroups, createLicenseTemplate } from '../../services';

  const dispatch = createEventDispatcher();
  const WIZARD_STEPS = [{ label: 'Basic Information' }, { label: 'Features' }, { label: 'Review' }];
  const notifications = {
    errorFetchingKnownData: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load know user emails and/or features! Please try again later.',
    },
    errorCreatingLicenseTemplate: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to create license template! Please try again later.',
    },
    errorTemplateExists: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to create license template! Template name already exists.',
    },
  };

  export let entityId,
    entityName,
    defaultDestinations,
    licenseDetails = [],
    open = false;

  let modalState = {
      loading: false,
      notifications: new Set(),
    },
    knownFeatures = [];

  function handleStepComplete({ detail: { stepIndex, values } }) {
    licenseDetails[stepIndex] = values;
  }

  async function fetchDependencies() {
    modalState = {
      ...modalState,
      loading: true,
    };

    try {
      const groupedFeatures = await getAllFeaturesAndGroups();

      modalState.notifications.delete('errorFetchingKnownData');

      knownFeatures = groupedFeatures ?? [];
    } catch (error) {
      modalState.notifications.add('errorFetchingKnownData');
      console.error('[AddLicenseModal] Failed to load know user emails and/or features...', error);
    } finally {
      modalState = {
        ...modalState,
        loading: false,
      };
    }
  }

  async function generateLicenseTemplate() {
    const [basic, features, misc] = licenseDetails;

    const licenseTemplate = {
      ownerId: entityId,
      ...basic,
      ...misc,
      ...extractFeaturesAndGroups(features, knownFeatures),
    };

    modalState = {
      ...modalState,
      loading: true,
    };

    try {
      const createdLicense = await createLicenseTemplate(licenseTemplate);
      modalState.notifications.delete('errorTemplateExists');
      modalState.notifications.delete('errorCreatingLicenseTemplate');

      dispatch('close', createdLicense);
    } catch (error) {
      if (error.response?.status === 409) {
        modalState.notifications.add('errorTemplateExists');
        console.error('[AddLicenseModal] Template name already exists...', error);
      } else {
        modalState.notifications.add('errorCreatingLicenseTemplate');
        console.error('[AddLicenseModal] Failed to create license template...', error);
      }
    } finally {
      modalState = {
        ...modalState,
        loading: false,
      };
    }
  }

  onMount(fetchDependencies);

  $: [basicLicenseInfo, licenseFeatures] = licenseDetails;
</script>

<div class="license-modal">
  <Modal
    size="lg"
    passiveModal
    modalHeading="Create License Template"
    preventCloseOnClickOutside={true}
    shouldSubmitOnEnter={false}
    bind:open
    on:close
  >
    {#each Object.keys(notifications) as notification}
      {#if modalState.notifications.has(notification)}
        <CustomInlineNotification {...notifications[notification]} />
      {/if}
    {/each}
    <div class="intro">
      <p>You are about to create a license template for <strong>{entityName}</strong>.</p>
      <p>
        <strong>Note:</strong> This is <strong>not</strong> an actual license but rather a template from which you can later generate licenses
        from.
      </p>
    </div>
    <Wizard
      loading={modalState.loading}
      steps={WIZARD_STEPS}
      finalStepButtonLabel="Create"
      confirmationMessage="I confirm the selections are correct and I am ready to create the license template"
      onFinish={generateLicenseTemplate}
    >
      <LicenseTemplateBasicStep {defaultDestinations} data={basicLicenseInfo} on:step-complete={handleStepComplete} />
      <LicenseTemplateFeatureStep {knownFeatures} data={licenseFeatures} on:step-complete={handleStepComplete} />
      <LicenseTemplateReviewStep knownValues={{ features: knownFeatures }} data={licenseDetails} on:step-complete={handleStepComplete} />
    </Wizard>
  </Modal>
</div>

<style>
  .intro {
    margin-bottom: 1rem;
  }

  .license-modal :global(.bx--modal-content) {
    margin-bottom: var(--cds-spacing-05);
  }
</style>
