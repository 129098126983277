<script>
  import { format } from 'date-fns';
  import copy from 'clipboard-copy';
  import { createEventDispatcher, onMount, tick } from 'svelte';

  import { Button, InlineNotification, Modal, MultiSelect, Select, SelectItem } from 'carbon-components-svelte';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';

  import EmailTemplateForm from '../emails/EmailTemplateForm.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { getGroupUsers, parseEmailTemplates, sendEmail } from '../../services';
  import { LICENSES_INTERNAL_EMAIL_ADDRESS } from '../../../shared/constants';

  export let license,
    open = false;

  let emailTemplates = [],
    groupUsers = [],
    availableEmails = [],
    selectedEmailTemplate = null,
    emailFormReady = false,
    toEmails = [],
    loading = false,
    resultNotification,
    emailData = null;

  const dispatch = createEventDispatcher();

  async function fetchDependencies() {
    loading = true;

    try {
      [groupUsers, emailTemplates] = await Promise.all([
        getGroupUsers(license.ownerId),
        parseEmailTemplates('licenses', license.templateId, {
          replacements: {
            expiration_date: license.isPermanent ? 'Permanent' : license.expirationDate,
            expiration_date_ext: license.isPermanent ? 'Permanent' : format(new Date(license.expirationDate), 'dd-MMM-yyyy'),
          },
        }),
      ]);

      selectedEmailTemplate = emailTemplates.find((emailTemplate) => emailTemplate.isDefault) ?? emailTemplates[0];
      [availableEmails, toEmails] = groupUsers.reduce(
        (all, user) => {
          const { id, name, email: text } = user;

          all[0].push({ id, name, text });

          if (user.licenseUpdates) {
            all[1].push(id);
          }

          return all;
        },
        [
          [{ id: LICENSES_INTERNAL_EMAIL_ADDRESS, name: LICENSES_INTERNAL_EMAIL_ADDRESS, text: LICENSES_INTERNAL_EMAIL_ADDRESS }],
          [LICENSES_INTERNAL_EMAIL_ADDRESS],
        ]
      );
    } catch (error) {
      console.error('[LicenseGenerationNotifyStep] Failed to load dependencies..', error);
    } finally {
      loading = false;
    }
  }

  async function handleEmailTemplateSelection({ detail: selectionId }) {
    if (selectionId === selectedEmailTemplate?.id) {
      return;
    }

    selectedEmailTemplate = null;
    await tick();
    selectedEmailTemplate = emailTemplates.find(({ id }) => id === selectionId);
  }

  function handleEmailFormChange({ detail }) {
    const { field, value } = detail;
    emailData = {
      ...emailData,
      [field]: value,
    };
  }

  function emailListToString() {
    return availableEmails
      .reduce((all, { id, text: email }) => {
        if (!toEmails.includes(id)) {
          return all;
        }

        return [...all, email];
      }, [])
      .join(', ');
  }

  async function notify() {
    loading = true;

    try {
      const emailEntry = await sendEmail({
        licenseId: license.id,
        emailInfo: {
          toEmails: availableEmails.reduce((all, { id, text: email }) => {
            if (!toEmails.includes(id)) {
              return all;
            }

            return [...all, { id, email }];
          }, []),
          subject: emailData.subject,
          body: emailData.body,
        },
        attachments: [
          {
            filename: `${license.filename}.lic`,
            content: license.file,
          },
        ],
      });
      dispatch('close', emailEntry);
    } catch (error) {
      console.error('[LicenseGenerationNotifyStep] Failed to send email..', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to send license! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  onMount(fetchDependencies);

  $: emailData = selectedEmailTemplate
    ? {
        subject: selectedEmailTemplate.subject,
        body: selectedEmailTemplate.body,
      }
    : null;
  $: canSubmit = emailData?.subject && emailData?.body && toEmails.length;
</script>

<svelte:head>
  <link
    href="../styles/quill.css"
    rel="stylesheet"
    on:load={() => {
      emailFormReady = true;
    }}
  />
</svelte:head>

<Modal
  modalHeading="Notify for license generation"
  primaryButtonText="Send"
  secondaryButtonText="Cancel"
  preventCloseOnClickOutside
  shouldSubmitOnEnter={false}
  primaryButtonDisabled={!canSubmit}
  bind:open
  on:close
  on:click:button--secondary={() => dispatch('close')}
  on:submit={notify}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <div class="selector">
      {#if emailTemplates.length && selectedEmailTemplate}
        <Select labelText="Email template" selected={selectedEmailTemplate?.id} on:change={handleEmailTemplateSelection}>
          {#each emailTemplates as template}
            <SelectItem value={template.id} text={template.name} />
          {/each}
        </Select>
      {/if}
    </div>
    <div class="email-form">
      <h4 class="h4">Email</h4>
      <div class="multi-select">
        <MultiSelect disabled={!availableEmails.length} titleText="To" items={availableEmails} bind:selectedIds={toEmails} let:item>
          <div>
            <strong>{item.text}</strong>
          </div>
          {#if item.text !== item.name}
            <div>{item.name}</div>
          {/if}
        </MultiSelect>
        <Button
          iconDescription="Copy selected"
          size="field"
          icon={Copy16}
          kind="tertiary"
          tooltipPosition="left"
          disabled={!availableEmails.length || !toEmails.length}
          on:click={() => copy(toEmails ? emailListToString() : '')}
        />
        {#if !availableEmails.length}
          <span class="bx--label">No connected users to group.</span>
        {/if}
      </div>
      {#if emailData}
        <EmailTemplateForm subject={emailData.subject} body={emailData.body} on:change={handleEmailFormChange} {emailFormReady} />
      {/if}
    </div>
  </LoadingSpinner>
</Modal>

<style>
  .h4 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .selector {
    margin-bottom: 2rem;
  }

  .email-form {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    background-color: var(--cds-background);
  }

  .multi-select {
    display: flex;
  }

  .multi-select :global(.bx--multi-select__wrapper) {
    width: 100%;
  }

  .multi-select :global(.bx--btn) {
    align-self: end;
  }

  .multi-select :global(.bx--list-box__menu-item__option),
  .multi-select :global(.bx--list-box__menu-item) {
    height: auto;
  }

  .multi-select :global(.bx--checkbox-label-text) {
    display: block;
  }
</style>
