<script>
  import { onMount } from 'svelte';

  import { InlineNotification, TabContent } from 'carbon-components-svelte';

  import AuditTable from '../../components/audit-table/AuditTable.svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import OrganizationBasicInformation from './OrganizationBasicInformation.svelte';
  import OrganizationGroupList from './OrganizationGroupList.svelte';
  import OrganizationUserList from './OrganizationUserList.svelte';
  import OrganizationParameterList from './OrganizationParameterList.svelte';
  import OrganizationWbPlanList from './OrganizationWbPlanList.svelte';
  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
  import { getOrganization } from '../../services';
  import { cacheEntityName } from '../../stores';

  export let organizationId;

  let pageData = { loading: true };

  const loadOrganizationData = async () => {
    pageData = { ...pageData, loading: true };
    try {
      const organization = await getOrganization(organizationId);
      pageData = { loading: false, organization };
      cacheEntityName(organizationId, organization.name);
    } catch (error) {
      console.error('[OrganizationDetails] Failed to retrieve organization data!', error);
      pageData = { loading: false, error };
    }
  };

  onMount(loadOrganizationData);
</script>

{#if pageData.loading}
  <LoadingSpinner withOverlay={false} />
{:else if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load organization data! Please try again later."
  />
{/if}

{#if !pageData.loading || pageData.organization}
  <TabsWithRouting
    tabsWrapperProps={{ 'aria-label': 'Organization details tabs' }}
    tabs={[
      { label: 'Basic Information', hash: '#basic-information' },
      { label: 'Groups', hash: '#groups' },
      { label: 'Users', hash: '#users' },
      { label: 'Build Parameters', hash: '#parameters' },
      { label: 'Workbench Plans', hash: '#wb-plans' },
      { label: 'History', hash: '#history' },
    ]}
  >
    <TabContent><OrganizationBasicInformation {organizationId} organization={pageData.organization} {loadOrganizationData} /></TabContent>
    <TabContent><OrganizationGroupList groups={pageData.organization?.groups} {organizationId} /></TabContent>
    <TabContent><OrganizationUserList {organizationId} /></TabContent>
    <TabContent><OrganizationParameterList {organizationId} organizationName={pageData.organization?.name} /></TabContent>
    <LazyLoadedTabContent hash="#wb-plans">
      <OrganizationWbPlanList {organizationId} organizationName={pageData.organization?.name} />
    </LazyLoadedTabContent>
    <LazyLoadedTabContent hash="#history"><AuditTable entityId={organizationId} entityType="organization" /></LazyLoadedTabContent>
  </TabsWithRouting>
{/if}
