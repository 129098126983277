<script>
  import startCase from 'lodash/startCase';
  import { useNavigate } from 'svelte-navigator';

  import { Link } from 'carbon-components-svelte';
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';

  export let eventData, planId;

  const navigate = useNavigate();
  const getEventChanges = () => {
    const { original, changes } = eventData;
    return Object.keys(original).map((key) => ({ key, original: original[key], changed: changes[key] }));
  };

  function handleNavigation(event) {
    event.preventDefault();
    navigate(`?filter=${planId}#wb-plans`);
  }
</script>

{#each getEventChanges(eventData) as { key, original, changed }}
  <div class="entity">
    <Link href={`?filter=${planId}#wb-plans`} on:click={handleNavigation}>Affected Plan</Link>
  </div>
  <h6 class="key-heading">{startCase(key)}</h6>
  <span class="property-change-details">{startCase(original)} <ArrowRight16 /> {startCase(changed)}</span>
{/each}

<style>
  .entity {
    margin: 0.5rem 0;
  }

  .property-change-details {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .property-change-details :global(svg[data-carbon-icon='ArrowRight16']) {
    margin: 0 0.5rem;
  }
</style>
