<script>
  import { Button, Modal } from 'carbon-components-svelte';
  import startCase from 'lodash/startCase';
  import isEmpty from 'lodash/isEmpty';

  import ChangedFeedDifference from './ChangedFeedDifference.svelte';
  import { putGroupFeedsPermissions } from '../../../../services';
  import { getServerErrorMessage } from '../../../../utils';
  import { DataInventoryServiceIds } from '../../../../../shared/constants';

  export let dbFeeds,
    fetchError,
    groupId,
    hasRunningConnection,
    permissionId,
    permissionTableLoading,
    serviceId,
    validationErrors,
    visibleFeeds;

  let confirmationModal = false;

  const getPermissionDifference = (existingPermission, updatedPermission) => {
    const difference = {};
    Object.keys(existingPermission).forEach((key) => {
      if (existingPermission[key] !== updatedPermission[key]) {
        difference[key] = { previous: existingPermission[key], change: updatedPermission[key] };
      }
    });

    return Object.keys(difference).length ? difference : null;
  };

  $: feedDifference = [...dbFeeds, ...visibleFeeds].reduce(
    (obj, curr) => {
      const alreadyExisting = dbFeeds.find(({ id }) => curr.id === id);
      const currentlyIncluded = visibleFeeds.find(({ id }) => curr.id === id);
      if (!alreadyExisting) {
        const { name, feedId, startDate, endDate, isOngoing, expiresAt } = curr;
        const addedFeed = { name, feedId, startDate, endDate, permissionId, isOngoing, expiresAt: expiresAt || null };
        return { ...obj, created: { ...obj.created, [curr.id]: addedFeed } };
      }

      if (alreadyExisting && !currentlyIncluded) {
        return { ...obj, deleted: { ...obj.deleted, [curr.id]: curr } };
      }

      const difference = getPermissionDifference(alreadyExisting, currentlyIncluded);
      if (difference) {
        const { name, startDate, endDate, isOngoing, expiresAt } = curr;
        const updatedFeed = {
          id: alreadyExisting.id,
          name,
          startDate,
          endDate,
          isOngoing,
          expiresAt: expiresAt || null,
          difference,
        };
        return { ...obj, updated: { ...obj.updated, [curr.id]: updatedFeed } };
      }
      return obj;
    },
    { created: {}, updated: {}, deleted: {} }
  );

  const handleSubmitConfirmationModal = async () => {
    permissionTableLoading = true;
    confirmationModal = false;
    try {
      const { newFeedPermissions } = await putGroupFeedsPermissions({
        ...feedDifference,
        permissionId,
        groupId,
        serviceId,
      });

      visibleFeeds = newFeedPermissions;
      dbFeeds = newFeedPermissions;
    } catch (error) {
      console.error('[SaveFeeds] Failed to put group feed permissions!', error);
      fetchError = getServerErrorMessage(error) || 'Failed to save group feed permissions.';
    } finally {
      permissionTableLoading = false;
    }
  };

  $: validationErrorExists = Object.values(validationErrors).some((rowValidationErrors) =>
    Object.values(rowValidationErrors)?.some(Boolean)
  );

  $: submitable =
    serviceId !== DataInventoryServiceIds.SFTP05 ||
    !isEmpty(feedDifference.created) ||
    !isEmpty(feedDifference.updated) ||
    !isEmpty(feedDifference.deleted);
</script>

{#if visibleFeeds.length || dbFeeds.length}
  <div class="save-button">
    <Button
      disabled={validationErrorExists || !submitable}
      on:click={() => {
        confirmationModal = true;
      }}
    >
      Save
    </Button>
  </div>
{/if}

<Modal
  modalHeading="Confirm changes"
  preventCloseOnClickOutside={true}
  primaryButtonText={`Apply${hasRunningConnection ? ' (option blocked until current entitlement is finished)' : ''}`}
  secondaryButtonText="Cancel"
  size="lg"
  primaryButtonDisabled={hasRunningConnection}
  on:click:button--secondary={() => {
    confirmationModal = false;
  }}
  on:close={() => {
    confirmationModal = false;
  }}
  bind:open={confirmationModal}
  on:submit={handleSubmitConfirmationModal}
>
  <p>Are you sure you want to apply the following changes?</p>
  {#each ['created', 'updated', 'deleted'] as type}
    {#if Object.keys(feedDifference[type]).length}
      <div class="change-type-wrapper">
        <h5>{startCase(type)}</h5>
        <ul>
          {#each Object.values(feedDifference[type]) as { id, name, startDate, endDate, difference }}
            <li>
              - {name}:
              {#if difference}
                <ChangedFeedDifference {difference} />
              {:else}
                {startDate} - {endDate}
              {/if}
            </li>
          {/each}
        </ul>
      </div>
    {/if}
  {/each}
</Modal>

<style>
  .save-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .change-type-wrapper:not(:first-child) {
    margin-top: 2rem;
  }
</style>
