<script>
  import { tick } from 'svelte';
  import dayjs from 'dayjs';

  import { Checkbox, InlineNotification, Modal, Search, Tile } from 'carbon-components-svelte';
  import AddAlt20 from 'carbon-icons-svelte/lib/AddAlt20';
  import TrashCan20 from 'carbon-icons-svelte/lib/TrashCan20';
  import sortBy from 'lodash/sortBy';
  import uniq from 'lodash/uniq';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { getFirstDayOfTheMonth, getLastDayOfTheMonth, toLocalHyphenatedDateString } from '../../../../../shared/convertDates';

  import { validateDateInput, validateDemoDate } from './_shared';

  export let bulkAddModalOpen, addFeeds, feedInventory;

  let addedFeeds = [];
  let values = { startDate: '', endDate: '', isOngoing: false, expiresAt: '' };
  let noOverlapErrors = [];
  let searchData = '';

  const convertDate = (date) => date.slice(0, values.expiresAt ? 10 : 7);

  $: addableFeeds = feedInventory.filter(({ name }) => !addedFeeds.some(({ name: addedFeedName }) => addedFeedName === name));

  $: filteredAddableFeeds = searchData
    ? addableFeeds?.filter(({ name }) => name.toLocaleLowerCase().includes(searchData.toLocaleLowerCase()))
    : addableFeeds;

  const onSearchTextChange = ({ target }) => {
    searchData = target?.value?.trim() ?? '';
  };

  const refreshDates = async () => {
    await tick();
    if (
      values.expiresAt &&
      (!values.startDate || values.startDate < addedFeedsStartAvailability || values.startDate > addedFeedsEndAvailability)
    ) {
      values.startDate = addedFeedsStartAvailability;
      values.endDate = addedFeedsStartAvailability;
      return;
    }
    if (!values.startDate || values.startDate < addedFeedsStartAvailability || values.startDate > addedFeedsEndAvailability) {
      values.startDate = addedFeedsStartAvailability.slice(0, 7);
    }
    if (!values.endDate || values.endDate > addedFeedsEndAvailability || values.endDate < addedFeedsStartAvailability) {
      values.endDate = addedFeedsEndAvailability.slice(0, 7);
    }
  };

  const handleAddFeed = async ({
    id,
    name,
    availabilityStartDate = dayjs('2000-01-01').format('YYYY-MM-DD'),
    availabilityEndDate = dayjs().format('YYYY-MM-DD'),
    availabilityEndMonth = dayjs().format('YYYY-MM'),
    missingDays = [],
    missingMonths = [],
  }) => {
    if (addedFeeds.length && (availabilityStartDate > addedFeedsEndAvailability || availabilityEndDate < addedFeedsStartAvailability)) {
      noOverlapErrors = [{ name }, ...noOverlapErrors.slice(0, 1)];
      return;
    }
    addedFeeds = [
      ...addedFeeds,
      { id, name, startDate: availabilityStartDate, endDate: availabilityEndDate, availabilityEndMonth, missingDays, missingMonths },
    ];
    await refreshDates();
  };

  const handleDeleteFeed = async (name) => {
    addedFeeds = addedFeeds.filter((addedFeed) => addedFeed.name !== name);
  };

  const handleSubmitFeedModal = () => {
    if (Object.values(validationErrors).some(Boolean)) {
      return;
    }

    addFeeds(
      addedFeeds.map(({ id, name }) => ({
        id,
        name,
        availabilityStartDate: values.startDate,
        availabilityEndDate: values.endDate,
        isOngoing: values.isOngoing,
        expiresAt: values.expiresAt,
        isEdited: false,
      }))
    );

    addedFeeds = [];
    bulkAddModalOpen = false;
    values = { startDate: '', endDate: '', isOngoing: false, expiresAt: '' };
  };

  const setAndValidateKeyDate = (value, key) => {
    values[key] = value;
  };

  const handleToggleIsOngoing = (event) => {
    event.preventDefault();
    if (values.expiresAt) {
      return;
    }
    values.isOngoing = !values.isOngoing;
  };

  const handleToggleExpiresAt = (event) => {
    event.preventDefault();
    if (values.expiresAt) {
      values.expiresAt = null;
      values.startDate = addedFeedsLatestStartDate?.slice(0, 7);
      values.endDate = addedFeedsEarliestEndDate?.slice(0, 7);
    } else {
      const today = toLocalHyphenatedDateString();
      values.expiresAt = today;
      values.isOngoing = false;
      values.startDate = addedFeedsLatestStartDate;
      values.endDate = addedFeedsLatestStartDate;
    }
  };

  const handleExpiresAtChange = ({ target: { value } }) => {
    values.expiresAt = value;
  };

  const generalizedValidateDateInput = (key, value) => {
    const { missingDays, missingMonths } = addedFeeds.reduce((obj, curr) => {
      return {
        missingDays: uniq([...(obj.missingDays || []), ...curr.missingDays]),
        missingMonths: uniq([...(obj.missingMonths || []), ...curr.missingMonths]),
      };
    }, {});
    const oppositeDateKey = key === 'startDate' ? 'endDate' : 'startDate';
    return validateDateInput({
      value,
      availableStartDate: new Date(addedFeedsStartAvailability),
      availableEndDate: new Date(addedFeedsEndAvailability),
      isDemo: Boolean(values.expiresAt),
      key,
      oppositeDate: values[oppositeDateKey],
      missingDays,
      missingMonths,
    });
  };

  $: sortedAddedFeeds = sortBy(addedFeeds, 'name');
  $: addedFeedsLatestStartDate = sortBy(addedFeeds, 'startDate').pop()?.startDate ?? '';
  $: addedFeedsEarliestEndDate = sortBy(addedFeeds, 'endDate')[0]?.endDate ?? '';
  $: addedFeedsEarliestEndMonth = sortBy(addedFeeds, 'availabilityEndMonth')[0]?.availabilityEndMonth ?? '';

  $: addedFeedsStartAvailability = values.expiresAt
    ? addedFeedsLatestStartDate
    : addedFeedsLatestStartDate && getFirstDayOfTheMonth(addedFeedsLatestStartDate?.slice(0, 7));

  $: addedFeedsEndAvailability = values.expiresAt
    ? addedFeedsEarliestEndDate
    : addedFeedsEarliestEndMonth && getLastDayOfTheMonth(addedFeedsEarliestEndMonth);

  // validationErrors must be computed after other computed values
  $: validationErrors = {
    startDate: addedFeeds.length && generalizedValidateDateInput('startDate', values.startDate),
    endDate: addedFeeds.length && generalizedValidateDateInput('endDate', values.endDate),
    expiresAt: values.expiresAt && validateDemoDate(values.expiresAt),
  };
</script>

<Modal
  modalHeading="Bulk Add Feeds"
  preventCloseOnClickOutside={true}
  primaryButtonText="Add"
  secondaryButtonText="Cancel"
  size="lg"
  on:click:button--secondary={() => {
    bulkAddModalOpen = false;
  }}
  on:close={() => {
    bulkAddModalOpen = false;
  }}
  bind:open={bulkAddModalOpen}
  on:submit={handleSubmitFeedModal}
>
  <div class="errors-wrapper">
    {#each noOverlapErrors as { name }}
      <InlineNotification
        kind="warning"
        lowContrast
        title="Warning:"
        subtitle={`Feed ${name} cannot be added because it has no overlap with the current feed set.`}
      />
    {/each}
  </div>
  <div class="added-feeds-wrapper">
    <div class="section-wrapper" role="group" aria-label="addable feeds">
      <h5 class="subheading">Addable Feeds</h5>
      <Search
        labelText="Search for feeds"
        persistent
        size="sm"
        value={searchData}
        on:clear={onSearchTextChange}
        on:input={onSearchTextChange}
      />

      <div class="tile-wrapper">
        {#each filteredAddableFeeds as feed}
          <Tile key={feed.id} light on:click={() => handleAddFeed(feed)}><AddAlt20 class="data-feed-tile-icon add-icon" />{feed.name}</Tile>
        {/each}
      </div>
    </div>
    <div class="section-wrapper" role="group" aria-label="added feeds">
      <h5 class="subheading">Added Feeds</h5>

      <div class="added-tile-wrapper">
        {#each sortedAddedFeeds as { id, name }}
          <Tile key={id} light on:click={() => handleDeleteFeed(name)}><TrashCan20 class="data-feed-tile-icon trash-can-icon" />{name}</Tile
          >
        {/each}
      </div>
    </div>
  </div>

  <div class="settings-wrapper">
    {#if addedFeedsStartAvailability}
      <div class="feed-availability">
        The current selection is available from {convertDate(addedFeedsStartAvailability)} to {convertDate(addedFeedsEndAvailability)}
      </div>
    {/if}
    <TextInput
      inline
      name="startDate"
      labelText="Start Date"
      placeholder="Start date"
      required
      size="sm"
      invalid={validationErrors.startDate}
      invalidText={validationErrors.startDate}
      value={values.startDate}
      on:blur={(e) => setAndValidateKeyDate(e.target.value, 'startDate')}
    />
    <TextInput
      inline
      name="endDate"
      labelText="End Date"
      placeholder="End date"
      required
      size="sm"
      invalid={validationErrors.endDate}
      invalidText={validationErrors.endDate}
      value={values.endDate}
      on:blur={(e) => setAndValidateKeyDate(e.target.value, 'endDate')}
    />
    <Checkbox labelText="Ongoing" checked={values.isOngoing} on:click={handleToggleIsOngoing} disabled={Boolean(values.expiresAt)} />
    <Checkbox labelText="Demo Access" checked={Boolean(values.expiresAt)} on:click={handleToggleExpiresAt} />
    <TextInput
      inline
      invalid={validationErrors.expiresAt}
      invalidText={validationErrors.expiresAt}
      labelText="Demo Expires At"
      placeholder={values.expiresAt && 'yyyy-mm-dd'}
      disabled={!values.expiresAt}
      size="sm"
      value={values.expiresAt}
      on:blur={handleExpiresAtChange}
    />
  </div>
</Modal>

<style>
  .errors-wrapper {
    margin: 0 2rem;
  }

  .added-feeds-wrapper {
    display: flex;
    margin-bottom: 2rem;
  }

  .section-wrapper {
    margin: 0 2rem;
    width: calc(50% - 4rem);
  }

  .tile-wrapper {
    height: 20.8rem;
    overflow: auto;
  }

  .added-tile-wrapper {
    height: 22.8rem;
    overflow: auto;
  }

  :global(.data-feed-tile-icon) {
    min-width: 1.5rem;
  }

  .subheading {
    margin-bottom: 0.5rem;
  }

  .section-wrapper :global(.bx--tile) {
    cursor: pointer;
    border-bottom: 1px dotted var(--cds-ui-05, #161616);
    display: flex;
    align-items: center;
    min-height: 2rem;
    max-height: 2rem;
  }

  .section-wrapper :global(.bx--tile:hover) {
    background: var(--cds-hover-ui, #e5e5e5);
  }

  .section-wrapper :global(svg) {
    margin-right: 0.5rem;
  }

  .added-tile-wrapper :global(.trash-can-icon) {
    color: var(--cds-text-error);
  }

  .tile-wrapper :global(.add-icon) {
    color: var(--cds-support-02);
  }

  .settings-wrapper {
    margin: 0 2rem;
  }

  .feed-availability {
    margin-bottom: 1rem;
  }

  .settings-wrapper > :global(.bx--form-item) {
    margin-bottom: 1rem;
  }

  .settings-wrapper > :global(.bx--checkbox-wrapper) {
    max-width: 8rem;
  }

  .settings-wrapper :global(.bx--text-input:disabled) {
    background-color: var(--cds-field-02, #c6c6c6);
  }
</style>
