import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function createExternalKey(data) {
  return request(`${ROOT_SERVER_URL}/api/external-keys`, {
    data,
    method: 'POST',
  });
}

export function deleteExternalKey(keyId) {
  return request(`${ROOT_SERVER_URL}/api/external-keys/${keyId}`, {
    method: 'DELETE',
  });
}

export function getGroupExternalKeys(groupId) {
  return request(`${ROOT_SERVER_URL}/api/external-keys/list/${groupId}`);
}

export function updateExternalKey(keyId, changes) {
  return request(`${ROOT_SERVER_URL}/api/external-keys/${keyId}`, {
    data: changes,
    method: 'PUT',
  });
}
