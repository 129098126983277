<script>
  import { onMount } from 'svelte';
  import { useNavigate } from 'svelte-navigator';

  import { OverflowMenu, OverflowMenuItem, Tag, Tooltip, TooltipDefinition } from 'carbon-components-svelte';
  import CalendarIcon from 'carbon-icons-svelte/lib/Calendar24';
  import ClockIconBig from 'carbon-icons-svelte/lib/Time24';
  import DataBaseIcon from 'carbon-icons-svelte/lib/DataBase16';
  import truncate from 'lodash/truncate';

  import { LicenseStatus, findLicenseStatus } from '../../utils/licenseStatus';
  import { SUPPORTED_RENEWAL_CYCLES } from './LicenseTemplateBasicForm.svelte';
  import { appConfig } from '../../stores';

  export let license,
    focus = false,
    editBasicLicenseInfo,
    editFeatures,
    viewLicense,
    addOrEditNote,
    generateLicense,
    licenseLookbackDays = 15,
    deleteTemplate;

  let cardElement;

  const navigate = useNavigate();

  function focusCard() {
    if (!focus || !cardElement) {
      return;
    }

    cardElement.focus();
  }

  function goToGeneratedLicenses(templateId) {
    navigate(`/licenses?filter=${templateId}`);
  }

  onMount(focusCard);

  $: licenseStatus = findLicenseStatus(license, licenseLookbackDays);
  $: isRenewal = [LicenseStatus.EXPIRED, LicenseStatus.DANGER].includes(licenseStatus.status);
</script>

<div bind:this={cardElement} class={`license ${licenseStatus.status}`} tabindex="0">
  <div class="license-message">
    <svelte:component this={licenseStatus.icon} />
    {licenseStatus.message}
  </div>
  <div class="title">
    <h3 class="h3">{truncate(license.name, { length: 20 })}</h3>
  </div>
  <div class="details">
    <div class="info">
      <ClockIconBig />
      <div class="label">
        <strong>Cycle</strong>
        <span>{SUPPORTED_RENEWAL_CYCLES[license.cycle]}</span>
      </div>
    </div>
    <div class="info">
      <CalendarIcon />
      <div class="label">
        <strong>Expiration date</strong>
        <span>{license.newestGeneratedLicense?.isPermanent ? 'No expiration' : license.newestGeneratedLicense?.expirationDate ?? '-'}</span>
      </div>
    </div>
    <div class="selections">
      <div class="selection">
        {#if license.fullFeatureList.length}
          <Tooltip hideIcon>
            <h3 slot="triggerText" class="h3 tooltip-text">{license.fullFeatureList.length}</h3>
            <ul>
              {#each license.fullFeatureList as { name: feature }}
                <li>{feature}</li>
              {/each}
            </ul>
          </Tooltip>
        {:else}
          <h3 class="h3 non-tooltip-text">{license.fullFeatureList.length}</h3>
        {/if}
        <strong>Feature{license.fullFeatureList.length !== 1 ? 's' : ''}</strong>
      </div>
      <div class="selection">
        {#if license.flatSelectedGroups.length}
          <Tooltip hideIcon>
            <h3 slot="triggerText" class="h3 tooltip-text">{license.flatSelectedGroups.length}</h3>
            <ul>
              {#each license.flatSelectedGroups as { name: group }}
                <li>{group}</li>
              {/each}
            </ul>
          </Tooltip>
        {:else}
          <h3 class="h3 non-tooltip-text">{license.flatSelectedGroups.length}</h3>
        {/if}
        <strong>Group{license.flatSelectedGroups.length !== 1 ? 's' : ''}</strong>
      </div>
    </div>
  </div>
  {#if license.isDemo}
    <Tag type="blue" size="sm">DEMO</Tag>
  {/if}
  <div class="toolbar">
    <div class="extra">
      <Tooltip icon={DataBaseIcon} triggerText={license.uploadDestinations.length} align="start">
        <ul>
          {#each license.uploadDestinations as { destination, path }}
            <li>{$appConfig.data.supportedUploadDestinations[destination]} {path}</li>
          {/each}
        </ul>
      </Tooltip>
      {#if license.note}
        <div class="note">
          <TooltipDefinition tooltipText={license.note}>Note</TooltipDefinition>
        </div>
      {/if}
    </div>
    <OverflowMenu flipped>
      <OverflowMenuItem
        class="border-bottom"
        text={isRenewal ? 'Renew License' : 'Generate license'}
        disabled={licenseStatus.status === LicenseStatus.ERROR}
        on:click={generateLicense}
      />
      <OverflowMenuItem text="Edit basic information" on:click={editBasicLicenseInfo} />
      <OverflowMenuItem text="Edit features" on:click={editFeatures} />
      <OverflowMenuItem text={license.note ? 'Edit note' : 'Add note'} on:click={addOrEditNote} />
      <OverflowMenuItem class="border-top" text="View license" disabled={!license.newestGeneratedLicense} on:click={viewLicense} />
      <OverflowMenuItem class="border-bottom" text="View generated" on:click={goToGeneratedLicenses(license.id)} />
      <OverflowMenuItem danger text="Delete template" on:click={deleteTemplate} />
    </OverflowMenu>
  </div>
</div>

<style>
  .h3 {
    font-size: 1.2rem;
  }

  .tooltip-text {
    cursor: pointer;
    border-bottom: 0.0625rem dotted var(--cds-text-02);
    margin-bottom: 0.25rem;
    padding: 0 0.2rem;
  }

  .non-tooltip-text {
    /* based on .tooltip-text border + margin */
    margin-bottom: calc(0.0625rem + 0.25rem);
    color: var(--cds-text-02);
    user-select: none;
  }

  .license-message {
    display: flex;
    align-items: center;
    font-size: 0.725rem;
    position: absolute;
    top: -1.5rem;
    right: 0.5rem;
    padding: 0.2rem 0.5rem 0.11rem;
    border-radius: 2px 2px 0 0;
    color: var(--cds-text-04);
  }

  .license-message :global(svg) {
    margin-right: 0.2rem;
  }

  .license {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    margin: 0.3rem;
    background-color: var(--cds-ui-01);
  }

  .license:focus {
    outline: 2px solid var(--cds-focus);
    outline-offset: -2px;
  }

  .license.warning {
    border: 3px solid var(--outline-warning-color);
  }

  .license.warning .license-message {
    background-color: var(--outline-warning-color);
  }

  .license.danger {
    border: 3px solid var(--cds-danger);
  }

  .license.danger .license-message {
    background-color: var(--cds-danger);
  }

  .license.expired,
  .license.error {
    border: 3px solid var(--cds-active-danger);
  }

  .license.expired .license-message,
  .license.error .license-message {
    background-color: var(--cds-active-danger);
  }

  .license.valid {
    border: 3px solid #24a148;
  }

  .license.valid .license-message {
    background-color: #24a148;
  }

  .license .title {
    display: flex;
    flex-direction: column;
  }

  .license .details {
    margin: 0.5rem 0;
  }

  .license .details .info {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }

  .license .details .info :global(svg) {
    fill: var(--cds-text-02);
  }

  .license .details .info :global(strong) {
    margin-bottom: 0.2rem;
  }

  .license .details .info :global(span) {
    color: var(--cds-text-02);
    font-size: 0.75rem;
  }

  .license .details .label {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  .license .details .selections {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
  }

  .license .details .selections .selection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .license .details .selections .selection :global(ul) {
    max-height: 150px;
    overflow-y: auto;
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-self: flex-end;
    margin-top: auto;
  }

  .toolbar .extra {
    display: flex;
    width: 100%;
  }

  .toolbar .extra > :global(div) {
    margin-right: 1rem;
  }

  .toolbar .extra .note {
    justify-self: flex-end;
    margin-left: auto;
  }

  .toolbar :global(.bx--tooltip__content ul) {
    list-style: unset;
    margin-left: 1rem;
  }

  .toolbar :global(.bx--tooltip__content ul li) {
    margin-bottom: 0.3rem;
  }

  .toolbar :global(.bx--overflow-menu-options) {
    min-width: 11.25rem;
  }

  .toolbar :global(.bx--overflow-menu-options__option.border-top) {
    border-top: 1px solid var(--cds-ui-03);
  }

  .toolbar :global(.bx--overflow-menu-options__option.border-bottom) {
    border-bottom: 1px solid var(--cds-ui-03);
  }
</style>
