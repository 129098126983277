<script>
  import { onMount } from 'svelte';
  import truncate from 'lodash/truncate';

  import { Button, InlineNotification, OverflowMenu, OverflowMenuItem, Toolbar, ToolbarContent } from 'carbon-components-svelte';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import AddOrEditParameterModal from './AddOrEditParameterModal.svelte';
  import DeleteParameterNameModal from './DeleteParameterNameModal.svelte';
  import ParameterValuesModal from './ParameterValuesModal.svelte';

  import CustomInlineNotification from '../../components/CustomInlineNotification.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { rowContainsText } from '../../utils';
  import { getParameterNames } from '../../services';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  const PARAMETER_TYPES = {
    single: 'Single-valued',
    multiple: 'Multi-valued',
    boolean: 'Boolean',
  };

  let pageData = { parameters: [], loading: true },
    pagination = { currentPage: 1, pageSize: 25 },
    searchText = '',
    filteredRows = [];

  let modals = {
    addOrEdit: { open: false },
    values: { open: false },
    delete: { open: false },
  };

  function updatePageData(modification) {
    const { action, data } = modification;
    let newParameters;

    if (!data) {
      return;
    }

    searchText = '';

    switch (action) {
      case 'add-parameter':
        newParameters = [...(pageData.parameters ?? []), data];

        pageData = {
          ...pageData,
          parameters: newParameters,
        };

        break;

      case 'update-parameter':
        newParameters = (pageData.parameters ?? []).reduce(
          (all, param) => [...all, param.id === data.id ? { ...param, ...data } : param],
          []
        );

        pageData = {
          ...pageData,
          parameters: newParameters,
        };

        break;

      case 'delete-parameter':
        newParameters = (pageData.parameters ?? []).filter((param) => param.id !== data.id);

        pageData = {
          ...pageData,
          parameters: newParameters,
        };

        break;

      case 'update-values':
        newParameters = (pageData.parameters ?? []).map((param) =>
          param.id === data.id ? { ...param, valuesCount: data.valuesCount } : param
        );

        pageData = {
          ...pageData,
          parameters: newParameters,
        };

        break;

      default:
        console.warn(`[SystemParameterMeta] Unknown action "${action}" for page data update`, data);
        break;
    }
  }

  async function fetchParameters() {
    try {
      const parameters = await getParameterNames();
      pageData = { parameters, loading: false };
    } catch (error) {
      console.error('[SystemParameterMeta] Failed to load parameters!', error);
      pageData = { loading: false, error };
    }
  }

  function onSearchTextChange(e) {
    searchText = e?.detail ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  function openModal(name, forwardData = {}) {
    return function handleClickForOpen() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  onMount(fetchParameters);

  $: if (pageData.parameters) {
    filteredRows = searchText ? pageData.parameters.filter((parameter) => rowContainsText(parameter, searchText)) : pageData.parameters;
  }
</script>

{#if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load parameters! Please try again later."
  />
{/if}
{#if !pageData.parameters.length && !pageData.loading}
  <CustomInlineNotification
    kind="info"
    lowContrast
    title="Info:"
    subtitle="There are not any known parameter names or values defined yet."
    actions={[{ text: 'Add Parameter', onClick: openModal('addOrEdit') }]}
  />
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'name', value: 'Parameter' },
        { key: 'description', value: 'Description', display: (description) => truncate(description || '-', { length: 50 }) },
        { key: 'type', value: 'Type', display: (type) => PARAMETER_TYPES[type] },
        { key: 'valuesCount', value: 'Known Values', display: (valueCount) => valueCount || '-' },
        { key: 'actions', empty: true, sort: false },
      ]}
      rows={filteredRows}
      sortable
      pageSize={pagination.pageSize}
      page={pagination.currentPage}
    >
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem text="Edit Parameter" on:click={openModal('addOrEdit', { data: row })} />
            {#if row.type !== 'boolean'}
              <OverflowMenuItem text="Modify Values" on:click={openModal('values', { data: row })} />
            {/if}
            <OverflowMenuItem danger text="Delete Parameter" on:click={openModal('delete', { data: row })} />
          </OverflowMenu>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearchWithRouting
            labelText="Search for parameter"
            persistent
            bind:searchText
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          <Button on:click={openModal('addOrEdit')}>Add parameter</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:page={pagination.currentPage}
      bind:pageSize={pagination.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={filteredRows.length}
    />
  </LoadingSpinner>
{/if}
{#if modals.addOrEdit.open}
  <AddOrEditParameterModal
    open
    initialValues={modals.addOrEdit.data}
    on:close={({ detail: data }) =>
      closeModal('addOrEdit', {
        action: modals.addOrEdit.data ? 'update-parameter' : 'add-parameter',
        data,
      })}
  />
{/if}
{#if modals.delete.open}
  <DeleteParameterNameModal
    open
    parameter={modals.delete.data}
    on:close={({ detail: data }) =>
      closeModal('delete', {
        action: 'delete-parameter',
        data,
      })}
  />
{/if}
{#if modals.values.open}
  <ParameterValuesModal
    open
    parameter={modals.values.data}
    on:update-values={({ detail: data }) => updatePageData({ action: 'update-values', data })}
    on:close={() => closeModal('values')}
  />
{/if}

<style>
  .action-cell :global(.bx--overflow-menu) {
    min-height: 2.5rem;
  }

  .action-cell :global(.bx--overflow-menu-options) {
    min-width: 11rem;
  }
</style>
