<script>
  import { onMount } from 'svelte';

  import { ComboBox } from '@mst-fe/carbon-components-svelte';

  import { getAllUsers } from '../../../services';
  import { rowContainsText } from '../../../utils';

  export let errors, resultNotification, setValue, touched, values;

  let users;

  onMount(async () => {
    try {
      users = await getAllUsers();
    } catch (error) {
      console.error('[UserSelectComboBox] Failed to retrieve users!', error);
      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to load users!`,
      };
    }
  });

  function shouldFilterUser(user, value) {
    if (!value || value.length < 2) {
      return true;
    }

    return rowContainsText(user, value);
  }

  async function onSelectUser({ detail: { selectedId } }, setFormValue) {
    if (!selectedId) {
      return;
    }
    setFormValue('userId', selectedId);
  }

  $: userOptions = users?.map((user) => ({
    ...user,
    text: user.name === user.email ? user.name : `${user.name} (${user.email})`,
  }));

  $: selectedUserId = userOptions ? values.userId : null;
</script>

<ComboBox
  invalid={touched.userId && !!errors.userId}
  invalidText={errors.userId}
  items={userOptions || [{ id: '', text: 'Users data is loading...' }]}
  name="userId"
  placeholder="User name"
  selectedId={selectedUserId}
  shouldFilterItem={shouldFilterUser}
  titleText="User"
  required
  on:clear={() => {
    setValue('userId', '');
  }}
  on:select={(event) => onSelectUser(event, setValue)}
/>
