import { ROOT_SERVER_URL } from '../config';
import { collectQueryParams, request } from '../utils';

export function cancelExecution(executionId) {
  return request(`${ROOT_SERVER_URL}/api/tasks/${executionId}/cancel`, {
    method: 'POST',
  });
}

export function getExecutions(params) {
  const urlSearchParams = collectQueryParams(params);
  return request(`${ROOT_SERVER_URL}/api/tasks/executions?${urlSearchParams}`);
}

export function getTasks() {
  return request(`${ROOT_SERVER_URL}/api/tasks`);
}

export function triggerTask(taskId) {
  return request(`${ROOT_SERVER_URL}/api/tasks/${taskId}/execution`, {
    method: 'POST',
  });
}
