<script>
  import { InlineNotification, NotificationActionButton } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import LoadingSpinner from '../../../../components/LoadingSpinner.svelte';

  export let feedPermissionsTableNotifications;

  const navigate = useNavigate();
</script>

{#if feedPermissionsTableNotifications.noFeedsAdded}
  <InlineNotification
    kind="info"
    lowContrast
    title="Info:"
    subtitle={`
        This group has no data feed entitlements.
        Click either the "Bulk add feeds" button or the "+" button next to a data feed below to get started.
      `}
  />
{/if}

{#if feedPermissionsTableNotifications.hasRunningConnection}
  <InlineNotification kind="info" lowContrast>
    <p class="bx--inline-notification__title connection-warning">
      <span>Info:</span>
      <LoadingSpinner
        withOverlay={false}
        centered={false}
        compact
        description="There is running connection for this data feeds permissions!"
        inlineLeftMargin
      />
    </p>
    <div class="bx--inline-notification__subtitle">Entitlement in progress. Submitting new changes disabled until it's finished!</div>
    <div slot="actions">
      <NotificationActionButton on:click={() => navigate('/tasks#executions')}>View task executions</NotificationActionButton>
    </div>
  </InlineNotification>
{/if}

<style>
  .connection-warning {
    display: inline-flex;
  }
</style>
