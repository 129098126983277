<script>
  import { createEventDispatcher } from 'svelte';

  import { FormGroup, InlineNotification, Modal } from 'carbon-components-svelte';
  import * as yup from 'yup';

  import { TextInput, TextArea } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import ApiRoutesControl from './ApiRoutesControl.svelte';
  import UserSelectComboBox from './UserSelectComboBox.svelte';
  import LoadingSpinner from '../../../components/LoadingSpinner.svelte';

  import { addApiKey, updateApiKey } from '../../../services';

  export let availableRoutes,
    apiKeyId,
    isEdit,
    initialValues,
    open = false;

  const dispatch = createEventDispatcher();

  let formSubmitButtonRef,
    loading = false,
    resultNotification = null;

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }
    formSubmitButtonRef.click();
  }

  async function handleFormSubmission({ detail: { values } }) {
    loading = true;
    try {
      if (isEdit) {
        await updateApiKey(values, apiKeyId);
        open = false;
      } else {
        const { apiKey } = await addApiKey(values);
        resultNotification = {
          kind: 'success',
          title: 'Success:',
          subtitle: `API key added successfully! The key is: '${apiKey}'. 
          As this value is sensitive, it will not be shown again.`,
        };
      }
      dispatch('keyAddedOrUpdated');
    } catch (error) {
      console.error(`[AddOrEditApiKeyModal] Failed to ${isEdit ? 'update' : 'create'} API key!`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to ${isEdit ? 'update' : 'create'} API key!`,
      };
    } finally {
      loading = false;
    }
  }

  const routeObject = yup.object({
    method: yup.string().required(),
    route: yup.string().required(),
  });
  $: schema = yup.object().shape({
    ...(isEdit ? {} : { userId: yup.string().required('Select a user!') }),
    alias: yup.string().required('Enter an alias!'),
    description: yup.string(),
    routes: yup.array(routeObject).min(1, 'Select at least one route!').required('Select at least one route!'),
  });
</script>

<Modal
  hasForm
  modalHeading={`${isEdit ? 'Edit' : 'Add'} API Key`}
  preventCloseOnClickOutside
  primaryButtonText={isEdit ? 'Save' : 'Add'}
  primaryButtonDisabled={resultNotification}
  secondaryButtonText="Cancel"
  size="lg"
  shouldSubmitOnEnter={false}
  bind:open
  on:click:button--secondary={() => {
    open = false;
  }}
  on:close={() => {
    open = false;
  }}
  on:submit={onModalSubmit}
>
  {#if resultNotification}
    <InlineNotification
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
      hideCloseButton
    />
  {:else}
    <LoadingSpinner {loading}>
      <p>
        API keys require an alias to help identify the key, a description of the key, and an associated user. Associated users are required
        for all keys to improve readability of audit logs; for this reason, MACE users should be created for all integrations and given
        informative names.
      </p>
      <Form
        validateOnBlur={false}
        validateOnChange={false}
        {initialValues}
        {schema}
        let:submitForm
        let:errors
        let:setValue
        let:touched
        let:values
        on:submit={handleFormSubmission}
      >
        <h4>Basic Information</h4>
        <FormGroup>
          {#if !isEdit}
            <UserSelectComboBox {errors} {setValue} {touched} {values} bind:resultNotification />
          {/if}
          <TextInput
            required
            name="alias"
            invalid={touched.alias && !!errors.alias}
            invalidText={errors.alias}
            labelText="Alias"
            placeholder="Alias..."
            value={values.alias}
            on:change={({ detail: text }) => setValue('alias', text)}
          />
          <TextArea
            invalid={touched.description && !!errors.description}
            invalidText={errors.description}
            labelText="Description"
            name="description"
            placeholder="Description..."
            type="text"
            value={values.description}
            on:change={({ target }) => setValue('description', target.value)}
          />
        </FormGroup>

        <h4>Authorized Endpoints</h4>
        <FormGroup>
          <ApiRoutesControl {availableRoutes} initialRoutes={initialValues?.routes} setRoutes={setValue} validationError={errors.routes} />
        </FormGroup>
        <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
      </Form>
    </LoadingSpinner>
  {/if}
</Modal>

<style>
  h4 {
    font-size: var(--cds-productive-heading-02-font-size, 1rem);
    font-weight: var(--cds-productive-heading-02-font-weight, 600);
    line-height: var(--cds-productive-heading-02-line-height, 1.375);
    letter-spacing: var(--cds-productive-heading-02-letter-spacing, 0);
    margin: 1rem 0 0.5rem;
  }
</style>
