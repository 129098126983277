export const TOKEN_EXPIRY_TOLERANCE = 5000;

export const ROOT_SERVER_URL = window.location.origin.startsWith('http://entitlements.local.maystreet.com')
  ? 'http://localhost:3002'
  : window.location.origin;

export const WEBSOCKET_URL = (() => {
  const { host, protocol } = new URL(ROOT_SERVER_URL);
  return `${protocol === 'https:' ? 'wss' : 'ws'}://${host}`;
})();
