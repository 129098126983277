<script>
  import { useLocation, useNavigate } from 'svelte-navigator';
  import { Link } from 'carbon-components-svelte';
  import ArrowLeft16 from 'carbon-icons-svelte/lib/ArrowLeft16';

  import GroupPermissionsList from './GroupPermissionsList.svelte';
  import DataFeedPermission from './data-feeds/DataFeedPermission.svelte';
  import InstancePermission from './instances/InstancePermission.svelte';

  export let groupId, groupName;

  const location = useLocation();
  const navigate = useNavigate();

  function onNavigateBack(event) {
    event.preventDefault();
    navigate('#permissions');
  }

  $: currentHash = $location?.hash;
  $: pathname = $location.pathname;
</script>

{#if currentHash !== '#permissions'}
  <Link class="back-link" href={`${pathname}#permissions`} icon={ArrowLeft16} on:click={onNavigateBack}>All permissions</Link>
{/if}

{#if currentHash === '#permissions'}
  <GroupPermissionsList {groupId} {groupName} />
{:else if currentHash === '#permissions-data-feeds-sftp05'}
  <DataFeedPermission {groupId} serviceId={1} type="data-feeds-sftp05" />
{:else if currentHash === '#permissions-data-feeds-lake'}
  <DataFeedPermission {groupId} serviceId={2} type="data-feeds-lake" />
{:else if currentHash === '#permissions-data-feeds-aws-arbitrated-pcaps'}
  <DataFeedPermission {groupId} serviceId={3} type="data-feeds-aws-arbitrated-pcaps" />
{:else if currentHash === '#permissions-data-feeds-crushftp'}
  <DataFeedPermission {groupId} serviceId={1} type="data-feeds-crushftp" />
{:else if currentHash === '#permissions-workbench'}
  <InstancePermission {groupId} />
{/if}

<style>
  :global(.bx--link.back-link) {
    display: inline-flex;
    flex-direction: row-reverse;
    margin: 0 0 0.5rem 0;
  }
  :global(.bx--link.back-link > .bx--link__icon) {
    margin-left: 0;
    margin-right: 0.375rem;
  }
</style>
