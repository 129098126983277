<script>
  import { Breadcrumb, BreadcrumbItem } from 'carbon-components-svelte';
  import Home16 from 'carbon-icons-svelte/lib/Home16';
  import { useNavigate, useLocation } from 'svelte-navigator';

  import LoadingSpinner from './LoadingSpinner.svelte';
  import { entityNameStore } from '../stores';

  const location = useLocation();
  const navigate = useNavigate();

  let routeHeadingRef;

  const replaceUuid = (route) => route.replace(/([^/]+$)/, ':uuid');

  const ROUTES_CONFIGURATION = {
    '/organizations': {
      title: 'Organizations',
    },
    '/groups': {
      title: 'Groups',
    },
    '/users': {
      title: 'Users',
    },
    '/tasks': {
      title: 'Tasks',
    },
    '/data-feeds': {
      title: 'Data Feed Inventory',
    },
    '/customer-builds': {
      title: 'Customer Builds',
    },
    '/licenses': {
      title: 'Licenses',
    },
    '/workbench': {
      title: 'Workbench Plans',
    },
    '/emails': {
      title: 'Emails',
    },
    '/user/:uuid': {
      additionalRoutes: [{ title: 'Users', route: '/users' }],
      replaceUuid: true,
      title: 'User Details',
    },
    '/user/create': {
      additionalRoutes: [{ title: 'Users', route: '/users' }],
      breadcrumbTitle: 'New',
      title: 'New User',
    },
    '/organization/:uuid': {
      additionalRoutes: [{ title: 'Organizations', route: '/organizations' }],
      replaceUuid: true,
      title: 'Organization Details',
    },
    '/organization/create': {
      additionalRoutes: [{ title: 'Organizations', route: '/organizations' }],
      breadcrumbTitle: 'New',
      title: 'New Organization',
    },
    '/group/:uuid': {
      additionalRoutes: [{ title: 'Groups', route: '/groups' }],
      replaceUuid: true,
      title: 'Group Details',
    },
    '/group/create': {
      additionalRoutes: [{ title: 'Groups', route: '/groups' }],
      breadcrumbTitle: 'New',
      title: 'New Group',
    },
    '/data-feed/:uuid': {
      additionalRoutes: [{ title: 'Data Feed Inventory', route: '/data-feeds' }],
      replaceUuid: true,
      title: 'Data Feed Details',
    },
    '/admin': {
      title: 'Admin Dashboard',
    },
    '/': {
      noBreadcrumb: true,
      title: 'Home',
    },
  };

  const ROUTES = Object.keys(ROUTES_CONFIGURATION);

  $: pathname = $location.pathname;
  $: routeConfiguration = Object.entries(ROUTES_CONFIGURATION).find(([route, config]) => {
    if (ROUTES.includes(pathname)) {
      return route === pathname;
    }
    return config.replaceUuid && replaceUuid(pathname) === route;
  })?.[1];

  $: uuid = routeConfiguration.replaceUuid ? pathname.slice(pathname.lastIndexOf('/') + 1) : null;

  $: if (pathname) {
    routeHeadingRef?.focus();
  }
</script>

<svelte:head>
  <title>{routeConfiguration.title} - MACE</title>
</svelte:head>
<h1 class="route-heading-title" tabindex="0" bind:this={routeHeadingRef}>{routeConfiguration.title}</h1>
{#if routeConfiguration && !routeConfiguration.noBreadcrumb}
  <Breadcrumb noTrailingSlash>
    <BreadcrumbItem
      href="/"
      on:click={(event) => {
        event.preventDefault();
        navigate('/');
      }}
    >
      <Home16 title="Home" />
    </BreadcrumbItem>
    {#each routeConfiguration.additionalRoutes || [] as { route, title }}
      <BreadcrumbItem
        href={route}
        on:click={(event) => {
          event.preventDefault();
          navigate(route);
        }}
      >
        {title}
      </BreadcrumbItem>
    {/each}
    <BreadcrumbItem isCurrentPage>
      {#if !uuid}
        {routeConfiguration.breadcrumbTitle ?? routeConfiguration.title}
      {:else if !$entityNameStore[uuid]}
        <LoadingSpinner compact centered={false} withOverlay={false} />
      {:else}
        {$entityNameStore[uuid]}
      {/if}
    </BreadcrumbItem>
  </Breadcrumb>
{/if}

<style>
  .route-heading-title {
    display: inline-block;
  }
</style>
