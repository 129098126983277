const { listDaysBetweenTwoDates, listMonthsBetweenTwoDates } = require('./convertDates');

const listDatesBetweenTwoDates = (startDate, endDate, isDemo) => {
  return isDemo ? listDaysBetweenTwoDates(startDate, endDate) : listMonthsBetweenTwoDates(startDate, endDate);
};

const getPickedMissingDates = ({ isDemo, missingMonths, missingDays, startDate, endDate }) => {
  const missingDates = isDemo ? missingDays : missingMonths;
  const datesList = listDatesBetweenTwoDates(startDate, endDate, isDemo);
  return {
    chunks: datesList.filter((date) => missingDates.includes(date)),
    chunkType: isDemo ? 'days' : 'months',
  };
};

module.exports = { getPickedMissingDates, listDatesBetweenTwoDates };
