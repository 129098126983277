<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { removeBuildConfig } from '../../services';

  export let targetName,
    targetId,
    hasParameters,
    isDefault,
    open = false;

  let loading = false;
  let resultNotification;

  const dispatch = createEventDispatcher();

  async function deleteTarget() {
    loading = true;

    try {
      await removeBuildConfig(targetId);

      dispatch('close', { id: targetId, name: targetName });
    } catch (error) {
      console.error('[RemoveTargetModal] Failed to remove target!', error);

      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to remove target! ${error?.response?.data?.error || 'Please try again later.'}`,
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  {...isDefault
    ? {
        passiveModal: true,
        modalHeading: 'Cannot Delete Target',
      }
    : {
        danger: true,
        modalHeading: 'Delete Target',
        primaryButtonText: 'Delete',
        secondaryButtonText: 'Cancel',
      }}
  preventCloseOnClickOutside={true}
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={deleteTarget}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    {#if isDefault}
      <InlineNotification
        hideCloseButton
        lowContrast
        kind="warning"
        title="Unable to delete current target"
        subtitle="The target you are trying to delete is set as default for the current group."
      />
      <p>Please select another target as the default one, in order to be able to proceed.</p>
    {:else}
      <p>You are about to delete build target <strong>{targetName}</strong>.</p>
      {#if hasParameters}
        <p><strong>Every build parameter under this target will be lost.</strong></p>
      {/if}
      <br />
      <p>Are you sure?</p>
    {/if}
  </LoadingSpinner>
</Modal>
