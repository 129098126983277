<script>
  import copy from 'clipboard-copy';
  import { createEventDispatcher } from 'svelte';

  import { Button, OverflowMenu, OverflowMenuItem, Toolbar, ToolbarContent, ToolbarMenu, ToolbarMenuItem } from 'carbon-components-svelte';
  import { DataTable } from '@mst-fe/carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import Box32 from 'carbon-icons-svelte/lib/Box32';

  import AddOrEditParameterModal from './AddOrEditParameterModal.svelte';
  import BuildOverviewModal from './BuildOverviewModal.svelte';
  import CloneBuildConfigModal from './CloneBuildConfigModal.svelte';
  import CustomInlineNotification from '../CustomInlineNotification.svelte';
  import CustomParameterValueCell from './CustomParameterValueCell.svelte';
  import JsonBuildInputModal from './JsonBuildInputModal.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import RemoveParameterModal from './RemoveParameterModal.svelte';
  import RemoveConfigParameters from './RemoveConfigParameters.svelte';
  import ToolbarSearchWithRouting from '../ToolbarSearchWithRouting.svelte';

  import { getInheritedBuildParameters } from '../../services';
  import { convertToLocalDisplayTime, rowContainsText } from '../../utils';
  import { PARAMETER_MERGE_SUFFIX } from '../../../shared/constants';

  export let loading,
    entityType,
    entityName,
    entityId,
    buildConfig,
    activeNotifications,
    extraOptions = true;

  let knownConfigs = {};

  const dispatch = createEventDispatcher();
  const navigate = useNavigate();

  const shouldBeOverwritten = {
    system: ['organization', 'group'],
    organization: ['group'],
  };

  const basicTableHeaders = [
    { key: 'parameterName', value: 'Parameter Name' },
    { key: 'parameterValue', value: 'Parameter Value' },
    { key: 'createdAt', value: 'Added', display: convertToLocalDisplayTime },
    { key: 'updatedAt', value: 'Updated', display: convertToLocalDisplayTime },
    { key: 'actions', empty: true, sort: false },
  ];

  const customCellComponents = {
    parameterValue: CustomParameterValueCell,
  };

  const initialPageData = {
    loading: false,
    inheritanceActive: false,
    inheritedParameters: {},
    searchText: '',
  };

  let pageData = { ...initialPageData };

  const notifications = {
    errorParameters: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load build parameters! Please try again later.',
    },
    noParameters: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no build parameters for this entity.',
      actions: [
        {
          text: 'Add parameter',
          onClick: handleClickForModal('parameter'),
        },
        {
          text: 'Add JSON configuration',
          onClick: handleClickForModal('jsonInput'),
        },
      ],
      extraActions: [],
    },
    noInheritedParameters: {
      kind: 'warning',
      subtitle: 'There are no inherited parameters for this entity.',
      dismissible: true,
      onClose: () => dispatch('notification', { action: 'delete', value: 'noInheritedParameters' }),
    },
    buildSuccess: {
      dismissible: true,
      kind: 'success',
      title: 'Success:',
      subtitle: 'Build(s) successfully triggered',
      onClose: () => dispatch('notification', { action: 'delete', value: 'buildSuccess' }),
      actions: [
        {
          text: 'Check details',
          onClick: () => navigate('/customer-builds#builds-history'),
        },
      ],
    },
    buildFailure: {
      dismissible: true,
      kind: 'error',
      title: 'Error:',
      subtitle: 'Build(s) could not be triggered',
      onClose: () => dispatch('notification', { action: 'delete', value: 'buildFailure' }),
      actions: [
        {
          text: 'Check details',
          onClick: () => navigate('/customer-builds#builds-history'),
        },
      ],
    },
    buildPartially: {
      dismissible: true,
      kind: 'warning',
      title: 'Warning:',
      subtitle: 'Not all builds triggered successfully',
      onClose: () => dispatch('notification', { action: 'delete', value: 'buildPartially' }),
      actions: [
        {
          text: 'Check details',
          onClick: () => navigate('/customer-builds#builds-history'),
        },
      ],
    },
  };

  let modals = {
    clone: { open: false },
    overview: { open: false },
    parameter: { open: false },
    removeParameter: { open: false },
    removeParameters: { open: false },
    jsonInput: { open: false },
  };

  // inheritance for every entity except global
  if (entityType !== 'system') {
    notifications.noParameters.extraActions.push({
      text: 'Clone configuration',
      onClick: handleClickForModal('clone'),
    });
  }

  async function showInheritedParameters() {
    pageData = { ...pageData, error: null, loading: true };

    try {
      const inheritedParameters = await getInheritedBuildParameters({
        configOwnerId: entityId,
        configOwnerType: entityType,
        isDefaultConfig: !!buildConfig.isDefault,
      });

      if (!inheritedParameters.length) {
        dispatch('notification', {
          action: 'add',
          value: 'noInheritedParameters',
        });

        return;
      }

      dispatch('notification', {
        action: 'delete',
        value: 'noParameters',
      });

      pageData = {
        ...pageData,
        inheritedParameters: { configId: buildConfig.id, data: inheritedParameters },
        inheritanceActive: true,
      };
    } catch (error) {
      console.error('[DataTable] Failed to retrieve parameters!', error);
      pageData = { ...pageData, error };
    } finally {
      pageData = { ...pageData, loading: false };
    }
  }

  function hideInheritedParameters() {
    pageData = {
      ...pageData,
      inheritanceActive: false,
      inheritedParameters: {},
    };

    if (!buildConfig?.data?.length) {
      dispatch('notification', {
        action: 'add',
        value: 'noParameters',
      });
    }
  }

  function handleCopy(data) {
    return function copyValue() {
      const { parameterValue: rawParameterValue } = data;
      let parameterValue;

      try {
        const possibleArray = JSON.parse(rawParameterValue);
        parameterValue = Array.isArray(possibleArray) ? possibleArray : rawParameterValue;
      } catch (_) {
        parameterValue = rawParameterValue;
      }

      copy(`${Array.isArray(parameterValue) ? parameterValue.join('\n') : parameterValue}`);
    };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      dispatch(name, modification);
    }

    modals = {
      ...modals,
      [name]: { open: false }, // discard data after modal close
    };
  }

  function handleClickForModal(name, forwardData = {}) {
    return function openModal() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  function normalizeTableData(data) {
    if (!pageData.inheritanceActive) {
      return data;
    }

    return data.map((row) => (row.inherited ? row : { ...row, inherited: false, inheritedFrom: '-' }));
  }

  function onSearchTextChange(e) {
    pageData = {
      ...pageData,
      searchText: e?.detail ?? '',
    };
  }

  $: if (entityType === 'group') {
    const configId = buildConfig.id;

    if (knownConfigs[configId]) {
      if (knownConfigs[configId].isDefault !== buildConfig.isDefault && pageData.inheritanceActive) {
        hideInheritedParameters();
      }
    }

    knownConfigs = {
      ...knownConfigs,
      [configId]: { isDefault: buildConfig.isDefault },
    };
  }

  $: tableData = buildConfig.data?.length
    ? normalizeTableData([...buildConfig.data, ...(pageData.inheritedParameters.data ?? [])])
    : pageData.inheritedParameters.data ?? [];

  $: tableHeaders = pageData.inheritedParameters.data?.length
    ? [
        ...basicTableHeaders.slice(0, basicTableHeaders.length - 1),
        { key: 'inheritedFrom', value: 'Inherited From' },
        ...basicTableHeaders.slice(basicTableHeaders.length - 1),
      ]
    : basicTableHeaders;

  $: if (pageData.inheritedParameters.configId && buildConfig.id !== pageData.inheritedParameters.configId) {
    pageData = { ...initialPageData };
  }

  $: if (!loading && !buildConfig.data?.length && !activeNotifications.has('noParameters') && !pageData.inheritanceActive) {
    dispatch('notification', {
      action: 'add',
      value: 'noParameters',
    });
  }

  $: tableRowClasses =
    pageData.inheritedParameters.data?.reduce((all, currentParameter) => {
      let classes = 'inherited';

      const matchCurrentLevelParam = buildConfig.data?.find((param) => param.parameterName === currentParameter.parameterName);
      const matchOtherLevel = pageData.inheritedParameters.data.find(
        (param) =>
          param.parameterName === currentParameter.parameterName &&
          param.id !== currentParameter.id &&
          shouldBeOverwritten[currentParameter.ownerType] &&
          shouldBeOverwritten[currentParameter.ownerType].includes(param.ownerType)
      );

      if ((matchCurrentLevelParam || matchOtherLevel) && !currentParameter.parameterName.endsWith(PARAMETER_MERGE_SUFFIX)) {
        classes = classes.concat(' overwritten');
      }

      return {
        ...all,
        [currentParameter.id]: classes,
      };
    }, {}) ?? {};

  $: filteredRows = pageData.searchText ? tableData.filter((param) => rowContainsText(param, pageData.searchText)) : tableData;
</script>

<LoadingSpinner {loading}>
  {#each Object.keys(notifications) as notification}
    {#if activeNotifications.has(notification)}
      <div class="custom-notification">
        <CustomInlineNotification {...notifications[notification]} />
      </div>
    {/if}
  {/each}
  {#if tableData.length}
    <DataTable headers={tableHeaders} rows={filteredRows} rowClasses={tableRowClasses} sortable>
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'actions'}
          {#if !row.inherited}
            <OverflowMenu aria-label="Open and close list of options" flipped>
              <OverflowMenuItem class="border-bottom" text="Copy Parameter" on:click={handleCopy(row)} />
              <OverflowMenuItem text="Edit Parameter" on:click={handleClickForModal('parameter', { data: row })} />
              <OverflowMenuItem danger text="Delete Parameter" on:click={handleClickForModal('removeParameter', { data: row })} />
            </OverflowMenu>
          {/if}
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else if customCellComponents[cell.key]}
          <svelte:component this={customCellComponents[cell.key]} value={cell.value} />
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar class="table-toolbar">
        {#if pageData.loading}
          <div class="data-table-spinner">
            <LoadingSpinner small withOverlay={false} loading={pageData.loading} />
          </div>
        {/if}
        <ToolbarContent>
          <ToolbarSearchWithRouting
            placeholder="Search for parameters"
            persistent
            bind:searchText={pageData.searchText}
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          {#if entityType !== 'system' && extraOptions}
            <ToolbarMenu>
              <ToolbarMenuItem primaryFocus on:click={pageData.inheritanceActive ? hideInheritedParameters : showInheritedParameters}>
                {pageData.inheritanceActive ? 'Hide' : 'View'} inherited parameters
              </ToolbarMenuItem>
              <ToolbarMenuItem on:click={handleClickForModal('overview')} disabled={!buildConfig.data?.length}>
                View build overview
              </ToolbarMenuItem>
              <ToolbarMenuItem danger on:click={handleClickForModal('removeParameters')} disabled={!buildConfig.data?.length}>
                Delete all parameters
              </ToolbarMenuItem>
            </ToolbarMenu>
          {/if}
          <Button on:click={handleClickForModal('parameter')}>Add parameter</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    {#if pageData.searchText && !filteredRows.length}
      <div class="empty-table">
        <div class="icon">
          <Box32 />
        </div>
        <em>No results for your search</em>
      </div>
    {/if}
    {#if pageData.inheritanceActive}
      <div class="inheritance-info-box">
        <div class="info-wrapper">
          <div class="display-box simple">i</div>
          Current Level Parameters
        </div>
        <div class="info-wrapper">
          <div class="display-box simple inherited">i</div>
          Inherited Parameters
        </div>
        <div class="info-wrapper">
          <div class="display-box inherited overwritten">i</div>
          Overwritten Parameters
        </div>
      </div>
    {/if}
  {/if}
</LoadingSpinner>
{#if modals.parameter.open}
  <AddOrEditParameterModal
    open
    targetName={buildConfig.name}
    configId={buildConfig.id}
    parameter={modals.parameter.data}
    {entityType}
    {entityName}
    {entityId}
    on:close={({ detail: data }) =>
      closeModal('parameter', {
        action: modals.parameter.data ? 'update-parameter' : 'add-parameter',
        data,
      })}
  />
{/if}
{#if modals.removeParameter.open}
  <RemoveParameterModal
    open
    targetName={buildConfig.name}
    parameter={modals.removeParameter.data}
    {entityType}
    {entityName}
    on:close={({ detail: data }) =>
      closeModal('removeParameter', {
        action: 'remove-parameter',
        data,
      })}
  />
{/if}
{#if modals.removeParameters.open}
  <RemoveConfigParameters
    open
    targetName={buildConfig.name}
    configId={buildConfig.id}
    {entityType}
    {entityName}
    on:close={({ detail: data }) =>
      closeModal('removeParameters', {
        action: 'remove-parameters',
        data,
      })}
  />
{/if}
{#if modals.clone.open}
  <CloneBuildConfigModal
    open
    targetName={buildConfig.name}
    configId={buildConfig.id}
    parameter={modals.parameter.data}
    {entityType}
    {entityName}
    {entityId}
    on:close={({ detail: data }) =>
      closeModal('clone', {
        action: 'clone-config',
        data,
      })}
  />
{/if}
{#if modals.overview.open}
  <BuildOverviewModal
    open
    targetName={buildConfig.name}
    configId={buildConfig.id}
    isDefault={!!buildConfig.isDefault}
    {entityType}
    {entityName}
    {entityId}
    on:close={() => closeModal('overview')}
  />
{/if}
{#if modals.jsonInput.open}
  <JsonBuildInputModal
    open
    targetName={buildConfig.name}
    configId={buildConfig.id}
    {entityType}
    {entityName}
    {entityId}
    on:close={({ detail: data }) =>
      closeModal('jsonInput', {
        action: 'add-parameters',
        data,
      })}
  />
{/if}

<style>
  .custom-notification :global(.actions) {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }

  .data-table-spinner {
    width: 3rem;
    height: 3rem;
  }

  .empty-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
    user-select: none;
    background-color: var(--cds-layer);
    padding: 2rem 0;
  }

  .empty-table .icon {
    padding: 0.35rem;
  }

  :global(.overwritten),
  :global(tr.overwritten:hover) {
    background: repeating-linear-gradient(
      -34deg,
      var(--cds-ui-01),
      var(--cds-ui-01) 10px,
      var(--cds-ui-02) 10px,
      var(--cds-ui-02) 20px
    ) !important;
  }

  :global(.overwritten td),
  :global(.overwritten tr:hover td) {
    background: unset !important;
  }

  .display-box:global(.inherited),
  :global(.inherited td) {
    font-style: italic;
  }

  .inheritance-info-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  .display-box {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  .simple {
    background-color: var(--cds-ui-01);
  }

  :global(.table-toolbar .bx--overflow-menu-options__btn) {
    max-width: unset;
  }

  :global(.table-toolbar .bx--toolbar-content) {
    z-index: 1;
  }

  :global(.table-toolbar .bx--overflow-menu-options) {
    min-width: 13rem;
  }

  .action-cell :global(.bx--overflow-menu) {
    min-height: 2.5rem;
  }

  .action-cell :global(.bx--overflow-menu-options) {
    min-width: 11rem;
  }

  .action-cell :global(.bx--overflow-menu-options__option.border-bottom) {
    border-bottom: 1px solid var(--cds-ui-03);
  }
</style>
