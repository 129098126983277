<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import {
    Button,
    Column,
    Checkbox,
    FormGroup,
    Grid,
    InlineNotification,
    Link,
    Loading,
    MultiSelect,
    Row,
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
    TextArea,
  } from 'carbon-components-svelte';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';
  import copy from 'clipboard-copy';
  import truncate from 'lodash/truncate';
  import uniqueBy from 'lodash/uniqBy';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import { ComboBox, TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { confirmUser, disableUser, enableUser, getOrganization, sendInvitationEmail, updateUser } from '../../services';
  import { convertToLocalDisplayTime, rowContainsText } from '../../utils';
  import { CognitoUserStatus } from '../../../shared/constants';

  export let userProfile = {};
  export let organizations = [];
  export let userId;

  let pageNotification, initialValues;
  let groupOptions = [];
  let groupOptionsLoading = false;
  let selectedOrganizationId = '';
  let verifyingEmail = false;
  let emailManuallyVerified = false;
  let sendingInvitationEmail = false;
  let invitationSent = false;
  let submitting = false;
  let togglingStatus = false;

  const dispatch = createEventDispatcher();
  const navigate = useNavigate();

  const SUCCESS_SEND_INVITATION_EMAIL = {
    kind: 'success',
    title: 'Success:',
    subtitle: 'Invitation email sent!',
  };
  const SUCCESS_VERIFY_EMAIL = {
    kind: 'success',
    title: 'Success:',
    subtitle: 'Email address manually verified!',
  };
  const SUCCESS_UPDATE_NOTIFICATION = {
    kind: 'success',
    title: 'Success:',
    subtitle: 'User updated successfully!',
  };

  function onClearOrganization(setFormValue) {
    groupOptions = [];
    selectedOrganizationId = '';
    setFormValue('organizationId', '');
    setFormValue('groupIds', []);
  }

  async function onFormSubmit({ detail: { values } }) {
    submitting = true;
    try {
      await updateUser(userId, values);
      dispatch('userUpdated');
      pageNotification = SUCCESS_UPDATE_NOTIFICATION;
    } catch (error) {
      console.error('[UserBasicInformation] Failed to update user data!', error);
      pageNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to update user! Verify your submission and try again.',
      };
    } finally {
      submitting = false;
    }
  }

  async function onLoginPermittedToggle() {
    togglingStatus = true;
    try {
      await (userIsEnabled ? disableUser(userId) : enableUser(userId));
      dispatch('userUpdated');
      pageNotification = SUCCESS_UPDATE_NOTIFICATION;
    } catch (error) {
      console.error('[UserBasicInformation] Failed to change the user status!', error);
      pageNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to enable or disable user! Verify your submission and try again.',
      };
    } finally {
      togglingStatus = false;
    }
  }

  async function getOrganizationGroups(orgId) {
    selectedOrganizationId = orgId;
    groupOptionsLoading = true;

    try {
      const { groups } = await getOrganization(orgId);
      groupOptionsLoading = false;
      return groups.map((group) => ({ id: group.id, text: group.name }));
    } catch (error) {
      console.error('[UserBasicInformation] Failed to retrieve organization groups!', error);
      groupOptionsLoading = false;
      return [];
    }
  }

  async function onSelectOrganization({ detail: { selectedId } }, setFormValue) {
    if (!selectedId) {
      return;
    }

    groupOptions = await getOrganizationGroups(selectedId);

    setFormValue('organizationId', selectedId);
    setFormValue('groupIds', []);
  }

  async function onSendInvitationEmail() {
    sendingInvitationEmail = true;
    try {
      await sendInvitationEmail(userId);
      pageNotification = SUCCESS_SEND_INVITATION_EMAIL;
      invitationSent = true;
      dispatch('userUpdated');
    } catch (error) {
      console.error('[UserBasicInformation] Failed to send invitation email!', error);
      pageNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to send invitation email! Please notify the system administrator.',
      };
    } finally {
      sendingInvitationEmail = false;
    }
  }

  async function onVerifyEmail() {
    verifyingEmail = true;
    try {
      await confirmUser(userId);
      pageNotification = SUCCESS_VERIFY_EMAIL;
      emailManuallyVerified = true;
      dispatch('userUpdated');
    } catch (error) {
      console.error('[UserBasicInformation] Failed to manually confirm user!', error);
      pageNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to manually verify email! Please notify the system administrator.',
      };
    } finally {
      verifyingEmail = false;
    }
  }

  function shouldFilterOrganization(organization, value) {
    if (!value || value.length < 2) {
      return true;
    }

    return rowContainsText(organization, value);
  }

  function viewGroupDetails(event, groupId) {
    event.preventDefault();
    navigate(`/group/${groupId}`);
  }

  function viewOrganizationDetails(event, organizationId) {
    event.preventDefault();
    navigate(`/organization/${organizationId}`);
  }

  async function fetchDependencies() {
    const formValues = {
      notes: userProfile.notes,
      name: userProfile.name,
      lsegUuid: userProfile.lsegUuid,
      licenseUpdates: userProfile.licenseUpdates,
      organizationId: userProfile.organizationId,
    };

    if (!userProfile.organizationId) {
      initialValues = { ...formValues, groupIds: [] };
      return;
    }

    groupOptions = await getOrganizationGroups(userProfile.organizationId);
    initialValues = {
      ...formValues,
      groupIds: userGroups.map(({ group }) => group.id),
    };
  }

  onMount(fetchDependencies);

  $: busy = submitting || togglingStatus;

  $: userGroups = uniqueBy(userProfile.groupsWithRoles, 'groupId');
  $: userIsEnabled = userProfile.isEnabled;

  $: groupSelectionDisabled = !selectedOrganizationId || groupOptions.length === 0;
  $: groupSelectionHelperText = (() => {
    if (groupOptionsLoading) {
      return undefined;
    }
    if (!selectedOrganizationId) {
      return 'Group selection is unavailable because no organization is selected.';
    }
    if (groupOptions.length === 0) {
      return 'Group selection is unavailable because this organization has no groups.';
    }
  })();
  $: organizationOptions = organizations.map((organization) => ({
    ...organization,
    text: organization.name,
  }));

  $: schema = yup.object().shape({
    notes: yup.string().nullable(),
    name: yup.string().required("Enter the user's full name!"),
    lsegUuid: yup.string().nullable(),
    licenseUpdates: yup.boolean(),
    organizationId: yup.string().nullable(),
    groupIds: yup.array(),
  });
</script>

{#if pageNotification}
  <InlineNotification
    kind={pageNotification.kind}
    lowContrast
    title={pageNotification.title}
    subtitle={pageNotification.subtitle}
    on:close={() => {
      pageNotification = null;
    }}
  />
{/if}
<Grid noGutterLeft noGutterRight padding>
  <Row>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading">Read-Only Fields</h2>
      <StructuredList condensed flush>
        <StructuredListRow>
          <StructuredListCell noWrap>User ID</StructuredListCell>
          <StructuredListCell>
            <div class="id-controls">
              {truncate(userProfile.id, { length: 20 })}
              <Button
                class="copy-button"
                hasIconOnly
                icon={Copy16}
                iconDescription="Copy to clipboard"
                kind="ghost"
                size="small"
                on:click={() => copy(userProfile.id)}
              />
            </div>
          </StructuredListCell>
        </StructuredListRow>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Email Address</StructuredListCell>
            <StructuredListCell>{userProfile.email}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Self-Described Organization</StructuredListCell>
            <StructuredListCell>{userProfile.declaredOrganizationName ?? '—'}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
      <h2 class="column-heading">Hierarchy Information</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Organization</StructuredListCell>
            <StructuredListCell>
              {#if !userProfile.organization}
                —
              {:else}
                <Link
                  href={`/organization/${userProfile.organization.id}`}
                  on:click={(event) => viewOrganizationDetails(event, userProfile.organization.id)}
                >
                  {userProfile.organization.name}
                </Link>
              {/if}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Groups</StructuredListCell>
            <StructuredListCell>
              {#if !userGroups || userGroups.length === 0}
                —
              {:else}
                {#each userGroups as { group }}
                  <Link href={`/group/${group.id}`} on:click={(event) => viewGroupDetails(event, group.id)}>
                    {group.name}
                  </Link>
                  <br />
                {/each}
              {/if}
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
      <h2 class="column-heading">Metadata</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Status</StructuredListCell>
            <StructuredListCell>{userProfile.statusDescription}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Login Permitted</StructuredListCell>
            <StructuredListCell>{userProfile.isEnabled && !userProfile.isSynthetic ? 'Yes' : 'No'}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Email Verified</StructuredListCell>
            <StructuredListCell>{userProfile.emailVerified ? 'Yes' : 'No'}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Invited At</StructuredListCell>
            <StructuredListCell>
              {userProfile.invitedAt ? convertToLocalDisplayTime(userProfile.invitedAt) : '—'}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Account Created</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(userProfile.createdAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Created By</StructuredListCell>
            <StructuredListCell>{userProfile.createdByDisplayName}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Last Updated</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(userProfile.updatedAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Updated By</StructuredListCell>
            <StructuredListCell>{userProfile.updatedByDisplayName}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
    </Column>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading column-heading-form">Editable Fields</h2>
      {#if initialValues}
        <Form
          {schema}
          {initialValues}
          fieldDomString="input,textarea,select,div[name='organizationId'],div[name='groupIds']"
          validateOnBlur={true}
          validateOnChange={true}
          on:submit={onFormSubmit}
          let:submitForm
          let:setValue
          let:errors
          let:touched
          let:values
        >
          <FormGroup>
            <TextInput
              helperText="Changes to this field will be visible to the user on the Customer Portal."
              invalid={touched.name && !!errors.name}
              invalidText={errors.name}
              labelText="Full Name"
              name="name"
              placeholder="Full name"
              type="text"
              value={values.name}
              required
              on:change={({ detail: text }) => setValue('name', text)}
            />
            <TextInput
              helperText="This value provides a link between MACE and LSEG's AAA/CARE authentication system; only modify this value if you know what you're doing!"
              invalid={touched.lsegUuid && !!errors.lsegUuid}
              invalidText={errors.lsegUuid}
              labelText="LSEG AAA/CARE/CIAM UUID"
              name="name"
              placeholder="UUID"
              type="text"
              value={values.lsegUuid}
              required
              on:change={({ detail: text }) => setValue('lsegUuid', text)}
            />
            <TextArea
              invalid={touched.notes && !!errors.notes}
              invalidText={errors.notes}
              labelText="Notes"
              name="notes"
              placeholder="Additional notes"
              type="text"
              value={values.notes}
              on:change={({ target }) => setValue('notes', target.value)}
            />
            <ComboBox
              invalid={touched.organizationId && !!errors.organizationId}
              invalidText={errors.organizationId}
              items={organizationOptions}
              name="organizationId"
              placeholder="Organization name"
              selectedId={selectedOrganizationId}
              shouldFilterItem={shouldFilterOrganization}
              titleText="Organization"
              on:clear={() => onClearOrganization(setValue)}
              on:select={(event) => onSelectOrganization(event, setValue)}
            />
            <MultiSelect
              disabled={groupSelectionDisabled}
              filterable
              helperText={groupSelectionHelperText}
              items={groupOptions}
              selectedIds={values.groupIds}
              name="groupIds"
              placeholder="Groups"
              spellcheck="false"
              titleText="Groups"
              on:select={({ detail: { selectedIds } }) => setValue('groupIds', selectedIds)}
            />
            {#if groupOptionsLoading}
              <div class="group-loading-indicator">
                <LoadingSpinner centered={false} compact withOverlay={false}>
                  <span class="bx--form__helper-text">Loading groups...</span>
                </LoadingSpinner>
              </div>
            {/if}
            <Checkbox
              name="licenseUpdates"
              labelText="Notify for license updates"
              checked={values.licenseUpdates}
              on:check={({ detail }) => setValue('licenseUpdates', detail)}
            />
          </FormGroup>
          <div class="form-controls">
            <LoadingButton kind="primary" on:click={submitForm} disabled={busy} isLoading={submitting}>Save</LoadingButton>
          </div>
        </Form>
      {:else}
        <Loading withOverlay={false} />
      {/if}
      <h2 class="column-heading column-heading-additional-actions">Additional Actions</h2>
      <div class="form-controls">
        <p class="text-info">
          {#if userProfile.isSynthetic}
            This user is synthetic, so they cannot be disabled or enabled at this time.
          {:else if userIsEnabled}
            This user account is currently enabled. Disabling this user account will prevent the user from logging into applications such as
            the Customer Portal.
          {:else}
            This user account is currently disabled. As a result, the user is unable to log into applications such as the Customer Portal.
            Click "Enable user" below to restore this account.
          {/if}
          {#if userProfile.canSendInvitationEmail && userProfile.invitedAt}
            An invitation email has already been sent to this user, but can be re-sent.
          {:else if userProfile.canSendInvitationEmail}
            The user can be invited to create an account. When invited, the user will no longer be synthetic.
          {:else}
            An invitation email cannot be sent to this user because they already have an account.
          {/if}
          {#if userProfile.canConfirm}
            This user's email address can be manually marked as confirmed. This may be useful when the user is having technical difficulties
            or waited too long to verify their email.
          {:else if userProfile.status !== CognitoUserStatus.CONFIRMED}
            The user's email address cannot be manually marked as confirmed because their account was migrated from Auth0 or the account is
            synthetic.
          {/if}
        </p>
        <LoadingButton
          kind={userIsEnabled ? 'danger' : 'secondary'}
          disabled={busy || userProfile.isSynthetic}
          isLoading={togglingStatus}
          on:click={onLoginPermittedToggle}
        >
          {userIsEnabled ? 'Disable' : 'Enable'} User
        </LoadingButton>
        {#if userProfile.canSendInvitationEmail}
          <LoadingButton
            kind="secondary"
            disabled={busy || invitationSent}
            isLoading={sendingInvitationEmail}
            on:click={onSendInvitationEmail}
          >
            {userProfile.invitedAt ? 'Re-send' : 'Send'} invitation email
          </LoadingButton>
        {/if}
        {#if userProfile.canConfirm}
          <LoadingButton kind="secondary" disabled={busy || emailManuallyVerified} isLoading={verifyingEmail} on:click={onVerifyEmail}>
            Verify email
          </LoadingButton>
        {/if}
      </div>
    </Column>
  </Row>
</Grid>

<style>
  /* For accessibility, keep the column-heading elements as h2 tags, but style them similar to h4 */
  .column-heading {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .column-heading + :global(.bx--structured-list) {
    margin-bottom: 2.5rem;
  }
  .column-heading + :global(.bx--structured-list:last-child) {
    margin-bottom: 0.5rem;
  }
  .column-heading + :global(.bx--structured-list .bx--structured-list-td) {
    width: 50%;
  }

  .column-heading-additional-actions {
    margin-top: 2.5rem;
  }

  .column-heading-form + :global(.sveltejs-forms > .bx--fieldset > .bx--form-item:first-child) {
    margin: 0 0 0.75rem;
  }

  @media screen and (min-width: 66rem) {
    .column-heading-form {
      margin-bottom: 1.875rem;
    }
    .column-heading-form + :global(.sveltejs-forms) {
      margin-top: 0;
    }
  }

  .id-controls :global(.bx--btn) {
    min-height: unset;
    padding-top: 0;
  }
  .id-controls :global(.bx--btn svg > path) {
    fill: var(--cds-link-01) !important;
  }

  .group-loading-indicator :global(.loading-container) {
    align-items: center;
    display: flex;
    padding-top: 0.375rem;
  }

  .group-loading-indicator :global(.bx--form__helper-text) {
    margin-top: 0;
    padding-right: 0.25rem;
    width: auto;
  }

  .text-info {
    color: var(--cds-text-02);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
</style>
