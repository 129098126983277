<script>
  import { onMount } from 'svelte';

  import {
    Button,
    InlineNotification,
    NotificationActionButton,
    OverflowMenu,
    OverflowMenuItem,
    Toolbar,
    ToolbarContent,
  } from 'carbon-components-svelte';
  import copy from 'clipboard-copy';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import AddOrEditSshKeyModal from '../../components/ssh-keys/AddOrEditSshKeyModal.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import RemoveSshKeyModal from '../../components/ssh-keys/RemoveSshKeyModal.svelte';
  import LongTextTooltipCell from '../../components/LongTextTooltipCell.svelte';
  import StatusIcon from '../../components/StatusIcon.svelte';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';

  import { appConfig } from '../../stores';
  import { getSshKeys, grantServiceAccess, revokeServiceAccess } from '../../services';
  import { convertToLocalDisplayTime } from '../../utils';

  export let group = {};

  const { id: groupId, name: groupName, sftp05Username, crushftpUsername } = group;

  const { crushFTPEnabled } = $appConfig.data.flags;
  const notifications = {
    associationError: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to add or remove service association! Please try again later.',
    },
    error: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load SSH keys for group! Please try again later.',
      viewBlocking: true,
    },
    noKeys: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no SSH keys for this group.',
      action: {
        text: 'Create key',
        onClick: () => openModal('addOrEditKey'),
      },
      viewBlocking: true,
    },
    sftpNamesUndefined: {
      kind: 'info',
      title: 'Info:',
      subtitle: `Access to certain services may not be possible for these keys
      until the corresponding services' usernames are set on the group.`,
    },
  };

  const miscHeaders = [
    { key: 'createdAt', value: 'Created', display: convertToLocalDisplayTime },
    { key: 'updatedAt', value: 'Updated', display: convertToLocalDisplayTime },
    { key: 'actions', empty: true, sort: false },
  ];

  const headers = [
    { key: 'ownerType', value: 'Owner' },
    { key: 'publicKey', value: 'Public Key' },
    { key: 'sftp05Access', value: 'SFTP05 Access' },
    ...(crushFTPEnabled ? [{ key: 'crushftpAccess', value: 'CrushFTP Access' }, ...miscHeaders] : [...miscHeaders]),
  ];

  let modals = {
    addOrEditKey: { open: false, sshKey: undefined },
    removeKey: { open: false, sshKey: undefined },
  };
  let pageData = { loading: true };
  const query = { pageSize: 25, currentPage: 1 };

  async function loadGroupKeys() {
    pageData = { ...pageData, loading: true };
    try {
      const sshKeys = await getSshKeys({ ownerId: groupId, ownerType: 'group' });
      const usernamesUndefinedNotification = !sftp05Username || !crushftpUsername ? 'sftpNamesUndefined' : null;
      const displayedNotification = sshKeys.length === 0 ? 'noKeys' : usernamesUndefinedNotification;
      pageData = { ...pageData, displayedNotification, loading: false, sshKeys };
    } catch (error) {
      console.error('[GroupKeyList] Failed to load group SSH keys!', error);
      pageData = { ...pageData, displayedNotification: 'error', loading: false };
    }
  }

  /**
   * @description Close a given modal by name. Since the modal components may forward the inner Carbon modal's "close"
   *    event multiple times, a single "toggle" method is more prone to unexpected behavior.
   * @param name
   */
  function closeModal(name) {
    modals = {
      ...modals,
      [name]: { ...modals[name], open: false },
    };
  }

  async function onGrantServiceAccess(sshKeyId, serviceName) {
    pageData = { ...pageData, loading: true };
    try {
      const updatedSshKey = await grantServiceAccess(sshKeyId, { groupId, serviceName });
      const nextSshKeys = pageData.sshKeys.reduce((acc, sshKey) => {
        if (sshKey.id !== sshKeyId) {
          return acc.concat(sshKey);
        }

        return acc.concat({
          ...sshKey,
          dataServiceAssociations: updatedSshKey.dataServiceAssociations,
        });
      }, []);
      pageData = { ...pageData, loading: false, sshKeys: nextSshKeys };
    } catch (error) {
      console.error('[GroupKeyList] Failed to associate key with service!', error);
      pageData = { ...pageData, loading: false, displayedNotification: 'associationError' };
    }
  }

  /**
   * @description Open a given modal by name, optionally passing an object of data to forward.
   * @param {string} name
   * @param {object?} forwardData
   */
  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  function onCopyPublicKey(sshKeyId) {
    const sshKey = pageData.sshKeys.find(({ id }) => id === sshKeyId);
    copy(sshKey.publicKey);
  }

  function onEditKey(sshKeyId) {
    const sshKey = pageData.sshKeys.find(({ id }) => id === sshKeyId);
    openModal('addOrEditKey', { sshKey });
  }

  function onRemoveKey(sshKeyId) {
    const sshKey = pageData.sshKeys.find(({ id }) => id === sshKeyId);
    openModal('removeKey', { sshKey });
  }

  async function onRevokeServiceAccess(sshKeyId, serviceName) {
    pageData = { ...pageData, loading: true };
    try {
      const sshKey = pageData.sshKeys.find(({ id }) => id === sshKeyId);
      const serviceAssociationId = sshKey.dataServiceAssociations.find((association) => association.serviceName === serviceName)?.id;

      await revokeServiceAccess(sshKeyId, serviceAssociationId);
      const nextSshKeys = pageData.sshKeys.reduce((acc, curr) => {
        if (curr.id !== sshKeyId) {
          return acc.concat(curr);
        }

        const updatedSshKey = {
          ...sshKey,
          dataServiceAssociations: sshKey.dataServiceAssociations.filter((association) => association.id !== serviceAssociationId),
        };
        return acc.concat(updatedSshKey);
      }, []);
      pageData = { ...pageData, loading: false, sshKeys: nextSshKeys };
    } catch (error) {
      console.error('[GroupKeyList] Failed to remove service association!', error);
      pageData = { ...pageData, loading: false, displayedNotification: 'associationError' };
    }
  }

  onMount(loadGroupKeys);

  $: showDataGrid = (pageData.loading || pageData.sshKeys?.length > 0) && !pageData.displayedNotification?.viewBlocking;
</script>

{#if pageData.displayedNotification}
  <InlineNotification
    hideCloseButton={notifications[pageData.displayedNotification].viewBlocking}
    lowContrast
    {...notifications[pageData.displayedNotification]}
  >
    <div slot="actions">
      {#if notifications[pageData.displayedNotification].action}
        <NotificationActionButton on:click={notifications[pageData.displayedNotification].action?.onClick}>
          {notifications[pageData.displayedNotification].action?.text}
        </NotificationActionButton>
      {/if}
    </div>
  </InlineNotification>
{/if}

{#if showDataGrid}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable {headers} rows={pageData.sshKeys ?? []} sortable pageSize={query.pageSize} page={query.currentPage}>
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'ownerType'}
          {cell.value === 'user' ? `User (${row.ownerDisplayName})` : 'Group'}
        {:else if cell.key === 'publicKey'}
          <LongTextTooltipCell text={cell.value} />
        {:else if cell.key === 'sftp05Access'}
          <StatusIcon valid={row.dataServiceAssociations?.some((association) => association.serviceName === 'sftp05')} />
        {:else if cell.key === 'crushftpAccess'}
          <StatusIcon valid={row.dataServiceAssociations?.some((association) => association.serviceName === 'crushftp')} />
        {:else if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem text="Copy to clipboard" on:click={() => onCopyPublicKey(row.id)} />
            <OverflowMenuItem text="Edit key" on:click={() => onEditKey(row.id)} />
            {#if row.dataServiceAssociations?.some((association) => association.serviceName === 'sftp05')}
              <OverflowMenuItem danger text="Revoke sftp05 access" on:click={() => onRevokeServiceAccess(row.id, 'sftp05')} />
            {:else}
              <OverflowMenuItem
                text="Grant sftp05 access"
                on:click={() => onGrantServiceAccess(row.id, 'sftp05')}
                disabled={!sftp05Username}
              />
            {/if}
            {#if crushFTPEnabled}
              {#if row.dataServiceAssociations?.some((association) => association.serviceName === 'crushftp')}
                <OverflowMenuItem danger text="Revoke CrushFTP access" on:click={() => onRevokeServiceAccess(row.id, 'crushftp')} />
              {:else}
                <OverflowMenuItem
                  text="Grant CrushFTP access"
                  on:click={() => onGrantServiceAccess(row.id, 'crushftp')}
                  disabled={!crushftpUsername}
                />
              {/if}
            {/if}
            <OverflowMenuItem danger text="Remove key" on:click={() => onRemoveKey(row.id)} />
          </OverflowMenu>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <Button size="small" on:click={() => openModal('addOrEditKey')}>Add group SSH key</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:page={query.currentPage}
      bind:pageSize={query.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={pageData.sshKeys?.length}
    />
  </LoadingSpinner>
{/if}
<AddOrEditSshKeyModal
  editingSshKey={modals.addOrEditKey.sshKey}
  ownerId={modals.addOrEditKey.sshKey?.ownerId ?? groupId}
  ownerName={modals.addOrEditKey.sshKey?.ownerType === 'user' ? modals.addOrEditKey.sshKey.ownerDisplayName : groupName}
  ownerType={modals.addOrEditKey.sshKey?.ownerType ?? 'group'}
  open={modals.addOrEditKey.open}
  on:close={() => closeModal('addOrEditKey')}
  on:submit={loadGroupKeys}
/>
<RemoveSshKeyModal
  currentGroupId={groupId}
  sshKeyId={modals.removeKey.sshKey?.id}
  ownerName={modals.removeKey.sshKey?.ownerType === 'user' ? modals.removeKey.sshKey.ownerDisplayName : groupName}
  ownerType={modals.removeKey.sshKey?.ownerType ?? 'group'}
  open={modals.removeKey.open}
  on:close={() => closeModal('removeKey')}
  on:submit={loadGroupKeys}
/>

<style>
  .action-cell :global(.bx--overflow-menu-options) {
    min-width: 11.5rem;
  }
</style>
