<script>
  import { createEventDispatcher } from 'svelte';

  import { InlineNotification, Modal } from 'carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { removeGroupAwsPrincipal } from '../../services';

  export let cloudPrincipal;
  export let open = false;

  let loading = false;
  let resultNotification;

  const dispatch = createEventDispatcher();

  async function removeFromGroup() {
    loading = true;
    try {
      await removeGroupAwsPrincipal(cloudPrincipal.id);

      dispatch('submit');
      dispatch('close');
    } catch (error) {
      console.error('[RemoveCloudPrincipalFromGroupModal] Failed to remove cloud principal from group!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to remove cloud principal from group! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  danger
  modalHeading="Remove Cloud Principal from Group"
  preventCloseOnClickOutside={true}
  primaryButtonText="Remove"
  secondaryButtonText="Cancel"
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={removeFromGroup}
>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <p>
      You are about to remove {cloudPrincipal?.arn} from this group. Are you sure?
    </p>
  </LoadingSpinner>
</Modal>
