<script>
  import { TabContent } from 'carbon-components-svelte';

  import AuditTable from '../../components/audit-table/AuditTable.svelte';
  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';

  import AdminBasicInformation from './AdminBasicInformation.svelte';
  import AdminApiKeys from './api-keys/AdminApiKeys.svelte';
</script>

<TabsWithRouting
  tabsWrapperProps={{ 'aria-label': 'Admin Dashboard module tabs' }}
  tabs={[
    { label: 'Basic Information', hash: '#basic-information' },
    { label: 'API Keys', hash: '#api-keys' },
    { label: 'History', hash: '#history' },
  ]}
>
  <TabContent><AdminBasicInformation /></TabContent>
  <TabContent><AdminApiKeys /></TabContent>
  <LazyLoadedTabContent hash="#history"><AuditTable queryParams={{ entityType: 'api-key' }} entityType="api-key" /></LazyLoadedTabContent>
</TabsWithRouting>
