<script>
  import copy from 'clipboard-copy';
  import { Button, Modal } from 'carbon-components-svelte';
  import { JsonView } from '@zerodevx/svelte-json-view';

  import Copy16 from 'carbon-icons-svelte/lib/Copy16';

  export let name,
    data,
    hasCopyButton = true,
    open = false;
</script>

<Modal passiveModal modalHeading={name} bind:open on:close>
  {#if hasCopyButton}
    <div class="action-bar">
      <Button
        iconDescription="Copy to clipboard"
        icon={Copy16}
        kind="ghost"
        tooltipPosition="right"
        on:click={() => copy(JSON.stringify(data, null, 2))}
      />
    </div>
  {/if}
  <div class="wrapper">
    <JsonView json={data} />
  </div>
</Modal>

<style>
  .action-bar {
    height: 3rem;
    background-color: var(--cds-layer-accent);
  }

  .wrapper {
    --nodePaddingLeft: 1.5rem; /* css variable for svelte-json-view indentation; only available under .raw-view container */
    padding: 1rem 2rem;
    border: 1px solid var(--cds-layer-accent);
    word-break: break-word;
  }

  .wrapper :global(.key) {
    color: var(--cds-text-01);
  }
</style>
