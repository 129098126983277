<script>
  import {
    Column,
    Content,
    Grid,
    Header,
    HeaderAction,
    HeaderPanelDivider,
    HeaderPanelLink,
    HeaderPanelLinks,
    HeaderUtilities,
    Link,
    Row,
    SkipToContent,
  } from 'carbon-components-svelte';
  import User20 from 'carbon-icons-svelte/lib/User20';
  import { useNavigate } from 'svelte-navigator';

  import AppThemeIcon from './components/AppThemeIcon.svelte';
  import CriticalWarningsBanner from './components/CriticalWarningsBanner.svelte';
  import SearchBar from './pages/search/SearchBar.svelte';
  import { userSession } from './stores';

  let isAccountHeaderActionOpen = false;

  const navigate = useNavigate();

  function onClickHome(event) {
    event.preventDefault();
    navigate($userSession.session ? '/' : '/login');
  }

  function onSignOut() {
    userSession.signOut();
    window.location.href = window.location.origin;
  }

  $: breakpoints = $userSession.session
    ? {}
    : {
        sm: {
          span: 4,
          offset: 0,
        },
        md: {
          span: 6,
          offset: 1,
        },
        lg: {
          span: 8,
          offset: 4,
        },
        xlg: {
          span: 6,
          offset: 5,
        },
      };
</script>

<svelte:head>
  <title>MayStreet Access Control Engine</title>
</svelte:head>
<Header persistentHamburgerMenu={false} expansionBreakpoint={0}>
  <Link href={$userSession.session ? '/' : '/login'} slot="platform" on:click={onClickHome}>
    <img alt="MayStreet Access Control Engine" class="app-logo" src="/app-header-logo.png" />
  </Link>
  <div slot="skip-to-content">
    <SkipToContent />
  </div>

  <HeaderUtilities>
    {#if $userSession.session}
      <SearchBar />
    {/if}
    <AppThemeIcon />
    {#if $userSession.session}
      <HeaderAction
        aria-label={`${isAccountHeaderActionOpen ? 'Close' : 'Open'} account menu`}
        icon={User20}
        bind:isAccountHeaderActionOpen
      >
        <HeaderPanelLinks>
          <HeaderPanelDivider>My Account</HeaderPanelDivider>
          <HeaderPanelLink on:click={onSignOut}>Log out</HeaderPanelLink>
        </HeaderPanelLinks>
      </HeaderAction>
    {/if}
  </HeaderUtilities>
</Header>
<Content class={!$userSession.session ? 'login-main' : ''}>
  <Grid class={!$userSession.session ? 'login-grid' : ''} noGutterLeft noGutterRight>
    <CriticalWarningsBanner />
    <Row>
      <Column {...breakpoints}>
        <slot />
      </Column>
    </Row>
  </Grid>
</Content>

<style>
  :global(.bx--content) {
    background-color: var(--cds-ui-background) !important;
    height: calc(100vh - 48px);
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  :global(.login-main) {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }

  :global(.login-grid) {
    margin: 0 !important;
  }

  :global(body) {
    overflow-y: hidden;
  }
</style>
