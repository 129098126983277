<script>
  import { onMount } from 'svelte';
  import { Button } from 'carbon-components-svelte';
  import { v4 as uuidv4 } from 'uuid';
  import { DataTable } from '@mst-fe/carbon-components-svelte';
  import Add16 from 'carbon-icons-svelte/lib/Add16';
  import { getWorkbenchInstances } from '../../../../services';

  export let selectedInstances, fetchError, workbenchInstanceInventory, inventoryTableLoading;

  onMount(async () => {
    try {
      const workbenchInstances = await getWorkbenchInstances();
      workbenchInstanceInventory = workbenchInstances;
    } catch (error) {
      console.error('[AddOrEditWorkbenchInstanceModal] Failed to load workbench instances', error);
      fetchError = 'Failed to load workbench instances';
    } finally {
      inventoryTableLoading = false;
    }
  });

  const addInstances = (addedInstances) => {
    const newInstances = addedInstances.map(({ id, name, instancePermissions }) => {
      return {
        id: uuidv4(),
        workbenchInstanceId: id,
        workbenchInstanceName: name,
        instancePermissions: instancePermissions.map((instancePermission) => ({
          ...instancePermission,
          id: uuidv4(),
          checked: false,
          workbenchInstancePermissionId: instancePermission.id,
        })),
      };
    });

    selectedInstances = [...selectedInstances, ...newInstances];
  };

  $: filteredWorkbenchInstanceInventory = workbenchInstanceInventory.filter(
    (instance) => !selectedInstances.map((sI) => sI.workbenchInstanceId).includes(instance.id)
  );
</script>

<h5 class="section-heading">Instance Inventory</h5>

<DataTable
  headers={[
    { key: 'name', value: 'Type', width: '90%' },
    { key: 'actions', empty: true, sort: false },
  ]}
  rows={filteredWorkbenchInstanceInventory ?? []}
  size="short"
  sortable
>
  <span slot="cell" let:cell let:row>
    {#if cell.key === 'actions'}
      <Button
        kind="tertiary"
        size="sm"
        iconDescription="Add instance"
        icon={Add16}
        tooltipPosition="left"
        on:click={() => addInstances([row])}
      />
    {:else if cell.display}
      {cell.display(cell.value, row)}
    {:else}
      {cell.value}
    {/if}
  </span>
</DataTable>
