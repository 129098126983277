<script>
  import * as yup from 'yup';
  import startCase from 'lodash/startCase';
  import { format } from 'date-fns';
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    Accordion,
    AccordionItem,
    Checkbox,
    FormGroup,
    Modal,
    OutboundLink,
    RadioButton,
    RadioButtonGroup,
  } from 'carbon-components-svelte';
  import { DatePicker, DatePickerInput, NumberInput } from '@mst-fe/carbon-components-svelte';

  import { Form } from '@mst-fe/sveltejs-forms';

  import CustomInlineNotification from '../CustomInlineNotification.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import WbUserLimitPlanForm from './WbUserLimitPlanForm.svelte';

  import { createWbPlan, getOrganizationUsersWithWbAccess, getLoginDefaults, getResourceCharges } from '../../services';
  import { getServerErrorMessage } from '../../utils';

  import { WB_PLAN_EXCEED_ACTIONS } from '../../../shared/constants';

  export let organizationId,
    organizationName,
    open = false;

  let planData = { date: format(new Date(), 'MM-dd-yyyy'), users: {}, invalid: false, active: true },
    confirmSelections = false,
    onExceed = 'shutdown',
    organizationUsers = [],
    loginDefaults = [],
    resourceCharges = {},
    selectedAmounts = {
      compute: 0,
      storage: 0,
      egress: 0,
    },
    formSubmitButtonRef,
    modalState = {
      loading: false,
      notifications: new Set(),
    };

  const initialValues = {
    compute: 0,
    storage: 0,
    egress: 0,
    cushion: 0,
    exceedAction: Object.keys(WB_PLAN_EXCEED_ACTIONS)[0],
    hardLimit: 0,
    notify: true,
  };

  const dispatch = createEventDispatcher();
  const notifications = {
    errorFetchingDependencies: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load organizations users and/or Workbench pricing! Please try again later.',
    },
    noConnectedUsers: {
      kind: 'warning',
      subtitle: `Currently, there are no users on any of the organization's groups that have access to Workbench. Creating a pricing plan now,
        will not allow you to introduce per-user limits. All resources will be shared to the groups that have Workbench permission enabled.`,
    },
    errorCreatingPlan: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to create subscription plan! Please try again later.',
    },
  };

  async function fetchDependencies() {
    modalState = {
      ...modalState,
      loading: true,
    };

    try {
      [organizationUsers, loginDefaults, resourceCharges] = await Promise.all([
        getOrganizationUsersWithWbAccess(organizationId),
        getLoginDefaults(),
        getResourceCharges(),
      ]);

      if (!organizationUsers.length) {
        modalState.notifications.add('noConnectedUsers');
      }
    } catch (error) {
      modalState.notifications.add('errorFetchingDependencies');
      console.error('[WbPlanModal] Failed to load dependencies...', error);
    } finally {
      modalState = {
        ...modalState,
        loading: false,
      };
    }
  }

  function calculateTotalSelections({ compute, storage, egress }) {
    return compute + storage * (resourceCharges.storage?.unitCharge ?? 0) + egress * (resourceCharges.egress?.unitCharge ?? 0);
  }

  function trackTotalAmount(field, amount) {
    if (!(field in selectedAmounts)) {
      return;
    }

    selectedAmounts = { ...selectedAmounts, [field]: amount };
  }

  function handleDateSelection({ detail: { dateStr: date } }) {
    planData = { ...planData, date };
  }

  function handleCustomInputChange(field, set) {
    return function onNumberInputChange(event) {
      const { detail: value } = event;

      if (field === 'exceedAction') {
        onExceed = value;
      }

      trackTotalAmount(field, value);
      set(field, value);
    };
  }

  async function handleFormSubmission({ detail: { values } }) {
    modalState = {
      ...modalState,
      loading: true,
    };

    modalState.notifications.delete('errorCreatingPlan');

    try {
      const plan = await createWbPlan(organizationId, { ...values, ...planData });
      dispatch('close', plan);
    } catch (error) {
      const errorMessage = getServerErrorMessage(error);
      console.error('[WbPlanModal] Failed to create wb plan...', errorMessage ?? error);
      modalState.notifications.add('errorCreatingPlan');
    } finally {
      modalState = {
        ...modalState,
        loading: false,
      };
    }
  }

  function onModalSubmit() {
    if (!formSubmitButtonRef) {
      throw new Error('Form submit button not found!');
    }

    formSubmitButtonRef.click();
  }

  onMount(fetchDependencies);

  $: totalUsers = organizationUsers.length;
  $: totalAmount = calculateTotalSelections(selectedAmounts);
  $: totalPricing = `${totalUsers}${totalUsers === 1 ? ' user' : ' users'} access${
    totalAmount ? ` + $${totalAmount} of extra resources` : ''
  }`;
  $: schema = yup.object().shape({
    compute: yup
      .number()
      .typeError(`Compute must be a positive number and an increment of ${resourceCharges.compute?.purchasableChunk}`)
      .min(0, 'Compute must be a positive number')
      .test(
        'increment-of-compute',
        `Compute must be an increment of ${resourceCharges.compute?.purchasableChunk}`,
        (value) => value % (resourceCharges.compute?.purchasableChunk ?? 250) === 0
      ),
    storage: yup
      .number()
      .typeError(`Storage must be a positive number and an increment of ${resourceCharges.storage?.purchasableChunk}`)
      .min(0, 'Storage must be a positive number')
      .test(
        'increment-of-storage',
        `Storage must be an increment of ${resourceCharges.storage?.purchasableChunk}`,
        (value) => value % (resourceCharges.storage?.purchasableChunk ?? 1000) === 0
      ),
    egress: yup
      .number()
      .typeError(`Egress must be a positive number and an increment of ${resourceCharges.egress?.purchasableChunk}`)
      .min(0, 'Egress must be a positive number')
      .test(
        'increment-of-egress',
        `Egress must be an increment of ${resourceCharges.egress?.purchasableChunk}`,
        (value) => value % (resourceCharges.egress?.purchasableChunk ?? 1000) === 0
      ),
    cushion: yup
      .number()
      .min(0, 'Cushion must be a percentage between 0-100')
      .max(100, 'Cushion must be percentage between 0-100')
      .test('has-resource', 'You can only set cushion if you at least have one purchased resource', function validate(value) {
        const { compute, storage, egress } = this.parent;
        return !value || !!compute || !!storage || !!egress;
      }),
    exceedAction: yup.mixed().oneOf(Object.keys(WB_PLAN_EXCEED_ACTIONS)),
    hardLimit: yup
      .number()
      .typeError('Hard limit must be a positive number')
      .when(['compute', 'storage', 'egress', 'cushion', 'exceedAction'], (compute, storage, egress, cushion, exceedAction, schemaObj) => {
        return exceedAction === 'charge'
          ? schemaObj
              .required()
              .min(
                Math.ceil(calculateTotalSelections({ compute, storage, egress }) * (1 + cushion / 100)),
                'Hard limit must be higher than purchased amount + cushion percentage'
              )
          : schemaObj;
      }),
    notify: yup.boolean(),
  });
</script>

<Modal
  modalHeading="Configure Plan"
  preventCloseOnClickOutside={true}
  shouldSubmitOnEnter={false}
  primaryButtonText="Create"
  primaryButtonDisabled={!confirmSelections ||
    planData.invalid ||
    modalState.notifications.has('errorFetchingDependencies') ||
    (!totalUsers && !totalAmount)}
  secondaryButtonText="Cancel"
  bind:open
  size="lg"
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={onModalSubmit}
>
  <LoadingSpinner loading={modalState.loading}>
    {#each Object.keys(notifications) as notification}
      {#if modalState.notifications.has(notification) && !modalState.loading}
        <CustomInlineNotification {...notifications[notification]} />
      {/if}
    {/each}
    <div class="intro">
      <p>You are about to configure a Workbench pricing plan for <strong>{organizationName}</strong>.</p>
    </div>
    <div class="options">
      <DatePicker
        value={planData.date}
        flatpickrProps={{ static: true }}
        datePickerType="single"
        dateFormat="m-d-Y"
        on:change={handleDateSelection}
      >
        <DatePickerInput required labelText="Plan start date" placeholder="mm-dd-yyyy" />
      </DatePicker>
      <div class="active">
        <Checkbox labelText="Make this plan active" bind:checked={planData.active} />
        {#if planData.active}
          <span class="bx--label">Any other plans with same start date as the one selected above, will become inactive.</span>
        {/if}
      </div>
      {#if organizationUsers.length}
        <div class="users">
          <span class="bx--label">Users under organization's groups with access to Workbench</span>
          <div class="list">
            {#if !totalAmount}
              <CustomInlineNotification kind="warning" subtitle="You cannot edit user limits when no extra resources are purchased" />
            {/if}
            <Accordion size="sm" disabled={!totalAmount}>
              {#each organizationUsers as user}
                <AccordionItem>
                  <svelte:fragment slot="title">
                    <h5>
                      {user.name}
                      <br />
                      {#each user.roles as role}
                        <em>({role.name} under {role.groupName} group)</em>
                      {/each}
                    </h5>
                    {#if loginDefaults.length}
                      <div class="defaults">
                        {#each loginDefaults as resourceDefault}
                          <div class="default">
                            <strong>{startCase(resourceDefault.resource)}</strong>
                            <em>
                              {#if resourceDefault.unit === 'USD'}
                                $
                              {/if}
                              {resourceDefault.value}
                              {#if resourceDefault.unit !== 'USD'}
                                {resourceDefault.unit}
                              {/if}
                            </em>
                          </div>
                        {/each}
                      </div>
                    {/if}
                  </svelte:fragment>
                  <div>
                    <WbUserLimitPlanForm {user} {selectedAmounts} {onExceed} bind:planData />
                  </div>
                </AccordionItem>
              {/each}
            </Accordion>
          </div>
        </div>
      {/if}
      <div class="extra">
        <Accordion size="sm" disabled={modalState.notifications.has('errorFetchingDependencies')}>
          <AccordionItem title="Extend Basic Plan">
            <Form
              fieldDomString="input,div[name='exceedAction']"
              {initialValues}
              {schema}
              let:submitForm
              let:setValue
              let:touched
              let:values
              let:errors
              on:submit={handleFormSubmission}
            >
              <FormGroup>
                <NumberInput
                  name="compute"
                  label="Compute (amount of $)"
                  value={values.compute}
                  min={0}
                  step={resourceCharges.compute?.purchasableChunk}
                  helperText={`Compute can be purchased in increments of $${resourceCharges.compute?.purchasableChunk}`}
                  invalid={touched.compute && !!errors.compute}
                  invalidText={errors.compute}
                  on:change={handleCustomInputChange('compute', setValue)}
                />
                <NumberInput
                  name="storage"
                  label="Storage (amount of GB)"
                  value={values.storage}
                  step={resourceCharges.storage?.purchasableChunk}
                  min={0}
                  helperText={`Storage can be purchased in increments of ${resourceCharges.storage?.purchasableChunk}GB`}
                  invalid={touched.storage && !!errors.storage}
                  invalidText={errors.storage}
                  on:change={handleCustomInputChange('storage', setValue)}
                />
                <NumberInput
                  name="egress"
                  label="Egress (amount of GB)"
                  value={values.egress}
                  min={0}
                  step={resourceCharges.egress?.purchasableChunk}
                  helperText={`Egress can be purchased in increments of ${resourceCharges.egress?.purchasableChunk}GB`}
                  invalid={touched.egress && !!errors.egress}
                  invalidText={errors.egress}
                  on:change={handleCustomInputChange('egress', setValue)}
                />
                <hr />
                <NumberInput
                  name="cushion"
                  min={0}
                  max={100}
                  label="Cushion %"
                  value={values.cushion}
                  helperText={!values.cushion
                    ? 'Extra percent that can go over the agreed amount, without any extra charges'
                    : `Client can go up $${Math.ceil(totalAmount * (1 + values.cushion / 100))} but still charged $${totalAmount}`}
                  invalid={touched.cushion && !!errors.cushion}
                  invalidText={errors.cushion}
                  on:change={handleCustomInputChange('cushion', setValue)}
                />
                <hr />
                <div class="exceed">
                  <RadioButtonGroup
                    legendText="Resource Exceed Action"
                    selected={values.exceedAction}
                    name="exceedAction"
                    on:change={handleCustomInputChange('exceedAction', setValue)}
                  >
                    {#each Object.entries(WB_PLAN_EXCEED_ACTIONS) as [value, labelText]}
                      <div class="radio">
                        <RadioButton {labelText} {value} />
                        {#if value === 'charge'}
                          <OutboundLink href="/workbench#prices">Check charges</OutboundLink>
                        {/if}
                      </div>
                    {/each}
                  </RadioButtonGroup>
                  <div class:hidden={values.exceedAction !== 'charge'}>
                    <NumberInput
                      required
                      name="hardLimit"
                      min={0}
                      label="Hard Limit (amount of $)"
                      value={values.hardLimit}
                      helperText="An amount that the client won't be able to exceed (compute + storage + egress)"
                      invalid={touched.hardLimit && !!errors.hardLimit}
                      invalidText={errors.hardLimit}
                      on:change={handleCustomInputChange('hardLimit', setValue)}
                    />
                  </div>
                </div>
                <Checkbox name="notify" labelText="Notify when certain limits are reached or exceeded" checked={values.notify} />
              </FormGroup>
              <button hidden type="button" on:click={submitForm} bind:this={formSubmitButtonRef} />
            </Form>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
    <div class="pricing">
      <h5>Total: <em>{totalPricing}</em></h5>
    </div>
    <Checkbox
      disabled={!planData.date || planData.invalid || (!totalUsers && !totalAmount)}
      labelText="I confirm all selections and I want to create this subscription plan"
      bind:checked={confirmSelections}
    />
  </LoadingSpinner>
</Modal>

<style>
  .intro,
  .options {
    margin-bottom: 1rem;
  }

  .options .bx--label {
    margin-bottom: 0.5rem;
  }

  .active {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .users {
    margin-top: 1rem;
  }

  .users .list {
    max-height: 300px;
    overflow-y: auto;
    padding: 1rem;
    border: 2px solid var(--cds-ui-03);
    border-radius: 10px;
  }

  .users :global(h5) {
    display: flex;
    align-items: center;
  }

  .users :global(h5 em) {
    font-size: 0.75rem;
    font-weight: normal;
    margin-left: 0.25rem;
  }

  .defaults {
    display: flex;
  }

  .defaults .default {
    margin-right: 0.5rem;
  }

  .exceed .hidden {
    display: none;
  }

  .exceed :global(fieldset) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .exceed .radio {
    display: flex;
    margin-bottom: 0.25rem;
  }

  .exceed .radio :global(a) {
    margin-left: 0.5rem;
  }

  .exceed :global(.bx--form-item:last-child) {
    margin-top: 0.5rem;
  }

  .exceed :global(a.bx--link) {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
  }

  .exceed :global(a.bx--link svg) {
    width: 12px;
  }

  .pricing {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
  }

  .extra {
    margin-top: 2rem;
  }

  .extra :global(hr) {
    margin: 1.5rem 0;
  }
</style>
