<script>
  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
  import TaskExecutions from './TaskExecutions.svelte';
  import TaskList from './TaskList.svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';
</script>

<TabsWithRouting
  defaultHash={'#overview'}
  tabsWrapperProps={{ 'aria-label': 'Tasks module tabs' }}
  tabs={[
    { label: 'Overview', hash: '#overview' },
    { label: 'Executions', hash: '#executions' },
  ]}
>
  <LazyLoadedTabContent hash="#overview"><TaskList /></LazyLoadedTabContent>
  <LazyLoadedTabContent hash="#executions"><TaskExecutions /></LazyLoadedTabContent>
</TabsWithRouting>
