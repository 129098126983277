<script>
  import { useNavigate } from 'svelte-navigator';

  import {
    Accordion,
    AccordionItem,
    Button,
    Link,
    StructuredList,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
    Tag,
    Toolbar,
    ToolbarBatchActions,
    ToolbarContent,
    Tooltip,
  } from 'carbon-components-svelte';
  import Box32 from 'carbon-icons-svelte/lib/Box32';
  import ChevronDown16 from 'carbon-icons-svelte/lib/ChevronDown16';
  import ChevronUp16 from 'carbon-icons-svelte/lib/ChevronUp16';
  import GroupIcon from 'carbon-icons-svelte/lib/Events16';
  import Help20 from 'carbon-icons-svelte/lib/Help20';
  import OrganizationIcon from 'carbon-icons-svelte/lib/Category16';
  import Play16 from 'carbon-icons-svelte/lib/Play16';
  import Search32 from 'carbon-icons-svelte/lib/Search32';
  import startCase from 'lodash/startCase';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import CustomParameterValue from '../../components/build-parameters/CustomParameterValueCell.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  export let selectedRowIds,
    results,
    advancedFiltersOverview,
    advancedSearchEnabled,
    loading,
    searchTerm,
    handleSearchTextChange,
    handleClickForModal,
    handleFilterRemoval,
    handleModeChange;

  const navigate = useNavigate();

  const ENTITY_ICONS = {
    organization: OrganizationIcon,
    group: GroupIcon,
  };

  const SEARCH_TIPS = [
    {
      title: 'Simple name/value search',
      example: 'total',
      results: 'At least one parameter name or value contains "total"',
    },
    {
      title: 'Name & value pair search',
      example: 'feed:total',
      results: 'At least one parameter contains "feed" in its name and also has at least one value that contains "total"',
    },
    {
      title: 'Name & multiple values search',
      example: 'feed:total,bats',
      results: 'At least one parameter contains "feed" in its name and also has values that contains both "total" and "bats"',
    },
    {
      title: 'Multiple conditions',
      example: 'feed:total,bats+bpstat',
      results: `At least one parameter contains "feed" in its name and also has values that contains both "total" and "bats",
        while the same build has another parameter name or value that contains "bpstat"`,
    },
  ];

  function goToEntity(id, targetId, type) {
    return function go(event) {
      event.preventDefault();
      navigate(`/${type}/${id}?target=${targetId}#parameters`);
    };
  }

  $: expandedRowIds = results.map(({ id }) => id);
</script>

<DataTable
  class={`builds-table${advancedSearchEnabled && selectedRowIds.length ? ' no-header' : ''}`}
  sortable
  expandable
  batchSelection
  batchExpansion
  bind:selectedRowIds
  {expandedRowIds}
  rows={results}
  headers={[
    { key: 'owner', value: 'Owner' },
    { key: 'name', value: 'Name' },
  ]}
>
  <span slot="cell" let:cell let:row>
    {#if cell.key === 'owner'}
      <span>{cell.value}<Tag icon={ENTITY_ICONS[row.type]}>{startCase(row.type)}</Tag></span>
    {:else if cell.key === 'name'}
      <Link href={`/${row.type}/${row.ownerId}?target=${row.id}#parameters`} on:click={goToEntity(row.ownerId, row.id, row.type)}>
        {cell.value ?? 'Default'}
      </Link>
    {:else}
      {cell.value}
    {/if}
  </span>
  <div slot="expanded-row" let:row>
    <StructuredList condensed>
      <StructuredListHead>
        <StructuredListRow head>
          <StructuredListCell head>Parameter Name</StructuredListCell>
          <StructuredListCell head>Parameter Value</StructuredListCell>
        </StructuredListRow>
      </StructuredListHead>
      <StructuredListBody>
        {#each row.parameters as { parameterName, parameterValue }}
          <StructuredListRow>
            <StructuredListCell>{parameterName}</StructuredListCell>
            <StructuredListCell><svelte:component this={CustomParameterValue} value={parameterValue} /></StructuredListCell>
          </StructuredListRow>
        {/each}
      </StructuredListBody>
    </StructuredList>
  </div>
  <Toolbar class="table-toolbar">
    <ToolbarBatchActions formatTotalSelected={(totalSelected) => `${totalSelected} ${totalSelected > 1 ? 'builds' : 'build'} selected`}>
      <Button on:click={handleClickForModal('build')} icon={Play16}>Run {selectedRowIds.length > 1 ? 'builds' : 'build'}</Button>
    </ToolbarBatchActions>
    <ToolbarContent>
      {#if advancedSearchEnabled}
        <div class="selections">
          {#each advancedFiltersOverview as filter, index}
            <Tag type="outline" filter on:close={handleFilterRemoval(index)}>{filter}</Tag>
          {/each}
        </div>
      {:else}
        <ToolbarSearchWithRouting
          placeholder="Search for builds"
          persistent
          bind:searchText={searchTerm}
          on:toolbarSearchValueChange={handleSearchTextChange}
        />
      {/if}
      <Button
        tooltipPosition="left"
        iconDescription={`${advancedSearchEnabled ? 'Hide' : 'Show'} custom search`}
        on:click={handleModeChange}
        icon={advancedSearchEnabled ? ChevronDown16 : ChevronUp16}
      />
      {#if !advancedSearchEnabled && !searchTerm}
        <div class="search-help">
          <Tooltip align="end" icon={Help20}>
            <div>Search tips:</div>
            <Accordion>
              {#each SEARCH_TIPS as { title, example, results }}
                <AccordionItem {title}>
                  <div><strong>Example:</strong><code>{example}</code></div>
                  <div><strong>Matches builds where:</strong> {results}</div>
                </AccordionItem>
              {/each}
            </Accordion>
          </Tooltip>
        </div>
      {/if}
    </ToolbarContent>
  </Toolbar>
</DataTable>
{#if !results.length}
  <LoadingSpinner {loading}>
    <div class="empty-table">
      <div class="icon">
        {#if loading && searchTerm}
          <Search32 />
        {:else}
          <Box32 />
        {/if}
      </div>
      <em>
        {#if searchTerm}
          {loading ? 'Looking for matches...' : 'No results for your search'}
        {:else}
          Search results will appear here
        {/if}
      </em>
    </div>
  </LoadingSpinner>
{/if}

<style>
  :global(.builds-table.bx--data-table-container) {
    padding-top: unset;
  }

  /* :global(.builds-table .bx--batch-actions ~ .bx--toolbar-content) {
    clip-path: unset;
  } */

  :global(.builds-table.no-header thead tr) {
    visibility: hidden;
  }

  :global(.builds-table) .search-help {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    right: 5rem;
  }

  :global(.builds-table) .search-help :global(.bx--tooltip) {
    max-width: unset;
    min-width: 20rem;
    background-color: var(--cds-layer);
    color: var(--cds-text-01);
  }

  :global(.builds-table) .search-help :global(.bx--tooltip button) {
    padding-right: unset;
  }

  :global(.builds-table) .search-help :global(.bx--tooltip__caret) {
    border-color: var(--cds-layer);
  }

  :global(.builds-table) .search-help :global(.bx--tooltip__content > div) {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
  }

  :global(.builds-table) .search-help :global(.bx--accordion__content) {
    font-size: 0.8125rem;
    padding-right: 1rem;
  }

  :global(.builds-table) .search-help :global(.bx--accordion__content code) {
    padding: 0.1rem 0.4rem;
    border: 1px solid var(--cds-active-01);
    border-radius: 0.3rem;
    margin-left: 0.5rem;
    font-size: 0.75rem;
    font-family: monospace !important;
  }

  :global(.builds-table) .search-help :global(.bx--accordion__content > div:last-child) {
    margin-top: 1rem;
    line-height: normal;
  }

  :global(.builds-table) .search-help :global(.bx--accordion__content strong) {
    user-select: none;
  }

  .selections {
    display: inline-block;
    width: 100%;
    padding: 0.5rem 1rem;
    align-items: center;
    white-space: nowrap;
    overflow-y: auto;
  }

  :global(.table-toolbar .bx--overflow-menu-options__btn) {
    max-width: unset;
  }

  :global(.table-toolbar .bx--toolbar-content) {
    z-index: 1;
  }

  :global(.table-toolbar .bx--overflow-menu-options) {
    min-width: 13rem;
  }

  .empty-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
    user-select: none;
    padding: 2rem 0;
    background-color: var(--cds-layer);
  }

  .empty-table .icon {
    padding: 0.35rem;
  }
</style>
