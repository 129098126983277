<script>
  import Box from 'carbon-icons-svelte/lib/Box32';

  export let items = [],
    type = 'internal',
    active = true;
</script>

{#if items.length}
  <ul class="tiles">
    {#each items as item}
      <li class="tile" tabindex="0" draggable={active} data-type={type} data-item-id={item.id} on:dragstart on:dragend>
        <slot {item}>
          {item}
        </slot>
      </li>
    {/each}
  </ul>
{:else}
  <div class="empty">
    <Box />
    <span>No items</span>
  </div>
{/if}

<style>
  .tile {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 1rem;
    margin: 0.25rem 0;
    background-color: var(--cds-ui-01);
    transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
    width: 95%;
    height: 40px;
  }

  .tile[draggable='true'] {
    cursor: pointer;
  }

  .tile:hover {
    background-color: var(--cds-hover-ui);
  }

  .tile :global(button svg) {
    margin-left: 0;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
  }

  .empty :global(span) {
    margin-top: 0.5rem;
    color: var(--cds-text-helper);
  }

  .empty :global(svg) {
    fill: var(--cds-text-helper);
  }
</style>
