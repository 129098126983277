<script>
  import { createEventDispatcher } from 'svelte';

  import {
    DataTable,
    InlineNotification,
    Modal,
    Pagination,
    Select,
    SelectItem,
    StructuredList,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
    Tag,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
  } from 'carbon-components-svelte';
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';
  import Box32 from 'carbon-icons-svelte/lib/Box32';
  import GroupIcon from 'carbon-icons-svelte/lib/Events16';
  import OrganizationIcon from 'carbon-icons-svelte/lib/Category16';
  import Search32 from 'carbon-icons-svelte/lib/Search32';
  import startCase from 'lodash/startCase';

  import CustomParameterValue from './CustomParameterValueCell.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { cloneBuildConfiguration, getMatchedBuildConfigs } from '../../services';
  import { getServerErrorMessage } from '../../utils';

  export let entityId,
    entityType,
    entityName,
    configId,
    targetName = null,
    open;

  let loading = false,
    resultNotification,
    searchText = '',
    results = [],
    searchType = 'parameter',
    debounce,
    pagination = { currentPage: 1, pageSize: 25 },
    selectedRowIds = [];

  const DEBOUNCE_MS = 500;
  const ENTITY_ICONS = {
    organization: OrganizationIcon,
    group: GroupIcon,
  };
  const SEARCH_PLACEHOLDERS = {
    parameter: 'parameter name/value',
    entity: 'owner name',
  };

  const dispatch = createEventDispatcher();

  function onSearchTypeChange({ detail: selection }) {
    searchType = selection;
    searchText = '';
    results = [];
  }

  function onSearchTextChange({ target }) {
    clearTimeout(debounce);

    searchText = target?.value?.trim() ?? '';
    pagination = { ...pagination, currentPage: 1 };
    results = [];

    if (!searchText) {
      loading = false;
      return;
    }

    loading = true;

    debounce = setTimeout(async () => {
      try {
        results = await getMatchedBuildConfigs({ type: searchType, searchText });
      } catch (error) {
        const errorMessage = getServerErrorMessage(error) ?? 'Could not fetch configurations!';
        console.error(`[CloneBuildConfigModal] Failed to fetch configurations! Server says: ${errorMessage}`, error);
      } finally {
        loading = false;
      }
    }, DEBOUNCE_MS);
  }

  async function handleConfigurationClone() {
    loading = true;

    try {
      const configuration = await cloneBuildConfiguration({
        fromConfigId: selectedRowIds[0],
        toConfigId: configId,
        toEntity: entityId,
        entityType,
      });

      dispatch('close', configuration);
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Could not clone configuration!';
      console.error(`[CloneBuildConfigModal] Failed to clone configuration! Server says: ${errorMessage}`, error);

      resultNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: errorMessage,
      };
    } finally {
      loading = false;
    }
  }

  $: expandedRowIds = results.map(({ id }) => id);
  $: selectedConfig = selectedRowIds.length ? results.find(({ id }) => id === selectedRowIds[0]) : null;
</script>

<Modal
  class="clone-modal"
  modalHeading="Clone Build Configuration"
  preventCloseOnClickOutside={true}
  primaryButtonText="Clone"
  secondaryButtonText="Cancel"
  primaryButtonDisabled={!selectedRowIds.length}
  size="lg"
  bind:open
  on:click:button--primary={handleConfigurationClone}
  on:click:button--secondary={() => dispatch('close')}
  on:close
>
  {#if resultNotification}
    <InlineNotification
      kind={resultNotification.kind}
      lowContrast
      title={resultNotification.title}
      subtitle={resultNotification.subtitle}
    />
  {/if}
  <DataTable
    class={selectedConfig ? 'padded-table' : ''}
    headers={[
      { key: 'owner', value: 'Owner' },
      { key: 'name', value: 'Name', display: (value, row) => (row.type === 'organization' ? '-' : value) },
    ]}
    radio
    expandable
    {expandedRowIds}
    rows={results}
    size="medium"
    sortable
    bind:selectedRowIds
    pageSize={pagination.pageSize}
    page={pagination.currentPage}
  >
    <span slot="cell" let:cell let:row>
      {#if cell.display}
        {cell.display(cell.value, row)}
      {:else if cell.key === 'owner'}
        <span>{cell.value}<Tag icon={ENTITY_ICONS[row.type]}>{startCase(row.type)}</Tag></span>
      {:else}
        {cell.value}
      {/if}
    </span>
    <div slot="expanded-row" let:row>
      <StructuredList class="parameter-list" condensed>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Parameter Name</StructuredListCell>
            <StructuredListCell head>Parameter Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          {#each row.parameters as { parameterName, parameterValue }}
            <StructuredListRow>
              <StructuredListCell>{parameterName}</StructuredListCell>
              <StructuredListCell><svelte:component this={CustomParameterValue} value={parameterValue} /></StructuredListCell>
            </StructuredListRow>
          {/each}
        </StructuredListBody>
      </StructuredList>
    </div>
    <Toolbar>
      <ToolbarContent>
        <Select class="search-select" selected={searchType} on:change={onSearchTypeChange} size="xl">
          <SelectItem value="parameter" text="Parameter" />
          <SelectItem value="entity" text="Owner" />
        </Select>
        <ToolbarSearch
          placeholder={`Search for configurations based on ${SEARCH_PLACEHOLDERS[searchType]}`}
          labelText="Search for users"
          persistent
          value={searchText}
          on:clear={onSearchTextChange}
          on:input={onSearchTextChange}
        />
      </ToolbarContent>
    </Toolbar>
  </DataTable>
  {#if results.length > pagination.pageSize}
    <Pagination
      bind:page={pagination.currentPage}
      bind:pageSize={pagination.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={results.length}
    />
  {/if}
  {#if !results.length}
    <LoadingSpinner {loading}>
      <div class="empty-table">
        <div class="icon">
          {#if loading && searchText}
            <Search32 />
          {:else}
            <Box32 />
          {/if}
        </div>
        <em>
          {#if searchText}
            {loading ? 'Looking for matches...' : 'No results for your search'}
          {:else}
            Search for specific build configuration using the search bar above
          {/if}
        </em>
      </div>
    </LoadingSpinner>
  {/if}
  {#if selectedConfig}
    <div class="status">
      <div class="details">
        <Tag icon={ENTITY_ICONS[selectedConfig.type]}>{startCase(selectedConfig.type)}</Tag>
        <strong>{selectedConfig.owner}</strong>
        {#if selectedConfig.type === 'group'}
          <em>(target: {selectedConfig.name})</em>
        {/if}
      </div>
      <ArrowRight16 />
      <div class="details">
        <Tag icon={ENTITY_ICONS[entityType]}>{startCase(entityType)}</Tag>
        <strong>{entityName}</strong>
        {#if targetName}
          <em>(target: {targetName})</em>
        {/if}
      </div>
    </div>
  {/if}
</Modal>

<style>
  :global(.clone-modal .bx--modal-content) {
    min-height: 300px;
  }

  :global(.clone-modal .padded-table) {
    margin-bottom: 4rem;
  }

  :global(.search-select .bx--select) {
    flex-direction: row;
    min-width: 9rem;
  }

  :global(.parameter-list) {
    margin-bottom: 2rem;
  }

  .empty-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
    user-select: none;
  }

  .empty-table .icon {
    margin-top: 1rem;
    padding: 0.35rem;
  }

  .status {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 4rem;
    left: 0;
    padding: 1rem;
    background-color: var(--cds-ui-01);
  }

  .status .details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .details > :global(strong) {
    font-size: 16px;
  }

  .details > :global(em) {
    font-size: 12px;
  }

  .status > :global(svg) {
    margin: 0 1rem;
  }
</style>
