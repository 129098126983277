<script>
  import { Button, SkeletonPlaceholder } from 'carbon-components-svelte';

  export let loading, active, activeText, actionText, onClick;
</script>

{#if loading}
  <SkeletonPlaceholder style="height: 20px; width: 80px" heading />
{:else if active}
  <h3 class="h3 no-wrap">{activeText}</h3>
{:else}
  <div class="xs-btn">
    <Button class="xs-btn" kind="ghost" size="sm" on:click={onClick}>{actionText}</Button>
  </div>
{/if}

<style>
  .no-wrap {
    white-space: nowrap;
  }

  .h3 {
    font-size: 1.25rem;
    margin: 0 0.5rem;
  }

  .xs-btn :global(.bx--btn.bx--btn--sm) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    min-height: unset;
  }
</style>
