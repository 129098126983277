<script>
  import { onMount } from 'svelte';
  import { produce } from 'immer';

  import {
    Button,
    InlineNotification,
    SideNav,
    SideNavDivider,
    SideNavItems,
    SideNavLink,
    Tag,
    TextInput,
    TextInputSkeleton,
  } from 'carbon-components-svelte';

  import Add16 from 'carbon-icons-svelte/lib/Add16';
  import ChevronRight from 'carbon-icons-svelte/lib/ChevronRight16';
  import ChevronLeft from 'carbon-icons-svelte/lib/ChevronLeft16';
  import Document16 from 'carbon-icons-svelte/lib/Document16';
  import Edit16 from 'carbon-icons-svelte/lib/Edit16';
  import Enable16 from 'carbon-icons-svelte/lib/CheckmarkOutline16';
  import Disable16 from 'carbon-icons-svelte/lib/Error16';
  import Information16 from 'carbon-icons-svelte/lib/Information16';
  import Play16 from 'carbon-icons-svelte/lib/Play16';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';
  import WarningAltFilled16 from 'carbon-icons-svelte/lib/WarningAltFilled16';

  import Badge from '../../components/Badge.svelte';
  import BuildNotesModal from '../../components/build-parameters/BuildNotesModal.svelte';
  import BuildParameterDataTable from '../../components/build-parameters/DataTable.svelte';
  import CustomInlineNotification from '../../components/CustomInlineNotification.svelte';
  import DisableTargetModal from './DisableTargetModal.svelte';
  import EditTargetModal from './EditTargetModal.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import RunBuildModal from '../../components/build-parameters/RunBuildModal.svelte';
  import TargetDetailsModal from './TargetDetailsModal.svelte';
  import TextOrAction from '../../components/TextOrAction.svelte';
  import RemoveTargetModal from './RemoveTargetModal.svelte';

  import { getBuildConfigs, getBuildParameters, createBuildConfig, getParentBuildConfigs } from '../../services';

  export let groupId, groupName;

  let loaders = {
    targetCreation: false,
    data: true,
    parameters: false,
    parentConfigurations: false,
  };

  let messages = {
    targetCreation: null,
    targetDefault: null,
  };

  let pageData = {
    creationActive: false,
    newTargetName: '',
    selectedTarget: '',
    targets: {},
    groupHasTargets: false,
    notifications: new Set(),
    parentConfigurations: {},
    selectedEntity: 'target',
  };

  let modals = {
    build: { open: false },
    editTarget: { open: false },
    deleteTarget: { open: false },
    disableTarget: { open: false },
    targetInfo: { open: false },
    notes: { open: false },
  };

  const PARENT_TARGETS = {
    system: {
      id: 'system',
      label: 'Global',
      info: 'You are currently viewing the global build configuration. All entries below are inherited by every organization.',
    },
    organization: {
      id: 'organization',
      label: 'Organization',
      info: `You are currently viewing the organization's build configuration.
        All entries below are inherited by every group within this organization.`,
    },
    group: {
      id: 'group',
      label: 'Group',
      info: `You are currently viewing the group's default build configuration.
        All entries below are inherited by every target belonging to this group.`,
    },
  };
  const notifications = {
    errorTargets: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load targets list! Please try again later.',
    },
    noTargets: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no build targets for this group.',
      actions: [
        {
          text: 'Add Target',
          onClick: () => createTarget(),
        },
      ],
    },
    noDefaultTarget: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Please select at least one target as the default.',
    },
  };

  async function fetchParentConfigs() {
    loaders = {
      ...loaders,
      parentConfigurations: true,
    };

    try {
      const parentConfigurations = await getParentBuildConfigs({
        ownerId: groupId,
        ownerType: 'group',
        isDefault: false,
      });

      pageData = {
        ...pageData,
        parentConfigurations,
      };
    } catch (error) {
      console.error('[GroupTargetsList] Failed to load targets!', error);
    } finally {
      loaders = {
        ...loaders,
        parentConfigurations: false,
      };
    }
  }

  async function loadTargetParameters(configId) {
    loaders = { ...loaders, parameters: true };

    try {
      const parameters = await getBuildParameters({ configId });
      pageData.notifications.delete('errorParameters');

      return parameters;
    } catch (error) {
      console.error('[GroupTargetsList] Failed to load parameters!', error);
      pageData.notifications.add('errorParameters');
    } finally {
      loaders = { ...loaders, parameters: false };
    }
  }

  async function loadGroupTargets() {
    loaders = { ...loaders, data: true };

    try {
      let targets = await getBuildConfigs({ ownerId: groupId, ownerType: 'group' });
      let selectedTarget = '';

      const groupHasTargets = !!Object.keys(targets).length;

      if (groupHasTargets) {
        const targetsArray = Object.values(targets);
        const urlParams = new URLSearchParams(window.location.search);
        const urlTarget = urlParams.get('target');

        selectedTarget = (urlTarget && targetsArray.find((target) => target.id === urlTarget)?.name) || targetsArray[0].name;
        const parameters = await loadTargetParameters(targets[selectedTarget].id);

        if (!parameters.length) {
          pageData.notifications.add('noParameters');
        }

        targets = {
          ...targets,
          [selectedTarget]: {
            ...targets[selectedTarget],
            data: parameters,
          },
        };
      } else {
        pageData.notifications.add('noTargets');
      }

      pageData = {
        ...pageData,
        targets,
        groupHasTargets,
        selectedTarget,
      };
    } catch (error) {
      console.error('[GroupTargetsList] Failed to load targets!', error);
      pageData.notifications.add('errorTargets');
      pageData = { ...pageData, targets: {} };
    } finally {
      loaders = { ...loaders, data: false };
    }
  }

  async function handleKeyDown(event) {
    if (!['Enter', 'Escape'].includes(event.key)) {
      return;
    }

    messages = { ...messages, targetCreation: null };

    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
        if (
          !pageData.newTargetName ||
          Object.keys(pageData.targets).some((target) => target.toLocaleLowerCase() === pageData.newTargetName.toLocaleLowerCase())
        ) {
          messages = {
            ...messages,
            targetCreation: !pageData.newTargetName ? 'Please provide a target name' : 'Target name already exists',
          };
          pageData = { ...pageData, creationActive: false, newTargetName: '' };
          return;
        }

        loaders = { ...loaders, targetCreation: true };

        try {
          const buildConfig = await createBuildConfig({
            ownerType: 'group',
            ownerId: groupId,
            name: pageData.newTargetName,
            isDefault: false,
          });

          pageData.notifications.add('noParameters');

          pageData = {
            ...pageData,
            targets: { ...pageData.targets, [pageData.newTargetName]: { ...buildConfig, buildConfigNotes: [] } },
            groupHasTargets: true,
            selectedTarget: pageData.newTargetName,
            selectedEntity: 'target',
          };
        } catch (error) {
          console.error('[GroupTargetList] Error while creating target...', error);
          messages = { ...messages, targetCreation: 'Failed to create target' };
        } finally {
          loaders = { ...loaders, targetCreation: false };
          pageData = { ...pageData, newTargetName: '', creationActive: false };
        }

        break;

      case 'Escape':
        if (!pageData.groupHasTargets) {
          pageData.notifications.add('noTargets');
        }

        pageData = { ...pageData, newTargetName: '', creationActive: false };

        break;
    }
  }

  /**
   * @description Initiates the creation of a build target
   */
  function createTarget() {
    pageData.notifications.delete('noTargets');

    pageData = {
      ...pageData,
      creationActive: true,
      newTargetName: 'Basic',
    };
  }

  function findAndSelectTarget(targetId) {
    const selectedTarget = Object.values(pageData.targets).find((target) => target.id === targetId);
    selectTarget(selectedTarget.name);
  }

  /**
   * @description Selects a build target and (re)fetch its parameters
   * @param selectedTarget
   */
  async function selectTarget(selectedTarget) {
    if (selectedTarget === pageData.selectedTarget) {
      return;
    }

    const selectedTargetId = pageData.targets[selectedTarget].id;
    const parameters = await loadTargetParameters(selectedTargetId);

    const action = parameters.length ? 'delete' : 'add';
    pageData.notifications[action]('noParameters');
    pageData.notifications.delete('noInheritedParameters');

    pageData = {
      ...pageData,
      selectedTarget,
      selectedEntity: 'target',
      parentConfigurations: {},
      targets: {
        ...pageData.targets,
        [selectedTarget]: {
          ...pageData.targets[selectedTarget],
          data: parameters,
        },
      },
    };
  }

  function updatePageData(modification) {
    const { action, data } = modification;
    let updatedTargets;

    switch (action) {
      case 'target-rename':
        if (!data) {
          break;
        }

        updatedTargets = Object.keys(pageData.targets).reduce(
          (targets, target) => ({
            ...targets,
            ...(pageData.selectedTarget === target
              ? {
                  [data.name]: {
                    ...pageData.targets[target],
                    ...data,
                  },
                }
              : { [target]: { ...pageData.targets[target] } }),
          }),
          {}
        );

        pageData = {
          ...pageData,
          selectedTarget: data.name,
          targets: updatedTargets,
        };

        break;

      case 'add-parameter':
        if (!data) {
          break;
        }

        pageData.notifications.delete('noParameters');

        if (pageData.selectedEntity === 'target') {
          const newParameters = [...(pageData.targets[pageData.selectedTarget]?.data ?? []), data];

          updatedTargets = Object.keys(pageData.targets).reduce(
            (targets, target) => ({
              ...targets,
              [target]:
                pageData.selectedTarget === target
                  ? {
                      ...pageData.targets[target],
                      data: newParameters,
                    }
                  : { ...pageData.targets[target] },
            }),
            {}
          );

          pageData = {
            ...pageData,
            targets: updatedTargets,
          };
        } else {
          const newParentConfigurations = produce(pageData.parentConfigurations, (draft) => {
            draft[pageData.selectedEntity].buildConfig.data.push(data);
            if (!draft[pageData.selectedEntity].buildConfig.id) {
              // eslint-disable-next-line no-param-reassign
              draft[pageData.selectedEntity].buildConfig.id = data.configId;
            }
          });

          pageData = {
            ...pageData,
            parentConfigurations: newParentConfigurations,
          };
        }

        break;

      case 'update-parameter':
        if (!data) {
          break;
        }

        if (pageData.selectedEntity === 'target') {
          updatedTargets = Object.keys(pageData.targets).reduce(
            (targets, target) => ({
              ...targets,
              [target]:
                data.configId === pageData.targets[target].id
                  ? {
                      ...pageData.targets[target],
                      data: pageData.targets[target].data.map((param) => (param.id === data.id ? data : param)),
                    }
                  : { ...pageData.targets[target] },
            }),
            {}
          );

          pageData = {
            ...pageData,
            targets: updatedTargets,
          };
        } else {
          const newParentConfigurations = produce(pageData.parentConfigurations, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft[pageData.selectedEntity].buildConfig.data = draft[pageData.selectedEntity].buildConfig.data.map((param) =>
              param.id === data.id ? data : param
            );
          });

          pageData = {
            ...pageData,
            parentConfigurations: newParentConfigurations,
          };
        }

        break;

      case 'remove-parameter':
        if (!data) {
          break;
        }

        if (pageData.selectedEntity === 'target') {
          updatedTargets = Object.keys(pageData.targets).reduce(
            (targets, target) => ({
              ...targets,
              [target]:
                data.configId === pageData.targets[target].id
                  ? {
                      ...pageData.targets[target],
                      data: pageData.targets[target].data.filter((param) => param.id !== data.id),
                    }
                  : { ...pageData.targets[target] },
            }),
            {}
          );

          pageData = {
            ...pageData,
            targets: updatedTargets,
          };
        } else {
          const newParentConfigurations = produce(pageData.parentConfigurations, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft[pageData.selectedEntity].buildConfig.data = draft[pageData.selectedEntity].buildConfig.data.filter(
              (param) => param.id !== data.id
            );
          });

          pageData = {
            ...pageData,
            parentConfigurations: newParentConfigurations,
          };
        }

        break;

      case 'remove-parameters':
        if (!data) {
          break;
        }

        if (pageData.selectedEntity === 'target') {
          updatedTargets = Object.keys(pageData.targets).reduce(
            (targets, target) => ({
              ...targets,
              [target]:
                data.configId === pageData.targets[target].id
                  ? {
                      ...pageData.targets[target],
                      data: [],
                    }
                  : { ...pageData.targets[target] },
            }),
            {}
          );

          pageData = {
            ...pageData,
            targets: updatedTargets,
          };
        } else {
          const newParentConfigurations = produce(pageData.parentConfigurations, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft[pageData.selectedEntity].buildConfig.data = [];
          });

          pageData = {
            ...pageData,
            parentConfigurations: newParentConfigurations,
          };
        }

        break;
      case 'delete-target':
        if (!data) {
          break;
        }

        delete pageData.targets[data.name];

        // eslint-disable-next-line no-case-declarations
        const groupHasTargets = !!Object.keys(pageData.targets).length;
        if (!groupHasTargets) {
          pageData.notifications.add('noTargets');
        }

        // eslint-disable-next-line no-case-declarations
        const selectedTarget = Object.values(pageData.targets)[0]?.name ?? '';

        pageData = {
          ...pageData,
          groupHasTargets,
          selectedTarget,
          selectedEntity: 'target',
          parentConfigurations: {},
        };

        if (selectedTarget) {
          const notificationAction = pageData.targets[pageData.selectedTarget].data?.length ? 'delete' : 'add';
          pageData.notifications[notificationAction]('noParameters');
        }

        break;

      case 'add-parameters':
      case 'clone-config':
        if (!data) {
          break;
        }

        pageData.notifications.delete('noParameters');

        if (pageData.selectedEntity === 'target') {
          updatedTargets = Object.keys(pageData.targets).reduce(
            (targets, target) => ({
              ...targets,
              [target]:
                pageData.selectedTarget === target
                  ? {
                      ...pageData.targets[target],
                      data: data.parameters,
                    }
                  : { ...pageData.targets[target] },
            }),
            {}
          );

          pageData = {
            ...pageData,
            targets: updatedTargets,
          };
        } else {
          const newParentConfigurations = produce(pageData.parentConfigurations, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft[pageData.selectedEntity].buildConfig.data = data.parameters;
            if (!draft[pageData.selectedEntity].buildConfig.id) {
              // eslint-disable-next-line no-param-reassign
              draft[pageData.selectedEntity].buildConfig.id = data.parameters[0].configId;
            }
          });

          pageData = {
            ...pageData,
            parentConfigurations: newParentConfigurations,
          };
        }

        break;

      case 'build-result':
        if (!data) {
          break;
        }

        pageData.notifications.add(data.done ? 'buildSuccess' : 'buildFailure');
        pageData = { ...pageData };

        break;

      case 'note-deletion':
        if (!data) {
          break;
        }

        updatedTargets = produce(pageData.targets, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft[pageData.selectedTarget].buildConfigNotes = draft[pageData.selectedTarget].buildConfigNotes.filter(
            ({ id }) => id !== data.noteId
          );
        });

        pageData = {
          ...pageData,
          targets: updatedTargets,
        };

        break;

      case 'note-creation':
        if (!data) {
          break;
        }

        updatedTargets = produce(pageData.targets, (draft) => {
          draft[pageData.selectedTarget].buildConfigNotes.push(data.note);
        });

        pageData = {
          ...pageData,
          targets: updatedTargets,
        };

        break;

      case 'note-update':
        if (!data) {
          break;
        }

        updatedTargets = produce(pageData.targets, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft[pageData.selectedTarget].buildConfigNotes = draft[pageData.selectedTarget].buildConfigNotes.map((note) =>
            note.id !== data.note.id ? note : data.note
          );
        });

        pageData = {
          ...pageData,
          targets: updatedTargets,
        };

        break;

      case 'disable-target':
        if (!data) {
          break;
        }

        updatedTargets = Object.keys(pageData.targets).reduce(
          (targets, target) => ({
            ...targets,
            ...(pageData.selectedTarget === target
              ? {
                  [target]: {
                    ...pageData.targets[target],
                    disabled: data.disabled,
                  },
                }
              : { [target]: { ...pageData.targets[target] } }),
          }),
          {}
        );

        pageData = {
          ...pageData,
          targets: updatedTargets,
        };

        break;
      default:
        console.warn(`[GroupTargetList] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function handleNotificationChange({ action, value }) {
    pageData.notifications[action](value);
    pageData = { ...pageData };
  }

  function handleEntitySelection(selectedEntity) {
    return function selectEntity() {
      pageData = {
        ...pageData,
        notifications: new Set(),
        selectedEntity,
      };

      const selectionData =
        selectedEntity === 'target'
          ? pageData.targets[pageData.selectedTarget]?.data
          : pageData.parentConfigurations[selectedEntity]?.buildConfig?.data;
      const action = selectionData?.length ? 'delete' : 'add';

      pageData.notifications[action]('noParameters');
    };
  }

  /**
   * @description Close a given modal by name. Since the modal components may forward the inner Carbon modal's "close"
   *    event multiple times, a single "toggle" method is more prone to unexpected behavior.
   * @param name
   */
  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  /**
   * @description Open a given modal by name, optionally passing an object of data to forward.
   * @param {string} name
   * @param {object?} forwardData
   */
  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  onMount(loadGroupTargets);

  $: tableData = {
    ...pageData.parentConfigurations,
    target: {
      entityType: 'group',
      entityName: groupName,
      entityId: groupId,
      buildConfig: pageData.targets[pageData.selectedTarget],
    },
  };
</script>

{#each Object.keys(notifications) as notification}
  {#if pageData.notifications.has(notification)}
    <CustomInlineNotification {...notifications[notification]} />
  {/if}
{/each}
{#if !pageData.notifications.has('noTargets') && !pageData.notifications.has('errorTargets')}
  <LoadingSpinner withOverlay={false} loading={loaders.data}>
    <h2 class="h2">Build Targets</h2>
    <div class="vertical-tabs">
      <SideNav isOpen fixed aria-label="Build Targets">
        <SideNavItems>
          {#each Object.keys(pageData.targets) as name}
            <SideNavLink
              icon={pageData.targets[name].disabled ? Disable16 : null}
              text={name}
              on:click={() => selectTarget(name)}
              isSelected={pageData.selectedTarget === name}
            />
          {/each}
          {#if pageData.creationActive}
            <div class="tab-creation">
              {#if loaders.targetCreation}
                <TextInputSkeleton size="sm" hideLabel />
              {:else}
                <TextInput
                  size="sm"
                  hideLabel
                  labelText="Target Name"
                  placeholder="Enter target name..."
                  autofocus
                  helperText="'ESC' to cancel; 'ENTER' to save"
                  bind:value={pageData.newTargetName}
                  on:keydown={handleKeyDown}
                />
              {/if}
            </div>
          {:else if messages.targetCreation}
            <div class="inline-message">
              <WarningAltFilled16 />
              <p>{messages.targetCreation}</p>
            </div>
          {/if}
          <SideNavDivider />
          <div class="tabs-action">
            <SideNavLink
              icon={Add16}
              text="Create new target"
              on:click={() => {
                pageData.creationActive = true;
              }}
            />
          </div>
        </SideNavItems>
      </SideNav>
      <div class="tabs-content">
        {#if pageData.targets[pageData.selectedTarget]}
          <div class={`tab-header${pageData.selectedEntity !== 'target' ? ' with-notification' : ''}`}>
            <div class={`tab-info${Object.keys(pageData.parentConfigurations).length ? ' scrollable' : ''}`}>
              {#if loaders.parentConfigurations || Object.keys(pageData.parentConfigurations).length}
                {#each Object.values(PARENT_TARGETS) as parent}
                  <TextOrAction
                    loading={loaders.parentConfigurations}
                    active={pageData.selectedEntity === parent.id}
                    actionText={parent.label}
                    activeText={parent.label}
                    onClick={handleEntitySelection(parent.id)}
                  />
                  <ChevronRight />
                {/each}
              {:else}
                <Button
                  class="narrow-btn"
                  size="sm"
                  kind="ghost"
                  iconDescription="Show parents"
                  icon={ChevronLeft}
                  on:click={fetchParentConfigs}
                />
              {/if}
              <TextOrAction
                loading={loaders.parameters}
                active={pageData.selectedEntity === 'target'}
                actionText={pageData.targets[pageData.selectedTarget].name}
                activeText={pageData.targets[pageData.selectedTarget].name}
                onClick={handleEntitySelection('target')}
              />
            </div>
            {#if pageData.targets[pageData.selectedTarget].disabled}
              <div class="status"><Tag size="sm">Disabled</Tag></div>
            {/if}
            {#if pageData.targets[pageData.selectedTarget].id && pageData.selectedEntity === 'target'}
              <div class="tab-actions">
                <Button size="field" kind="ghost" iconDescription="Info" icon={Information16} on:click={() => openModal('targetInfo')} />
                <Badge
                  size="small"
                  active={pageData.targets[pageData.selectedTarget].buildConfigNotes?.length}
                  value={pageData.targets[pageData.selectedTarget].buildConfigNotes.length}
                >
                  <Button size="field" kind="ghost" iconDescription="Notes" icon={Document16} on:click={() => openModal('notes')} />
                </Badge>
                <Button size="field" kind="ghost" iconDescription="Edit Target" icon={Edit16} on:click={() => openModal('editTarget')} />
                <Button
                  size="field"
                  kind="ghost"
                  iconDescription={pageData.targets[pageData.selectedTarget].disabled ? 'Enable Target' : 'Disable Target'}
                  icon={pageData.targets[pageData.selectedTarget].disabled ? Enable16 : Disable16}
                  on:click={() => openModal('disableTarget')}
                />
                <Button
                  size="field"
                  kind="danger-tertiary"
                  iconDescription="Delete Target"
                  icon={TrashCan16}
                  on:click={() => openModal('deleteTarget')}
                />
                <div class="main-action">
                  <Button
                    disabled={!pageData.targets[pageData.selectedTarget]?.data?.length}
                    size="field"
                    icon={Play16}
                    on:click={() => openModal('build')}
                  >
                    Build target
                  </Button>
                </div>
              </div>
              {#if modals.disableTarget.open}
                <DisableTargetModal
                  open
                  targetName={pageData.selectedTarget}
                  targetId={pageData.targets[pageData.selectedTarget].id}
                  disabled={!!pageData.targets[pageData.selectedTarget].disabled}
                  on:close={({ detail: data }) => closeModal('disableTarget', { action: 'disable-target', data })}
                />
              {/if}
              {#if modals.deleteTarget.open}
                <RemoveTargetModal
                  open
                  targetName={pageData.selectedTarget}
                  targetId={pageData.targets[pageData.selectedTarget].id}
                  isDefault={!!pageData.targets[pageData.selectedTarget].isDefault}
                  hasParameters={pageData.targets[pageData.selectedTarget]?.data?.length}
                  on:close={({ detail: data }) => closeModal('deleteTarget', { action: 'delete-target', data })}
                />
              {/if}
              {#if modals.build.open}
                <RunBuildModal
                  open
                  configurations={[
                    {
                      configId: pageData.targets[pageData.selectedTarget].id,
                      targetName: pageData.selectedTarget,
                      isDefault: !!pageData.targets[pageData.selectedTarget].isDefault,
                      entityType: 'group',
                      entityName: groupName,
                      entityId: groupId,
                    },
                  ]}
                  on:close={({ detail: data }) =>
                    closeModal('build', {
                      action: 'build-result',
                      data,
                    })}
                />
              {/if}
            {/if}
          </div>
          {#if pageData.selectedEntity !== 'target'}
            <div class="attached-notification">
              <InlineNotification lowContrast hideCloseButton kind="warning" subtitle={PARENT_TARGETS[pageData.selectedEntity].info} />
            </div>
          {/if}
          <BuildParameterDataTable
            entityType={tableData[pageData.selectedEntity].entityType}
            entityName={tableData[pageData.selectedEntity].entityName}
            entityId={tableData[pageData.selectedEntity].entityId}
            buildConfig={tableData[pageData.selectedEntity].buildConfig}
            loading={loaders.parameters}
            extraOptions={!Object.keys(PARENT_TARGETS).includes(pageData.selectedEntity)}
            activeNotifications={pageData.notifications}
            on:selectTarget={({ detail: targetId }) => findAndSelectTarget(targetId)}
            on:parameter={({ detail: data }) => updatePageData(data)}
            on:removeParameter={({ detail: data }) => updatePageData(data)}
            on:removeParameters={({ detail: data }) => updatePageData(data)}
            on:clone={({ detail: data }) => updatePageData(data)}
            on:jsonInput={({ detail: data }) => updatePageData(data)}
            on:build={({ detail: data }) => updatePageData(data)}
            on:notification={({ detail: data }) => handleNotificationChange(data)}
          />
          {#if modals.editTarget.open}
            <EditTargetModal
              open
              target={pageData.targets[pageData.selectedTarget]}
              on:close={({ detail: data }) => closeModal('editTarget', { action: 'target-rename', data })}
            />
          {/if}
          {#if modals.targetInfo.open}
            <TargetDetailsModal open target={pageData.targets[pageData.selectedTarget]} on:close={() => closeModal('targetInfo')} />
          {/if}
          {#if modals.notes.open}
            <BuildNotesModal
              open
              notes={pageData.targets[pageData.selectedTarget].buildConfigNotes}
              entityId={pageData.targets[pageData.selectedTarget].id}
              on:refresh={({ detail: data }) => updatePageData(data)}
              on:close={() => closeModal('notes')}
            />
          {/if}
        {/if}
      </div>
    </div>
  </LoadingSpinner>
{/if}

<style>
  .h2 {
    font-size: 1.5rem;
  }
  .vertical-tabs {
    display: flex;
    margin-top: 0.5rem;
  }

  .vertical-tabs :global(nav) {
    background-color: var(--cds-ui-background);
    min-width: 100px;
    position: relative;
    top: 0;
    z-index: 1;
  }

  .vertical-tabs :global(.bx--side-nav__item:not(.bx--side-nav__item--active) > .bx--side-nav__link:hover) {
    background-color: var(--cds-ui-01);
    cursor: pointer;
  }

  .vertical-tabs :global(.bx--side-nav__item:not(.bx--side-nav__item--active) > .bx--side-nav__link:hover > span) {
    color: var(--cds-text-01);
  }

  .vertical-tabs :global(.bx--side-nav__link-text) {
    color: var(--cds-text-02);
  }

  .vertical-tabs :global(.bx--side-nav__link--current) {
    background-color: var(--cds-ui-01);
  }

  .vertical-tabs :global(.bx--side-nav__link--current .bx--side-nav__link-text) {
    color: var(--cds-text-01);
  }

  .tabs-content {
    width: 100%;
    padding-left: 1rem;
  }

  .tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--cds-ui-04);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    min-height: 50px;
  }

  .with-notification {
    margin-bottom: 0;
  }

  .attached-notification :global(.bx--inline-notification) {
    margin-top: 0;
    border-left: unset;
    max-width: 100%;
    min-height: unset;
  }

  .attached-notification :global(.bx--inline-notification__details) {
    display: flex;
    align-items: center;
  }

  .attached-notification :global(.bx--inline-notification__icon) {
    display: none;
  }

  .attached-notification :global(.bx--inline-notification__text-wrapper) {
    padding: 0.5rem 0;
  }

  .attached-notification :global(.bx--inline-notification__title),
  .attached-notification :global(.bx--inline-notification__subtitle) {
    font-size: 0.8rem;
  }

  .attached-notification :global(.bx--inline-notification--low-contrast::before) {
    border-width: 0 1px 1px 1px;
  }

  .tab-info {
    display: flex;
    align-items: center;
  }

  .tab-info.scrollable {
    overflow-x: auto;
  }

  .tab-info :global(.narrow-btn) {
    padding-right: 0.1rem;
    padding-left: 0.1rem;
  }

  .tab-info :global(.narrow-btn.bx--btn svg > path) {
    fill: var(--cds-hover-primary-text) !important;
  }

  .tab-actions {
    display: flex;
  }

  .main-action {
    padding-left: 0.75rem;
    margin-left: 0.75rem;
    border-left: 1px solid var(--cds-ui-04);
  }

  :global(html[theme='g90']) .main-action :global(.bx--btn.bx--btn--primary:not(.bx--btn:disabled)) {
    background-color: var(--cds-inverse-support-02);
  }

  .main-action :global(.bx--btn.bx--btn--primary:not(.bx--btn:disabled)) {
    background-color: #24a148;
    /* use same transition effect from carbon button but animate "filter" instead of "background" */
    transition: filter 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9),
      border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  .main-action :global(.bx--btn.bx--btn--primary:hover:not(.bx--btn:disabled)) {
    /* carbon doesn't offer a darker green accent */
    filter: brightness(95%);
  }

  .tab-creation :global(.bx--form__helper-text) {
    animation: slideIn 0.3s forwards;
    transform: scaleY(0);
  }

  .inline-message {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  .inline-message > :global(p) {
    color: var(--cds-text-01);
    font-size: 0.875rem;
  }

  .inline-message > :global(svg) {
    fill: orange;
    margin-right: 0.2rem;
  }

  .tabs-action :global(.bx--side-nav__icon > svg) {
    fill: var(--cds-text-02);
  }

  .status {
    margin-right: auto;
  }

  @keyframes slideIn {
    100% {
      transform: scaleY(1);
      transform-origin: top;
    }
  }
</style>
