<script>
  import { InlineNotification } from 'carbon-components-svelte';

  import { permissionTypeDescription } from '../../../../../server/db/constants';

  import FeedInventoryTable from './FeedInventoryTable.svelte';
  import FeedPermissionsTable from './FeedPermissionsTable.svelte';
  import FeedPermissionsTableNotifications from './FeedPermissionsTableNotifications.svelte';
  import LoadingSpinner from '../../../../components/LoadingSpinner.svelte';

  export let groupId, serviceId, type;

  let visibleFeeds = [];
  let dbFeeds = [];
  let feedInventory = [];
  let feedPermissionsTableNotifications = {};
  let inventoryTableLoading = true;
  let permissionTableLoading = true;
  let fetchError = null;

  const permissionId = new URLSearchParams(location.search).get('permissionId');
</script>

{#if fetchError}
  <InlineNotification kind="error" lowContrast title="Error:" subtitle={fetchError} />
{:else}
  <LoadingSpinner loading={inventoryTableLoading || permissionTableLoading}>
    <h4 class="subpage-heading">{permissionTypeDescription[type]}</h4>
    <FeedPermissionsTableNotifications {feedPermissionsTableNotifications} />
    <FeedInventoryTable {serviceId} bind:feedInventory bind:fetchError bind:inventoryTableLoading bind:visibleFeeds />
    <FeedPermissionsTable
      {feedInventory}
      {groupId}
      {permissionId}
      {serviceId}
      bind:dbFeeds
      bind:feedPermissionsTableNotifications
      bind:fetchError
      bind:permissionTableLoading
      bind:visibleFeeds
    />
  </LoadingSpinner>
{/if}

<style>
  .subpage-heading {
    margin-bottom: 2rem;
  }
</style>
