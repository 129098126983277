<script>
  const ALT_SIZES = { small: 0.7 };
  const ACCEPTABLE_HORIZONTAL_PLACEMENT = ['right', 'left'];
  const ACCEPTABLE_VERTICAL_PLACEMENT = ['top', 'middle', 'bottom'];
  const DEFAULT_PLACEMENT = 'top-right';
  const OPPOSITE_SIDE = {
    right: 'left',
    left: 'right',
  };

  export let active = false,
    position = DEFAULT_PLACEMENT,
    size = 'normal',
    value = '';

  function findPlacement(placement) {
    const [vertical, horizontal] = placement.split('-');
    const [defaultVertical, defaultHorizontal] = DEFAULT_PLACEMENT.split('-');
    const finalVertical = ACCEPTABLE_VERTICAL_PLACEMENT.includes(vertical) ? vertical : defaultVertical;
    const finalHorizontal = ACCEPTABLE_HORIZONTAL_PLACEMENT.includes(horizontal) ? horizontal : defaultHorizontal;

    return [finalVertical, finalHorizontal];
  }

  $: [verticalPlacement, horizontalPlacement] = findPlacement(position);
  $: placementClasses = `${verticalPlacement} ${horizontalPlacement}`;
  $: classes = `badge ${placementClasses}${$$props.class ? ` ${$$props.class}` : ''}`;
  $: style = ALT_SIZES[size] ? `transform:scale(${ALT_SIZES[size]});transform-origin:${OPPOSITE_SIDE[horizontalPlacement]};` : '';
</script>

<div class="badge-wrapper">
  {#if active}
    <div class={classes} {...style ? { style } : {}}>
      <slot name="value">
        {value}
      </slot>
    </div>
  {/if}
  <slot />
</div>

<style>
  .badge-wrapper {
    position: relative;
  }

  .badge {
    --badge-size: 1.1rem;

    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: var(--cds-tag-background-red);
    color: var(--cds-tag-color-red);
    border-radius: 50%;
    width: var(--badge-size);
    height: var(--badge-size);
    font-size: 0.625rem;
    user-select: none;
    pointer-events: none;
  }

  .top {
    top: 0;
  }

  .bottom {
    bottom: 0;
  }

  .right {
    right: -0.5rem;
  }

  .left {
    left: -0.5rem;
  }

  .middle {
    top: calc(50% - (var(--badge-size) / 2));
  }
</style>
