const { sortBy, uniq } = require('lodash');
const moment = require('moment');

const DAY_INTERVAL = 'days';
const MONTH_INTERVAL = 'months';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_MONTH_ONLY_FORMAT = 'YYYY-MM';

const DATE_FORMATS_TO_INTERVALS_MAP = {
  [DAY_INTERVAL]: DATE_FORMAT,
  [MONTH_INTERVAL]: DATE_MONTH_ONLY_FORMAT,
};

const toLocalHyphenatedDateString = (dateObject) => moment(dateObject).local().format(DATE_FORMAT);

const trimDate = (date, interval) => date.slice(0, interval === MONTH_INTERVAL ? 7 : 10);

const datesAreAdjacent = (date, comparedDate, interval) => {
  if (!date || !comparedDate) {
    return;
  }
  return Math.abs(moment(trimDate(date, interval)).diff(moment(trimDate(comparedDate, interval)), interval)) === 1;
};

const getFirstDayOfTheMonth = (monthDate) => {
  return `${trimDate(monthDate, MONTH_INTERVAL)}-01`;
};

const getLastDayOfTheMonth = (monthDate) => {
  return moment(getFirstDayOfTheMonth(monthDate)).endOf(MONTH_INTERVAL).format(DATE_FORMAT);
};

const getDateRangesFromDateList = (dates, interval) => {
  const sortedDates = sortBy(uniq(dates));
  return sortedDates.reduce((arr, curr, index) => {
    const nextDate = datesAreAdjacent(curr, sortedDates[index - 1], interval);
    if (nextDate) {
      // eslint-disable-next-line no-param-reassign
      arr[arr.length - 1] = { ...(arr[arr.length - 1] || {}), endDate: trimDate(curr) };
    } else {
      arr.push({ startDate: trimDate(curr, interval), endDate: trimDate(curr, interval) });
    }
    return arr;
  }, []);
};
const getDayRangesFromDaysList = (days) => getDateRangesFromDateList(days, DAY_INTERVAL);

const getMonthRangesFromMonthList = (months) => {
  return getDateRangesFromDateList(months, MONTH_INTERVAL).map(({ startDate, endDate }) => ({
    startDate: getFirstDayOfTheMonth(startDate),
    endDate: getLastDayOfTheMonth(endDate),
  }));
};

const listDatesFromDateRange = (startDate, endDate, interval) => {
  const addedDate = moment(trimDate(startDate, interval));
  const endDateObj = moment(trimDate(endDate, interval));

  const dates = [];
  while (addedDate <= endDateObj) {
    dates.push(addedDate.format(DATE_FORMATS_TO_INTERVALS_MAP[interval]));
    addedDate.add(1, interval);
  }
  return dates;
};

const listMonthsBetweenTwoDates = (startDate, endDate) => listDatesFromDateRange(startDate, endDate, MONTH_INTERVAL);
const listDaysBetweenTwoDates = (startDate, endDate) => listDatesFromDateRange(startDate, endDate, DAY_INTERVAL);

module.exports = {
  datesAreAdjacent,
  getDayRangesFromDaysList,
  getMonthRangesFromMonthList,
  getFirstDayOfTheMonth,
  getLastDayOfTheMonth,
  listDaysBetweenTwoDates,
  listMonthsBetweenTwoDates,
  toLocalHyphenatedDateString,
};
