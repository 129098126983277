<script>
  import { onMount } from 'svelte';
  import { Button, InlineNotification, Modal, TextArea } from 'carbon-components-svelte';
  import DownloadIcon from 'carbon-icons-svelte/lib/Download16';

  import LicenseInfoAccordion from './LicenseInfoAccordion.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import { downloadLicenseFile } from '../../utils/downloadFile';
  import { getAllFeaturesAndGroups } from '../../services';

  export let license,
    open = false;

  let features = [],
    loading = false,
    resultNotification = null;

  async function fetchFeatures() {
    loading = true;

    try {
      features = await getAllFeaturesAndGroups();
    } catch (error) {
      console.error('[PreviewGeneratedLicense] Failed to load dependencies..', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to load features! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  onMount(fetchFeatures);

  $: basicLicenseInfo = {
    name: `${license.licenseTemplate.name} (${license.filename}.lic)`,
    cycle: license.cycle,
    diff: license.diff,
    hosts: license.hosts,
    uploadDestinations: license.uploadDestinations,
    explicitlySelectedFeatures: license.features,
    flatSelectedGroups: [],
  };
</script>

<Modal modalHeading="License Preview" passiveModal preventCloseOnClickOutside={true} bind:open on:close>
  <LoadingSpinner {loading}>
    {#if resultNotification}
      <InlineNotification
        hideCloseButton
        lowContrast
        kind={resultNotification.kind}
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <div class="preview">
      <Button icon={DownloadIcon} size="field" on:click={() => downloadLicenseFile(license.filename, license.file)}>Download</Button>
      <TextArea light disabled rows={20} labelText="License file" value={license.file} />
    </div>
    {#if features.length}
      <LicenseInfoAccordion license={basicLicenseInfo} {features} />
    {/if}
  </LoadingSpinner>
</Modal>

<style>
  .preview {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .preview :global(button) {
    justify-self: flex-end;
    margin-left: auto;
    top: 24px;
  }

  .preview :global(.bx--label),
  .preview :global(textarea) {
    color: unset;
  }
</style>
